import {selectClientId} from '../../../store/slices/user';
import {store} from '../../../store/store';
import {RoomUserFlag} from '../../../services/gRPC/rooms/enums_pb';
import {EMIT} from '../../../utils/utils';
import {mergeMap} from 'rxjs/operators';
import {selectUserFlags} from '../../../store/slices/roomUsers';
import {EMPTY, of, throwError} from 'rxjs';
import {flagSenderWS$} from '../../../services/ws/senders.ws';
import {roomGlobalRef} from './roomGlobalRef';

/**
 *  Based on current flags in store, send 2 packets, in order to reset subscriptions on other room users devices.
 *  First packet includes all client flags, excluding: RUFLAG_SHARING_AUDIO = 1 & RUFLAG_SHARING_VIDEO = 2.
 *  Second packet is sent after the first one is successful.
 *  Second packet includes all client flags.
 *
 *  Unsubscriptions of this is needed when:
 *   - new Sender reconnection starts
 *   - new WS reconnection starts
 *   - user left room
 *
 *  Running 2 of those observables at the same time is not recommended!
 *
 *  Cannot be used when client is not in a room.
 *
 *  If client doesn't have any flags the observable will be immediately completed.
 */
export const resendClientFlags$ = () => {
	const clientId = selectClientId(store.getState());
	const flagsToRemove = [RoomUserFlag.RUFLAG_SHARING_VIDEO, RoomUserFlag.RUFLAG_SHARING_AUDIO];
	// check if user is in room
	if (!roomGlobalRef.currentRoomPid) {
		return throwError(() => new Error('Client is not in room'));
	}
	return EMIT.pipe(
		mergeMap(() => {
			const clientFlags = selectUserFlags(clientId)(store.getState());
			if (!clientFlags) {
				return EMPTY;
			}
			const filteredClientFlagsList: RoomUserFlag[] = Object.keys(clientFlags).map(f => Number(f)).filter(f => !flagsToRemove.includes(f));
			return of(filteredClientFlagsList);
		}),
		mergeMap((filteredClientFlagsList) => flagSenderWS$(filteredClientFlagsList, Date.now())),
		mergeMap(() => {
			const clientFlags = selectUserFlags(clientId)(store.getState());
			if (!clientFlags) {
				return throwError(() => new Error('Somehow client doesnt have any flags here'));
			}
			const clientFlagsList: RoomUserFlag[] = Object.keys(clientFlags).map(f => Number(f));
			return of(clientFlagsList);
		}),
		mergeMap((clientFlagsList) => flagSenderWS$(clientFlagsList, Date.now())),
		mergeMap((packet) => {
			roomGlobalRef.shouldResendFlags = false;
			// check if user is in room
			if (!roomGlobalRef.currentRoomPid) {
				return throwError(() => new Error('Client is not in room'));
			}
			return of(packet);
		})
	);
}
