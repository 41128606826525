import {Observable, of, timer} from 'rxjs';
import {delay, map, mergeMap, tap} from 'rxjs/operators';
import {EMIT, emit} from '../../utils/utils';
import {setWebSocket$} from '../../services/ws/webSocket';
import {UserRole} from '../../services/roomServices';
import {refreshTokenResultsFirebase$} from '../../services/firebaseAuth';
import {clearRoomGlobalRef, roomGlobalRef} from '../Room/utils/roomGlobalRef';
import {closeRoomComponent, loadRoomComponent} from '../Room/PreRoomPage';
import {createGuestService$} from '../../services/userServices';
import {store} from '../../store/store';
import {selectClientRole, selectIsAuthNotFinished, userActions} from '../../store/slices/user';
import {ModalType} from '../../singleComponents/modals/modal.types';
import {modalsSystem} from '../../singleComponents/modals/modalSystem';
import {roomActions} from '../../store/slices/room';
import {roomUsersActions} from '../../store/slices/roomUsers';
import {loadUserDataIfCan$} from '../../utils/customHooks/useLoadLoggedUserData';
import {loadGuestData$} from '../../utils/customHooks/useLoadLoggedGuestData';
import {authToken} from '../../services/ws/senders.ws';

type ProcessType = 'signupStep2' | 'logged' | typeof emit

// Rare case occurs when user refreshes the app during setting up profile (profileSetUpModal$)
interface UseAuthProcessProps {
	forceAuthCompletion?: boolean;
	preventCoverClick?: boolean;
	startPosition?: 'LOGIN' | 'SIGNUP';
}

let loginModalState: { email?: string } | undefined;
let preventCoverClick: boolean;

const preReloadUserData$ = () => {
	const userIsInRoom = !!roomGlobalRef.currentRoomPid;
	if (userIsInRoom) {
		return EMIT.pipe(
			tap(() => closeRoomComponent.next(emit)),
			delay(1),
			tap(() => {
				clearRoomGlobalRef();
				store.dispatch(roomActions.clearSlice());
				store.dispatch(roomUsersActions.clearSlice());
			}),
			map(() => true)
		);
	}
	return of(false);
};

const reloadUserData$: (userWasInRoom: boolean) => Observable<typeof emit> = (userWasInRoom) => EMIT.pipe(
	tap(() => authToken.current = undefined),
	mergeMap(() => refreshTokenResultsFirebase$()),
	mergeMap((tokenResults) => {
		if (userWasInRoom) {
			return timer(0).pipe(
				tap(() => {
					store.dispatch(userActions.setUserFirebaseTokenResult({...tokenResults}));
				}),
				mergeMap(() => loadGuestData$()),
				mergeMap(() => loadUserDataIfCan$()),
				tap(() => {
					loadRoomComponent.next(emit);
				})
			);
		} else {
			return EMIT.pipe(
				tap(() => {
					store.dispatch(userActions.setUserFirebaseTokenResult({...tokenResults}));
				}),
				mergeMap(() => loadGuestData$()),
				mergeMap(() => loadUserDataIfCan$())
			);
		}
	}),
	map(() => emit)
);

const resetPasswordModal$: () => Observable<ProcessType> = () => modalsSystem(ModalType.RESET_PASSWORD, {
	...loginModalState,
	preventCoverClick
}).pipe(
	mergeMap(() => EMIT)
);

const loginModal$: () => Observable<ProcessType> = () => modalsSystem(ModalType.LOGIN, {...loginModalState, preventCoverClick}).pipe(
	tap(output => loginModalState = {email: output.email}),
	mergeMap(output => {
		switch (output.next) {
			case 'FIREBASE_SIGN_UP':
				return firebaseSignUpModal$();
			case 'RESET_PASSWORD':
				return resetPasswordModal$();
			default:
				return EMIT.pipe(
					mergeMap(() => refreshTokenResultsFirebase$()),
					mergeMap(tokenResults => {
						if (tokenResults.claims._user_id) {
							return EMIT.pipe(
								mergeMap(() => preReloadUserData$()),
								tap(() => {
									authToken.current = undefined;
									// @ts-ignore
									window.lastAuthPacket = undefined;
								}),
								mergeMap((userWasInRoom) => setWebSocket$().pipe(
									map(() => userWasInRoom)
								)),
								mergeMap((userWasInRoom) => reloadUserData$(userWasInRoom)),
								map(() => 'logged')
							);
						} else {
							return createGuestService$().pipe(
								tap(() => refreshTokenResultsFirebase$()),
								mergeMap(() => profileSetUpModal$().pipe(
									map(() => tokenResults)
								))
							);
						}
					})
				) as Observable<ProcessType>;
		}
	})
);

const firebaseSignUpModal$: () => Observable<ProcessType> = () => modalsSystem(ModalType.FIREBASE_SIGN_UP, {
	...loginModalState,
	preventCoverClick
}).pipe(
	mergeMap((output) => {
		if (output.next === 'LOGIN') {
			return loginModal$()
		} else if (output.next === "PROFILE_SET_UP") {
			return profileSetUpModal$()
		} else {
			return EMIT.pipe(
				mergeMap(() => loadUser()),
			)
		}
	})
);

const profileSetUpModal$: () => Observable<ProcessType> = () => modalsSystem(ModalType.PROFILE_SET_UP).pipe(
	mergeMap(() => loadUser()),
	map(() => 'signupStep2')
);

const loadUser = () => EMIT.pipe(
	mergeMap(() => preReloadUserData$()),
	tap(() => {
		authToken.current = undefined;
		// @ts-ignore
		window.lastAuthPacket = undefined;
	}),
		mergeMap((userWasInRoom) => setWebSocket$().pipe(
			map(() => userWasInRoom)
		)),
		mergeMap((userWasInRoom) => reloadUserData$(userWasInRoom)),
)

const authSelectModal$ = () => modalsSystem(ModalType.AUTH_SELECT, {preventCoverClick}).pipe(
	mergeMap(output => output.next === 'LOGIN' ? loginModal$() : firebaseSignUpModal$())
);

export const authProcess$ = (useAuthProcessProps?: UseAuthProcessProps) => of(selectClientRole(store.getState()) === UserRole.GUEST).pipe(
	mergeMap((isGuest) => {
		const showCase = useAuthProcessProps?.forceAuthCompletion;
		const startPosition = useAuthProcessProps?.startPosition;
		preventCoverClick = !!useAuthProcessProps?.preventCoverClick;

		if (startPosition === 'LOGIN') {
			return loginModal$();
		}
		if (startPosition === 'SIGNUP') {
			return firebaseSignUpModal$();
		}
		if (showCase && selectIsAuthNotFinished(store.getState())) {
			return profileSetUpModal$();
		}

		if (!showCase && isGuest) {
			return authSelectModal$();
		}
		return EMIT;
	})
);
