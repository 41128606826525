import {
	ActiveStreamsResponsePacket,
	DisconnectedPacket,
	LocalInputReadyPacket,
	MigrationRequiredPacket,
	NoDataPacket,
	ServerPacket,
	StreamsAnswerPacket,
	SubscriptionRTTPacket,
	VideoCodecChangeRequiredPacket
} from '../gRPC/sfu/packets_pb';
import {EMPTY, Observable, of} from 'rxjs';
import {packetSfuGuarder, SfuMessageType} from './incomingMessagesTypes.sfu';
import {LayoutPacket} from '../gRPC/mcu/packets_pb';
import {Layout} from '../gRPC/mcu/models_pb';
import {LayoutNames} from '../../pages/Room/utils/listeners/initPcSenderChannelListeners';

export type SfuOutputOverload = {
	(param: SfuMessageType.LAYOUT): Observable<{ layout: Omit<Layout.AsObject, 'name'> & { name: LayoutNames } }>;
	(param: SfuMessageType.LOCAL_INPUT_READY): Observable<LocalInputReadyPacket.AsObject>;
	(param: SfuMessageType.NO_DATA_PACKET): Observable<NoDataPacket.AsObject>;
	(param: SfuMessageType.STREAMS_ANSWER_PACKET): Observable<StreamsAnswerPacket.AsObject>;
	(param: SfuMessageType.SUBSCRIPTION_RTT): Observable<SubscriptionRTTPacket.AsObject>;
	(param: SfuMessageType.VIDEO_CODEC_CHANGE_REQUIRED): Observable<VideoCodecChangeRequiredPacket.AsObject>;
	(param: SfuMessageType.MIGRATION_REQUIRED): Observable<MigrationRequiredPacket.AsObject>;
	(param: SfuMessageType.DISCONNECTED): Observable<DisconnectedPacket.AsObject>;
	(param: SfuMessageType.ACTIVE_STREAMS): Observable<ActiveStreamsResponsePacket.AsObject>
}

export const sfuSorter$ = (serverPacket: ServerPacket.AsObject, messageType: SfuMessageType) => {

	switch (messageType) {

		case SfuMessageType.LAYOUT: {
			if (packetSfuGuarder(SfuMessageType.LAYOUT, serverPacket)) {
				return of(LayoutPacket.deserializeBinary(serverPacket.data).toObject());
			} else {
				return EMPTY;
			}
		}

		case SfuMessageType.LOCAL_INPUT_READY: {
			if (packetSfuGuarder(SfuMessageType.LOCAL_INPUT_READY, serverPacket)) {
				return of(LocalInputReadyPacket.deserializeBinary(serverPacket.data).toObject());
			} else {
				return EMPTY;
			}
		}

		case SfuMessageType.NO_DATA_PACKET: {
			if (packetSfuGuarder(SfuMessageType.NO_DATA_PACKET, serverPacket)) {
				return of(NoDataPacket.deserializeBinary(serverPacket.data).toObject());
			} else {
				return EMPTY;
			}
		}

		case SfuMessageType.STREAMS_ANSWER_PACKET: {
			if (packetSfuGuarder(SfuMessageType.STREAMS_ANSWER_PACKET, serverPacket)) {
				return of(StreamsAnswerPacket.deserializeBinary(serverPacket.data).toObject());
			} else {
				return EMPTY;
			}
		}

		case SfuMessageType.SUBSCRIPTION_RTT: {
			if (packetSfuGuarder(SfuMessageType.SUBSCRIPTION_RTT, serverPacket)) {
				return of(SubscriptionRTTPacket.deserializeBinary(serverPacket.data).toObject());
			} else {
				return EMPTY;
			}
		}

		case SfuMessageType.VIDEO_CODEC_CHANGE_REQUIRED: {
			if (packetSfuGuarder(SfuMessageType.VIDEO_CODEC_CHANGE_REQUIRED, serverPacket)) {
				return of(VideoCodecChangeRequiredPacket.deserializeBinary(serverPacket.data).toObject());
			} else {
				return EMPTY;
			}
		}

		case SfuMessageType.MIGRATION_REQUIRED: {
			if (packetSfuGuarder(SfuMessageType.MIGRATION_REQUIRED, serverPacket)) {
				return of(MigrationRequiredPacket.deserializeBinary(serverPacket.data).toObject());
			} else {
				return EMPTY;
			}
		}

		case SfuMessageType.DISCONNECTED: {
			if (packetSfuGuarder(SfuMessageType.DISCONNECTED, serverPacket)) {
				return of(DisconnectedPacket.deserializeBinary(serverPacket.data).toObject());
			} else {
				return EMPTY;
			}
		}

		case SfuMessageType.ACTIVE_STREAMS: {
			if (packetSfuGuarder(SfuMessageType.ACTIVE_STREAMS, serverPacket)) {
				return of(ActiveStreamsResponsePacket.deserializeBinary(serverPacket.data).toObject());
			} else {
				return EMPTY;
			}
		}

		default: {
			console.log(`%cUnknown message from SFU (Sender)`, 'color: red; font-weight: 900; background: black');
			return EMPTY;
		}

	}

};
