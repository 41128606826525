import styled from 'styled-components';
import {remm} from '../../utils/remm';

export const LabelS = styled.label`
	display: flex;
	margin: 0 8px 2px 12px;
	color: ${({theme}) => theme.labelColor};
	font-size: ${remm('14px')};
	letter-spacing: normal;
	font-weight: ${({theme}) => theme.dark ? `600` : `700`};

	i {
		cursor: pointer;
		font-size: ${remm(12)};
	}

`;
