import {StreamType} from '../../../services/gRPC/sfu/enums_pb';
import {RoomUserFlag} from '../../../services/gRPC/rooms/enums_pb';
import {selectClientId} from '../../../store/slices/user';
import {store} from '../../../store/store';
import {applyFlag, dispatchFlag} from './updaters/dispatchFlag';
import {roomGlobalRef, RoomMode} from './roomGlobalRef';
import {SubscriptionManager} from './SubscriptionManager';

// This queue saves only flags updates incoming from WS
// The queue is blocked while waiting for server response (with info about roomUsers (getRoomStateService$))
// After response is ready, and it is applied to the state the queue is opened to apply the WS data about flags.

export class FlagsQueue {

	private isOpen = false;

	private flagsUpdatesStore: Map<string, {timestamp: number, flags: RoomUserFlag[]}> = new Map();

	/// force parameter is used to skip debounce time before applying flags update
	applyFlagsUpdate(flags: RoomUserFlag[], userId: string, timestamp: number,) {

		if (selectClientId(store.getState()) !== userId) {
			if (this.isOpen) {
				this.process(flags, userId);
			} else {
				this.flagsUpdatesStore.set(userId, {timestamp, flags});
			}
		}
	}

	private process(flags: RoomUserFlag[], userId: string) {
		if (selectClientId(store.getState()) !== userId) {
			const isDisabledAudioFlag = !!flags.find(flag => flag === RoomUserFlag.RUFLAG_DISABLED_AUDIO);
			const isDisabledCameraFlag = !!flags.find(flag => flag === RoomUserFlag.RUFLAG_DISABLED_VIDEO);
			const isSharingAudioFlag = !!flags.find(flag => flag === RoomUserFlag.RUFLAG_SHARING_AUDIO);
			const isSharingCameraFlag = !!flags.find(flag => flag === RoomUserFlag.RUFLAG_SHARING_VIDEO);
			const isSharingScreenFlag = !!flags.find(flag => flag === RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO);
			const isSharingScreenAudioFlag = !!flags.find(flag => flag === RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO);

			if(!isSharingCameraFlag || !isSharingAudioFlag) { // apply on clear or resend flags
				SubscriptionManager.applyNextImmediately = true;
			}


			dispatchFlag(RoomUserFlag.RUFLAG_DISABLED_AUDIO, isDisabledAudioFlag, userId, 1);
			applyFlag(RoomUserFlag.RUFLAG_DISABLED_AUDIO, isDisabledAudioFlag, userId, 1);
			dispatchFlag(RoomUserFlag.RUFLAG_DISABLED_VIDEO, isDisabledCameraFlag, userId, 1);
			applyFlag(RoomUserFlag.RUFLAG_DISABLED_VIDEO, isDisabledCameraFlag, userId, 1);

			console.log(userId, `isSharingCameraFlag: ${isSharingCameraFlag}`, `isSharingAudioFlag: ${isSharingAudioFlag}`)

			if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
				isSharingAudioFlag ?
					roomGlobalRef.currentSubscriptionState.sub([StreamType.AUDIO], userId) :
					roomGlobalRef.currentSubscriptionState.unsub([StreamType.AUDIO], userId);
				isSharingCameraFlag ?
					roomGlobalRef.currentSubscriptionState.sub([StreamType.CAMERA], userId) :
					roomGlobalRef.currentSubscriptionState.unsub([StreamType.CAMERA], userId);
				isSharingScreenFlag ?
					roomGlobalRef.currentSubscriptionState.sub([StreamType.SCREEN], userId) :
					roomGlobalRef.currentSubscriptionState.unsub([StreamType.SCREEN], userId);
				isSharingScreenAudioFlag ?
					roomGlobalRef.currentSubscriptionState.sub([StreamType.SCREEN_AUDIO], userId) :
					roomGlobalRef.currentSubscriptionState.unsub([StreamType.SCREEN_AUDIO], userId);
				roomGlobalRef.subscriptionManager!.applySubs();

				dispatchFlag(RoomUserFlag.RUFLAG_SHARING_AUDIO, isSharingAudioFlag, userId, 1);
				dispatchFlag(RoomUserFlag.RUFLAG_SHARING_VIDEO, isSharingCameraFlag, userId, 1);
				dispatchFlag(RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO, isSharingScreenAudioFlag, userId, 1);
				dispatchFlag(RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO, isSharingScreenFlag, userId, 1);
			} else {
				dispatchFlag(RoomUserFlag.RUFLAG_SHARING_AUDIO, isSharingAudioFlag, userId, 1);
				applyFlag(RoomUserFlag.RUFLAG_SHARING_AUDIO, isSharingAudioFlag, userId, 1);
				dispatchFlag(RoomUserFlag.RUFLAG_SHARING_VIDEO, isSharingCameraFlag, userId, 1);
				applyFlag(RoomUserFlag.RUFLAG_SHARING_VIDEO, isSharingCameraFlag, userId, 1);
				dispatchFlag(RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO, isSharingScreenAudioFlag, userId, 1);
				applyFlag(RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO, isSharingScreenAudioFlag, userId, 1);
				dispatchFlag(RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO, isSharingScreenFlag, userId, 1);
				applyFlag(RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO, isSharingScreenFlag, userId, 1);
			}
		}
	}

	open(date: number) {
		this.isOpen = true;
		this.flagsUpdatesStore.forEach(({flags, timestamp}, userId) => {
			console.log(userId, flags, timestamp);
			if(timestamp > date) {
				this.process(flags, userId);
			} else {
				console.log(date, 'OLD PACKET: ', userId, timestamp, flags);
			}
		});
		this.flagsUpdatesStore.clear();
	}

	close() {
		this.isOpen = false;
	}

}
