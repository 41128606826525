import styled from 'styled-components';
import {ToastContainer} from 'react-toastify';
import {remm} from './remm';

export const NotificationsContainer = styled(ToastContainer)`
	@media only screen and (max-width: 480px) {
		width: 330px;
		padding: 0px;
		left: auto;
		margin: 2px;
	}
	
	.Toastify__toast {
		font-size: ${remm('14px')};
		width: 320px;
		font-weight: 600;
		margin: 5px -10px 0px auto;
		padding: 8px;
		top: 0;
		border-radius: 20px;
	}

	.Toastify__toast-body {
		width: 100%;
		margin: 0;
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Toastify__close-button {
		position: absolute;
		right: 8px;
		top: 8px;
		color: ${({theme}) => theme.weakTextColor};
		opacity: 1;
		:hover {
			color: ${({theme}) => theme.textColor};
		}
	}

	.Toastify__toast--error {
		background: ${({theme}) => theme.color3};
		color: ${({theme}) => theme.textColor};
	}

	.Toastify__toast--warning {
		background: ${({theme}) => theme.color3};
		color: ${({theme}) => theme.textColor};
	}

	.Toastify__toast--info {
		background: ${({theme}) => theme.color3};
		color: ${({theme}) => theme.textColor};
	}

	.Toastify__toast--success {
		background: ${({theme}) => theme.color3};
		color: ${({theme}) => theme.textColor};
	}

`;
