import {Overwrite} from '../../utils/utils';
import {
	AuthenticationPacket,
	DisconnectRoomUserPacket,
	DisconnectSessionPacket,
	DisconnectUserPacket,
	EnableMaintenanceBreakPacket,
	FailurePacket,
	HeartbeatPacket,
	Packet,
	RefreshPacket,
	RemindMaintenanceBreakPacket,
	SuccessPacket
} from '../gRPC/wsserver/packets.public_pb';
import {Observable} from 'rxjs';
// import {UserChatMessagePacket} from '../gRPC/ws-users-api/packets_pb';
import {EventChatMessagePacket, RoomChatMessagePacket, UserChatMessagePacket} from '../gRPC/wsgateway/packets.public_pb';
import {
	CreateUserRelationPacket,
	DeleteChatMessagePacket as DeleteUserChatMessagePacket,
	DeleteUserRelationPacket,
	RefreshUserTokenPacket,
	UpdateUserAvatarPacket,
	UpdateUserNamePacket,
	UpdateUserStatusPacket
} from '../gRPC/users/packets.public_pb';
import {
	CancelEventPacket,
	ClearChatMessagesPacket,
	ClearRoomStreamsPacket,
	CreateEventObserverPacket,
	CreateEventPerformerPacket,
	CreateRoomStreamPacket,
	CreateUserTicketPacket,
	DeleteChatMessagePacket as DeleteRoomChatMessagePacket,
	DeleteEventObserverPacket,
	DeleteEventPerformerPacket,
	DeleteRoomPacket,
	DeleteRoomStreamPacket,
	DeliverRoomInvitePacket,
	FinishEventPacket,
	PrepareEventPerformerPacket,
	RefreshEventBroadcastPacket,
	StartEventPacket,
	UpdateChatMessageContentPacket,
	UpdateChatMessageContentPacket as UpdateRoomChatMessageContentPacket,
	UpdateChatStatusPacket,
	UpdateEventAudiencePacket,
	UpdateEventNamePacket,
	UpdateEventPerformerPositionPacket,
	UpdateEventStatusPacket,
	UpdateRoomBackgroundPacket,
	UpdateRoomNamePacket,
	UpdateRoomPidPacket,
	UpdateRoomStreamMetadataOffsetPacket,
	UpdateRoomStreamMetadataPausedPacket,
	UpdateRoomStreamMetadataRelatedPacket,
	UpdateRoomStreamMetadataStackPacket,
	UpdateRoomStreamMetadataSuggestPacket,
	UpdateRoomStreamPositionPacket,
	UpdateRoomTypePacket,
	UpdateRoomUserFlagsPacket,
	UpdateRoomUserReceiverConnectionStatusPacket,
	UpdateRoomUserRolePacket,
	UpdateRoomUserSenderConnectionStatusPacket,
	UpdateRoomUserStatusPacket
} from '../gRPC/rooms/packets.public_pb';
import {IceCandidatePacket, OfferPacket, StreamsAnswerPacket} from '../gRPC/sfu/packets_pb';
import {UserTicketInfo} from '../gRPC/rooms/models_pb';


///
/// Frontend-side ChatMessageType Guard
///
// export interface ChatMessageInputWS {
// 	frontend_id: string
// 	content: string
// 	createdAt: number
// 	userId?: string,
// 	id?: string,
// }

// const likeChatMessageInputWSObject: ChatMessageInputWS = {
// 	frontend_id: '',
// 	content: '',
// 	createdAt: 0,
// 	userId: '',
// 	id: ''
// };
//
// const likeChatMessageInfoUserObject: ChatMessageInfoUser.AsObject = {
// 	chatId: '',
// 	content: '',
// 	createdAt: 0,
// 	id: '',
// 	updatedAt: 0,
// 	userId: '',
// 	name: '',
// 	role: 0,
// 	avatarUrl: ''
// };
//
// const likeChatMessageInfoRoomObject: ChatMessageInfoRoom.AsObject = {
// 	chatId: '',
// 	content: '',
// 	createdAt: 0,
// 	id: '',
// 	updatedAt: 0,
// 	avatarUrl: '',
// 	role: 0,
// 	userId: '',
// 	name: ''
// };

// export enum ChatMessageType {
// 	ROOM,
// 	PRIVATE,
// 	FRONTEND
// }

// type IncomingToGuardMessageType = ChatMessageInfoRoom.AsObject | ChatMessageInfoUser.AsObject | ChatMessageInputWS;

// type ChatMessageTypeGuardOverload = {
// 	(param: ChatMessageType.PRIVATE, message: IncomingToGuardMessageType): message is ChatMessageInfoUser.AsObject;
// 	(param: ChatMessageType.ROOM, message: IncomingToGuardMessageType): message is ChatMessageInfoRoom.AsObject;
// 	(param: ChatMessageType.FRONTEND, message: IncomingToGuardMessageType): message is ChatMessageInputWS;
// }

// export const chatMessageTypeGuard = (((expectedMessage: ChatMessageType, message: IncomingToGuardMessageType) => {
// 	if (expectedMessage === ChatMessageType.FRONTEND) {
// 		if (checkType.like<ChatMessageInputWS>(message, likeChatMessageInputWSObject)) {
// 			return true;
// 		}
// 	} else if (expectedMessage === ChatMessageType.ROOM) {
// 		if (checkType.like<ChatMessageInfoRoom.AsObject>(message, likeChatMessageInfoRoomObject)) {
// 			return true;
// 		}
// 	}
// 	//
// 	else if (expectedMessage === ChatMessageType.PRIVATE) {
// 		if (checkType.like<ChatMessageInfoUser.AsObject>(message, likeChatMessageInfoUserObject)) {
// 			return true;
// 		}
// 	}
// 	return false;
// }) as ChatMessageTypeGuardOverload);
///
/// END Frontend-side ChatMessageType Guard
///

//
// ChatMessageInfo
//

// const likeChatMessageInfoObject = {
// 	chatId: '',
// 	content: '',
// 	createdAt: 0,
// 	id: '',
// 	updatedAt: 0,
// 	userAvatarUrl: '',
// 	userGlobalRole: 0,
// 	userId: '',
// 	userName: '',
// 	userRoomRole: 0
// };

// export const likeChatMessageInfoGuard = (obj => {
// 	return checkType.like(obj, likeChatMessageInfoObject);
// }) as (obj: any) => obj is ChatMessageInfoRoom.AsObject;

// export const likePrivateChatMessageInfoGuard = (obj => {
// 	return checkType.like(obj, likeChatMessageInfoUserObject);
// }) as (obj: any) => obj is ChatMessageInfo.AsObject;

//
// chat message
//

// export interface ChatMessageOutputWS {
// 	frontend_id: number
// 	chat_message_info: ChatMessageInfo.AsObject
// }

// *

// export const likeChatMessageInputWSGuard = (obj => {
// 	return checkType.like<ChatMessageInputWS>(obj, likeChatMessageInputWSObject);
// }) as (obj: any) => obj is ChatMessageInputWS;

// *

// export type PrivateAndRoomChatMessagePacketAsObject = Overwrite<RoomChatMessagePacket.AsObject | UserChatMessagePacket.AsObject,
// 	{ message: ChatMessageInfo.AsObject }>


// const likePrivateAndRoomChatMessagePacketAsObject: PrivateAndRoomChatMessagePacketAsObject = {
// 	message: {
// 		chatId: '',
// 		content: '',
// 		createdAt: 0,
// 		id: '',
// 		updatedAt: 0,
// 		userId: '',
// 		name: '',
// 		role: 0,
// 		avatarUrl: ''
// 	},
// 	timestamp: 0
// };

// export const likePrivateAndRoomChatMessagePacketInputWSGuard = (obj => {
// 	return checkType.like<RoomChatMessagePacket.AsObject>(obj, likePrivateAndRoomChatMessagePacketAsObject);
// }) as (obj: any) => obj is PrivateAndRoomChatMessagePacketAsObject;

// export type PrivateAndRoomChatMessageAsObject = ChatMessageInfo.AsObject

// const likePrivateAndRoomChatMessageAsObject: PrivateAndRoomChatMessageAsObject = {
// 	chatId: '',
// 	content: '',
// 	createdAt: 0,
// 	id: '',
// 	updatedAt: 0,
// 	userId: '',
// 	name: '',
// 	role: 0,
// 	avatarUrl: ''
// };

// export const likePrivateAndRoomChatMessageInputWSGuard = (obj => {
// 	return checkType.like<PrivateAndRoomChatMessageAsObject>(obj, likePrivateAndRoomChatMessageAsObject);
// }) as (obj: any) => obj is PrivateAndRoomChatMessageAsObject;
// *

//
// chat message delete
//

// export interface ChatMessageDeleteInputOutputWS {
// 	chatId: string
// 	messageId: string
// }

//
// chat message edit
//


// export interface ChatMessageEditInputWS {
// 	message: ChatMessageInfo.AsObject
// }
//
// export interface ChatMessageEditOutputWS {
// 	chat_message_info: ChatMessageInfo.AsObject
// }

// TODO Everything above is for testing - Remove it.

///
/// Message Types
///
/// * not related with backend stuff
/// * names based on beginning of Ws guards names
/// * arrangement based on Ws guards
///
export enum WsMessageType {
	AUTHENTICATION,
	SUCCESS,
	FAILURE,
	REFRESH_WEBAPP,
	REMIND_MAINTENANCE_BREAK,
	ENABLE_MAINTENANCE_BREAK,
	REFRESH_TOKEN,
	PRIVATE_CHAT_MESSAGE,
	OFFER,
	STREAMS_ANSWER,
	UPDATE_PRIVATE_CHAT_MESSAGE_CONTENT,
	DELETE_PRIVATE_CHAT_MESSAGE,
	UPDATE_ROOM_NAME,
	UPDATE_ROOM_TYPE,
	UPDATE_ROOM_BACKGROUND,
	CREATE_EVENT_OBSERVER,
	DELETE_EVENT_OBSERVER,
	UPDATE_EVENT_NAME,
	UPDATE_EVENT_STATUS,
	PREPARE_EVENT_PERFORMER,
	CREATE_EVENT_PERFORMER,
	DELETE_EVENT_PERFORMER,
	UPDATE_EVENT_PERFORMER_POSITION,
	START_EVENT,
	FINISH_EVENT,
	CANCEL_EVENT,
	UPDATE_ROOM_USER_ROLE,
	UPDATE_ROOM_USER_FLAGS,
	ROOM_CHAT_MESSAGE,
	EVENT_CHAT_MESSAGE,
	UPDATE_ROOM_CHAT_MESSAGE_CONTENT,
	DELETE_ROOM_CHAT_MESSAGE,
	CREATE_USER_RELATION,
	DELETE_USER_RELATION,
	UPDATE_USER_STATUS,
	UPDATE_USER_AVATAR,
	UPDATE_USER_NAME,
	UPDATE_CHAT_STATUS,
	CLEAR_CHAT,
	UPDATE_EVENT_AUDIENCE,
	REFRESH_EVENT_BROADCAST,
	PAUSE_ROOM_STREAM,
	CHANGE_OFFSET_ROOM_STREAM,
	CREATE_ROOM_STREAM,
	DELETE_ROOM_STREAM,
	UPDATE_ROOM_USER_STATUS,
	UPDATE_ROOM_PID,
	DELETE_ROOM,
	CLEAR_ROOM_STREAMS,
	UPDATE_ROOM_STREAM_POSITON,
	DELIVER_ROOM_INVITE,
	DISCONNECT_SESSION,
	DISCONNECT_ROOM_USER,
	DISCONNECT_USER,
	CREATE_USER_TICKET,
	UPDATE_ROOM_USER_RECEIVER_CONNECTION_STATUS,
	UPDATE_ROOM_USER_SENDER_CONNECTION_STATUS,
	ICE_CANDIDATE,
	HEARTBEAT,
	SUGGEST_ROOM_STREAMS,
	RELATED_ROOM_STREAMS,
	STACK_ROOM_STREAMS
}

export type WebSocketOutputOverload = {
	(param: WsMessageType.AUTHENTICATION): Observable<AuthenticationPacket.AsObject>;
	(param: WsMessageType.SUCCESS): Observable<SuccessPacket.AsObject>;
	(param: WsMessageType.FAILURE): Observable<FailurePacket.AsObject>;
	(param: WsMessageType.REFRESH_WEBAPP): Observable<RefreshPacket.AsObject>;
	(param: WsMessageType.REMIND_MAINTENANCE_BREAK): Observable<RemindMaintenanceBreakPacket.AsObject>;
	(param: WsMessageType.ENABLE_MAINTENANCE_BREAK): Observable<EnableMaintenanceBreakPacket.AsObject>;
	(param: WsMessageType.REFRESH_TOKEN): Observable<RefreshUserTokenPacket.AsObject>;
	(param: WsMessageType.PRIVATE_CHAT_MESSAGE): Observable<UserChatMessagePacket.AsObject>;
	(param: WsMessageType.OFFER): Observable<OfferPacket.AsObject>;
	(param: WsMessageType.STREAMS_ANSWER): Observable<StreamsAnswerPacket.AsObject>;
	(param: WsMessageType.UPDATE_PRIVATE_CHAT_MESSAGE_CONTENT): Observable<UpdateChatMessageContentPacket.AsObject>;
	(param: WsMessageType.DELETE_PRIVATE_CHAT_MESSAGE): Observable<DeleteUserChatMessagePacket.AsObject>;
	(param: WsMessageType.UPDATE_ROOM_NAME): Observable<UpdateRoomNamePacket.AsObject>;
	(param: WsMessageType.UPDATE_ROOM_BACKGROUND): Observable<UpdateRoomBackgroundPacket.AsObject>;
	(param: WsMessageType.UPDATE_ROOM_TYPE): Observable<UpdateRoomTypePacket.AsObject>;
	(param: WsMessageType.CREATE_EVENT_OBSERVER): Observable<CreateEventObserverPacket.AsObject>;
	(param: WsMessageType.DELETE_EVENT_OBSERVER): Observable<DeleteEventObserverPacket.AsObject>;
	(param: WsMessageType.UPDATE_EVENT_NAME): Observable<UpdateEventNamePacket.AsObject>;
	(param: WsMessageType.UPDATE_EVENT_STATUS): Observable<UpdateEventStatusPacket.AsObject>;
	(param: WsMessageType.PREPARE_EVENT_PERFORMER): Observable<PrepareEventPerformerPacket.AsObject>;
	(param: WsMessageType.CREATE_EVENT_PERFORMER): Observable<CreateEventPerformerPacket.AsObject>;
	(param: WsMessageType.DELETE_EVENT_PERFORMER): Observable<DeleteEventPerformerPacket.AsObject>;
	(param: WsMessageType.UPDATE_EVENT_PERFORMER_POSITION): Observable<UpdateEventPerformerPositionPacket.AsObject>;
	(param: WsMessageType.START_EVENT): Observable<StartEventPacket.AsObject>;
	(param: WsMessageType.FINISH_EVENT): Observable<FinishEventPacket.AsObject>;
	(param: WsMessageType.CANCEL_EVENT): Observable<CancelEventPacket.AsObject>;
	(param: WsMessageType.UPDATE_ROOM_USER_ROLE): Observable<UpdateRoomUserRolePacket.AsObject>;
	(param: WsMessageType.UPDATE_ROOM_USER_FLAGS): Observable<UpdateRoomUserFlagsPacket.AsObject>;
	(param: WsMessageType.ROOM_CHAT_MESSAGE): Observable<RoomChatMessagePacket.AsObject>;
	(param: WsMessageType.EVENT_CHAT_MESSAGE): Observable<EventChatMessagePacket.AsObject>;
	(param: WsMessageType.UPDATE_ROOM_CHAT_MESSAGE_CONTENT): Observable<UpdateRoomChatMessageContentPacket.AsObject>;
	(param: WsMessageType.DELETE_ROOM_CHAT_MESSAGE): Observable<DeleteRoomChatMessagePacket.AsObject>;
	(param: WsMessageType.CREATE_USER_RELATION): Observable<CreateUserRelationPacket.AsObject>;
	(param: WsMessageType.DELETE_USER_RELATION): Observable<DeleteUserRelationPacket.AsObject>;
	(param: WsMessageType.UPDATE_USER_STATUS): Observable<UpdateUserStatusPacket.AsObject>;
	(param: WsMessageType.UPDATE_USER_AVATAR): Observable<UpdateUserAvatarPacket.AsObject>;
	(param: WsMessageType.UPDATE_USER_NAME): Observable<UpdateUserNamePacket.AsObject>;
	(param: WsMessageType.UPDATE_CHAT_STATUS): Observable<UpdateChatStatusPacket.AsObject>;
	(param: WsMessageType.CLEAR_CHAT): Observable<ClearChatMessagesPacket.AsObject>;
	(param: WsMessageType.UPDATE_EVENT_AUDIENCE): Observable<UpdateEventAudiencePacket.AsObject>;
	(param: WsMessageType.REFRESH_EVENT_BROADCAST): Observable<RefreshEventBroadcastPacket.AsObject>;
	(param: WsMessageType.PAUSE_ROOM_STREAM): Observable<UpdateRoomStreamMetadataPausedPacket.AsObject>;
	(param: WsMessageType.CHANGE_OFFSET_ROOM_STREAM): Observable<UpdateRoomStreamMetadataOffsetPacket.AsObject>;
	(param: WsMessageType.CREATE_ROOM_STREAM): Observable<CreateRoomStreamPacket.AsObject>
	(param: WsMessageType.DELETE_ROOM_STREAM): Observable<DeleteRoomStreamPacket.AsObject>
	(param: WsMessageType.UPDATE_ROOM_USER_STATUS): Observable<UpdateRoomUserStatusPacket.AsObject>
	(param: WsMessageType.UPDATE_ROOM_PID): Observable<UpdateRoomPidPacket.AsObject>
	(param: WsMessageType.DELETE_ROOM): Observable<DeleteRoomPacket.AsObject>
	(param: WsMessageType.CLEAR_ROOM_STREAMS): Observable<ClearRoomStreamsPacket.AsObject>
	(param: WsMessageType.DELIVER_ROOM_INVITE): Observable<DeliverRoomInvitePacket.AsObject>
	(param: WsMessageType.UPDATE_ROOM_STREAM_POSITON): Observable<UpdateRoomStreamPositionPacket.AsObject>
	(param: WsMessageType.DISCONNECT_SESSION): Observable<DisconnectSessionPacket.AsObject>
	(param: WsMessageType.DISCONNECT_ROOM_USER): Observable<DisconnectRoomUserPacket.AsObject>
	(param: WsMessageType.DISCONNECT_USER): Observable<DisconnectUserPacket.AsObject>
	(param: WsMessageType.CREATE_USER_TICKET): Observable<Overwrite<CreateUserTicketPacket.AsObject, { ticket: UserTicketInfo.AsObject }>>
	(param: WsMessageType.UPDATE_ROOM_USER_RECEIVER_CONNECTION_STATUS): Observable<UpdateRoomUserReceiverConnectionStatusPacket.AsObject>
	(param: WsMessageType.UPDATE_ROOM_USER_SENDER_CONNECTION_STATUS): Observable<UpdateRoomUserSenderConnectionStatusPacket.AsObject>
	(param: WsMessageType.ICE_CANDIDATE): Observable<IceCandidatePacket.AsObject>
	(param: WsMessageType.HEARTBEAT): Observable<HeartbeatPacket.AsObject>,
	(param: WsMessageType.SUGGEST_ROOM_STREAMS): Observable<UpdateRoomStreamMetadataSuggestPacket.AsObject>,
	(param: WsMessageType.RELATED_ROOM_STREAMS): Observable<UpdateRoomStreamMetadataRelatedPacket.AsObject>,
	(param: WsMessageType.STACK_ROOM_STREAMS): Observable<UpdateRoomStreamMetadataStackPacket.AsObject>
}

////////////
//////////// WebSocket Main
////////////

/// Ping Packet
export const heartbeatPacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.HeartbeatPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Authorized Packet
export const authenticationPacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.AuthenticationPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Success Packet
export const successPacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.SuccessPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Auth Error Packet
export const failurePacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.FailurePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Refresh Webapp Packet
export const refreshWebappPacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.RefreshWebappPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Remind Maintenance Break Packet
export const remindMaintenanceBreakPacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.RemindMaintenanceBreakPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Enable Maintenance Break Packet
export const enableMaintenanceBreakPacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.EnableMaintenanceBreakPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Refresh Token Packet
export const refreshUserTokenPacketWsGuard = (packet => {
	return packet.type === 'users.api.v1.RefreshUserTokenPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Disconnect Session Packet
export const disconnectSessionPacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.DisconnectSessionPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

////////////
//////////// SFU
////////////

/// Offer
export const offerPacketWsGuard = (packet => {
	return packet.type === 'sfu.api.v1.OfferPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Streams Answer
export const streamsAnswerPacketWsGuard = (packet => {
	return packet.type === 'sfu.api.v1.StreamsAnswerPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Streams Answer
export const iceCandidatePacketWsGuard = (packet => {
	return packet.type === 'sfu.api.v1.IceCandidatePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

////////////
//////////// Private Chat
////////////

/// Receive Message
export const userChatMessagePacketWsGuard = (packet => {
	return packet.type === 'wsgateway.api.v1.UserChatMessagePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Message
export const updatePrivateChatMessageContentPacketWsGuard = (packet => {
	return packet.type === 'users.api.v1.UpdateChatMessageContentPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Delete Message
export const deleteUserChatMessagePacketWsGuard = (packet => {
	return packet.type === 'users.api.v1.DeleteChatMessagePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

////////////
//////////// Room
////////////

/// Update Room Name
export const updateRoomNamePacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomNamePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Room Type
export const updateRoomTypePacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomTypePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Room Type
export const updateRoomBackgroundPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomBackgroundPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Room Pid
export const updateRoomPidPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomPidPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Delete Room
export const deleteRoomPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.DeleteRoomPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Send Room Invite
export const deliverRoomInvitePacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.DeliverRoomInvitePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

////////////
//////////// Room user
////////////

/// Update Room User Status
export const updateRoomUserStatusPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomUserStatusPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

export const updateRoomUserReceiverConnectionStatusPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomUserReceiverConnectionStatusPacket';
}) as  (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

export const updateRoomUserSenderConnectionStatusPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomUserSenderConnectionStatusPacket';
}) as  (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

//////
////// Event / Stream
//////

/// Create Event Observer
export const createEventObserverPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.CreateEventObserverPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Delete Event Observer
export const deleteEventObserverPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.DeleteEventObserverPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Event Name
export const updateEventNamePacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateEventNamePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Event Status
export const updateEventStatusPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateEventStatusPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Prepare Event Performer
export const prepareEventPerformerPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.PrepareEventPerformerPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Create Event Performer
export const createEventPerformerPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.CreateEventPerformerPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Delete Event Performer
export const deleteEventPerformerPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.DeleteEventPerformerPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Event Performer Position
export const updateEventPerformerPositionPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateEventPerformerPositionPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Start Event
export const startEventPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.StartEventPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Finish Event
export const finishEventPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.FinishEventPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Cancel Event
export const cancelEventPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.CancelEventPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Refresh Event Broadcast
export const refreshEventBroadcastPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.RefreshEventBroadcastPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Event Audience Packet
export const updateEventAudienceWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateEventAudiencePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Pause Room Stream
export const pauseRoomStreamWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomStreamMetadataPausedPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Change Room Stream Offset
export const changeStreamOffsetWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomStreamMetadataOffsetPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Add Room Stream
export const addRoomStreamWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.CreateRoomStreamPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Delete Room Stream
export const deleteRoomStreamWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.DeleteRoomStreamPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Clear Room Streams
export const clearRoomStreamsWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.ClearRoomStreamsPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Room Stream Position
export const updateRoomStreamPositionWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomStreamPositionPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Room Stream Metadata Suggest
export const updateRoomStreamMetadataSuggestWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomStreamMetadataSuggestPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Room Stream Metadata Related
export const updateRoomStreamMetadataRelatedWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomStreamMetadataRelatedPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Room Stream Metadata Stack
export const updateRoomStreamMetadataStackWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomStreamMetadataStackPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

//////
////// User
//////

/// Update Room User Role
export const updateRoomUserRolePacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomUserRolePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update User Flags
export const updateRoomUserFlagsPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateRoomUserFlagsPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Disconnect room user
export const disconnectRoomUserPacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.DisconnectRoomUserPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Disconnect  user
export const disconnectUserPacketWsGuard = (packet => {
	return packet.type === 'wsserver.api.v1.DisconnectUserPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// create user ticket
export const createUserTicketPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.CreateUserTicketPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;


//////
////// Message
//////

/// Receive Room Message
export const roomChatMessagePacketWsGuard = (packet => {
	return packet.type === 'wsgateway.api.v1.RoomChatMessagePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Receive Event Message
export const eventChatMessagePacketWsGuard = (packet => {
	return packet.type === 'wsgateway.api.v1.EventChatMessagePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Update Message
export const updateRoomChatMessageContentPacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateChatMessageContentPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

/// Delete Message
export const deleteRoomChatMessagePacketWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.DeleteChatMessagePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

//////
////// Friends
//////

export const createUserRelationWsGuard = (packet => {
	return packet.type === 'users.api.v1.CreateUserRelationPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

export const deleteUserRelationWsGuard = (packet => {
	return packet.type === 'users.api.v1.DeleteUserRelationPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

export const updateUserStatusWsGuard = (packet => {
	return packet.type === 'users.api.v1.UpdateUserStatusPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

export const updateUserAvatarWsGuard = (packet => {
	return packet.type === 'users.api.v1.UpdateUserAvatarPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

export const updateUserNameWsGuard = (packet => {
	return packet.type === 'users.api.v1.UpdateUserNamePacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

//////
////// CHAT
//////

export const updateChatStatusWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.UpdateChatStatusPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;

export const clearChatWsGuard = (packet => {
	return packet.type === 'rooms.api.v1.ClearChatMessagesPacket';
}) as (packet: Packet.AsObject) => packet is Overwrite<Packet.AsObject, { data: Uint8Array }>;
