/* eslint-disable */
// source: wsclient/packets.public.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var sfu_enums_pb = require('../sfu/enums_pb.js');
goog.object.extend(proto, sfu_enums_pb);
var sfu_models_pb = require('../sfu/models_pb.js');
goog.object.extend(proto, sfu_models_pb);
var rooms_enums_pb = require('../rooms/enums_pb.js');
goog.object.extend(proto, rooms_enums_pb);
var wsclient_enums_pb = require('../wsclient/enums_pb.js');
goog.object.extend(proto, wsclient_enums_pb);
var wsgateway_enums_pb = require('../wsgateway/enums_pb.js');
goog.object.extend(proto, wsgateway_enums_pb);
goog.exportSymbol('proto.wsclient.api.v1.AuthenticationPacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.DisconnectPacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.EventChatPacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.HandshakePacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.HeartbeatPacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.Packet', null, global);
goog.exportSymbol('proto.wsclient.api.v1.RoomChatPacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.RoomJoinPacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.RoomLeavePacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.RoomUserFlagsPacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.SfuConnectPacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.SfuIceCandidatePacket', null, global);
goog.exportSymbol('proto.wsclient.api.v1.UserChatPacket', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.Packet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.Packet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.Packet.displayName = 'proto.wsclient.api.v1.Packet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.HeartbeatPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.HeartbeatPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.HeartbeatPacket.displayName = 'proto.wsclient.api.v1.HeartbeatPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.HandshakePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.HandshakePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.HandshakePacket.displayName = 'proto.wsclient.api.v1.HandshakePacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.DisconnectPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.DisconnectPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.DisconnectPacket.displayName = 'proto.wsclient.api.v1.DisconnectPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.AuthenticationPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.AuthenticationPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.AuthenticationPacket.displayName = 'proto.wsclient.api.v1.AuthenticationPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.SfuConnectPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.SfuConnectPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.SfuConnectPacket.displayName = 'proto.wsclient.api.v1.SfuConnectPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.SfuIceCandidatePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.SfuIceCandidatePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.SfuIceCandidatePacket.displayName = 'proto.wsclient.api.v1.SfuIceCandidatePacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.UserChatPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.UserChatPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.UserChatPacket.displayName = 'proto.wsclient.api.v1.UserChatPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.RoomChatPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.RoomChatPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.RoomChatPacket.displayName = 'proto.wsclient.api.v1.RoomChatPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.RoomJoinPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.RoomJoinPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.RoomJoinPacket.displayName = 'proto.wsclient.api.v1.RoomJoinPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.RoomLeavePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.RoomLeavePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.RoomLeavePacket.displayName = 'proto.wsclient.api.v1.RoomLeavePacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.EventChatPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wsclient.api.v1.EventChatPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.EventChatPacket.displayName = 'proto.wsclient.api.v1.EventChatPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wsclient.api.v1.RoomUserFlagsPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wsclient.api.v1.RoomUserFlagsPacket.repeatedFields_, null);
};
goog.inherits(proto.wsclient.api.v1.RoomUserFlagsPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wsclient.api.v1.RoomUserFlagsPacket.displayName = 'proto.wsclient.api.v1.RoomUserFlagsPacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.Packet.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.Packet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.Packet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.Packet.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.Packet}
 */
proto.wsclient.api.v1.Packet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.Packet;
  return proto.wsclient.api.v1.Packet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.Packet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.Packet}
 */
proto.wsclient.api.v1.Packet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {!proto.wsclient.api.v1.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.Packet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.Packet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.Packet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.Packet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.wsclient.api.v1.Packet.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wsclient.api.v1.Packet} returns this
 */
proto.wsclient.api.v1.Packet.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Action action = 2;
 * @return {!proto.wsclient.api.v1.Action}
 */
proto.wsclient.api.v1.Packet.prototype.getAction = function() {
  return /** @type {!proto.wsclient.api.v1.Action} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wsclient.api.v1.Action} value
 * @return {!proto.wsclient.api.v1.Packet} returns this
 */
proto.wsclient.api.v1.Packet.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bytes data = 3;
 * @return {string}
 */
proto.wsclient.api.v1.Packet.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.wsclient.api.v1.Packet.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.Packet.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wsclient.api.v1.Packet} returns this
 */
proto.wsclient.api.v1.Packet.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.HeartbeatPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.HeartbeatPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.HeartbeatPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.HeartbeatPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.HeartbeatPacket}
 */
proto.wsclient.api.v1.HeartbeatPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.HeartbeatPacket;
  return proto.wsclient.api.v1.HeartbeatPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.HeartbeatPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.HeartbeatPacket}
 */
proto.wsclient.api.v1.HeartbeatPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.HeartbeatPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.HeartbeatPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.HeartbeatPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.HeartbeatPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.wsclient.api.v1.HeartbeatPacket.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wsclient.api.v1.HeartbeatPacket} returns this
 */
proto.wsclient.api.v1.HeartbeatPacket.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.HandshakePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.HandshakePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.HandshakePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.HandshakePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocol: jspb.Message.getFieldWithDefault(msg, 1, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.HandshakePacket}
 */
proto.wsclient.api.v1.HandshakePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.HandshakePacket;
  return proto.wsclient.api.v1.HandshakePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.HandshakePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.HandshakePacket}
 */
proto.wsclient.api.v1.HandshakePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wsclient.api.v1.Protocol} */ (reader.readEnum());
      msg.setProtocol(value);
      break;
    case 2:
      var value = /** @type {!proto.wsclient.api.v1.Platform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.HandshakePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.HandshakePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.HandshakePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.HandshakePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProtocol();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional Protocol protocol = 1;
 * @return {!proto.wsclient.api.v1.Protocol}
 */
proto.wsclient.api.v1.HandshakePacket.prototype.getProtocol = function() {
  return /** @type {!proto.wsclient.api.v1.Protocol} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wsclient.api.v1.Protocol} value
 * @return {!proto.wsclient.api.v1.HandshakePacket} returns this
 */
proto.wsclient.api.v1.HandshakePacket.prototype.setProtocol = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Platform platform = 2;
 * @return {!proto.wsclient.api.v1.Platform}
 */
proto.wsclient.api.v1.HandshakePacket.prototype.getPlatform = function() {
  return /** @type {!proto.wsclient.api.v1.Platform} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wsclient.api.v1.Platform} value
 * @return {!proto.wsclient.api.v1.HandshakePacket} returns this
 */
proto.wsclient.api.v1.HandshakePacket.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.DisconnectPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.DisconnectPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.DisconnectPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.DisconnectPacket.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.DisconnectPacket}
 */
proto.wsclient.api.v1.DisconnectPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.DisconnectPacket;
  return proto.wsclient.api.v1.DisconnectPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.DisconnectPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.DisconnectPacket}
 */
proto.wsclient.api.v1.DisconnectPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.DisconnectPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.DisconnectPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.DisconnectPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.DisconnectPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.AuthenticationPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.AuthenticationPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.AuthenticationPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.AuthenticationPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.AuthenticationPacket}
 */
proto.wsclient.api.v1.AuthenticationPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.AuthenticationPacket;
  return proto.wsclient.api.v1.AuthenticationPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.AuthenticationPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.AuthenticationPacket}
 */
proto.wsclient.api.v1.AuthenticationPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.AuthenticationPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.AuthenticationPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.AuthenticationPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.AuthenticationPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.wsclient.api.v1.AuthenticationPacket.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wsclient.api.v1.AuthenticationPacket} returns this
 */
proto.wsclient.api.v1.AuthenticationPacket.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.SfuConnectPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.SfuConnectPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.SfuConnectPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    sfuType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sdpOffer: msg.getSdpOffer_asB64(),
    clientType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    protocolVersion: jspb.Message.getFieldWithDefault(msg, 4, 0),
    options: (f = msg.getOptions()) && sfu_models_pb.SfuConnectionOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.SfuConnectPacket}
 */
proto.wsclient.api.v1.SfuConnectPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.SfuConnectPacket;
  return proto.wsclient.api.v1.SfuConnectPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.SfuConnectPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.SfuConnectPacket}
 */
proto.wsclient.api.v1.SfuConnectPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!proto.wsgateway.api.v1.SfuType} */ (reader.readEnum());
      msg.setSfuType(value);
      break;
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSdpOffer(value);
      break;
    case 3:
      var value = /** @type {!proto.sfu.api.v1.ClientType} */ (reader.readEnum());
      msg.setClientType(value);
      break;
    case 4:
      var value = /** @type {!proto.sfu.api.v1.ProtocolVersion} */ (reader.readEnum());
      msg.setProtocolVersion(value);
      break;
    case 5:
      var value = new sfu_models_pb.SfuConnectionOptions;
      reader.readMessage(value,sfu_models_pb.SfuConnectionOptions.deserializeBinaryFromReader);
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.SfuConnectPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.SfuConnectPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.SfuConnectPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSfuType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSdpOffer_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getClientType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProtocolVersion();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOptions();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      sfu_models_pb.SfuConnectionOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional wsgateway.api.v1.SfuType sfu_type = 2;
 * @return {!proto.wsgateway.api.v1.SfuType}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.getSfuType = function() {
  return /** @type {!proto.wsgateway.api.v1.SfuType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wsgateway.api.v1.SfuType} value
 * @return {!proto.wsclient.api.v1.SfuConnectPacket} returns this
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.setSfuType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bytes sdp_offer = 1;
 * @return {string}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.getSdpOffer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes sdp_offer = 1;
 * This is a type-conversion wrapper around `getSdpOffer()`
 * @return {string}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.getSdpOffer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSdpOffer()));
};


/**
 * optional bytes sdp_offer = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSdpOffer()`
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.getSdpOffer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSdpOffer()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wsclient.api.v1.SfuConnectPacket} returns this
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.setSdpOffer = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional sfu.api.v1.ClientType client_type = 3;
 * @return {!proto.sfu.api.v1.ClientType}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.getClientType = function() {
  return /** @type {!proto.sfu.api.v1.ClientType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sfu.api.v1.ClientType} value
 * @return {!proto.wsclient.api.v1.SfuConnectPacket} returns this
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.setClientType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional sfu.api.v1.ProtocolVersion protocol_version = 4;
 * @return {!proto.sfu.api.v1.ProtocolVersion}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.getProtocolVersion = function() {
  return /** @type {!proto.sfu.api.v1.ProtocolVersion} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sfu.api.v1.ProtocolVersion} value
 * @return {!proto.wsclient.api.v1.SfuConnectPacket} returns this
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional sfu.api.v1.SfuConnectionOptions options = 5;
 * @return {?proto.sfu.api.v1.SfuConnectionOptions}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.getOptions = function() {
  return /** @type{?proto.sfu.api.v1.SfuConnectionOptions} */ (
    jspb.Message.getWrapperField(this, sfu_models_pb.SfuConnectionOptions, 5));
};


/**
 * @param {?proto.sfu.api.v1.SfuConnectionOptions|undefined} value
 * @return {!proto.wsclient.api.v1.SfuConnectPacket} returns this
*/
proto.wsclient.api.v1.SfuConnectPacket.prototype.setOptions = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wsclient.api.v1.SfuConnectPacket} returns this
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.clearOptions = function() {
  return this.setOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wsclient.api.v1.SfuConnectPacket.prototype.hasOptions = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.SfuIceCandidatePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.SfuIceCandidatePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    sfuType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sdpCandidate: msg.getSdpCandidate_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.SfuIceCandidatePacket}
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.SfuIceCandidatePacket;
  return proto.wsclient.api.v1.SfuIceCandidatePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.SfuIceCandidatePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.SfuIceCandidatePacket}
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wsgateway.api.v1.SfuType} */ (reader.readEnum());
      msg.setSfuType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSdpCandidate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.SfuIceCandidatePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.SfuIceCandidatePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSfuType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSdpCandidate_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional wsgateway.api.v1.SfuType sfu_type = 1;
 * @return {!proto.wsgateway.api.v1.SfuType}
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.prototype.getSfuType = function() {
  return /** @type {!proto.wsgateway.api.v1.SfuType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wsgateway.api.v1.SfuType} value
 * @return {!proto.wsclient.api.v1.SfuIceCandidatePacket} returns this
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.prototype.setSfuType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes sdp_candidate = 2;
 * @return {string}
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.prototype.getSdpCandidate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes sdp_candidate = 2;
 * This is a type-conversion wrapper around `getSdpCandidate()`
 * @return {string}
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.prototype.getSdpCandidate_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSdpCandidate()));
};


/**
 * optional bytes sdp_candidate = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSdpCandidate()`
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.prototype.getSdpCandidate_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSdpCandidate()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wsclient.api.v1.SfuIceCandidatePacket} returns this
 */
proto.wsclient.api.v1.SfuIceCandidatePacket.prototype.setSdpCandidate = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.UserChatPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.UserChatPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.UserChatPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.UserChatPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    fid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chatId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.UserChatPacket}
 */
proto.wsclient.api.v1.UserChatPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.UserChatPacket;
  return proto.wsclient.api.v1.UserChatPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.UserChatPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.UserChatPacket}
 */
proto.wsclient.api.v1.UserChatPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.UserChatPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.UserChatPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.UserChatPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.UserChatPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChatId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string fid = 1;
 * @return {string}
 */
proto.wsclient.api.v1.UserChatPacket.prototype.getFid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wsclient.api.v1.UserChatPacket} returns this
 */
proto.wsclient.api.v1.UserChatPacket.prototype.setFid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string chat_id = 2;
 * @return {string}
 */
proto.wsclient.api.v1.UserChatPacket.prototype.getChatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wsclient.api.v1.UserChatPacket} returns this
 */
proto.wsclient.api.v1.UserChatPacket.prototype.setChatId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.wsclient.api.v1.UserChatPacket.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wsclient.api.v1.UserChatPacket} returns this
 */
proto.wsclient.api.v1.UserChatPacket.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.RoomChatPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.RoomChatPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.RoomChatPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.RoomChatPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    fid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.RoomChatPacket}
 */
proto.wsclient.api.v1.RoomChatPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.RoomChatPacket;
  return proto.wsclient.api.v1.RoomChatPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.RoomChatPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.RoomChatPacket}
 */
proto.wsclient.api.v1.RoomChatPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.RoomChatPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.RoomChatPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.RoomChatPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.RoomChatPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string fid = 1;
 * @return {string}
 */
proto.wsclient.api.v1.RoomChatPacket.prototype.getFid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wsclient.api.v1.RoomChatPacket} returns this
 */
proto.wsclient.api.v1.RoomChatPacket.prototype.setFid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.wsclient.api.v1.RoomChatPacket.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wsclient.api.v1.RoomChatPacket} returns this
 */
proto.wsclient.api.v1.RoomChatPacket.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.RoomJoinPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.RoomJoinPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.RoomJoinPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.RoomJoinPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    force: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.RoomJoinPacket}
 */
proto.wsclient.api.v1.RoomJoinPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.RoomJoinPacket;
  return proto.wsclient.api.v1.RoomJoinPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.RoomJoinPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.RoomJoinPacket}
 */
proto.wsclient.api.v1.RoomJoinPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.RoomJoinPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.RoomJoinPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.RoomJoinPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.RoomJoinPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getForce();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.wsclient.api.v1.RoomJoinPacket.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wsclient.api.v1.RoomJoinPacket} returns this
 */
proto.wsclient.api.v1.RoomJoinPacket.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool force = 2;
 * @return {boolean}
 */
proto.wsclient.api.v1.RoomJoinPacket.prototype.getForce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wsclient.api.v1.RoomJoinPacket} returns this
 */
proto.wsclient.api.v1.RoomJoinPacket.prototype.setForce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.RoomLeavePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.RoomLeavePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.RoomLeavePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.RoomLeavePacket.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.RoomLeavePacket}
 */
proto.wsclient.api.v1.RoomLeavePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.RoomLeavePacket;
  return proto.wsclient.api.v1.RoomLeavePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.RoomLeavePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.RoomLeavePacket}
 */
proto.wsclient.api.v1.RoomLeavePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.RoomLeavePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.RoomLeavePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.RoomLeavePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.RoomLeavePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.EventChatPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.EventChatPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.EventChatPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.EventChatPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    fid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.EventChatPacket}
 */
proto.wsclient.api.v1.EventChatPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.EventChatPacket;
  return proto.wsclient.api.v1.EventChatPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.EventChatPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.EventChatPacket}
 */
proto.wsclient.api.v1.EventChatPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.EventChatPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.EventChatPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.EventChatPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.EventChatPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string fid = 1;
 * @return {string}
 */
proto.wsclient.api.v1.EventChatPacket.prototype.getFid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wsclient.api.v1.EventChatPacket} returns this
 */
proto.wsclient.api.v1.EventChatPacket.prototype.setFid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.wsclient.api.v1.EventChatPacket.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wsclient.api.v1.EventChatPacket} returns this
 */
proto.wsclient.api.v1.EventChatPacket.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.wsclient.api.v1.RoomUserFlagsPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wsclient.api.v1.RoomUserFlagsPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    flagsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wsclient.api.v1.RoomUserFlagsPacket}
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wsclient.api.v1.RoomUserFlagsPacket;
  return proto.wsclient.api.v1.RoomUserFlagsPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wsclient.api.v1.RoomUserFlagsPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wsclient.api.v1.RoomUserFlagsPacket}
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.rooms.api.v1.RoomUserFlag>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFlags(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wsclient.api.v1.RoomUserFlagsPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wsclient.api.v1.RoomUserFlagsPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlagsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated rooms.api.v1.RoomUserFlag flags = 1;
 * @return {!Array<!proto.rooms.api.v1.RoomUserFlag>}
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.prototype.getFlagsList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.RoomUserFlag>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RoomUserFlag>} value
 * @return {!proto.wsclient.api.v1.RoomUserFlagsPacket} returns this
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.prototype.setFlagsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.rooms.api.v1.RoomUserFlag} value
 * @param {number=} opt_index
 * @return {!proto.wsclient.api.v1.RoomUserFlagsPacket} returns this
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.prototype.addFlags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wsclient.api.v1.RoomUserFlagsPacket} returns this
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.prototype.clearFlagsList = function() {
  return this.setFlagsList([]);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wsclient.api.v1.RoomUserFlagsPacket} returns this
 */
proto.wsclient.api.v1.RoomUserFlagsPacket.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.wsclient.api.v1);
