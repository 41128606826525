import {
	AcceptUserRelationRequest,
	ActivateUserRequest,
	CreateUserRelationRequest,
	CreateUserRequest,
	CreateUserResponse,
	DeclineUserRelationRequest,
	DeclineUserRelationResponse,
	DeleteUserRelationRequest,
	GetChatStateRequest,
	GetUserInfoRequest,
	ListChatsRequest,
	ListUsersRequest,
	RemindUserPasswordRequest,
	ResetUserPasswordRequest,
	SearchUsersRequest,
	TokenizeStorageObjectRequest,
	TokenizeUserRequest,
	UpdateUserRequest
} from './gRPC/users/services.public_pb';
import {defer, from, Observable, of, throwError} from 'rxjs';
import {map, mergeMap, retry, tap} from 'rxjs/operators';
import {PublicServiceClient as UserService} from './gRPC/users/Services.publicServiceClientPb';
import {getCurrentTokenResultsFirebase$} from './firebaseAuth';
import {
	ChatState,
	UserAvatarValue,
	UserBirthDateValue,
	UserEmailValue,
	UserFirstNameValue,
	UserInfo,
	UserLastNameValue,
	UserNameValue,
	UserOptionsValue,
	UserRelationInfo
} from './gRPC/users/models_pb';
import {ajax} from 'rxjs/ajax';
import {envVars} from '../environments/env';
import {UserOption, UserRelationType} from './gRPC/users/enums_pb';
import {computeChecksumMd5, EMIT} from '../utils/utils';
import {UpdateProfileModalProps} from '../singleComponents/modals/User/profileProcess';
import {dataURLtoFile} from '../utils/Image';
import {interceptError, retryConfig} from '../utils/errorInterceptor';
import {IdTokenResult} from 'firebase/auth';

const service = new UserService(envVars.apiUsersUrl);
const supportedUsersVersion = envVars.supportedUsersVersion;

const prepareHeaders = (token: IdTokenResult) => {
	return {'Authorization': 'Bearer ' + token.token, 'Supported-Service-Version': supportedUsersVersion};
};

export const resetUserPasswordService$ = (token: string, secret: string) => {
	const request = new ResetUserPasswordRequest();
	request.setSecret(secret);
	request.setToken(token);
	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.resetUserPassword(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const remindUserPasswordService$ = (email: string) => {
	const request = new RemindUserPasswordRequest();

	request.setEmail(email).setReturnUrl(`${envVars.pageUrl}/reset-password`);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.remindUserPassword(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};


export const createUserRelationService$ = (type: UserRelationType, relatedUserId: string, userId?: string) => {
	const request = new CreateUserRelationRequest();
	request.setType(type).setRelatedId(relatedUserId);
	userId && request.setUserId(userId);


	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.createUserRelation(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as UserRelationInfo.AsObject))
	);
};

export const deleteUserRelationService$ = (id: string) => {
	const request = new DeleteUserRelationRequest();

	request.setId(id);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.deleteUserRelation(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const acceptUserRelationService$ = (id: string) => {
	const request = new AcceptUserRelationRequest();

	request.setId(id);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.acceptUserRelation(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as UserRelationInfo.AsObject))
	);
};

export const declineUserRelationService$ = (id: string) => {
	const request = new DeclineUserRelationRequest();

	request.setId(id);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.declineUserRelation(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data as DeclineUserRelationResponse.AsObject))
	);
};

export const searchUsersService$ = (query: string, limit?: number) => {
	const request = new SearchUsersRequest();

	request.setQuery(query);
	request.setLimit(limit ?? 10);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.searchUsers(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.usersList))
	);
};

export const createUserService$ = (
	nickname: string,
	firstname?: string,
	lastname?: string
) => {

	const request = new ActivateUserRequest();

	request.setName(nickname);
	firstname && request.setFirstName(firstname);
	lastname && request.setLastName(lastname);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.activateUser(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as UserInfo.AsObject))
	);
};

export const listChatsService$ = (offset: number, limit: number) => {

	const request = new ListChatsRequest();
	request.setOffset(offset).setLimit(limit);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listChats(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);

};

export const getChatStateService$ = (userId: string, id?: string) => {

	const request = new GetChatStateRequest();
	id && request.setId(id);
	request.setUserId(userId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getChatState(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.state as ChatState.AsObject))
	);

};

export const listUsersService$ = (idList: string[]) => {
	if (idList.length === 0) {
		return of([] as UserInfo.AsObject[]);
	}

	const request = new ListUsersRequest();
	request.setIdsList(idList);
	request.setLimit(100).setOffset(0);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listUsers(request, header))).pipe(
			retry(3)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.usersList as UserInfo.AsObject[]))
	);

};

export const updateUserService$ = (data: Partial<UpdateProfileModalProps>) => {
	const request = new UpdateUserRequest();
	request.setOptions(new UserOptionsValue().setValueList([]));
	data.email && request.setEmail(new UserEmailValue().setValue(data.email));
	data.name && request.setName(new UserNameValue().setValue(data.name));
	data.firstName && request.setFirstName(new UserFirstNameValue().setValue(data.firstName));
	data.lastName && request.setLastName(new UserLastNameValue().setValue(data.lastName));
	data.birthDate && request.setBirthDate(new UserBirthDateValue().setValue(Math.floor(Number(data.birthDate) / 1000)));
	data.ignorePrivateMessages && request.setOptions(new UserOptionsValue().setValueList([UserOption.USEROPTION_IGNORE_MESSAGES]));
	if (data.avatarImage === '') {
		request.setAvatar(new UserAvatarValue().setValue(''));
	}

	const avatarFile = data.avatarImage && data.avatarImage !== '' ?
		dataURLtoFile(data.avatarImage, 'avatar.png') : undefined;

	return ((avatarFile ? updateUserAvatarService$(avatarFile).pipe(
		tap((tid) => request.setAvatar(new UserAvatarValue().setValue(tid)))
	) : EMIT) as Observable<unknown>).pipe(
		mergeMap(() => getCurrentTokenResultsFirebase$()),
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateUser(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as UserInfo.AsObject))
	);
};


export function getUserInfoService$(userId?: string) {

	const request = new GetUserInfoRequest();
	userId && request.setId(userId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getUserInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as UserInfo.AsObject))
	);

}


export function createGuestService$() {

	const request = new CreateUserRequest();

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.createUser(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data as CreateUserResponse.AsObject))
	);
}


export function updateUserAvatarService$(image: File) {
	let avatarRequest = new TokenizeStorageObjectRequest();

	avatarRequest.setType(image.type);
	avatarRequest.setSize(image.size);
	return computeChecksumMd5(image).pipe(
		mergeMap((hash) => of(hash).pipe(
				tap((hash) => {
					avatarRequest.setHash(hash);
				}),
				mergeMap(() => getCurrentTokenResultsFirebase$()),
				map(token => prepareHeaders(token)),
				mergeMap(header => defer(() => from(service.tokenizeStorageObject(avatarRequest, header))).pipe(
					retry(retryConfig)
				)),
				map(response => response.toObject()),
				mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data)),
				map(response => atob(response.token)),
				map(response => JSON.parse(response))).pipe(
				mergeMap((d: { url: string, tid: string }) => {
					return ajax({
						url: d.url,
						method: 'PUT',
						headers: {
							'Content-MD5': hash,
							'Content-Type': image.type,
							'Access-Control-Allow-Origin': 'Origin'
						},
						body: image
					}).pipe(
						map(() => d.tid)
					);
				})
			)
		));
}

export function createUserTokenService$() {

	const request = new TokenizeUserRequest();
	request.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.tokenizeUser(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.token))
	);
}
