import {rgba} from 'polished';
import {css} from 'styled-components';
import {remm} from '../utils/remm';

///
/// Colors
///

export const RED_LIGHT = '#F04747';
export const RED = '#e72429';
export const ORANGE = '#FF9100';
export const GREEN_2 = '#6AE0A0';
export const CYAN_LIGHTNING = '#15B9B9';

export const WHITE = '#FFF';
export const BLACK = '#000';

export const CYAN_800 = '#12abab';
export const CYAN_700 = '#2BB9B9';
export const CYAN_600 = '#18C9C9';
export const CYAN_500 = '#1AD5D5';
export const CYAN_400 = '#32DCDC';
export const CYAN_300 = '#8BE4E4';

export const GREEN_700 = '#88B50D';
export const GREEN_600 = '#A5D425';
export const GREEN_500 = '#C4E56C';
export const GREEN_400 = '#D8EDA1';

export const DARK_HIGHLIGHT = rgba(226, 240, 255, 0.08);
export const DARK_HIGHLIGHT2 = rgba(159, 176, 196, 0.2);
export const LIGHT_HIGHLIGHT = rgba(68, 98, 98, 0.09);
export const LIGHT_HIGHLIGHT2 = rgba(68, 98, 98, 0.16);

export const DARK_SCROLL = rgba(255, 255, 255, 0.3);
export const LIGHT_SCROLL = rgba(68, 55, 40, 0.3);

export const BORDER_WHITE_WEAK = 'rgba(255,255,255,0.06)';
export const BORDER_WHITE_NORMAL = 'rgba(203,224,255,0.18)';
export const BORDER_WHITE_STRONG = 'rgba(204,223,255,0.3)';
export const BORDER_BLACK_WEAK = 'rgba(0,0,0,0.05)';
export const BORDER_BLACK_NORMAL = 'rgba(0,0,0,0.16)';
export const BORDER_BLACK_STRONG = 'rgba(0,0,0,0.28)';


///
/// NewFromMobile
///
export const DARK1 = '#0C1117';
export const DARK2 = '#181D24';
export const DARK3 = '#242A33';
export const DARK4 = '#2F3641';
export const LIGHT1 = '#EAF0F0';
export const LIGHT2 = '#FFFFFF';
export const LIGHT3 = '#F7F7F7';
export const LIGHT4 = '#D6DBDB';

export const WHITE_STRONG = 'rgba(255,255,255,0.95)';
export const WHITE_MEDIUM = 'rgba(255,255,255,0.70)';
export const WHITE_WEAK = 'rgba(255,255,255,0.35)';
export const BLACK_STRONG = 'rgba(0,0,0,0.90)';
export const BLACK_MEDIUM = 'rgba(0,0,0,0.65)';
export const BLACK_WEAK = 'rgba(0,0,0,0.35)';

export const DARK_THEME_SHADOW = 'rgba(0,3,8,0.5)';
export const LIGHT_THEME_SHADOW = 'rgba(122,59,0,0.13)';

export const IMAGE_VIDEO_HIGHLIGHT = 'rgba(255,255,255,0.18)';

export const DARK_THEME_BOX_SHADOW = css`box-shadow: 0 ${remm(2)} ${remm(8)} 0 rgba(0, 0, 0, 0.24) !important;` as any;
export const LIGHT_THEME_BOX_SHADOW = css`box-shadow: 0 ${remm(2)} ${remm(8)} 0 rgba(0, 0, 0, 0.10) !important;` as any;
