import {Resolution} from './getResolution.types';
import {CameraMode} from './updaters/userMediaVideo';
import {MAX_BITRATE_TOTAL} from './updaters/useLimitMaxBitrate';

export interface VideoConstraints {
	width: {
		max: number,
		min?: number,
		ideal: number
	},
	height: {
		max: number,
		min?: number,
		ideal: number
	}
}

export const getResolution = (cameraMode: CameraMode = CameraMode.MAIN_VIEW_USER, resolution: Resolution, maxRes?: { width: number, height: number }, amount?: number) => {
	if (maxRes) {
		return {
			width: {ideal: maxRes.width, max: maxRes.width},
			height: {ideal: maxRes.height, max: maxRes.height}
		};
	} else {
		switch (cameraMode) {
			case CameraMode.PERFORMER:
				return ({
					width: {ideal: 1920, max: 1920},
					height: {ideal: 1080, max: 1080}
				});
			case CameraMode.MAIN_VIEW_USER: {
				const maxRes = getMaxResolution(amount);
				switch (resolution) {
					case Resolution.HIGH:
						const highRes = {
							width: {max: 720, min: 480, ideal: 720},
							height: {max: 720, min: 480, ideal: 720}
						};
						console.log(getMinResolution(highRes, maxRes))
						return getMinResolution(highRes, maxRes);
					case Resolution.MEDIUM:
						const mediumRes = {
							width: {max: 544, min: 320, ideal: 544},
							height: {max: 544, min: 320, ideal: 544}
						};
						console.log(getMinResolution(mediumRes, maxRes))
						return getMinResolution(mediumRes, maxRes)
					default: {
						const lowRes =  {
							width: {max: 320, min: 160, ideal: 240},
							height: {max: 320, min: 160, ideal: 240}
						};
						console.log(getMinResolution(lowRes, maxRes))
						return getMinResolution(lowRes, maxRes);
					}
				}
			}
			default:
				return {
					width: {max: 128},
					height: {max: 128}
				};

		}
	}
};

export const getMaxResolution = (amount?: number) => {
	if (!amount || amount <= 2) {
		return {
			width: {max: 720, min: 480, ideal: 720},
			height: {max: 720, min: 480, ideal: 720}
		};
	} else {
		const maxBitrate = MAX_BITRATE_TOTAL / (amount - 1);
		if (maxBitrate <= 200) {
			return {
				width: {max: 360, min: 240, ideal: 360},
				height: {max: 360, min: 240, ideal: 360}
			}
		} else if (maxBitrate <= 500) {
			return {
				width: {max: 480, min: 360, ideal: 480},
				height: {max: 480, min: 360, ideal: 480}
			};
		} else {
			return {
				width: {max: 720, min: 480, ideal: 720},
				height: {max: 720, min: 480, ideal: 720}
			};
		}
	}
};

export const getMinResolution = (res1: VideoConstraints, res2: VideoConstraints) => {
	return res1.width.max > res2.width.max ? res2 : res1
};
