import {disconnectSFU} from '../../../../services/sfu/senders.sfu';
import {roomGlobalRef, RoomMode} from '../roomGlobalRef';
import {userLeaveRoomSenderWS$} from '../../../../services/ws/senders.ws';

export const disconnectPc = () => {
	disconnectSFU(roomGlobalRef.mediaSenderServerChannel);
	if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
		disconnectSFU(roomGlobalRef.mediaReceiverServerChannel);
		roomGlobalRef.pcMediaReceiver?.close();
	}
	roomGlobalRef.pcMediaSender?.close();
	userLeaveRoomSenderWS$().subscribe();
};
