import {getCurrentTokenResultsFirebase$} from './firebaseAuth';
import {concatMap, map, mergeMap, retry, timeout} from 'rxjs/operators';
import {defer, from, of, throwError} from 'rxjs';
import {PublicServiceClient as PaymentsService} from './gRPC/payments/Services.publicServiceClientPb';
import {
	ActivatePartnerRequest,
	CreateCustomerPaymentRequest,
	CreateEventTicketRequest,
	CreateEventTicketVoucherRequest,
	DeleteEventTicketVoucherRequest,
	GetEventTicketVoucherInfoRequest,
	GetPartnerInfoRequest,
	ListCustomerOrdersRequest,
	ListEventTicketVouchersRequest,
	PurchaseEventTicketsRequest,
	UpdateEventTicketRequest,
	UpdateEventTicketVoucherRequest
} from './gRPC/payments/services.public_pb';
import {listUserTicketsService$} from './roomServices';
import {
	CustomerOrderTicket,
	CustomerPaymentCardMetadata,
	CustomerPaymentInfo,
	CustomerPaymentMetadata,
	CustomerPaymentStripeMetadata,
	EventTicketMetadata,
	EventTicketMetadataValue,
	EventTicketPriceMetadata,
	EventTicketVoucherAmountValue,
	EventTicketVoucherInfo,
	EventTicketVoucherStatusValue,
	EventTicketVoucherUsagesValue,
	Price
} from './gRPC/payments/models_pb';
import {PaymentMethod, Stripe, StripeCardNumberElement, StripeError} from '@stripe/stripe-js';
import {envVars} from '../environments/env';
import {EMIT} from '../utils/utils';
import {CustomerPaymentType, EventTicketType, EventTicketVoucherStatus} from './gRPC/payments/enums_pb';
import {interceptError, retryConfig} from '../utils/errorInterceptor';
import {IdTokenResult} from 'firebase/auth';

const service = new PaymentsService(envVars.apiPaymentsUrl);
const supportedPaymentsVersion = envVars.supportedPaymentsVersion;

const prepareHeaders = (token: IdTokenResult) => {
	return {'Authorization': 'Bearer ' + token.token, 'Supported-Service-Version': supportedPaymentsVersion};
};

///
/// Partner
///

export const getPartnerService$ = () => {

	const request = new GetPartnerInfoRequest();

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getPartnerInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const activatePartnerService$ = () => {

	const request = new ActivatePartnerRequest();

	request.setRefreshUrl(`${envVars.pageUrl}/error`);
	request.setReturnUrl(`${envVars.pageUrl}/partner-created`);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.activatePartner(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

///
/// Event Ticket
///

export const createEventTicketService$ = (eventId: string, ticketType: EventTicketType, prices: Price[]) => {

	const request = new CreateEventTicketRequest();
	request.setEventId(eventId);
	request.setType(ticketType);

	const metadata = new EventTicketMetadata()
	metadata.setPrice(new EventTicketPriceMetadata().setItemsList(prices))
	request.setMetadata(metadata);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.createEventTicket(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const updateEventTicketService$ = (ticketId: string, prices: Price[]) => {

	const request = new UpdateEventTicketRequest();
	request.setId(ticketId)

	const metadata = new EventTicketMetadata()
	metadata.setPrice(new EventTicketPriceMetadata().setItemsList(prices))
	request.setMetadata(new EventTicketMetadataValue().setValue(metadata))

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateEventTicket(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const purchaseEventTicketService = (ticketId: string, paymentId?: string, voucherId?: string) => {

	const request = new PurchaseEventTicketsRequest();
	request.addTickets(new CustomerOrderTicket()
		.setTicketid(ticketId)
		.setAmount(1)
	);

	if (paymentId) {
		request.setPaymentId(paymentId);
	}
	if (voucherId) {
		request.addVoucherIds(voucherId);
	}

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.purchaseEventTickets(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data)),
		mergeMap(() => listUserTicketsService$())
	);
};

///
/// Event Ticket Voucher
///

export const getEventTicketVoucherService$ = (ticketId: string, voucherCode: string) => {

	const request = new GetEventTicketVoucherInfoRequest();
	request.setPid(voucherCode);
	request.setTicketId(ticketId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getEventTicketVoucherInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as EventTicketVoucherInfo.AsObject))
	);
};

export const listEventTicketVouchersService$ = ({
	ticketId,
	limit,
	offset,
	order
}: { ticketId: string, limit?: number, offset?: number, order?: 'ASC' | 'DESC' }) => {

	const request = new ListEventTicketVouchersRequest();
	request.setTicketId(ticketId);

	limit && request.setLimit(limit);
	offset && request.setOffset(offset);
	order && request.setOrder(order);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listEventTicketVouchers(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const createEventTicketVoucherService$ = ({
	ticketId,
	amount,
	pid,
	usages
}: { ticketId: string, amount: number, pid?: string, usages?: number }) => {

	const request = new CreateEventTicketVoucherRequest();
	request.setTicketId(ticketId);
	request.setAmount(amount);

	pid && request.setPid(pid);
	usages && request.setUsages(usages);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.createEventTicketVoucher(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as EventTicketVoucherInfo.AsObject))
	);
};

export const updateEventTicketVoucherService$ = ({
	voucherId,
	amount,
	usages,
	status
}: { voucherId: string, amount?: number, usages?: number, status?: EventTicketVoucherStatus }) => {

	const request = new UpdateEventTicketVoucherRequest();
	request.setId(voucherId);

	usages !== undefined && request.setUsages(new EventTicketVoucherUsagesValue().setValue(usages));
	amount !== undefined && request.setAmount(new EventTicketVoucherAmountValue().setValue(amount));
	status && request.setStatus(new EventTicketVoucherStatusValue().setValue(status));

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateEventTicketVoucher(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as EventTicketVoucherInfo.AsObject))
	);
};

export const deleteEventTicketVoucherService$ = (voucherId: string) => {

	const request = new DeleteEventTicketVoucherRequest();
	request.setId(voucherId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.deleteEventTicketVoucher(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

///
/// Payment Method
///

export const createPaymentMethodService$ = (stripeId: string, expire: boolean, payment: PaymentMethod) => {
	const request = new CreateCustomerPaymentRequest();

	request.setExpires(expire);
	request.setType(CustomerPaymentType.CPTYPE_CARD);

	const card = new CustomerPaymentCardMetadata();
	if (payment.card?.last4) {
		card.setLast4(parseInt(payment.card.last4));
	}
	if (payment.card?.brand) {
		card.setBrand(payment.card?.brand);
	}
	if (payment.card?.exp_year && payment.card?.exp_month) {
		card.setExpiresAt(new Date(payment.card?.exp_year, payment.card?.exp_month).getTime() / 1000);
	}

	request.setMetadata(new CustomerPaymentMetadata()
		.setStripe(new CustomerPaymentStripeMetadata()
			.setId(stripeId)
		).setCard(card)
	);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.createCustomerPayment(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as CustomerPaymentInfo.AsObject))
	);
};

export const createPaymentMethodStripeService$ = (stripe: Stripe, cardElement: StripeCardNumberElement, name: string) => {
	return EMIT.pipe(
		concatMap(() =>
			stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					name: name
				}
			})),
		timeout(30000),
		mergeMap((data) => data.error ? throwError(() => data.error as StripeError) : of(data.paymentMethod as PaymentMethod))
	);
};

export const listCustomerOrdersService$ = (offset = 0) => {

	const request = new ListCustomerOrdersRequest();
	request.setOffset(offset).setLimit(30).setOrder('DESC');

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listCustomerOrders(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

