import {getCurrentTokenResultsFirebase$} from './firebaseAuth';
import {map, mergeMap, retry, tap} from 'rxjs/operators';
import {defer, forkJoin, from, iif, Observable, of, throwError} from 'rxjs';
import {
	ActivateRoomRequest,
	CancelEventRequest,
	ChangeRoomStreamRequest,
	ClearChatMessagesRequest,
	ClearRoomStreamsRequest,
	ConfirmEventPerformerRequest,
	CreateEventRequest,
	CreateRoomInviteRequest,
	CreateRoomRequest,
	CreatesRoomStream,
	DeleteEventPerformerRequest,
	DeleteRoomRequest,
	DeliverRoomInviteRequest,
	DisconnectRoomUserRequest,
	FindRoomRequest,
	FinishEventRequest,
	GetChatStateRequest,
	GetChatStateResponse,
	GetEventInfoRequest,
	GetEventOwnerReportInfoRequest,
	GetEventReportInfoRequest,
	GetEventStateRequest,
	GetRoomInfoRequest,
	GetRoomInviteInfoRequest,
	GetRoomStateRequest,
	GetRoomStreamInfoRequest,
	GetRoomUserInfoRequest,
	GetTiktokObjectInfoRequest,
	InsertRoomStreamRequest,
	InsertRoomStreamResponse,
	LeaveRoomRequest,
	ListEventPerformersRequest,
	ListEventReportsRequest,
	ListEventsRequest,
	ListRecommendationsRequest,
	ListRoomsRequest,
	ListRoomUsersRequest,
	ListUserTicketsRequest,
	RemoveRoomStreamRequest,
	RestartEventRequest,
	SearchEventsRequest,
	SearchRoomsRequest,
	SearchTiktokObjectsRequest,
	SearchTiktokObjectsResponse,
	SearchYoutubeObjectsRequest,
	SearchYoutubeObjectsResponse,
	StartEventRequest,
	SwitchEventPerformerRequest,
	TokenizeEventRequest,
	TokenizeRoomUserRequest,
	UpdateChatRequest,
	UpdateChatResponse,
	UpdateEventRequest,
	UpdateRoomRequest,
	UpdateRoomStreamRequest,
	UpdateRoomStreamResponse,
	UpdateRoomUserRequest
} from './gRPC/rooms/services.public_pb';
import {PublicServiceClient as RoomService} from './gRPC/rooms/Services.publicServiceClientPb';
import {PublicServiceClient as BabySfuService} from './gRPC/babysfu/Services.publicServiceClientPb';
import {ajax} from 'rxjs/ajax';
import {
	ChatStatusValue,
	EventAccessValue,
	EventBackgroundValue,
	EventBroadcastOffsetValue,
	EventBroadcastUrlValue,
	EventDescriptionValue,
	EventFinishesAtValue,
	EventGenresValue,
	EventInfo,
	EventNameValue,
	EventOverseerValue,
	EventOwnerReportInfo,
	EventPerformerInfo,
	EventReportInfo,
	EventStartsAtValue,
	EventState,
	EventSummaryValue,
	EventThumbnailValue,
	RecommendationInfo,
	RoomAccessValue,
	RoomBackgroundValue,
	RoomInfo,
	RoomInviteInfo,
	RoomLimitValue,
	RoomNameValue,
	RoomPasswordValue,
	RoomPidValue,
	RoomState,
	RoomStreamMetadataOffsetValue,
	RoomStreamMetadataPausedValue,
	RoomStreamMetadataRelatedValue,
	RoomStreamMetadataSuggestValue,
	RoomStreamMetadataValue,
	RoomThumbnailValue,
	RoomUserInfo,
	RoomUserRoleValue,
	TiktokObjectInfo,
	UserTicketInfo
} from './gRPC/rooms/models_pb';
import {
	ChatStatus,
	EventAccess,
	EventGenre,
	EventOverseer,
	EventSource,
	EventStatus,
	EventType,
	RoomAccess,
	RoomStreamType,
	RoomType,
	RoomUserStatus,
	UserTicketStatus
} from './gRPC/rooms/enums_pb';
import {CreateConnectionRequest} from './gRPC/babysfu/services.public_pb';
import {envVars} from '../environments/env';
import {computeChecksumMd5, EMIT, WithRequired} from '../utils/utils';
import {dataURLtoFile} from '../utils/Image';
import {TokenizeStorageObjectRequest} from './gRPC/users/services.public_pb';
import {interceptError, retryConfig} from '../utils/errorInterceptor';
import {IdTokenResult} from 'firebase/auth';
import {createEventTicketService$, updateEventTicketService$} from './paymentServices';
import {EventTicketInfo, Price} from './gRPC/payments/models_pb';
import {EventTicketType} from './gRPC/payments/enums_pb';
import {store} from '../store/store';
import {performerActions} from '../store/slices/performer';
import {v4 as uuidv4} from 'uuid';

const service = new RoomService(envVars.apiRoomsUrl);
const babySfuService = new BabySfuService(envVars.apiBabySfuUrl);
const supportedRoomsVersion = envVars.supportedRoomsVersion;

export enum UserRole {
	GUEST = 100,
	USER = 200,
	MODERATOR = 300,
	ADMIN = 400
}

export enum RoomUserRole {
	USER = 200,
	MODERATOR = 300,
	ADMIN = 400,
	HOST = 500
}

const prepareHeaders = ((token?: IdTokenResult) => {
	if (token) {
		return {'Authorization': 'Bearer ' + token.token, 'Supported-Service-Version': supportedRoomsVersion};
	} else {
		return {'Supported-Service-Version': supportedRoomsVersion};
	}
}) as {
	(token: IdTokenResult): { 'Authorization': string, 'Supported-Service-Version': string }
	(): { 'Supported-Service-Version': string }
};

export function getRoomInviteInfoService$({
	invitePid
}: { invitePid: string }) {

	const request = new GetRoomInviteInfoRequest();

	invitePid && request.setPid(invitePid);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getRoomInviteInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as RoomInviteInfo.AsObject))
	);
}

export function createRoomUserTokenService$(args: { roomPid?: string, invitePid?: string, password?: string }) {

	const request = new TokenizeRoomUserRequest();
	args.roomPid && request.setRoomPid(args.roomPid);
	args.invitePid && request.setInvitePid(args.invitePid);
	args.password && request.setPassword(args.password);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.tokenizeRoomUser(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.token))
	);
}

export function getRoomStateService$() {

	const request = new GetRoomStateRequest();

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getRoomState(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.state as RoomState.AsObject))
	);

}

interface SearchEventsServiceArgs {
	query?: string;
	genres?: EventGenre[];
	freeEvents?: boolean;
	liveNowEvents?: boolean;
	activeEvents?: boolean;
	limit?: number,
	offset?: number
}

export const searchEventsService$ = ({
	query,
	genres,
	freeEvents,
	liveNowEvents,
	activeEvents,
	limit,
	offset
}: SearchEventsServiceArgs) => {

	const request = new SearchEventsRequest();

	request.setLimit(limit ?? 50);
	request.setOffset(offset ?? 0);
	query && request.setQuery(query);
	genres?.length && request.setGenresList(genres);
	freeEvents && request.setTypesList([EventType.ETYPE_FREE]);
	liveNowEvents && request.setStatusesList([EventStatus.ESTATUS_STARTED]);
	activeEvents && request.setStatusesList([EventStatus.ESTATUS_STARTED, EventStatus.ESTATUS_PLANNED, EventStatus.ESTATUS_CREATED, EventStatus.ESTATUS_QUEUED]);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.searchEvents(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const searchRoomsService$ = (query: string = '') => {

	const request = new SearchRoomsRequest();

	request.setLimit(50);
	request.setOffset(0);
	query && request.setQuery(query);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.searchRooms(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const findRoomRequestService$ = (eventId: string) => {

	const request = new FindRoomRequest();
	request.setEventId(eventId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.findRoom(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

interface createRoomInviteServiceArgs {
	roomId?: string;
}

export const createRoomInviteService$ = ({roomId}: createRoomInviteServiceArgs) => {
	const request = new CreateRoomInviteRequest();
	roomId && request.setRoomId(roomId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.createRoomInvite(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as RoomInviteInfo.AsObject))
	);
};

interface sendRoomInviteServiceArgs {
	roomId: string;
	userIds: string[];
	userEmails: string[];
}

export const deliverRoomInviteService$ = ({roomId, userIds, userEmails}: sendRoomInviteServiceArgs) => {
	const request = new DeliverRoomInviteRequest();
	request.setRoomId(roomId);
	userIds.length > 0 && request.setUserIdsList(userIds);
	userEmails.length > 0 && request.setUserEmailsList(userEmails);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.deliverRoomInvite(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const getRoomStreamInfoService$ = (id: string) => {
	const request = new GetRoomStreamInfoRequest();
	request.setId(id);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getRoomStreamInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

interface getRoomServiceArgs {
	roomId?: string,
	roomPid?: string,
	broadcast?: boolean
}

export const getRoomInfoService$ = (args?: getRoomServiceArgs) => {

	const request = new GetRoomInfoRequest();

	args?.roomId && request.setId(args.roomId);
	args?.roomPid && request.setPid(args.roomPid);
	args?.broadcast && request.setBroadcast(args.broadcast);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getRoomInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as RoomInfo.AsObject))
	);
};

export const createRoomWithStreamService$ = (streamId: string, type: RoomStreamType) => {

	const request = new CreateRoomRequest();
	const uuid = uuidv4();

	const roomStream = new CreatesRoomStream();
	roomStream.setType(type);
	roomStream.setSid(streamId);
	roomStream.setPaused(true);
	request.setName(`Watch Party ${uuid.substring(0, 4)}`);
	request.setStream(roomStream);
	request.setAccess(RoomAccess.RACCESS_PUBLIC);

	request.setType(RoomType.RTYPE_TEMPORARY);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.createRoom(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as RoomInfo.AsObject))
	);
};

export interface CreateRoomServiceArgs {
	eventId?: string,
	name: string
	link?: string
	limit?: number
	password?: string
	type?: RoomType
	access?: RoomAccess,
}

export const createRoomService$ = (args: CreateRoomServiceArgs) => {

	const request = new CreateRoomRequest();

	request.setName(args.name);

	args.link && request.setPid(args.link);
	args.type ? request.setType(args.type) : request.setType(RoomType.RTYPE_PERMANENT);
	args.limit && request.setLimit(args.limit);
	args.access && request.setAccess(args.access);
	args.password && request.setPassword(args.password);
	args.eventId && request.setStream(new CreatesRoomStream().setSid(args.eventId).setType(RoomStreamType.RSTYPE_EVENT));

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.createRoom(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as RoomInfo.AsObject))
	);
};

export const clearChatService$ = (chatId: string) => {

	const request = new ClearChatMessagesRequest();

	request.setChatId(chatId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.clearChatMessages(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const getEventStateService$ = () => {

	const request = new GetEventStateRequest();

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getEventState(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.state as EventState.AsObject))
	);
};

interface GetEventInfoProps {
	eventId?: string,
	pid?: string
}

export const getEventInfoService$ = ({eventId, pid}: GetEventInfoProps) => {

	const request = new GetEventInfoRequest();

	eventId && request.setId(eventId);
	pid && request.setPid(pid);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getEventInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as EventInfo.AsObject))
	);
};

export const disconnectRoomUserService$ = (userId: string) => {

	const request = new DisconnectRoomUserRequest();
	request.setUserId(userId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.disconnectRoomUser(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export interface UpdateRoomServiceArgs {
	id: string,
	name?: string
	link?: string,
	limit?: number,
	access?: RoomAccess,
	password?: string,
	thumbnailImage?: string,
	backgroundImage?: string,
}

export const updateRoomService$ = (data: UpdateRoomServiceArgs) => {

	const request = new UpdateRoomRequest();
	request.setId(data.id);

	data.name && request.setName(new RoomNameValue().setValue(data.name));
	data.link && request.setPid(new RoomPidValue().setValue(data.link));
	data.limit && request.setLimit(new RoomLimitValue().setValue(data.limit));
	data.access && request.setAccess(new RoomAccessValue().setValue(data.access));
	data.password && request.setPassword(new RoomPasswordValue().setValue(data.password));
	data.thumbnailImage === '' && request.setThumbnail(new RoomThumbnailValue().setValue(''));
	data.backgroundImage === '' && request.setBackground(new RoomBackgroundValue().setValue(''));

	const thumbnailFile = data.thumbnailImage && data.thumbnailImage !== '' ?
		dataURLtoFile(data.thumbnailImage, 'thumbnail.png') : undefined;
	const backgroundFile = data.backgroundImage && data.backgroundImage !== '' ?
		dataURLtoFile(data.backgroundImage, 'background.png') : undefined;

	return (thumbnailFile || backgroundFile ? forkJoin([
		(thumbnailFile ? uploadImageService$({image: thumbnailFile}).pipe(
			tap((tid) => request.setThumbnail(new RoomThumbnailValue().setValue(tid)))
		) : EMIT),
		(backgroundFile ? uploadImageService$({image: backgroundFile}).pipe(
			tap((tid) => request.setBackground(new RoomBackgroundValue().setValue(tid)))
		) : EMIT)
	]) : EMIT as Observable<unknown>).pipe(
		mergeMap(() => getCurrentTokenResultsFirebase$()),
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateRoom(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as RoomInfo.AsObject))
	);
};

export const updateRoomUserService$ = (params: { id?: string, userId?: string, role: RoomUserRole, }) => {

	const request = new UpdateRoomUserRequest();
	params.id && request.setId(params.id);
	params.userId && request.setUserId(params.userId);
	request.setRole(new RoomUserRoleValue().setValue(params.role));

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateRoomUser(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export interface CreateEventServiceArgs {
	name: string
	startsAt: number,
	finishesAt: number,
	type: EventType
	overseer: EventOverseer,
	prices?: Price[],
	access: EventAccess,
	source: EventSource,
	streamLink?: string,

	thumbnailImage?: string,
	backgroundImage?: string,
	summary: string,
	description?: string,
	genres: EventGenre[],
}

export function createEventService$(args: CreateEventServiceArgs) {

	const request = new CreateEventRequest();

	request.setName(args.name.trim());
	request.setStartsAt(args.startsAt);
	request.setFinishesAt(args.finishesAt);
	request.setType(args.type);
	request.setAccess(args.access);
	request.setOverseer(args.overseer);

	request.setSource(args.source);
	request.setSummary(args.summary);
	request.setGenresList(args.genres);

	args.description && request.setDescription(args.description);
	args.streamLink && request.setBroadcastUrl(args.streamLink);

	const thumbnailFile = args.thumbnailImage && args.thumbnailImage !== '' ?
		dataURLtoFile(args.thumbnailImage, 'thumbnail.png') : undefined;
	const backgroundFile = args.backgroundImage && args.backgroundImage !== '' ?
		dataURLtoFile(args.backgroundImage, 'background.png') : undefined;

	return (thumbnailFile || backgroundFile ? forkJoin([
		(thumbnailFile ? uploadImageService$({image: thumbnailFile}).pipe(
			tap((tid) => request.setThumbnail(tid))
		) : EMIT),
		(backgroundFile ? uploadImageService$({image: backgroundFile}).pipe(
			tap((tid) => request.setBackground(tid))
		) : EMIT)
	]) : EMIT as Observable<unknown>).pipe(
		mergeMap(() => getCurrentTokenResultsFirebase$()),
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.createEvent(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => of(data).pipe(
			mergeMap(() =>
				iif(() => args.prices !== undefined && data.info !== undefined,
					createEventTicketService$(data.info!.id, EventTicketType.ETTYPE_STANDARD, args.prices!).pipe(
						mergeMap((result) => result.error ? throwError(interceptError(result.error)) : of(result.info as EventTicketInfo.AsObject)),
						map((result) => data.info?.ticketsList.push(result))
					),
					EMIT
				)
			),
			map(() => data),
			mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as EventInfo.AsObject))
		))
	);
}

export interface UpdateEventServiceArgs {
	id: string,
	ticketId: string,

	name?: string
	startsAt?: number,
	finishesAt?: number,
	overseer?: EventOverseer,
	prices?: Price[],
	access?: EventAccess,
	streamLink?: string,

	thumbnailImage?: string,
	backgroundImage?: string,
	summary?: string,
	description?: string,
	genres?: EventGenre[],
}

export function updateEventService$(data: UpdateEventServiceArgs) {

	const request = new UpdateEventRequest();
	request.setId(data.id);

	data.name && request.setName(new EventNameValue().setValue(data.name));
	data.startsAt && request.setStartsAt(new EventStartsAtValue().setValue(data.startsAt));
	data.finishesAt && request.setFinishesAt(new EventFinishesAtValue().setValue(data.finishesAt));
	data.overseer && request.setOverseer(new EventOverseerValue().setValue(data.overseer));
	data.access && request.setAccess(new EventAccessValue().setValue(data.access));
	data.streamLink && request.setBroadcastUrl(new EventBroadcastUrlValue().setValue(data.streamLink));

	data.summary && request.setSummary(new EventSummaryValue().setValue(data.summary));
	data.description && request.setDescription(new EventDescriptionValue().setValue(data.description));
	data.genres && request.setGenres(new EventGenresValue().setValueList(data.genres));
	data.thumbnailImage === '' && request.setThumbnail(new EventThumbnailValue().setValue(''));
	data.backgroundImage === '' && request.setBackground(new EventBackgroundValue().setValue(''));

	const thumbnailFile = data.thumbnailImage && data.thumbnailImage !== '' ?
		dataURLtoFile(data.thumbnailImage, 'thumbnail.png') : undefined;
	const backgroundFile = data.backgroundImage && data.backgroundImage !== '' ?
		dataURLtoFile(data.backgroundImage, 'background.png') : undefined;

	return (thumbnailFile || backgroundFile ? forkJoin([
		(thumbnailFile ? uploadImageService$({image: thumbnailFile}).pipe(
			tap((tid) => request.setThumbnail(new RoomThumbnailValue().setValue(tid)))
		) : EMIT),
		(backgroundFile ? uploadImageService$({image: backgroundFile}).pipe(
			tap((tid) => request.setBackground(new RoomBackgroundValue().setValue(tid)))
		) : EMIT)
	]) : EMIT as Observable<unknown>).pipe(
		mergeMap(() =>
			iif(() => data.prices !== undefined,
				updateEventTicketService$(data.ticketId, data.prices!),
				EMIT
			)
		),
		mergeMap(() => getCurrentTokenResultsFirebase$()),
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateEvent(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as EventInfo.AsObject))
	);
}

export const listEventPerformersService$ = () => {

	const request = new ListEventPerformersRequest();
	request.setOffset(0).setLimit(100);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listEventPerformers(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const listUserTicketsService$ = () => {

	const request = new ListUserTicketsRequest();
	request.setStatusesList([UserTicketStatus.UTSTATUS_ACTIVE]);
	request.setOffset(0).setLimit(100);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listUserTickets(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.ticketsList as UserTicketInfo.AsObject[]))
	);
};

export const cancelEventService$ = (eventId: string) => {
	const request = new CancelEventRequest();
	request.setId(eventId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.cancelEvent(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data)),
		tap(() => store.dispatch(performerActions.deleteFutureEventReport({eventId: eventId})))
	);
};

export const listRoomsService$ = (params: { idsList?: string[], rolesList?: RoomUserRole[], offset?: number, limit?: number, order?: string }) => {
	const request = new ListRoomsRequest();

	params.idsList && request.setIdsList(params.idsList);
	params.rolesList && request.setRolesList(params.rolesList);
	params.offset && request.setOffset(params.offset);
	params.limit && request.setLimit(params.limit);
	params.order && request.setOrder(params.order);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listRooms(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const listRoomUsersService$ = (offset?: number) => {
	const request = new ListRoomUsersRequest();
	request.setStatusesList([RoomUserStatus.RUSTATUS_CONNECTED, RoomUserStatus.RUSTATUS_DISCONNECTED, RoomUserStatus.RUSTATUS_RECONNECTING]);
	offset && request.setOffset(offset);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listRoomUsers(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const listEventsService$ = ({
	idsList,
	roomId,
	statusesList,
	limit = 50,
	offset,
	order
}: { idsList?: string[], roomId?: string, statusesList?: EventStatus[], limit?: number, offset?: number, order?: 'ASC' | 'DESC' }) => {
	const request = new ListEventsRequest();
	idsList && request.setIdsList(idsList);
	roomId && request.setRoomId(roomId);
	request.setOffset(offset ?? 0).setLimit(limit);
	statusesList && request.setStatusesList(statusesList);
	order && request.setOrder(order);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listEvents(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const uploadImageService$ = ({image}: { image: File }) => {
	const request = new TokenizeStorageObjectRequest();

	request.setType(image.type);
	request.setSize(image.size);

	return computeChecksumMd5(image).pipe(
		mergeMap((hash) => of(hash).pipe(
				tap((hash) => {
					request.setHash(hash);
				}),
				mergeMap(() => getCurrentTokenResultsFirebase$()),
				map(token => ({
					'Authorization': 'Bearer ' + token.token,
					'Supported-Service-Version': supportedRoomsVersion
				})),
				mergeMap(header => defer(() => from(service.tokenizeStorageObject(request, header))).pipe(
					retry(retryConfig)
				)),
				map(response => response.toObject()),
				mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data)),
				map(response => atob(response.token)),
				map(response => JSON.parse(response))).pipe(
				mergeMap((d: { url: string, tid: string }) => {
					return ajax({
						url: d.url,
						method: 'PUT',
						headers: {
							'Content-MD5': hash,
							'Content-Type': image.type,
							'Access-Control-Allow-Origin': 'Origin'
						},
						body: image
					}).pipe(
						map(() => d.tid)
					);
				})
			)
		));
};

//
// CHATS
//
export const getChatStateService$ = (id: string) => {
	const request = new GetChatStateRequest();
	request.setId(id);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getChatState(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data as GetChatStateResponse.AsObject))
	);

};

export const updateChatService = (id: string, status: ChatStatus) => {
	const request = new UpdateChatRequest();
	request.setId(id).setStatus(new ChatStatusValue().setValue(status));

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateChat(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data as UpdateChatResponse.AsObject))
	);

};

export const startEventService$ = (eventId?: string) => {

	const request = new StartEventRequest();
	eventId && request.setId(eventId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.startEvent(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const finishEventService$ = (eventId?: string) => {

	const request = new FinishEventRequest();
	eventId && request.setId(eventId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.finishEvent(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const getEventReportInfoService$ = (eventId: string) => {

	const request = new GetEventReportInfoRequest();
	request.setEventId(eventId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getEventReportInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as WithRequired<EventReportInfo.AsObject, 'info' | 'merchant'>))
	);
};

export const listEventReportsService$ = (eventIds: string[]) => {

	const request = new ListEventReportsRequest();
	request.setEventIdsList(eventIds);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.listEventReports(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const getEventOwnerReportInfoService$ = () => {

	const request = new GetEventOwnerReportInfoRequest();

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getEventOwnerReportInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as EventOwnerReportInfo.AsObject))
	);
};

export const resetEventService$ = (eventId: string) => {

	const request = new RestartEventRequest();
	request.setId(eventId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.restartEvent(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const updateEventLinkService$ = (eventId: string, link: string, broadcastOffset?: number) => {

	const request = new UpdateEventRequest();
	request.setId(eventId).setBroadcastUrl(new EventBroadcastUrlValue().setValue(link));
	broadcastOffset && request.setBroadcastOffset(new EventBroadcastOffsetValue().setValue(broadcastOffset));

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateEvent(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);
};

export const createConnectionService$ = (token: string, offer: RTCSessionDescriptionInit) => {

	const request = new CreateConnectionRequest();
	request.setSdpOffer(btoa(JSON.stringify(offer)));
	request.setToken(token);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => ({'Authorization': 'Bearer ' + token.token})),
		mergeMap(header => defer(() => from(babySfuService.createConnection(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.sdpAnswer as string))
	);
};

export const createEventTokenService$ = (eventId?: string) => {

	const request = new TokenizeEventRequest();
	eventId && request.setId(eventId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.tokenizeEvent(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.token))
	);
};

export const switchEventPerformerService$ = (userId: string) => {
	const request = new SwitchEventPerformerRequest();
	request.setUserId(userId);
	// request.setPosition()

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.switchEventPerformer(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as EventPerformerInfo.AsObject))
	);
};

export const confirmEventPerformerService$ = () => {
	const request = new ConfirmEventPerformerRequest();

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.confirmEventPerformer(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as EventPerformerInfo.AsObject))
	);
};

export const deleteEventPerformerService$ = (userId: string) => {
	const request = new DeleteEventPerformerRequest();
	request.setUserId(userId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.deleteEventPerformer(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : EMIT)
	);
};

export const deleteRoomService$ = (roomId: string) => {
	const request = new DeleteRoomRequest();
	request.setId(roomId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.deleteRoom(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : EMIT)
	);
};

export const leaveRoomService$ = (roomId: string) => {
	const request = new LeaveRoomRequest();
	request.setId(roomId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.leaveRoom(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : EMIT)
	);
};

export function activateRoomService$() {
	const request = new ActivateRoomRequest();

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.activateRoom(request, header))).pipe(
			retry(retryConfig)
		)),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : EMIT)
	);
}

export const insertRoomStreamService$ = (id: string, type: RoomStreamType, duration: number, offset: number, suggest?: boolean, roomId?: string) => {
	const request = new InsertRoomStreamRequest();
	request.setType(type);
	request.setSid(id);
	request.setDuration(duration === 0 ? (6 * 3600) : duration);
	request.setOffset(offset);
	suggest && request.setSuggest(suggest);
	roomId && request.setRoomId(roomId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.insertRoomStream(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as InsertRoomStreamResponse.AsObject))
	);
};

export const updateRoomStreamService$ = (id: string, paused?: boolean, offset?: number) => {
	const request = new UpdateRoomStreamRequest();
	const metadata = new RoomStreamMetadataValue();
	if (paused !== undefined) {
		metadata.setPaused(new RoomStreamMetadataPausedValue().setValue(paused));
	}
	if (offset !== undefined) {
		metadata.setOffset(new RoomStreamMetadataOffsetValue().setValue(offset));
	}
	request.setId(id);
	request.setMetadata(metadata);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateRoomStream(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : EMIT)
	);
};

export const removeRoomStreamService$ = (id: string) => {
	const request = new RemoveRoomStreamRequest();
	request.setId(id);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.removeRoomStream(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : EMIT)
	);
};

export const clearRoomStreamsService$ = () => {
	const request = new ClearRoomStreamsRequest();

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.clearRoomStreams(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : EMIT)
	);
};

export const changeRoomStreamService$ = (prev: number, next: number) => {
	const request = new ChangeRoomStreamRequest();
	request.setSourcePosition(prev);
	request.setTargetPosition(next);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.changeRoomStream(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : EMIT)
	);
};

export const getRoomUserInfoService$ = (id: string) => {
	const request = new GetRoomUserInfoRequest();
	request.setUserId(id);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getRoomUserInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as RoomUserInfo.AsObject))
	);
};

export const getRecommendationInfoService$ = () => {
	const request = new ListRecommendationsRequest();

	return of(prepareHeaders()).pipe(
		mergeMap(header => defer(() => from(service.listRecommendations(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.recommendationsList as RecommendationInfo.AsObject[]))
	);
};

export const getTiktokObjectInfoService$ = (id: string) => {
	const request = new GetTiktokObjectInfoRequest();
	request.setId(id);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.getTiktokObjectInfo(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data.info as TiktokObjectInfo.AsObject))
	);
};

export const searchYoutubeObjectsService$ = (search: string, limit: number, token?: string) => {
	const request = new SearchYoutubeObjectsRequest();
	request.setQuery(search);
	request.setLimit(limit);
	token && request.setToken(token);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.searchYoutubeObjects(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data as SearchYoutubeObjectsResponse.AsObject))
	);
};

export const searchTiktokObjectsService$ = (search: string, limit: number, offset?: number) => {
	const request = new SearchTiktokObjectsRequest();
	request.setQuery(search);
	request.setLimit(limit);
	offset && request.setOffset(offset);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.searchTiktokObjects(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data as SearchTiktokObjectsResponse.AsObject))
	);
};

export const setPlaylistModeService$ = (id: string, suggest: boolean, related: boolean) => {
	const request = new UpdateRoomStreamRequest();
	request.setId(id);

	const metadata = new RoomStreamMetadataValue();
	metadata.setSuggest(new RoomStreamMetadataSuggestValue().setValue(suggest));
	metadata.setRelated(new RoomStreamMetadataRelatedValue().setValue(related));

	request.setMetadata(metadata);
	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token)),
		mergeMap(header => defer(() => from(service.updateRoomStream(request, header))).pipe(
			retry(retryConfig)
		)),
		map(response => response.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data as UpdateRoomStreamResponse.AsObject))
	);
};

