import {
	addRoomStreamWsGuard,
	authenticationPacketWsGuard,
	cancelEventPacketWsGuard,
	changeStreamOffsetWsGuard,
	clearChatWsGuard,
	clearRoomStreamsWsGuard,
	createEventObserverPacketWsGuard,
	createEventPerformerPacketWsGuard,
	createUserRelationWsGuard,
	createUserTicketPacketWsGuard,
	deleteEventObserverPacketWsGuard,
	deleteEventPerformerPacketWsGuard,
	deleteRoomChatMessagePacketWsGuard,
	deleteRoomPacketWsGuard,
	deleteRoomStreamWsGuard,
	deleteUserChatMessagePacketWsGuard,
	deleteUserRelationWsGuard,
	deliverRoomInvitePacketWsGuard,
	disconnectRoomUserPacketWsGuard,
	disconnectSessionPacketWsGuard,
	disconnectUserPacketWsGuard,
	enableMaintenanceBreakPacketWsGuard,
	eventChatMessagePacketWsGuard,
	failurePacketWsGuard,
	finishEventPacketWsGuard,
	iceCandidatePacketWsGuard,
	offerPacketWsGuard,
	pauseRoomStreamWsGuard,
	prepareEventPerformerPacketWsGuard,
	refreshEventBroadcastPacketWsGuard,
	refreshUserTokenPacketWsGuard,
	refreshWebappPacketWsGuard,
	remindMaintenanceBreakPacketWsGuard,
	roomChatMessagePacketWsGuard,
	startEventPacketWsGuard,
	streamsAnswerPacketWsGuard,
	successPacketWsGuard,
	updateChatStatusWsGuard,
	updateEventAudienceWsGuard,
	updateEventNamePacketWsGuard,
	updateEventPerformerPositionPacketWsGuard,
	updateEventStatusPacketWsGuard,
	updatePrivateChatMessageContentPacketWsGuard,
	updateRoomBackgroundPacketWsGuard,
	updateRoomChatMessageContentPacketWsGuard,
	updateRoomNamePacketWsGuard,
	updateRoomPidPacketWsGuard,
	updateRoomStreamMetadataRelatedWsGuard,
	updateRoomStreamMetadataStackWsGuard,
	updateRoomStreamMetadataSuggestWsGuard,
	updateRoomStreamPositionWsGuard,
	updateRoomTypePacketWsGuard,
	updateRoomUserFlagsPacketWsGuard,
	updateRoomUserReceiverConnectionStatusPacketWsGuard,
	updateRoomUserRolePacketWsGuard,
	updateRoomUserSenderConnectionStatusPacketWsGuard,
	updateRoomUserStatusPacketWsGuard,
	updateUserAvatarWsGuard,
	updateUserNameWsGuard,
	updateUserStatusWsGuard,
	userChatMessagePacketWsGuard,
	WsMessageType
} from './incomingMessagesTypes.ws';

import {
	AuthenticationPacket,
	DisconnectRoomUserPacket,
	DisconnectSessionPacket,
	DisconnectUserPacket,
	EnableMaintenanceBreakPacket,
	FailurePacket,
	Packet,
	RefreshPacket,
	RemindMaintenanceBreakPacket,
	SuccessPacket
} from '../gRPC/wsserver/packets.public_pb';
import {EventChatMessagePacket, RoomChatMessagePacket, UserChatMessagePacket} from '../gRPC/wsgateway/packets.public_pb';
import {EMPTY, of} from 'rxjs';
import {
	CancelEventPacket,
	ClearChatMessagesPacket,
	ClearRoomStreamsPacket,
	CreateEventObserverPacket,
	CreateEventPerformerPacket,
	CreateRoomStreamPacket,
	CreateUserTicketPacket,
	DeleteChatMessagePacket as DeleteRoomChatMessagePacket,
	DeleteEventObserverPacket,
	DeleteEventPerformerPacket,
	DeleteRoomPacket,
	DeleteRoomStreamPacket,
	DeliverRoomInvitePacket,
	FinishEventPacket,
	PrepareEventPerformerPacket,
	RefreshEventBroadcastPacket,
	StartEventPacket,
	UpdateChatMessageContentPacket as UpdateRoomChatMessageContentPacket,
	UpdateChatStatusPacket,
	UpdateEventAudiencePacket,
	UpdateEventNamePacket,
	UpdateEventPerformerPositionPacket,
	UpdateEventStatusPacket,
	UpdateRoomBackgroundPacket,
	UpdateRoomNamePacket,
	UpdateRoomPidPacket,
	UpdateRoomStreamMetadataOffsetPacket,
	UpdateRoomStreamMetadataPausedPacket,
	UpdateRoomStreamMetadataRelatedPacket,
	UpdateRoomStreamMetadataStackPacket,
	UpdateRoomStreamMetadataSuggestPacket,
	UpdateRoomStreamPositionPacket,
	UpdateRoomTypePacket,
	UpdateRoomUserFlagsPacket,
	UpdateRoomUserReceiverConnectionStatusPacket,
	UpdateRoomUserRolePacket,
	UpdateRoomUserSenderConnectionStatusPacket,
	UpdateRoomUserStatusPacket
} from '../gRPC/rooms/packets.public_pb';
import {
	CreateUserRelationPacket,
	DeleteChatMessagePacket as DeleteUserChatMessagePacket,
	DeleteUserRelationPacket,
	RefreshUserTokenPacket,
	UpdateChatMessageContentPacket as UpdateChatPrivateMessageContentPacket,
	UpdateUserAvatarPacket,
	UpdateUserNamePacket,
	UpdateUserStatusPacket
} from '../gRPC/users/packets.public_pb';
import {IceCandidatePacket, OfferPacket, StreamsAnswerPacket} from '../gRPC/sfu/packets_pb';

///
/// Sorter for received messages from web socket
///
/// * arrangement based on MessageType and Ws guards
///

export const webSocketSorter$ = (serverPacket: Packet.AsObject, messageType: WsMessageType) => {

	if (serverPacket) {

		////////////
		//////////// WebSocket Main
		////////////

		/// Ping Packet
		// ping packed is skipped in webSocketCore$

		switch (messageType) {

			/// Authorized Packet
			case WsMessageType.AUTHENTICATION: {
				if (authenticationPacketWsGuard(serverPacket)) {
					return of(AuthenticationPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Success Packet
			case WsMessageType.SUCCESS: {
				if (successPacketWsGuard(serverPacket)) {
					return of(SuccessPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Failure Packet
			case WsMessageType.FAILURE: {
				if (failurePacketWsGuard(serverPacket)) {
					return of(FailurePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Refresh Webapp Packet
			case WsMessageType.REFRESH_WEBAPP: {
				if (refreshWebappPacketWsGuard(serverPacket)) {
					return of(RefreshPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Remind Maintenance Break Packet
			case WsMessageType.REMIND_MAINTENANCE_BREAK: {
				if (remindMaintenanceBreakPacketWsGuard(serverPacket)) {
					return of(RemindMaintenanceBreakPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Enable Maintenance Break Packet
			case WsMessageType.ENABLE_MAINTENANCE_BREAK: {
				if (enableMaintenanceBreakPacketWsGuard(serverPacket)) {
					return of(EnableMaintenanceBreakPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Refresh Token Packet
			case WsMessageType.REFRESH_TOKEN: {
				if (refreshUserTokenPacketWsGuard(serverPacket)) {
					return of(RefreshUserTokenPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Disconnect Session Packet
			case WsMessageType.DISCONNECT_SESSION: {
				if (disconnectSessionPacketWsGuard(serverPacket)) {
					return of(DisconnectSessionPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			////////////
			//////////// SFU
			////////////

			/// Offer
			case WsMessageType.OFFER: {
				if (offerPacketWsGuard(serverPacket)) {
					return of(OfferPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Streams Answer
			case WsMessageType.STREAMS_ANSWER: {
				if (streamsAnswerPacketWsGuard(serverPacket)) {
					return of(StreamsAnswerPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Ice candidate
			case WsMessageType.ICE_CANDIDATE: {
				if (iceCandidatePacketWsGuard(serverPacket)) {
					return of(IceCandidatePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}


			////////////
			//////////// Private Chat
			////////////

			/// Receive Message
			case WsMessageType.PRIVATE_CHAT_MESSAGE: {
				if (userChatMessagePacketWsGuard(serverPacket)) {
					return of(UserChatMessagePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Message
			case WsMessageType.UPDATE_PRIVATE_CHAT_MESSAGE_CONTENT: {
				if (updatePrivateChatMessageContentPacketWsGuard(serverPacket)) {
					return of(UpdateChatPrivateMessageContentPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Delete Message
			case WsMessageType.DELETE_PRIVATE_CHAT_MESSAGE: {
				if (deleteUserChatMessagePacketWsGuard(serverPacket)) {
					return of(DeleteUserChatMessagePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			//////
			////// Room
			//////

			/// Update Room Name
			case WsMessageType.UPDATE_ROOM_NAME: {
				if (updateRoomNamePacketWsGuard(serverPacket)) {
					return of(UpdateRoomNamePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Room Type
			case WsMessageType.UPDATE_ROOM_TYPE: {
				if (updateRoomTypePacketWsGuard(serverPacket)) {
					return of(UpdateRoomTypePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Room Type
			case WsMessageType.UPDATE_ROOM_BACKGROUND: {
				if (updateRoomBackgroundPacketWsGuard(serverPacket)) {
					return of(UpdateRoomBackgroundPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Room Pid
			case WsMessageType.UPDATE_ROOM_PID: {
				if (updateRoomPidPacketWsGuard(serverPacket)) {
					return of(UpdateRoomPidPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Delete Room
			case WsMessageType.DELETE_ROOM: {
				if (deleteRoomPacketWsGuard(serverPacket)) {
					return of(DeleteRoomPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Send Room Invite
			case WsMessageType.DELIVER_ROOM_INVITE: {
				if (deliverRoomInvitePacketWsGuard(serverPacket)) {
					return of(DeliverRoomInvitePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			//////
			////// Room user
			//////

			/// Update User Status
			case WsMessageType.UPDATE_ROOM_USER_STATUS: {
				if (updateRoomUserStatusPacketWsGuard(serverPacket)) {
					return of(UpdateRoomUserStatusPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.UPDATE_ROOM_USER_RECEIVER_CONNECTION_STATUS: {
				if (updateRoomUserReceiverConnectionStatusPacketWsGuard(serverPacket)) {
					return of(UpdateRoomUserReceiverConnectionStatusPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.UPDATE_ROOM_USER_SENDER_CONNECTION_STATUS: {
				if (updateRoomUserSenderConnectionStatusPacketWsGuard(serverPacket)) {
					return of(UpdateRoomUserSenderConnectionStatusPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			//////
			////// Event / Stream
			//////

			/// Create Event Observer
			case WsMessageType.CREATE_EVENT_OBSERVER: {
				if (createEventObserverPacketWsGuard(serverPacket)) {
					return of(CreateEventObserverPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Delete Event Observer
			case WsMessageType.DELETE_EVENT_OBSERVER: {
				if (deleteEventObserverPacketWsGuard(serverPacket)) {
					return of(DeleteEventObserverPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Event Name
			case WsMessageType.UPDATE_EVENT_NAME: {
				if (updateEventNamePacketWsGuard(serverPacket)) {
					return of(UpdateEventNamePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Event Status
			case WsMessageType.UPDATE_EVENT_STATUS: {
				if (updateEventStatusPacketWsGuard(serverPacket)) {
					return of(UpdateEventStatusPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Prepare Event Performer
			case WsMessageType.PREPARE_EVENT_PERFORMER: {
				if (prepareEventPerformerPacketWsGuard(serverPacket)) {
					return of(PrepareEventPerformerPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Create Event Performer
			case WsMessageType.CREATE_EVENT_PERFORMER: {
				if (createEventPerformerPacketWsGuard(serverPacket)) {
					return of(CreateEventPerformerPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Delete Event Performer
			case WsMessageType.DELETE_EVENT_PERFORMER: {
				if (deleteEventPerformerPacketWsGuard(serverPacket)) {
					return of(DeleteEventPerformerPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Event Performer Position
			case WsMessageType.UPDATE_EVENT_PERFORMER_POSITION: {
				if (updateEventPerformerPositionPacketWsGuard(serverPacket)) {
					return of(UpdateEventPerformerPositionPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Start Event
			case WsMessageType.FINISH_EVENT: {
				if (finishEventPacketWsGuard(serverPacket)) {
					return of(FinishEventPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Finish Event
			case WsMessageType.START_EVENT: {
				if (startEventPacketWsGuard(serverPacket)) {
					return of(StartEventPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Cancel Event
			case WsMessageType.CANCEL_EVENT: {
				if (cancelEventPacketWsGuard(serverPacket)) {
					return of(CancelEventPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Event Livestream
			case WsMessageType.REFRESH_EVENT_BROADCAST: {
				if (refreshEventBroadcastPacketWsGuard(serverPacket)) {
					return of(RefreshEventBroadcastPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Audience
			case WsMessageType.UPDATE_EVENT_AUDIENCE: {
				if (updateEventAudienceWsGuard(serverPacket)) {
					return of(UpdateEventAudiencePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.PAUSE_ROOM_STREAM: {
				if (pauseRoomStreamWsGuard(serverPacket)) {
					return of(UpdateRoomStreamMetadataPausedPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.CHANGE_OFFSET_ROOM_STREAM: {
				if (changeStreamOffsetWsGuard(serverPacket)) {
					return of(UpdateRoomStreamMetadataOffsetPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.CREATE_ROOM_STREAM: {
				if (addRoomStreamWsGuard(serverPacket)) {
					return of(CreateRoomStreamPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.DELETE_ROOM_STREAM: {
				if (deleteRoomStreamWsGuard(serverPacket)) {
					return of(DeleteRoomStreamPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.CLEAR_ROOM_STREAMS: {
				if (clearRoomStreamsWsGuard(serverPacket)) {
					return of(ClearRoomStreamsPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.UPDATE_ROOM_STREAM_POSITON: {
				if (updateRoomStreamPositionWsGuard(serverPacket)) {
					return of(UpdateRoomStreamPositionPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.SUGGEST_ROOM_STREAMS: {
				if (updateRoomStreamMetadataSuggestWsGuard(serverPacket)) {
					return of(UpdateRoomStreamMetadataSuggestPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.RELATED_ROOM_STREAMS: {
				if (updateRoomStreamMetadataRelatedWsGuard(serverPacket)) {
					return of(UpdateRoomStreamMetadataRelatedPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.STACK_ROOM_STREAMS: {
				if (updateRoomStreamMetadataStackWsGuard(serverPacket)) {
					return of(UpdateRoomStreamMetadataStackPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			//////
			////// User
			//////

			/// Update Room User Role
			case WsMessageType.UPDATE_ROOM_USER_ROLE: {
				if (updateRoomUserRolePacketWsGuard(serverPacket)) {
					return of(UpdateRoomUserRolePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update User Flags
			case WsMessageType.UPDATE_ROOM_USER_FLAGS: {
				if (updateRoomUserFlagsPacketWsGuard(serverPacket)) {
					return of(UpdateRoomUserFlagsPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Disconnect room user
			case WsMessageType.DISCONNECT_ROOM_USER: {
				if (disconnectRoomUserPacketWsGuard(serverPacket)) {
					return of(DisconnectRoomUserPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Disconnect user
			case WsMessageType.DISCONNECT_USER: {
				if (disconnectUserPacketWsGuard(serverPacket)) {
					return of(DisconnectUserPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Create user ticket
			case WsMessageType.CREATE_USER_TICKET: {
				if (createUserTicketPacketWsGuard(serverPacket)) {
					return of(CreateUserTicketPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			//////
			////// Chat
			//////

			case WsMessageType.UPDATE_CHAT_STATUS: {
				if (updateChatStatusWsGuard(serverPacket)) {
					return of(UpdateChatStatusPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			case WsMessageType.CLEAR_CHAT: {
				if (clearChatWsGuard(serverPacket)) {
					return of(ClearChatMessagesPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			//////
			////// Friends
			//////

			case WsMessageType.CREATE_USER_RELATION: {
				if (createUserRelationWsGuard(serverPacket)) {
					return of(CreateUserRelationPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}
			case WsMessageType.DELETE_USER_RELATION: {
				if (deleteUserRelationWsGuard(serverPacket)) {
					return of(DeleteUserRelationPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}
			case WsMessageType.UPDATE_USER_STATUS: {
				if (updateUserStatusWsGuard(serverPacket)) {
					return of(UpdateUserStatusPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}
			case WsMessageType.UPDATE_USER_NAME: {
				if (updateUserNameWsGuard(serverPacket)) {
					return of(UpdateUserNamePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}
			case WsMessageType.UPDATE_USER_AVATAR: {
				if (updateUserAvatarWsGuard(serverPacket)) {
					return of(UpdateUserAvatarPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}
			//////
			////// Message
			//////

			/// Receive Room Message
			case WsMessageType.ROOM_CHAT_MESSAGE: {
				if (roomChatMessagePacketWsGuard(serverPacket)) {
					return of(RoomChatMessagePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Receive Event Message
			case WsMessageType.EVENT_CHAT_MESSAGE: {
				if (eventChatMessagePacketWsGuard(serverPacket)) {
					return of(EventChatMessagePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Update Message
			case WsMessageType.UPDATE_ROOM_CHAT_MESSAGE_CONTENT: {
				if (updateRoomChatMessageContentPacketWsGuard(serverPacket)) {
					return of(UpdateRoomChatMessageContentPacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			/// Delete Message
			case WsMessageType.DELETE_ROOM_CHAT_MESSAGE: {
				if (deleteRoomChatMessagePacketWsGuard(serverPacket)) {
					return of(DeleteRoomChatMessagePacket.deserializeBinary(serverPacket.data).toObject());
				} else {
					return EMPTY;
				}
			}

			default: {
				console.log(messageType);
				console.error('This type of WS message was not handled properly.');
				return EMPTY;
			}

		}

	} else {
		console.error('Server packet not passed in webSocketSorter$ function');
		return EMPTY;
	}
};
