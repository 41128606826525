import {EMPTY, forkJoin, from, of, throwError} from 'rxjs';
import {roomGlobalRef} from '../roomGlobalRef';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {selectClientId, selectIsSharingScreenVideo} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {roomUsersActions} from '../../../../store/slices/roomUsers';
import {Notification, showNotification} from '../../../../utils/showNotification';
import {toggleScreenShare} from './useStoreServiceFlagUpdater';
import platform from 'platform';
import {EMIT} from '../../../../utils/utils';
import {BrowserType, retrieveBrowserType} from '../../../../utils/browserTypeDetection';

export const startSharingScreen = () => {
	if (selectIsSharingScreenVideo(store.getState())) {
		console.error(new Error('failed to invoke startSharingScreen(), user is already sharing the screen, skipping'));
		return;
	}

	if (platform.name?.toLowerCase().includes('mobile')) {
		console.error(new Error('screen-sharing on mobile devices is not supported'));
		return;
	}

	const clientId = selectClientId(store.getState());
	const isSafari = retrieveBrowserType() === BrowserType.SAFARI;

	const deviceOptions = {
		video: {
			cursor: 'always',
			frameRate: {max: 30},

			/// on safari 'ideal' parameter brakes the stream
			width: isSafari ? {max: 1920} : {ideal: 1920, max: 1920},
			height: isSafari ? {max: 1080} : {ideal: 1080, max: 1080},
		},
		audio: {
			echoCancellation: true,
			noiseSuppression: true,
			sampleRate: 44100
		}
	};

	return from(navigator.mediaDevices.getDisplayMedia(deviceOptions)).pipe(
		map((stream) => stream as MediaStream),
		mergeMap((stream) => forkJoin([
			of(stream.getVideoTracks()[0]).pipe(mergeMap((screenVideoTrack) => {
				setVideoTrackContentHints(stream, 'text');

				screenVideoTrack.onended = () => {
					toggleScreenShare();
				};
				roomGlobalRef.clientScreenVideoTrack?.stop();
				roomGlobalRef.clientScreenVideoTrack = screenVideoTrack;
				return roomGlobalRef.cameraTransceiversMap.get(`screen-${clientId}`)!.sender.replaceTrack(screenVideoTrack);
			})),
			of(stream.getAudioTracks()[0]).pipe(mergeMap((screenAudioTrack: MediaStreamTrack | undefined) => {
				if (screenAudioTrack) {
					roomGlobalRef.clientScreenAudioTrack?.stop();
					roomGlobalRef.clientScreenAudioTrack = screenAudioTrack;
					return roomGlobalRef.audioTransceiversMap.get(`screen-audio-${clientId}`)!.sender.replaceTrack(screenAudioTrack);
				} else {
					return EMIT;
				}
			}))
		]).pipe(
			map(() => {
				roomGlobalRef.usersScreenVideoMediaStreams.set(clientId, stream);
				store.dispatch(roomUsersActions.setUserMediaTrackId({userId: clientId, trackId: stream.getVideoTracks()[0].id, type: 'screenVideo'}));
				if (stream.getAudioTracks().length) {
					roomGlobalRef.usersScreenAudioMediaStreams.set(clientId, stream);
					store.dispatch(roomUsersActions.setUserMediaTrackId({userId: clientId, trackId: stream.getAudioTracks()[0].id, type: 'screenAudio'}));
				}
				return stream;
			})
		)),
		catchError((err) => {
			if (err.message.toLowerCase().includes('permission')) {
				showNotification(Notification.INFO, 'Browser couldn\'t access your screen. Please check your system permissions.');
			} else if (err.message.toLowerCase().includes('user gesture handler')) {
				// skipping this error
				return EMPTY;
			}
			return throwError(() => new Error(`screen share exception: ${err.message}`));
		})
	);
};

export const stopSharingScreen = () => {

	if (!selectIsSharingScreenVideo(store.getState())) {
		console.error(new Error('failed to invoke stopSharingScreen(), user is not sharing the screen, skipping'));
		return;
	}

	const clientId = selectClientId(store.getState());
	roomGlobalRef.clientScreenVideoTrack?.stop();
	roomGlobalRef.clientScreenAudioTrack?.stop();
	store.dispatch(roomUsersActions.removeUserMediaTrack({userId: clientId, type: 'screenVideo'}));
	store.dispatch(roomUsersActions.removeUserMediaTrack({userId: clientId, type: 'screenAudio'}));
	roomGlobalRef.usersScreenVideoMediaStreams.delete(clientId);
	roomGlobalRef.usersScreenAudioMediaStreams.delete(clientId);
};

export const setVideoTrackContentHints = (stream: MediaStream, hint: string) => {
	const tracks = stream.getVideoTracks();
	tracks.forEach(track => {
		if ('contentHint' in track) {
			track.contentHint = hint;
			if (track.contentHint !== hint) {
				console.error('Invalid video track contentHint: \'' + hint + '\'');
			}
		} else {
			console.error('MediaStreamTrack contentHint attribute not supported');
		}
	});
};
