import React, {FC, useEffect, useRef, useState} from 'react';
import {ErrorTextS} from '../ErrorText/ErrorTextS';
import {LabelS} from '../Label/LabelS';
import {InputS} from './InputS';
import styled from 'styled-components';
import {useIsFirstRender} from '../../utils/customHooks/useIsFirstRender';
import {remm} from '../../utils/remm';

export interface InputProps extends React.ComponentPropsWithRef<'input'> {
	error?: string;
	label?: string;
	inline?: boolean;
	fixedText?: string;
	inputRef?: React.RefObject<HTMLInputElement> | null | undefined;
}

export const Input: FC<InputProps> = ({
	error = '',
	label,
	inline = false,
	className,
	fixedText,
	inputRef,
	...restProps
}) => {

	const isFirstRender = useIsFirstRender();
	const fixedTextRef = useRef<HTMLDivElement>(null);
	const [extraPadding, setExtraPadding] = useState<number | undefined>();

	useEffect(() => {
		if (fixedText && isFirstRender) {
			setExtraPadding(fixedTextRef.current!.offsetWidth);
		}
	}, [fixedText, isFirstRender]);

	return (
		<div style={{position: 'relative'}} className={`flex-grow-1 ${inline ? 'd-flex align-items-center' : ''}`}>
			{label && <LabelS>{label}</LabelS>}
			{fixedText &&
				<InputFixedTextS ref={fixedTextRef}>
					{fixedText}
				</InputFixedTextS>
			}
			<InputS {...restProps}
			        ref={inputRef}
			        error={error}
			        className={className}
			        extraPadding={extraPadding}/>
			<ErrorTextS>{error}</ErrorTextS>
		</div>
	);
};

export const InputFixedTextS = styled.div`
	color: ${({theme}) => theme.weakTextColor};
	text-align: left;
	padding: 13px;
	font-weight: 600;
	font-size: ${remm('15px')};
	letter-spacing: normal;
	position: absolute;
	transform: translateY(-2px);
`;
