import React, {FC} from 'react';
import {BoxS} from './BoxS';

export interface BoxProps extends React.ComponentPropsWithoutRef<'div'> {
	title?: string;
	width?: string;
	minHeight?: string;
	height?: string;
}

export const Box: FC<BoxProps> = ({
	title,
	children,
	...restProps
}) => {
	return (
		<BoxS {...restProps}>
			{title &&
			<div className='BoxS_header'>
				<span className='BoxS_title'>{title}</span>
			</div>}
			<div className="BoxS_body">
				{children}
			</div>
		</BoxS>
	);
};

