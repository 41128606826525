import {Subscription} from 'rxjs';
import clonedeep from 'lodash.clonedeep';
import {userLeaveRoomSenderWS$} from '../../../services/ws/senders.ws';
import {CameraMode} from './updaters/userMediaVideo';
import {disconnectSFU} from '../../../services/sfu/senders.sfu';
import {Codec, SfuType} from '../../../services/gRPC/sfu/enums_pb';
import {SubscriptionManager} from './SubscriptionManager';
import {SubscriptionState} from './SubscriptionState';
import {VideoStreamMuteManager} from './VideoStreamMuteManager';
import {FlagsQueue} from './FlagsQueue';
import {StatusesQueue} from './StatusesQueue';
import {RoomUsersStatusesQueue} from './RoomUsersStatusesQueue';
import {ActiveStreamsResponsePacket} from '../../../services/gRPC/sfu/packets_pb';

export enum RoomMode {
	STANDARD,
	NO_RECEIVER
}

interface RoomGlobalRef {
	currentMode: RoomMode,
	maxVideoBitrate?: number,
	maxScreenBitrate?: number,
	currentCodec?: Codec, // if not set - the codec is default
	shouldResendFlags: boolean // used for quick reconnect, when disconnected => connected
	currentRoomPid?: string,
	pcMediaSender?: RTCPeerConnection,
	pcMediaReceiver?: RTCPeerConnection,
	pcMediaPerformer?: RTCPeerConnection,
	currentSubscriptionState: SubscriptionState,
	clientSubscriptionState: SubscriptionState,
	flagsQueue: FlagsQueue,
	statusesQueue: StatusesQueue,
	roomUsersStatusesQueue: RoomUsersStatusesQueue,
	subscriptionManager?: SubscriptionManager,
	cameraTransceiversMap: Map<string, RTCRtpTransceiver>,
	audioTransceiversMap: Map<string, RTCRtpTransceiver>,
	unusedVideoTransceivers: RTCRtpTransceiver[],
	unusedAudioTransceivers: RTCRtpTransceiver[],
	performerVideoTransceiver?: RTCRtpTransceiver,
	performerAudioTransceiver?: RTCRtpTransceiver,
	mediaReceiverServerChannel?: RTCDataChannel,
	mediaSenderServerChannel?: RTCDataChannel,
	clientCameraTrack?: MediaStreamTrack,
	clientScreenVideoTrack?: MediaStreamTrack,
	clientScreenAudioTrack?: MediaStreamTrack,
	clientAudioDestinationTrack?: MediaStreamTrack,
	clientAudioOriginalTrack?: MediaStreamTrack,
	gainNode?: GainNode,
	audioContext?: AudioContext,
	analyser?: AnalyserNode,
	videoTrackIds: Map<string, string>,
	roomSubscriptions: Set<Subscription>,
	cameraMode?: CameraMode,
	isUserLeavingRoom: boolean,
	usersIdsThatLeftRoom: Set<string> // used to update correctly room state while entering room.
	usersAudioMediaStreams: Map<string, MediaStream>
	usersCameraMediaStreams: Map<string, MediaStream>
	usersScreenVideoMediaStreams: Map<string, MediaStream>
	usersScreenAudioMediaStreams: Map<string, MediaStream>
	performerAudioMediaStream?: MediaStream
	mcuVideoMediaStream?: MediaStream // it is video incoming from mcu (can be grid or performer stream)
	queuedCandidates: {
		[SfuType.STYPE_GATEWAY]: {
			unsentCandidates: any[],
			notAddedCandidates: any[],
			pendingSubscriptions: Subscription[],
			isOfferReady: boolean
		}
		[SfuType.STYPE_ROUTER]: {
			unsentCandidates: any[],
			notAddedCandidates: any[],
			pendingSubscriptions: Subscription[],
			isOfferReady: boolean
		}
		addUnsent: (sfuType: SfuType, candidate: any) => void,
		shiftUnsent: (sfuType: SfuType) => void,
		addNotAdded: (sfuType: SfuType, candidate: any) => void,
		shiftNotAdded: (sfuType: SfuType) => void
		setIsOfferReady: (sfuType: SfuType, isOfferReady: boolean) => void,
		clearCandidates: (sfuType: SfuType) => void
		addSub: (sfuType: SfuType, sub: Subscription) => void
	}
	videoStreamMuteManager: VideoStreamMuteManager
}

export const initialRoomGlobalRef: RoomGlobalRef = {
	currentMode: RoomMode.STANDARD,
	shouldResendFlags: false,
	currentSubscriptionState: new SubscriptionState(true),
	clientSubscriptionState: new SubscriptionState(false),
	flagsQueue: new FlagsQueue(),
	statusesQueue: new StatusesQueue(),
	roomUsersStatusesQueue: new RoomUsersStatusesQueue(),
	cameraTransceiversMap: new Map(),
	audioTransceiversMap: new Map(),
	unusedVideoTransceivers: [],
	unusedAudioTransceivers: [],
	videoTrackIds: new Map(),
	roomSubscriptions: new Set<Subscription>(),
	isUserLeavingRoom: false,
	usersIdsThatLeftRoom: new Set(),
	usersAudioMediaStreams: new Map(),
	usersCameraMediaStreams: new Map(),
	usersScreenVideoMediaStreams: new Map(),
	usersScreenAudioMediaStreams: new Map(),
	queuedCandidates: {
		[SfuType.STYPE_ROUTER]: {
			unsentCandidates: [],
			notAddedCandidates: [],
			pendingSubscriptions: [],
			isOfferReady: false
		},
		[SfuType.STYPE_GATEWAY]: {
			unsentCandidates: [],
			notAddedCandidates: [],
			pendingSubscriptions: [],
			isOfferReady: false
		},
		addUnsent: function (sfuType: SfuType, candidate: any) {
			if (sfuType !== SfuType.STYPE_UNKNOWN) {
				this[sfuType].unsentCandidates.push(candidate);
			}
		},
		shiftUnsent: function (sfuType: SfuType) {
			if (sfuType !== SfuType.STYPE_UNKNOWN) {
				this[sfuType].unsentCandidates.shift();
			}
		},
		addNotAdded: function (sfuType: SfuType, candidate: any) {
			if (sfuType !== SfuType.STYPE_UNKNOWN) {
				this[sfuType].notAddedCandidates.push(candidate);
			}
		},
		shiftNotAdded: function (sfuType: SfuType) {
			if (sfuType !== SfuType.STYPE_UNKNOWN) {
				this[sfuType].notAddedCandidates.shift();
			}
		},
		addSub: function (sfuType: SfuType, sub: Subscription) {
			if(sfuType !== SfuType.STYPE_UNKNOWN) {
				this[sfuType].pendingSubscriptions.push(sub);
			}
		},
		setIsOfferReady: function (sfuType: SfuType, isOfferReady: boolean) {
			if (sfuType !== SfuType.STYPE_UNKNOWN) {
				this[sfuType].isOfferReady = isOfferReady;
			}
		},
		clearCandidates: function (sfuType: SfuType) {
			if (sfuType !== SfuType.STYPE_UNKNOWN) {
				this[sfuType].pendingSubscriptions.forEach(sub => {
					sub.unsubscribe();
				})

				this[sfuType] = {
					unsentCandidates: [],
					notAddedCandidates: [],
					pendingSubscriptions: [],
					isOfferReady: false
				};
			}
		}
	},
	videoStreamMuteManager: new VideoStreamMuteManager()
};

export let roomGlobalRef: RoomGlobalRef = clonedeep(initialRoomGlobalRef);

//@ts-ignore
window.roomGlobalRef = roomGlobalRef;

export const clearRoomGlobalRef = () => {
	roomGlobalRef.subscriptionManager?.destroy();
	roomGlobalRef.mediaReceiverServerChannel && disconnectSFU(roomGlobalRef.mediaReceiverServerChannel);
	roomGlobalRef.mediaSenderServerChannel && disconnectSFU(roomGlobalRef.mediaSenderServerChannel);
	roomGlobalRef.isUserLeavingRoom = true;
	roomGlobalRef.roomSubscriptions.forEach(sub => {
		sub.unsubscribe();
	});
	roomGlobalRef.clientCameraTrack?.stop();
	roomGlobalRef.clientAudioOriginalTrack?.stop();
	roomGlobalRef.clientScreenVideoTrack?.stop();
	roomGlobalRef.clientScreenAudioTrack?.stop();
	roomGlobalRef.pcMediaSender?.close();
	roomGlobalRef.pcMediaReceiver?.close();
	roomGlobalRef.currentRoomPid && userLeaveRoomSenderWS$().subscribe();
	roomGlobalRef = clonedeep(initialRoomGlobalRef);
	//@ts-ignore
	window.roomGlobalRef = roomGlobalRef;
};

export const clearStreamsSubscriptions = () => {
	roomGlobalRef.currentSubscriptionState = new SubscriptionState(true);
	roomGlobalRef.clientSubscriptionState = new SubscriptionState(false);
	roomGlobalRef.subscriptionManager?.open();
};

export const selectRoomUserMediaStream = (userId: string, type: 'audio' | 'camera' | 'screenVideo' | 'screenAudio') => {
	if (type === 'camera') {
		return roomGlobalRef.usersCameraMediaStreams.get(userId);
	} else if (type === 'audio') {
		return roomGlobalRef.usersAudioMediaStreams.get(userId);
	} else if (type === 'screenVideo') {
		return roomGlobalRef.usersScreenVideoMediaStreams.get(userId);
	} else {
		return roomGlobalRef.usersScreenAudioMediaStreams.get(userId);
	}
};

export const updateRoomMode = (mode: string | null) => {
	if (!mode) {
		roomGlobalRef.currentMode = RoomMode.STANDARD;
	} else {
		const modes = mode.split(',');

		if (modes.includes('noReceiver')) {
			roomGlobalRef.currentMode = RoomMode.NO_RECEIVER;
		}
	}
};

export const updateVideoMaxBitrate = (maxVideoBitrate: string | null ) => {
	const bitrate = Number(maxVideoBitrate);
	if(!isNaN(bitrate)) roomGlobalRef.maxVideoBitrate = bitrate;
};

export const updateScreenMaxBitrate = (maxScreenBitrate: string | null ) => {
	const bitrate = Number(maxScreenBitrate);
	if(!isNaN(bitrate)) roomGlobalRef.maxScreenBitrate = bitrate;
};

export const handleUnusedTransceivers = (packet: ActiveStreamsResponsePacket.AsObject) => {
	// TODO: just to it
	console.log(packet, roomGlobalRef.unusedAudioTransceivers, roomGlobalRef.unusedVideoTransceivers);
}
