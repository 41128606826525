import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {Product} from '../../pages/PurchaseHistory/PurchaseHistory';

export interface PurchaseHistoryState {
	products: Product[]
	totalProducts: number,
	currentProductPage: number
}

export const initialState: PurchaseHistoryState = {
	products: [],
	totalProducts: 0,
	currentProductPage: 0
};

const distinctProducts = (products: Product[]) => {
	return [...new Map(products.map(item =>
		[item.id, item])).values()];
};


export const purchaseHistorySlice = createSlice({
	name: 'purchaseHistory',
	initialState,
	reducers: {
		addProducts: (state, action: PayloadAction<Product[]>) => {
			const updatedProducts = distinctProducts([...state.products, ...action.payload]);
			if (updatedProducts.length > state.totalProducts) {
				state.totalProducts = updatedProducts.length;
			}
			state.products = updatedProducts;

		},
		setTotalProducts: (state, action: PayloadAction<number>) => {
			state.totalProducts = action.payload;
		},
		setCurrentProductsPage: (state, action: PayloadAction<number>) => {
			state.currentProductPage = action.payload;
		},

		reset: (state) => {
			state.products = [];
			state.totalProducts = 0;
			state.currentProductPage = 0;
		}
	}
});

export const purchaseHistoryActions = purchaseHistorySlice.actions;
export const selectProducts = (state: RootState) => state.purchaseHistory.products;
export const selectTotalProducts = (state: RootState) => state.purchaseHistory.totalProducts;
export const selectCurrentProductsPage = (state: RootState) => state.purchaseHistory.currentProductPage;
export const selectProductsByIndexes = (start: number, end: number) => {
	return createSelector(
		selectProducts,
		reports => reports.slice(start, end)
	);
};
