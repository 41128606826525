import React, {FC, useEffect, useRef} from 'react';
import { McuSupplierS } from './McuSupplierS';
import {debounceTime, tap} from 'rxjs/operators';
import {BehaviorSubject, fromEvent, Subject, Subscription} from 'rxjs';
import {useAppSelector} from '../../../../store/hooks';
import {roomActions, selectIsMcuVideoStream, selectMcuTrackId, selectMcuVideoStreamGrid} from '../../../../store/slices/room';
import {store} from '../../../../store/store';
import {refreshCanvasEvent} from '../../utils/processes/refreshCanvasLoop';
import {colorDetector, pixelColorToGridType} from '../../utils/gridLayoutUtlis';
import {roomGlobalRef} from '../../utils/roomGlobalRef';

export const pixelWatch$$ = new BehaviorSubject(false);

//@ts-ignore
window.loop = 0;

export const McuSupplier: FC = (props) => {

	const isMcuVideoStream = useAppSelector(selectIsMcuVideoStream);
	const videoRef = useRef<HTMLVideoElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const refreshCanvasEventSub = useRef<Subscription>();
	const pixelWatchDisabler = new Subject<void>();
	const isPixelWatchDisablerEnabled = useRef(false);
	const mcuTrackId = useAppSelector(selectMcuTrackId);

	useEffect(() => {
		if (isMcuVideoStream) {
			videoRef.current!.srcObject = roomGlobalRef.mcuVideoMediaStream!;
		}
	}, [isMcuVideoStream, mcuTrackId]);

	useEffect(() => {
		const visibilityChangeSub = fromEvent(document, 'visibilitychange').subscribe(() => {
			if (!document.hidden) {
				if (selectIsMcuVideoStream(store.getState())) {
					pixelWatch$$.next(true);
				}
			}
		});

		const pixelWatchSub = pixelWatch$$.subscribe((shouldWatchNow) => {
			if (shouldWatchNow) {
				if (isPixelWatchDisablerEnabled.current) {
					pixelWatchDisabler.next();
				} else {
					refreshCanvasEventSub.current?.unsubscribe();
					refreshCanvasEventSub.current = refreshCanvasEvent.subscribe(() => {
						//@ts-ignore
						window.loop = window.loop + 1;
						const canvas = canvasRef.current;
						const video = videoRef.current;
						if (canvas && video) {
							const canvasCtx = canvas.getContext('2d')!;
							canvasCtx.drawImage(video, 0, 0, 1, 1, 0, 0, 1, 1);
							const [r, g, b] = canvasCtx.getImageData(0, 0, 1, 1).data;
							const grid = pixelColorToGridType(colorDetector(r, g, b));
							if (selectMcuVideoStreamGrid(store.getState())?.name !== grid.name) {
								store.dispatch(roomActions.setMcuVideoStreamGrid(grid));
							}
							if (!isPixelWatchDisablerEnabled.current) {
								isPixelWatchDisablerEnabled.current = true;
								pixelWatchDisabler.next();
							}
						}
					});
				}
			} else {
				refreshCanvasEventSub.current?.unsubscribe();
			}
		});

		const pixelWatchDisablerSub = pixelWatchDisabler.pipe(
			debounceTime(2000), // determinate how long we should watch the pixel (the video can be delayed)
			tap(() => isPixelWatchDisablerEnabled.current = false),
			tap(() => pixelWatch$$.next(false))
		).subscribe();

		return () => {
			refreshCanvasEventSub.current?.unsubscribe();
			pixelWatchSub.unsubscribe();
			pixelWatchDisablerSub.unsubscribe();
			visibilityChangeSub.unsubscribe();
		};
	}, [mcuTrackId]);

	return (
		<McuSupplierS {...props}>
			{isMcuVideoStream && <>
				<video id="mcuVideo" ref={videoRef} autoPlay muted loop controls={false}/>
				<canvas id="mcuCanvas" ref={canvasRef} width={1} height={1}/>
			</>
			}
		</McuSupplierS>
	);
};
