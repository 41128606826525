import React, {FC, useEffect, useState} from 'react';
import {ChatListS} from './ChatListS';
import {useAppDispatch, useAppSelector} from '../../../../../../store/hooks';
import {Chat, chatsActions, selectChatByMemberId, selectChatsByTypeWithLastMessage} from '../../../../../../store/slices/chats';
import {ChatListEntry} from './ChatListEntry/ChatListEntry';
import {getChatStateService$, listChatsService$} from '../../../../../../services/userServices';
import {ChatInfo, UserInfo} from '../../../../../../services/gRPC/users/models_pb';
import {ChatStatus} from '../../../../../../services/gRPC/rooms/enums_pb';
import {convertChatMessages} from '../../../common';
import {selectClientId, selectClientRole} from '../../../../../../store/slices/user';
import {store} from '../../../../../../store/store';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from '../../../../../../sharedComponents/Spinner/Spinner';
import {map} from 'rxjs/operators';
import {lastValueFrom, of} from 'rxjs';
import {selectNotification} from '../../../../../../store/slices/view';
import {UserSearch} from '../../../../../../sharedComponents/UserSearch/UserSearch';
import {Notification, showNotification} from '../../../../../../utils/showNotification';
import {UserRole} from '../../../../../../services/roomServices';
import {Button} from '../../../../../../sharedComponents/Button/Button';
import {authProcess$} from '../../../../../../pages/TestPage/useAuthProcess';

interface ChatListProps {
	openChatHandler: (chatId: string) => void;
}

export const ChatList: FC<ChatListProps> = (props) => {
	const {openChatHandler} = props;

	const dispatch = useAppDispatch();
	const chats = useAppSelector(selectChatsByTypeWithLastMessage('PRIVATE'));
	const notification = useAppSelector(selectNotification);
	const clientRole = useAppSelector(selectClientRole);

	const ItemsToLoad = 50;

	const [loadedChats, setLoadedChats] = useState<number>(chats.length);
	const [totalChats, setTotalChats] = useState<number>(0);

	useEffect(() => {
		if (chats.length === 0) {
			loadChats().then();
		}
	}, []);

	const loadChats = async () => {
		return await lastValueFrom(listChatsService$(loadedChats, loadedChats + ItemsToLoad).pipe(
			map((response) => {
				setLoadedChats(loadedChats + response.chatsList.length);
				setTotalChats(response.total);
				const convertedChats = convertChats(response.chatsList);
				dispatch(chatsActions.addChats(convertChats(response.chatsList)));
				return prepareChatEntries([...chats, ...convertedChats]);
			})
		));
	};

	const prepareChatEntries = (chats: Chat[]) => chats.map((chat) => {
		const member = chat.members![0];

		return (
			<ChatListEntry key={chat.id} userId={member.id} userName={member.name} avatarUrl={member.avatarUrl}
			               onClick={() => openChatHandler(chat.id)} lastMessage={chat.lastMessage}
			               notification={notification.active && notification.type === 'PRIVATE_CHAT' && notification.entryId === chat.id}
			/>
		);
	});

	const centeredSpinner = (
		<div className="d-flex justify-content-center align-items-center w-100">
			<Spinner/>
		</div>
	);

	const onUserClick = (user: UserInfo.AsObject) => {
		initChat(user.id).subscribe({
			next: (chatId) => openChatHandler(chatId),
			error: () => showNotification(Notification.ERROR, 'Failed to get chat, please try again')
		});
	};

	return (
		<ChatListS>
			{clientRole === UserRole.GUEST ?
				<div className='mt-4 px-2 w-100'>
					<Button onClick={() => authProcess$().subscribe()}>LOG IN TO SEND MESSAGES</Button>
				</div>
				:
				<>
					<UserSearch onUserClick={onUserClick}/>
					<div id={'chatListComponent'} className="ChatListS_EntriesWrapper">
						<InfiniteScroll
							dataLength={chats.length}
							next={loadChats}
							hasMore={loadedChats < totalChats}
							loader={centeredSpinner}
							scrollableTarget="chatListComponent"
						>
							{prepareChatEntries(chats)}
						</InfiniteScroll>
					</div>
				</>
			}
		</ChatListS>
	);
};

export const convertChats = (chats: ChatInfo.AsObject[]) => chats.map((chat) => {
	const clientId = selectClientId(store.getState());

	const result: Chat = {
		id: chat.id,
		type: 'PRIVATE',
		status: ChatStatus.CSTATUS_ACTIVE,
		createdAt: chat.createdAt,
		updatedAt: chat.createdAt,
		members: Array.from(chat.usersList.map((chatUser) => chatUser.user!)).filter((user) => user.id !== clientId),
		lastMessage: chat.lastMessage ? convertChatMessages([chat.lastMessage])[0] : undefined
	};
	return result;
});

export const initChat = (userId: string) => {
	const storedChat = selectChatByMemberId(userId)(store.getState());
	if (storedChat) {
		return of(storedChat.id);
	}

	return getChatStateService$(userId).pipe(
		map((result) => {
			const convertedChats = convertChats([result.info!]);
			convertedChats.length && store.dispatch(chatsActions.addChat(convertedChats[0]));
			return result.info!.id;
		})
	);
};
