import {defer, from, Observable, of, throwError} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import * as CheckType from 'check-types';
import {currentEnv, Environment, envVars} from '../environments/env';
import {UserFirebaseTokenResult} from '../store/slices/user';
import {initializeApp} from 'firebase/app';
import {
	browserLocalPersistence,
	connectAuthEmulator,
	EmailAuthProvider,
	FacebookAuthProvider,
	getAuth,
	GoogleAuthProvider,
	linkWithCredential,
	onAuthStateChanged,
	setPersistence,
	signInAnonymously,
	signInWithEmailAndPassword,
	signInWithPopup,
	Unsubscribe,
	User
} from 'firebase/auth';

export const firebaseApp = initializeApp(envVars.firebaseConfig);
export const auth = getAuth(firebaseApp);

if (currentEnv === Environment.Local) {
	connectAuthEmulator(auth, 'https://local.zu.casa');
	console.log('detected local environment, using firebase emulator');
}

export const logoutFirebase$ = () => {
	authStateChangeDetection.stop();
	return defer(() => auth.signOut());
};

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
	prompt: 'select_account'
})
const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({
	prompt: 'select_account'
})

export enum Provider {
	Google,
	Facebook
}


export const onStateChangedFirebase$$ = () =>
	new Observable<User | null>(obs => onAuthStateChanged(auth,
		user => obs.next(user),
		err => obs.error(err),
		() => obs.complete())
	);

export const isLoggedFirebase$ = () =>
	onStateChangedFirebase$$().pipe(
		take(1),
		mergeMap((user) => CheckType.assigned(user) ? of(true) : of(false))
	);

export const setPersistenceFirebase$ = () =>
	from(setPersistence(auth, browserLocalPersistence));

export const loginAsUserFirebase$ = (email: string, password: string) =>
	from(signInWithEmailAndPassword(auth, email, password));

export const loginAsGoogleUserFirebase$ = () =>
	from(signInWithPopup(auth, googleProvider));

export const loginAsFacebookUserFirebase$ = () =>
	from(signInWithPopup(auth, facebookProvider));

export const loginAsGuestFirebase$ = () =>
	from(signInAnonymously(auth));

export const getCurrentUserFirebase = () =>
	auth.currentUser;

export const getCurrentTokenResultsFirebase$ = () => {
	return auth.currentUser ? from(auth.currentUser.getIdTokenResult()) : throwError(() => new Error('User doesn\'t exist.'));
};

export const refreshTokenResultsFirebase$ = () => {
	const currentUser = getCurrentUserFirebase();
	return currentUser ? from(currentUser.getIdTokenResult(true)).pipe(
		map((tokenResult) => tokenResult as unknown as UserFirebaseTokenResult)
	) : throwError(() => new Error('User doesn\'t exist.'));
};

export const createAccountBasedOnGuest$ = (email: string, password: string) =>
	from(linkWithCredential(auth.currentUser!, EmailAuthProvider.credential(email, password)));

export const authStateChangeDetection = {
	unsubFn: undefined as undefined | Unsubscribe,
	start: function () {
		this.unsubFn = auth.onAuthStateChanged((user) => {
			if (user === null) {
				refreshTokenResultsFirebase$().subscribe()
			}
		});
	},
	stop: function () {
		this.unsubFn?.();
	}
};

