import {StreamInfo} from '../../../../services/gRPC/sfu/packets_pb';
import {roomGlobalRef} from '../roomGlobalRef';
import {store} from '../../../../store/store';
import {roomUsersActions} from '../../../../store/slices/roomUsers';
import {StreamType} from '../../../../services/gRPC/sfu/enums_pb';
import {StreamData} from '../SubscriptionManager';

export const setTransceiversAndMid = (streamsToAdd: StreamData[], streamsToRemove: StreamData[]) => {
	const usedTransceivers: {transceiver: RTCRtpTransceiver, kind: string}[] = [];

	const createStreamInfo = (transceivers: RTCRtpTransceiver[], streamData: StreamData, trackOrKind: string) => {
		if (transceivers.length) {
			transceivers.sort((tranA, tranB) => tranA.mid! < tranB.mid! ? -1 : 1);
			const unusedTransceiver = transceivers.shift()!;
			unusedTransceiver.direction = 'recvonly';
			usedTransceivers.push({ transceiver: unusedTransceiver, kind: trackOrKind});
			return {
				...streamData,
				mid: unusedTransceiver.mid!
			} as StreamInfo.AsObject;
		} else {
			usedTransceivers.push({transceiver: roomGlobalRef.pcMediaReceiver!.addTransceiver(trackOrKind, {'direction': 'recvonly'}), kind: trackOrKind});
			return {
				...streamData,
				mid: ''
			} as StreamInfo.AsObject;
		}
	};

	const streamsWithMidToAdd = streamsToAdd.map(streamData => {
		if (streamData.type === StreamType.CAMERA) {
			return createStreamInfo(roomGlobalRef.unusedVideoTransceivers, streamData, 'video');
		} else if (streamData.type === StreamType.AUDIO) {
			return createStreamInfo(roomGlobalRef.unusedAudioTransceivers, streamData, 'audio');
		} else if (streamData.type === StreamType.SCREEN) {
			return createStreamInfo(roomGlobalRef.unusedVideoTransceivers, streamData, 'video');
		} else if (streamData.type === StreamType.SCREEN_AUDIO) {
			return createStreamInfo(roomGlobalRef.unusedAudioTransceivers, streamData, 'audio');
		} else {
			throw new Error('The stream type: ' + streamData.type + ' is not supported in setTransceiversAndSubscribeStreams.');
		}
	});

	streamsToRemove.forEach(streamInfo => {
		if (streamInfo.type === StreamType.CAMERA) {
			const videoTransceiver = roomGlobalRef.cameraTransceiversMap.get(streamInfo.userId);
			if (videoTransceiver) {
				videoTransceiver.direction = 'inactive';
				roomGlobalRef.unusedVideoTransceivers.push(videoTransceiver);
				roomGlobalRef.cameraTransceiversMap.delete(streamInfo.userId);
				console.log('delete stream from store');
				store.dispatch(roomUsersActions.removeUserMediaTrack({userId: streamInfo.userId, type: 'camera'}));
				roomGlobalRef.usersCameraMediaStreams.delete(streamInfo.userId);
			}
		} else if (streamInfo.type === StreamType.AUDIO) {
			const audioTransceiver = roomGlobalRef.audioTransceiversMap.get(streamInfo.userId);
			if (audioTransceiver) {
				audioTransceiver.direction = 'inactive';
				roomGlobalRef.unusedAudioTransceivers.push(audioTransceiver);
				roomGlobalRef.audioTransceiversMap.delete(streamInfo.userId);
				store.dispatch(roomUsersActions.removeUserMediaTrack({userId: streamInfo.userId, type: 'audio'}));
				roomGlobalRef.usersAudioMediaStreams.delete(streamInfo.userId);
			}
		} else if (streamInfo.type === StreamType.SCREEN) {
			const screenVideoTransceiver = roomGlobalRef.cameraTransceiversMap.get(`screen-${streamInfo.userId}`);
			if (screenVideoTransceiver) {
				screenVideoTransceiver.direction = 'inactive';
				roomGlobalRef.unusedVideoTransceivers.push(screenVideoTransceiver);
				roomGlobalRef.cameraTransceiversMap.delete(`screen-${streamInfo.userId}`);
				store.dispatch(roomUsersActions.removeUserMediaTrack({userId: streamInfo.userId, type: 'screenVideo'}));
				roomGlobalRef.usersScreenVideoMediaStreams.delete(streamInfo.userId);
			}
		} else if (streamInfo.type === StreamType.SCREEN_AUDIO) {
			const screenAudioTransceiver = roomGlobalRef.audioTransceiversMap.get(`screen-audio-${streamInfo.userId}`);
			if (screenAudioTransceiver) {
				screenAudioTransceiver.direction = 'inactive';
				roomGlobalRef.unusedAudioTransceivers.push(screenAudioTransceiver);
				roomGlobalRef.audioTransceiversMap.delete(`screen-audio-${streamInfo.userId}`);
				store.dispatch(roomUsersActions.removeUserMediaTrack({userId: streamInfo.userId, type: 'screenAudio'}));
				roomGlobalRef.usersScreenAudioMediaStreams.delete(streamInfo.userId);
			}
		}
	});

	console.log(usedTransceivers);

	return {
		streamsToAdd: streamsWithMidToAdd,
		streamsToRemove: streamsToRemove,
		usedTransceivers
	};
};
