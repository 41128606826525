import {localStore} from '../../../localStorage/localStore';

const regex = new RegExp(/^([0-9].*)x([1-9].*)$/);

export const getMaxRes = () => {
	const maxRes = localStore.getItem('maxRes');

	if (!maxRes) return undefined;
	if (!maxRes.match(regex)) return undefined;

	const [width, height] = maxRes.split('x');
	return {width: Number(width), height: Number(height)};
}
