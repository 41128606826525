import React from 'react';
import Spinner from '../Spinner/Spinner';
import {remm} from '../../utils/remm';
import {ButtonS} from './ButtonS';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
	isLoading?: boolean;
	children?: React.ReactNode;
	buttonHeight?: string;
	buttonWidth?: string;
	buttonFontSize?: string;
	alignLeft?: boolean;
	selected?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
	children,
	alignLeft,
	isLoading,
	buttonHeight = remm(34),
	buttonWidth = '100%',
	...restProps
}, ref) => {

	const childrenArr = React.Children.toArray(children);

	return (
		<ButtonS ref={ref}
		         {...restProps}
		         buttonHeight={buttonHeight}
		         buttonWidth={buttonWidth}
		         alignLeft={alignLeft}
		         disabled={isLoading || restProps.disabled}
		>
			{isLoading &&
			<ButtonS.SpinnerContainerS buttonHeight={buttonHeight} isLoading={isLoading}>
				<Spinner/>
			</ButtonS.SpinnerContainerS>
			}
			<ButtonS.BodyS buttonHeight={buttonHeight}
			               isLoading={isLoading}
			               alignLeft={alignLeft}
			>
				{childrenArr[0]}
				{childrenArr.length === 2 && <div className='mr-2'/>}
				{childrenArr[1]}
			</ButtonS.BodyS>
		</ButtonS>
	);
});
