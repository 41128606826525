import {AnyAction, combineReducers, configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {createEpicMiddleware, Epic} from 'redux-observable';
import {rootEpic} from './epicRoot';
import {enableBatching} from 'redux-batched-actions';
import {roomsSlice} from './slices/rooms';
import {userSlice} from './slices/user';
import {ticketsSlice} from './slices/tickets';
import {usersSlice} from './slices/users';
import {roomUsersSlice} from './slices/roomUsers';
import {roomStreamsSlice} from './slices/roomStreams';
import {roomSlice} from './slices/room';
import {performerSlice} from './slices/performer';
import {eventsSlice} from './slices/events';
import {purchaseHistorySlice} from './slices/purchaseHistory';
import {vouchersSlice} from './slices/vouchers';
import {messagesSlice} from './slices/messages';
import {chatsSlice} from './slices/chats';
import {viewSlice} from './slices/view';
import {playlistSearchSlice} from './slices/playlistSearch';

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();

export const reducer = enableBatching(combineReducers({
  rooms: roomsSlice.reducer,
  user: userSlice.reducer,
  tickets: ticketsSlice.reducer,
  events: eventsSlice.reducer,
  performer: performerSlice.reducer,
  vouchers: vouchersSlice.reducer,
  users: usersSlice.reducer,
  view: viewSlice.reducer,
  messages: messagesSlice.reducer,
  chats: chatsSlice.reducer,
  room: roomSlice.reducer,
  roomUsers: roomUsersSlice.reducer,
  purchaseHistory: purchaseHistorySlice.reducer,
  roomStreams: roomStreamsSlice.reducer,
  playlistSearch: playlistSearchSlice.reducer,
}));

export const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false
    }),
    epicMiddleware
  ]
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof reducer>;
export type AppEpic = Epic<AnyAction, AnyAction, RootState>;

