import {
	AuthenticationPacket,
	EventChatPacket,
	HandshakePacket,
	HeartbeatPacket,
	Packet,
	RoomChatPacket,
	RoomJoinPacket,
	RoomLeavePacket,
	RoomUserFlagsPacket,
	SfuConnectPacket,
	SfuIceCandidatePacket,
	UserChatPacket
} from '../gRPC/wsclient/packets.public_pb';
import {createUserTokenService$} from '../userServices';
import {webSocketCore$$, webSocketOutput$$} from './webSocket';
import {RoomUserFlag} from '../gRPC/rooms/enums_pb';
import {ProtocolVersion} from '../gRPC/sfu/enums_pb';
import {isChromiumMobile, isiOS, retrieveClientType} from '../../utils/browserTypeDetection';
import {SfuConnectionOptions} from '../gRPC/sfu/models_pb';
import {Action, Platform, Protocol} from '../gRPC/wsclient/enums_pb';
import {EMPTY, merge, NEVER, Observable, of, race, throwError, timer} from 'rxjs';
import {WsMessageType} from './incomingMessagesTypes.ws';
import {catchError, filter, mergeMap, take, tap, timeout} from 'rxjs/operators';
import {SfuType} from '../gRPC/wsgateway/enums_pb';
import {EMIT} from '../../utils/utils';
import {subscribeThenDo} from '../../utils/rxjsOperators';
import {SuccessPacket} from '../gRPC/wsserver/packets.public_pb';
import {roomGlobalRef} from '../../pages/Room/utils/roomGlobalRef';
import {selectIsConnectedWithWs} from '../../store/slices/user';
import {store} from '../../store/store';

//////
////// Auth
//////

export const packetIndex = {
	_value: 1,
	getAndIncrease: function () {
		const toReturn = this._value;
		this._value++;
		return toReturn;
	},
	resetValue: function () {
		this._value = 1;
	}
};

// TODO: catch errors like 'session is not connected to any room' and similar, launch join room process and retry failed packet sending
const getWsSenderObservable$ = (packet: Uint8Array, createdIndex: number) => {
	return EMIT.pipe(
		subscribeThenDo(
			merge(
				webSocketOutput$$(WsMessageType.SUCCESS),
				webSocketOutput$$(WsMessageType.FAILURE)
			),
			() => webSocketCore$$.next(packet)
		),
		filter(({index}) => index === createdIndex),
		take(1),
		mergeMap((packet) => {
			// FAILURE packet logger
			if ('message' in packet) {
				console.log(packet.index + ' ' + packet.message);
				return throwError(() => new Error(packet.index + ' ' + packet.message));
			} else {
				return of(packet);
			}
		}),
		timeout(10000)
	);
};

const sendAuthMessages$ = (): Observable<never> => {
	return EMIT.pipe(
		// @ts-ignore
		mergeMap(() => (window.lastAuthPacket?.token ? of(window.lastAuthPacket.token as string) : createUserTokenService$()).pipe(
			mergeMap((token) => {
				const handShakePacketWrapper = new Packet();
				handShakePacketWrapper.setAction(Action.HANDSHAKE);

				const handShakePacket = new HandshakePacket();
				handShakePacket.setProtocol(Protocol.PROTOCOL_BINARY);
				if(isiOS() || isChromiumMobile()) {
					handShakePacket.setPlatform(Platform.PLATFORM_HYBRID);
				} else {
					handShakePacket.setPlatform(Platform.PLATFORM_DESKTOP);
				}
				handShakePacketWrapper.setData(handShakePacket.serializeBinary());

				webSocketCore$$.next(handShakePacketWrapper.serializeBinary());

				const authPacketWrapper = new Packet();
				authPacketWrapper.setAction(Action.AUTHENTICATE);
				const authPacket = new AuthenticationPacket();
				authPacket.setToken(token);
				authPacketWrapper.setData(authPacket.serializeBinary());
				webSocketCore$$.next(authPacketWrapper.serializeBinary());
				return EMPTY;
			})
		))
	);
};

let retryAuthPacketSending = true;

export const authToken: { current?: string } = {};
export const authMessageSenderWS$ = () => {
	return EMIT.pipe(
		mergeMap(() => merge(
			merge(
				webSocketOutput$$(WsMessageType.AUTHENTICATION).pipe(tap(() => {
					retryAuthPacketSending = true;
				})),
				webSocketOutput$$(WsMessageType.FAILURE).pipe(mergeMap((packet) => {
					return throwError(() => new Error(packet.message));
				}))
			).pipe(timeout(5000),
				catchError(err => {
					console.log(err);
					if (err && 'name' in err && err.name === 'TimeoutError') {
						if (retryAuthPacketSending) {
							retryAuthPacketSending = false;
							console.error(`TimeoutError - couldn't send auth packet. Trying again`);
							return sendAuthMessages$();
						} else {
							webSocketCore$$.complete();
							console.error(`TimeoutError - couldn't send auth packet. Closing ws connection`);
							return EMPTY;
						}
					}
					console.error(err);
					webSocketCore$$.complete();
					return EMPTY;
				})),
			sendAuthMessages$()
		))
	);
};

//@ts-ignore
window.heartbeat = true;

export const heartbeatMessageSenderWS$ = (timestamp: number) => {
	//@ts-ignore
	if (window.heartbeat) {
		const packetWrapper = new Packet();
		packetWrapper.setAction(Action.HEARTBEAT);

		const packet = new HeartbeatPacket();
		packet.setTimestamp(timestamp);
		packetWrapper.setData(packet.serializeBinary());
		webSocketCore$$.next(packetWrapper.serializeBinary());
	}
};

//////
////// Chat stuff
//////

export const roomChatMessageSenderWS$ = (chatId: string, message: string, fid: string) => {
	const packetWrapper = new Packet();
	const createdIndex = packetIndex.getAndIncrease();
	packetWrapper.setAction(Action.ROOM_CHAT);
	packetWrapper.setIndex(createdIndex);

	const packet = new RoomChatPacket();

	packet.setMessage(message);
	packet.setFid(fid);

	packetWrapper.setData(packet.serializeBinary());

	return getWsSenderObservable$(packetWrapper.serializeBinary(), createdIndex);
};

export const eventChatMessageSenderWS$ = (chatId: string, message: string, fid: string) => {
	const packetWrapper = new Packet();
	const createdIndex = packetIndex.getAndIncrease();
	packetWrapper.setAction(Action.EVENT_CHAT);
	packetWrapper.setIndex(createdIndex);

	const packet = new EventChatPacket();

	packet.setMessage(message);
	packet.setFid(fid);

	packetWrapper.setData(packet.serializeBinary());

	return getWsSenderObservable$(packetWrapper.serializeBinary(), createdIndex);
};

export const userChatMessageSenderWS$ = (chatId: string, message: string, fid: string) => {
	const packetWrapper = new Packet();
	const createdIndex = packetIndex.getAndIncrease();
	packetWrapper.setAction(Action.USER_CHAT);
	packetWrapper.setIndex(createdIndex);

	const packet = new UserChatPacket();

	packet.setMessage(message);
	packet.setChatId(chatId);
	packet.setFid(fid);

	packetWrapper.setData(packet.serializeBinary());

	return getWsSenderObservable$(packetWrapper.serializeBinary(), createdIndex);
};

//////
////// Room stuff
//////

let joinRoomRetriesCounter = 0;

export const userJoinRoomSenderWS$ = ((token: string, force?: Boolean) => {
	const packetWrapper = new Packet();
	const createdIndex = packetIndex.getAndIncrease();
	packetWrapper.setAction(Action.ROOM_JOIN);
	packetWrapper.setIndex(createdIndex);

	const packet = new RoomJoinPacket();

	packet.setToken(token);
	force && packet.setForce(true);

	packetWrapper.setData(packet.serializeBinary());

	return race(
		webSocketOutput$$(WsMessageType.FAILURE).pipe(
			tap(() => joinRoomRetriesCounter = 0),
			filter(packet => packet.index === createdIndex),
			mergeMap(() => throwError(() => new Error('failurePacket')))
		),
		webSocketOutput$$(WsMessageType.SUCCESS).pipe(
			tap(() => joinRoomRetriesCounter = 0),
			filter(e => e.index === createdIndex)
		),
		EMIT.pipe(
			mergeMap(() => {
				webSocketCore$$.next(packetWrapper.serializeBinary());
				return NEVER;
			})
		)
	).pipe(
		take(1),
		timeout(10000),
		catchError((err) => {
			if (err && 'name' in err && err.name === 'TimeoutError') {
				console.error('TimeoutError for joining room. retrying now...');
				if (++joinRoomRetriesCounter > 3) {
					return throwError(() => new Error('failed to join room after three retries'));
				}
				return userJoinRoomSenderWS$(token, force);
			}
			return throwError(err);
		})
	);
}) as (token: string, force?: Boolean) => Observable<SuccessPacket.AsObject>;

export const userLeaveRoomSenderWS$ = () => {
	const packetWrapper = new Packet();
	const createdIndex = packetIndex.getAndIncrease();
	packetWrapper.setAction(Action.ROOM_LEAVE);
	packetWrapper.setIndex(createdIndex);

	const packet = new RoomLeavePacket();

	packetWrapper.setData(packet.serializeBinary());

	return getWsSenderObservable$(packetWrapper.serializeBinary(), createdIndex);
};

export const nextReconnectionsDelays = [1000, 3000, 5000, 10000, 10000];

export const reconnectionsCounter = new Map<SfuType, number>();

export const findSfuSenderWS$ = ((offerData: any, sfuType: SfuType) => {
	const packetWrapper = new Packet();
	const createdIndex = packetIndex.getAndIncrease();
	packetWrapper.setAction(Action.SFU_CONNECT);
	packetWrapper.setIndex(createdIndex);

	const packet = new SfuConnectPacket();
	packet.setSdpOffer(offerData);
	packet.setSfuType(sfuType);
	packet.setClientType(retrieveClientType());
	packet.setProtocolVersion(ProtocolVersion.PROTOCOLVERSION_1_1_0);
	const options = new SfuConnectionOptions();
	options.setUseCompression(true);
	options.setUseProto(true);
	options.setMcuEmbedLayoutInFrames(false);
	options.setExchangeCandidatesAsync(true);
	packet.setOptions(options);

	packetWrapper.setData(packet.serializeBinary());

	return race(
		webSocketOutput$$(WsMessageType.FAILURE).pipe(
			filter(packet => packet.index === createdIndex),
			mergeMap((packet) => {
				return throwError(() => ({packet}));
			})
		),
		webSocketOutput$$(WsMessageType.SUCCESS).pipe(
			filter(e => e.index === createdIndex),
			tap(() => {
				reconnectionsCounter.set(sfuType, 0);
			})
		),
		EMIT.pipe(
			mergeMap(() => {
				webSocketCore$$.next(packetWrapper.serializeBinary());
				return NEVER;
			})
		)
	).pipe(
		take(1),
		timeout(12000),
		catchError((err) => {
			if (err && 'name' in err && err.name === 'TimeoutError') {
				if (selectIsConnectedWithWs(store.getState())) {
					console.error('TimeoutError for find sfu. retrying now...');
					const retries = reconnectionsCounter.get(sfuType) || 0;
					reconnectionsCounter.set(sfuType, retries + 1);
					return findSfuSenderWS$(offerData, sfuType);
				} else {
					console.error('TimeoutError for find sfu. No connection with websocket');
					if (sfuType === SfuType.STYPE_GATEWAY) {
						roomGlobalRef.pcMediaSender?.close();
					} else {
						roomGlobalRef.pcMediaReceiver?.close();
					}
					return throwError(err);
				}

			} else if ('packet' in err) {
				if (err.packet.message.includes('failed to handle sfu connect: session is not connected to any room')) {
					if (sfuType === SfuType.STYPE_GATEWAY) {
						roomGlobalRef.pcMediaSender?.close();
					} else {
						roomGlobalRef.pcMediaReceiver?.close();
					}
				}

				console.error('fail packet for find sfu. retrying with delay...');
				console.error(err.packet.index + ' ' + err.packet.message);
				const retries = reconnectionsCounter.get(sfuType) || 0;
				const delayTime = nextReconnectionsDelays[nextReconnectionsDelays.length - 1 <= retries ? nextReconnectionsDelays.length - 1 : retries];
				return timer(delayTime).pipe(
					mergeMap(() => {
						if (selectIsConnectedWithWs(store.getState())) {
							reconnectionsCounter.set(sfuType, retries + 1);
							return findSfuSenderWS$(offerData, sfuType);
						} else {
							if (sfuType === SfuType.STYPE_GATEWAY) {
								roomGlobalRef.pcMediaSender?.close();
							} else {
								roomGlobalRef.pcMediaReceiver?.close();
							}
							console.log('No connection with websocket. Prevented retrying');
							return throwError(err);
						}
					})
				);
			}
			return throwError(err);
		})
	);
}) as (offerData: any, sfuType: SfuType) => Observable<SuccessPacket.AsObject>;

export const sendICECandidatesWS$ = (candidate: any, sfuType: SfuType) => {
	const packetWrapper = new Packet();
	const createdIndex = packetIndex.getAndIncrease();
	packetWrapper.setAction(Action.SFU_ICE_CANDIDATE);
	packetWrapper.setIndex(createdIndex);

	const packet = new SfuIceCandidatePacket();
	packet.setSdpCandidate(candidate);
	packet.setSfuType(sfuType);

	packetWrapper.setData(packet.serializeBinary());

	return race(
		webSocketOutput$$(WsMessageType.FAILURE).pipe(
			filter(packet => packet.index === createdIndex),
			tap((err) => {
				console.log(err);
			})
		),
		webSocketOutput$$(WsMessageType.SUCCESS).pipe(
			filter(e => e.index === createdIndex)
		),
		EMIT.pipe(
			mergeMap(() => {
				return getWsSenderObservable$(packetWrapper.serializeBinary(), createdIndex);
			})
		)
	).pipe(
		take(1)
	);
};

export const flagSenderWS$ = (flags: RoomUserFlag[], timestamp = Date.now()) => {
	console.log(`%c Sending Flags Update`, 'color: #aafc37; font-weight: 900; background: black');
	console.log(flags);
	const packetWrapper = new Packet();
	const createdIndex = packetIndex.getAndIncrease();
	packetWrapper.setAction(Action.ROOM_USER_FLAGS);
	packetWrapper.setIndex(createdIndex);

	const packet = new RoomUserFlagsPacket();
	packet.setFlagsList(flags);
	packet.setTimestamp(timestamp);

	packetWrapper.setData(packet.serializeBinary());

	return getWsSenderObservable$(packetWrapper.serializeBinary(), createdIndex);
};
