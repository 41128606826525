import {RoomUserReceiverConnectionStatus, RoomUserSenderConnectionStatus} from '../../../services/gRPC/rooms/enums_pb';
import {store} from '../../../store/store';
import {roomUsersActions} from '../../../store/slices/roomUsers';

// This queue saves only flags updates incoming from WS
// The queue is blocked while waiting for server response (with info about roomUsers (getRoomStateService$))
// After response is ready, and it is applied to the state the queue is opened to apply the WS data about flags.
export class StatusesQueue {

	private isOpen = false;

	private senderStatusesUpdatesStore: Map<string, {timestamp: number, status: RoomUserSenderConnectionStatus}> = new Map();
	private receiverStatusesUpdatesStore: Map<string, {timestamp: number, status: RoomUserReceiverConnectionStatus}> = new Map();

	applySenderStatusUpdate(status: RoomUserSenderConnectionStatus, userId: string, timestamp: number) {
		if (this.isOpen) {
			this.processSenderStatus(status, userId);
		} else {
			this.senderStatusesUpdatesStore.set(userId, {timestamp, status});
		}
	}

	applyReceiverStatusUpdate(status: RoomUserReceiverConnectionStatus, userId: string, timestamp: number) {
		if (this.isOpen) {
			this.processReceiverStatus(status, userId);
		} else {
			this.receiverStatusesUpdatesStore.set(userId, {timestamp, status});
		}
	}

	private processSenderStatus(status: RoomUserSenderConnectionStatus, userId: string) {
		store.dispatch(roomUsersActions.updateRoomUserSenderConnectionStatus({userId: userId, senderConnectionStatus: status}));
	}

	private processReceiverStatus(status: RoomUserReceiverConnectionStatus, userId: string) {
		store.dispatch(roomUsersActions.updateRoomUserReceiverConnectionStatus({userId: userId, receiverConnectionStatus: status}));
	}

	open(date: number) {
		this.isOpen = true;

		this.senderStatusesUpdatesStore.forEach(({timestamp, status}, userId) => {
			if(timestamp > date) {
				this.processSenderStatus(status, userId);
			} else {
				console.log(date, 'OLD PACKET - SENDER: ', userId, status, timestamp);
			}
		});
		this.senderStatusesUpdatesStore.clear();

		this.receiverStatusesUpdatesStore.forEach(({timestamp, status}, userId) => {
			if(timestamp > date) {
				this.processReceiverStatus(status, userId);
			} else {
				console.log(date, 'OLD PACKET - RECEIVER: ', userId, status, timestamp);
			}
		});
		this.receiverStatusesUpdatesStore.clear();
	}

	close() {
		this.isOpen = false;
	}

}
