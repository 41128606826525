export enum WebSocketErrorType {
	NO_PING = 'NO_PING',
	BLOB_TO_ARRAY_ERR = 'BLOB_TO_ARRAY_ERR',
	DESERIALIZE_BINARY_ERR = 'DESERIALIZE_BINARY_ERR',
}

export class WebSocketError extends Error {
	type: WebSocketErrorType;

	constructor(type: WebSocketErrorType, message?: string) {
		super(message);
		this.type = type;
		this.name = 'WebSocketError';
	}
}

