import {roomGlobalRef} from '../roomGlobalRef';
import {EMIT} from '../../../../utils/utils';
import {createConnectionService$, createEventTokenService$, getRoomStateService$} from '../../../../services/roomServices';
import {from, throwError} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {store} from '../../../../store/store';
import {roomActions} from '../../../../store/slices/room';
import {batchActions} from 'redux-batched-actions';

export const startPerformerRtcListeners = () => {
	roomGlobalRef.pcMediaPerformer = new RTCPeerConnection();
	roomGlobalRef.performerVideoTransceiver = roomGlobalRef.pcMediaPerformer.addTransceiver('video', {'direction': 'sendrecv'});
	roomGlobalRef.performerAudioTransceiver = roomGlobalRef.pcMediaPerformer.addTransceiver('audio', {'direction': 'sendrecv'});

	roomGlobalRef.pcMediaPerformer.ontrack = function (event) {
		if (event.streams[0].id === 'video') {
			roomGlobalRef.mcuVideoMediaStream = event.streams[0];
			store.dispatch(roomActions.setPerformerMediaStream({type: 'camera', value: true}));
		} else if (event.streams[0].id === 'audio') {
			roomGlobalRef.performerAudioMediaStream = event.streams[0];
			store.dispatch(roomActions.setPerformerMediaStream({type: 'audio', value: true}));
		}
	};

	// connect with baby SFU
	EMIT.pipe(
		mergeMap(() => getRoomStateService$()),
		mergeMap((roomState) => from(roomGlobalRef.pcMediaPerformer!.createOffer({
				offerToReceiveVideo: true,
				offerToReceiveAudio: true
			}))
				.pipe(map((offer) => ({roomState, offer})))
		),
		tap(({offer}) => {
			roomGlobalRef.pcMediaPerformer!.setLocalDescription(offer);
		}),
		mergeMap(({roomState, offer}) => {
			const eventId = roomState.event?.info?.id;
			if (!eventId) {
				return throwError(() => new Error('Somehow stream id doesnt exist'));
			}
			return createEventTokenService$().pipe(map((token) => ({offer, token})));
		}),
		mergeMap(({token, offer}) => createConnectionService$(token, offer)),
		tap((sdpAnswer) => {
			const sdpAnswerConverted = JSON.parse(atob(sdpAnswer));
			roomGlobalRef.pcMediaPerformer!.setRemoteDescription(sdpAnswerConverted);
		})
	).subscribe({
		error: (err) => {
			console.error(err);
		}
	});

};

export const stopPerformerRtcListeners = () => {
	store.dispatch(batchActions([
		roomActions.setPerformerMediaStream({type: 'camera', value: false}),
		roomActions.setPerformerMediaStream({type: 'audio', value: false})
	], 'setPerformerMediaStream false'));
	roomGlobalRef.performerAudioMediaStream = undefined;
	roomGlobalRef.mcuVideoMediaStream = undefined;
	try {
		roomGlobalRef.pcMediaPerformer && roomGlobalRef.pcMediaPerformer.close();
	} catch (e) {
		// user already disconnected
	}
};
