import {stageEnv} from './env-stage';
import {prodEnv} from './env-prod';
import {stageMiniEnv} from './env-stage-mini';
import {localEnv} from './env-local';

export interface EnvVars {
	pageShortUrl: string,
	pageUrl: string,
	apiUsersUrl: string,
	apiRoomsUrl: string,
	apiWsUrl: string,
	apiPaymentsUrl: string,
	apiBabySfuUrl: string,
	stripeSecret: string,
	firebaseConfig: {
		apiKey: string,
		authDomain: string,
		projectId: string,
		storageBucket: string,
		messagingSenderId: string,
		appId: string,
	},
	supportedUsersVersion: string
	supportedRoomsVersion: string
	supportedPaymentsVersion: string
	chefsUnitedCasaId: string
	demoEventIds: string[]
	mainPageEventId: string,
	apiYoutubeKey: string,
	apiYoutubeUrl: string,
	playlistConfig: {
		TikTok: boolean,
		YouTube: boolean,
		ZuCasa: boolean,
	}
}

const config = (() => {

	switch (process.env.REACT_APP_ENV) {
		case 'production': {
			return prodEnv;
		}
		case 'stage': {
			return stageEnv;
		}
		case 'stage-mini': {
			return stageMiniEnv;
		}
		case 'local': {
			return localEnv;
		}
		default: {
			return stageEnv;
		}
	}

})();

export const currentEnv = process.env.REACT_APP_ENV

export enum Environment {
	Prod = 'production',
	Stage = 'stage',
	StageMini = 'stage-mini',
	Local = 'local',
}

export const envVars: EnvVars = {
	...config
};
