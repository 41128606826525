import {forkJoin, Observable} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import clonedeep from 'lodash.clonedeep';
import {getPartnerService$} from '../../../../services/paymentServices';
import {EMIT} from '../../../../utils/utils';
import {getRoomInfoService$} from '../../../../services/roomServices';
import {PartnerStatus} from '../../../../services/gRPC/payments/enums_pb';
import {selectClientIsPartner, userActions} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {ModalType} from '../../modal.types';
import {modalsSystem} from '../../modalSystem';
import {roomsActions} from '../../../../store/slices/rooms';
import {CreateEventDetailsModalProps} from './CreateEventDetailsModal';
import {CreateEventDataModalProps} from './CreateEventDataModal';
import {EventAccess, EventOverseer, EventSource} from '../../../../services/gRPC/rooms/enums_pb';
import {accessToString} from '../common/accessConverter';
import {sourceToString} from '../common/sourceConverter';
import {EventInfo, EventReportInfo, EventReportMerchant} from '../../../../services/gRPC/rooms/models_pb';
import {performerActions} from '../../../../store/slices/performer';
import {appNavigate} from '../../../../App';

const createEventDataModalInitialData: CreateEventDataModalProps = {
	eventName: '',
	startDate: undefined,
	startTime: undefined,
	duration: 0,
	type: undefined,
	price: undefined,
	access: accessToString(EventAccess.EACCESS_PUBLIC),
	source: sourceToString(EventSource.ESOURCE_MCU),
	overseer: EventOverseer.EOVERSEER_MANUAL,
	streamLink: '',
};

const createEventDetailsModalInitialData: CreateEventDetailsModalProps = {
	thumbnailImage: '',
	backgroundImage: '',
	summary: '',
	description: '',
	genres: [],
};

export interface CreateEventProcessData {
	createEventDataModal: CreateEventDataModalProps,
	createEventDetailsModal: CreateEventDetailsModalProps,
}

const getCreateEventProcessInitialData = () => {
	return clonedeep({
		createEventDataModal: createEventDataModalInitialData,
		createEventDetailsModal: createEventDetailsModalInitialData,
	});
}

export let createEventProcessData: CreateEventProcessData = getCreateEventProcessInitialData();

type ProcessType = 'GO_EVENT_DATA' | 'GO_EVENT_DETAILS' | 'EVENT_CREATED' | 'CROP_THUMBNAIL' | 'CROP_BACKGROUND' | EventInfo.AsObject

const createEventDataModal$: () => Observable<ProcessType> = () => {
	return modalsSystem(ModalType.CREATE_EVENT_DATA, createEventProcessData.createEventDataModal).pipe(
		mergeMap(() => createEventDetailsModal$())
	);
};

const createEventDetailsModal$: () => Observable<ProcessType> = () =>
	modalsSystem(ModalType.CREATE_EVENT_DETAILS, createEventProcessData.createEventDetailsModal).pipe(
		mergeMap((data) => {
			switch (data.next) {
				case 'GO_EVENT_DATA': {
					return createEventDataModal$();
				}
				case 'CROP_THUMBNAIL': {
					const image = data.image;
					return modalsSystem(ModalType.IMAGE_CROPPER, {image, aspectRatio: 16 / 9}).pipe(
						mergeMap((data) => {
							if (data?.image) {
								createEventProcessData.createEventDetailsModal.thumbnailImage = data.image;
							}
							return createEventDetailsModal$();
						})
					);
				}
				case 'CROP_BACKGROUND': {
					const image = data.image;
					return modalsSystem(ModalType.IMAGE_CROPPER, {image, aspectRatio: 16 / 9}).pipe(
						mergeMap((data) => {
							if (data?.image) {
								createEventProcessData.createEventDetailsModal.backgroundImage = data.image;
							}
							return createEventDetailsModal$();
						})
					);
				}
				case 'EVENT_CREATED': {
					return getRoomInfoService$({broadcast: true}).pipe(
						tap({
							next: (room) => {
								store.dispatch(roomsActions.addRoom(room));
							}
						}),
						catchError(() => {
							// user doesn't have performer room.
							return EMIT;
						}),
						map(() => data.info)
					);
				}
			}
		})
	);

export const createEventProcess$ = () => {

	return EMIT.pipe(
		mergeMap(() => forkJoin([
			EMIT.pipe(
				mergeMap(() => {
					if (selectClientIsPartner(store.getState()) !== undefined) {
						return EMIT;
					} else {
						return getPartnerService$().pipe(
							tap((res) => {
								if (res.info?.status === PartnerStatus.PSTATUS_ACTIVE) {
									store.dispatch(userActions.setIsPartner(true));
								} else {
									store.dispatch(userActions.setIsPartner(false));
								}
							}),
							catchError(() => {
								store.dispatch(userActions.setIsPartner(false));
								return EMIT;
							})
						);
					}
				})
			),
			createEventDataModal$()
		])),
		map(([, processType]) => {

			if (!isEventInfoGuard(processType)) {
				return;
			}

			const result = processType as EventInfo.AsObject;
			const eventReportInfo = {
				...new EventReportInfo().toObject(),
				info: result,
				merchant: new EventReportMerchant().toObject()
			};
			createEventProcessData = getCreateEventProcessInitialData();
			store.dispatch(performerActions.addFutureEventReport(eventReportInfo));
			appNavigate('/performer-panel');
			return result
		}),
	);
}

const isEventInfoGuard = (processType: ProcessType): processType is EventInfo.AsObject => {
	return !!(processType as EventInfo.AsObject).type;
}

