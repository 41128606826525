/* eslint-disable */
// source: sfu/packets.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var sfu_enums_pb = require('../sfu/enums_pb.js');
goog.object.extend(proto, sfu_enums_pb);
var sfu_models_pb = require('../sfu/models_pb.js');
goog.object.extend(proto, sfu_models_pb);
goog.exportSymbol('proto.sfu.api.v1.ActiveStreamsRequestPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.ActiveStreamsResponsePacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.ConferenceModePacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.DisconnectPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.DisconnectedPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.IceCandidatePacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.LocalInputReadyPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.MCUTestPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.MigrationRequiredPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.NoDataPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.OfferPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.Packet', null, global);
goog.exportSymbol('proto.sfu.api.v1.PingPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.PongPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.ServerPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.StreamInfo', null, global);
goog.exportSymbol('proto.sfu.api.v1.StreamsAnswerPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.StreamsPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.StreamsTogglePacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.SubscriptionRTTPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.UnsupportedActionPacket', null, global);
goog.exportSymbol('proto.sfu.api.v1.VideoCodecChangeRequiredPacket', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.ServerPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.ServerPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.ServerPacket.displayName = 'proto.sfu.api.v1.ServerPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.Packet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.Packet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.Packet.displayName = 'proto.sfu.api.v1.Packet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.StreamInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.StreamInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.StreamInfo.displayName = 'proto.sfu.api.v1.StreamInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.StreamsTogglePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sfu.api.v1.StreamsTogglePacket.repeatedFields_, null);
};
goog.inherits(proto.sfu.api.v1.StreamsTogglePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.StreamsTogglePacket.displayName = 'proto.sfu.api.v1.StreamsTogglePacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.StreamsPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sfu.api.v1.StreamsPacket.repeatedFields_, null);
};
goog.inherits(proto.sfu.api.v1.StreamsPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.StreamsPacket.displayName = 'proto.sfu.api.v1.StreamsPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.StreamsAnswerPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sfu.api.v1.StreamsAnswerPacket.repeatedFields_, null);
};
goog.inherits(proto.sfu.api.v1.StreamsAnswerPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.StreamsAnswerPacket.displayName = 'proto.sfu.api.v1.StreamsAnswerPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.OfferPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.OfferPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.OfferPacket.displayName = 'proto.sfu.api.v1.OfferPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.MigrationRequiredPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.MigrationRequiredPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.MigrationRequiredPacket.displayName = 'proto.sfu.api.v1.MigrationRequiredPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.VideoCodecChangeRequiredPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.VideoCodecChangeRequiredPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.VideoCodecChangeRequiredPacket.displayName = 'proto.sfu.api.v1.VideoCodecChangeRequiredPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.UnsupportedActionPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.UnsupportedActionPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.UnsupportedActionPacket.displayName = 'proto.sfu.api.v1.UnsupportedActionPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.DisconnectedPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.DisconnectedPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.DisconnectedPacket.displayName = 'proto.sfu.api.v1.DisconnectedPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.DisconnectPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.DisconnectPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.DisconnectPacket.displayName = 'proto.sfu.api.v1.DisconnectPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.ConferenceModePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.ConferenceModePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.ConferenceModePacket.displayName = 'proto.sfu.api.v1.ConferenceModePacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.PingPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.PingPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.PingPacket.displayName = 'proto.sfu.api.v1.PingPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.SubscriptionRTTPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.SubscriptionRTTPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.SubscriptionRTTPacket.displayName = 'proto.sfu.api.v1.SubscriptionRTTPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.PongPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.PongPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.PongPacket.displayName = 'proto.sfu.api.v1.PongPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.MCUTestPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.MCUTestPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.MCUTestPacket.displayName = 'proto.sfu.api.v1.MCUTestPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.LocalInputReadyPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.LocalInputReadyPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.LocalInputReadyPacket.displayName = 'proto.sfu.api.v1.LocalInputReadyPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.NoDataPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.NoDataPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.NoDataPacket.displayName = 'proto.sfu.api.v1.NoDataPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.IceCandidatePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.IceCandidatePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.IceCandidatePacket.displayName = 'proto.sfu.api.v1.IceCandidatePacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.ActiveStreamsRequestPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sfu.api.v1.ActiveStreamsRequestPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.ActiveStreamsRequestPacket.displayName = 'proto.sfu.api.v1.ActiveStreamsRequestPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sfu.api.v1.ActiveStreamsResponsePacket.repeatedFields_, null);
};
goog.inherits(proto.sfu.api.v1.ActiveStreamsResponsePacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sfu.api.v1.ActiveStreamsResponsePacket.displayName = 'proto.sfu.api.v1.ActiveStreamsResponsePacket';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.ServerPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.ServerPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.ServerPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.ServerPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.ServerPacket}
 */
proto.sfu.api.v1.ServerPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.ServerPacket;
  return proto.sfu.api.v1.ServerPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.ServerPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.ServerPacket}
 */
proto.sfu.api.v1.ServerPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.ServerPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.ServerPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.ServerPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.ServerPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.sfu.api.v1.ServerPacket.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sfu.api.v1.ServerPacket} returns this
 */
proto.sfu.api.v1.ServerPacket.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {string}
 */
proto.sfu.api.v1.ServerPacket.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.sfu.api.v1.ServerPacket.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.ServerPacket.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sfu.api.v1.ServerPacket} returns this
 */
proto.sfu.api.v1.ServerPacket.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.Packet.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.Packet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.Packet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.Packet.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.Packet}
 */
proto.sfu.api.v1.Packet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.Packet;
  return proto.sfu.api.v1.Packet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.Packet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.Packet}
 */
proto.sfu.api.v1.Packet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sfu.api.v1.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.Packet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.Packet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.Packet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.Packet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional Action action = 1;
 * @return {!proto.sfu.api.v1.Action}
 */
proto.sfu.api.v1.Packet.prototype.getAction = function() {
  return /** @type {!proto.sfu.api.v1.Action} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sfu.api.v1.Action} value
 * @return {!proto.sfu.api.v1.Packet} returns this
 */
proto.sfu.api.v1.Packet.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {string}
 */
proto.sfu.api.v1.Packet.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.sfu.api.v1.Packet.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.Packet.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sfu.api.v1.Packet} returns this
 */
proto.sfu.api.v1.Packet.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.StreamInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.StreamInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.StreamInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.StreamInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.StreamInfo}
 */
proto.sfu.api.v1.StreamInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.StreamInfo;
  return proto.sfu.api.v1.StreamInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.StreamInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.StreamInfo}
 */
proto.sfu.api.v1.StreamInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sfu.api.v1.StreamType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.StreamInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.StreamInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.StreamInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.StreamInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional StreamType type = 1;
 * @return {!proto.sfu.api.v1.StreamType}
 */
proto.sfu.api.v1.StreamInfo.prototype.getType = function() {
  return /** @type {!proto.sfu.api.v1.StreamType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sfu.api.v1.StreamType} value
 * @return {!proto.sfu.api.v1.StreamInfo} returns this
 */
proto.sfu.api.v1.StreamInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.sfu.api.v1.StreamInfo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sfu.api.v1.StreamInfo} returns this
 */
proto.sfu.api.v1.StreamInfo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mid = 3;
 * @return {string}
 */
proto.sfu.api.v1.StreamInfo.prototype.getMid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sfu.api.v1.StreamInfo} returns this
 */
proto.sfu.api.v1.StreamInfo.prototype.setMid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sfu.api.v1.StreamsTogglePacket.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.StreamsTogglePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.StreamsTogglePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.StreamsTogglePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.StreamsTogglePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    enableStreamsList: jspb.Message.toObjectList(msg.getEnableStreamsList(),
    proto.sfu.api.v1.StreamInfo.toObject, includeInstance),
    disableStreamsList: jspb.Message.toObjectList(msg.getDisableStreamsList(),
    proto.sfu.api.v1.StreamInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.StreamsTogglePacket}
 */
proto.sfu.api.v1.StreamsTogglePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.StreamsTogglePacket;
  return proto.sfu.api.v1.StreamsTogglePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.StreamsTogglePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.StreamsTogglePacket}
 */
proto.sfu.api.v1.StreamsTogglePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sfu.api.v1.StreamInfo;
      reader.readMessage(value,proto.sfu.api.v1.StreamInfo.deserializeBinaryFromReader);
      msg.addEnableStreams(value);
      break;
    case 2:
      var value = new proto.sfu.api.v1.StreamInfo;
      reader.readMessage(value,proto.sfu.api.v1.StreamInfo.deserializeBinaryFromReader);
      msg.addDisableStreams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.StreamsTogglePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.StreamsTogglePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.StreamsTogglePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.StreamsTogglePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnableStreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sfu.api.v1.StreamInfo.serializeBinaryToWriter
    );
  }
  f = message.getDisableStreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sfu.api.v1.StreamInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StreamInfo enable_streams = 1;
 * @return {!Array<!proto.sfu.api.v1.StreamInfo>}
 */
proto.sfu.api.v1.StreamsTogglePacket.prototype.getEnableStreamsList = function() {
  return /** @type{!Array<!proto.sfu.api.v1.StreamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sfu.api.v1.StreamInfo, 1));
};


/**
 * @param {!Array<!proto.sfu.api.v1.StreamInfo>} value
 * @return {!proto.sfu.api.v1.StreamsTogglePacket} returns this
*/
proto.sfu.api.v1.StreamsTogglePacket.prototype.setEnableStreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sfu.api.v1.StreamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sfu.api.v1.StreamInfo}
 */
proto.sfu.api.v1.StreamsTogglePacket.prototype.addEnableStreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sfu.api.v1.StreamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sfu.api.v1.StreamsTogglePacket} returns this
 */
proto.sfu.api.v1.StreamsTogglePacket.prototype.clearEnableStreamsList = function() {
  return this.setEnableStreamsList([]);
};


/**
 * repeated StreamInfo disable_streams = 2;
 * @return {!Array<!proto.sfu.api.v1.StreamInfo>}
 */
proto.sfu.api.v1.StreamsTogglePacket.prototype.getDisableStreamsList = function() {
  return /** @type{!Array<!proto.sfu.api.v1.StreamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sfu.api.v1.StreamInfo, 2));
};


/**
 * @param {!Array<!proto.sfu.api.v1.StreamInfo>} value
 * @return {!proto.sfu.api.v1.StreamsTogglePacket} returns this
*/
proto.sfu.api.v1.StreamsTogglePacket.prototype.setDisableStreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sfu.api.v1.StreamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sfu.api.v1.StreamInfo}
 */
proto.sfu.api.v1.StreamsTogglePacket.prototype.addDisableStreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sfu.api.v1.StreamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sfu.api.v1.StreamsTogglePacket} returns this
 */
proto.sfu.api.v1.StreamsTogglePacket.prototype.clearDisableStreamsList = function() {
  return this.setDisableStreamsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sfu.api.v1.StreamsPacket.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.StreamsPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.StreamsPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.StreamsPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.StreamsPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    addRequestsList: jspb.Message.toObjectList(msg.getAddRequestsList(),
    proto.sfu.api.v1.StreamInfo.toObject, includeInstance),
    removalRequestsList: jspb.Message.toObjectList(msg.getRemovalRequestsList(),
    proto.sfu.api.v1.StreamInfo.toObject, includeInstance),
    sdpOffer: msg.getSdpOffer_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.StreamsPacket}
 */
proto.sfu.api.v1.StreamsPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.StreamsPacket;
  return proto.sfu.api.v1.StreamsPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.StreamsPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.StreamsPacket}
 */
proto.sfu.api.v1.StreamsPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sfu.api.v1.StreamInfo;
      reader.readMessage(value,proto.sfu.api.v1.StreamInfo.deserializeBinaryFromReader);
      msg.addAddRequests(value);
      break;
    case 2:
      var value = new proto.sfu.api.v1.StreamInfo;
      reader.readMessage(value,proto.sfu.api.v1.StreamInfo.deserializeBinaryFromReader);
      msg.addRemovalRequests(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSdpOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.StreamsPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.StreamsPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.StreamsPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.StreamsPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sfu.api.v1.StreamInfo.serializeBinaryToWriter
    );
  }
  f = message.getRemovalRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sfu.api.v1.StreamInfo.serializeBinaryToWriter
    );
  }
  f = message.getSdpOffer_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * repeated StreamInfo add_requests = 1;
 * @return {!Array<!proto.sfu.api.v1.StreamInfo>}
 */
proto.sfu.api.v1.StreamsPacket.prototype.getAddRequestsList = function() {
  return /** @type{!Array<!proto.sfu.api.v1.StreamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sfu.api.v1.StreamInfo, 1));
};


/**
 * @param {!Array<!proto.sfu.api.v1.StreamInfo>} value
 * @return {!proto.sfu.api.v1.StreamsPacket} returns this
*/
proto.sfu.api.v1.StreamsPacket.prototype.setAddRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sfu.api.v1.StreamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sfu.api.v1.StreamInfo}
 */
proto.sfu.api.v1.StreamsPacket.prototype.addAddRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sfu.api.v1.StreamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sfu.api.v1.StreamsPacket} returns this
 */
proto.sfu.api.v1.StreamsPacket.prototype.clearAddRequestsList = function() {
  return this.setAddRequestsList([]);
};


/**
 * repeated StreamInfo removal_requests = 2;
 * @return {!Array<!proto.sfu.api.v1.StreamInfo>}
 */
proto.sfu.api.v1.StreamsPacket.prototype.getRemovalRequestsList = function() {
  return /** @type{!Array<!proto.sfu.api.v1.StreamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sfu.api.v1.StreamInfo, 2));
};


/**
 * @param {!Array<!proto.sfu.api.v1.StreamInfo>} value
 * @return {!proto.sfu.api.v1.StreamsPacket} returns this
*/
proto.sfu.api.v1.StreamsPacket.prototype.setRemovalRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sfu.api.v1.StreamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sfu.api.v1.StreamInfo}
 */
proto.sfu.api.v1.StreamsPacket.prototype.addRemovalRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sfu.api.v1.StreamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sfu.api.v1.StreamsPacket} returns this
 */
proto.sfu.api.v1.StreamsPacket.prototype.clearRemovalRequestsList = function() {
  return this.setRemovalRequestsList([]);
};


/**
 * optional bytes sdp_offer = 3;
 * @return {string}
 */
proto.sfu.api.v1.StreamsPacket.prototype.getSdpOffer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes sdp_offer = 3;
 * This is a type-conversion wrapper around `getSdpOffer()`
 * @return {string}
 */
proto.sfu.api.v1.StreamsPacket.prototype.getSdpOffer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSdpOffer()));
};


/**
 * optional bytes sdp_offer = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSdpOffer()`
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.StreamsPacket.prototype.getSdpOffer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSdpOffer()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sfu.api.v1.StreamsPacket} returns this
 */
proto.sfu.api.v1.StreamsPacket.prototype.setSdpOffer = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sfu.api.v1.StreamsAnswerPacket.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.StreamsAnswerPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.StreamsAnswerPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.StreamsAnswerPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    sdpAnswer: msg.getSdpAnswer_asB64(),
    addRequestsList: jspb.Message.toObjectList(msg.getAddRequestsList(),
    proto.sfu.api.v1.StreamInfo.toObject, includeInstance),
    removalRequestsList: jspb.Message.toObjectList(msg.getRemovalRequestsList(),
    proto.sfu.api.v1.StreamInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.StreamsAnswerPacket}
 */
proto.sfu.api.v1.StreamsAnswerPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.StreamsAnswerPacket;
  return proto.sfu.api.v1.StreamsAnswerPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.StreamsAnswerPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.StreamsAnswerPacket}
 */
proto.sfu.api.v1.StreamsAnswerPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSdpAnswer(value);
      break;
    case 2:
      var value = new proto.sfu.api.v1.StreamInfo;
      reader.readMessage(value,proto.sfu.api.v1.StreamInfo.deserializeBinaryFromReader);
      msg.addAddRequests(value);
      break;
    case 3:
      var value = new proto.sfu.api.v1.StreamInfo;
      reader.readMessage(value,proto.sfu.api.v1.StreamInfo.deserializeBinaryFromReader);
      msg.addRemovalRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.StreamsAnswerPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.StreamsAnswerPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.StreamsAnswerPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSdpAnswer_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAddRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sfu.api.v1.StreamInfo.serializeBinaryToWriter
    );
  }
  f = message.getRemovalRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sfu.api.v1.StreamInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes sdp_answer = 1;
 * @return {string}
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.getSdpAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes sdp_answer = 1;
 * This is a type-conversion wrapper around `getSdpAnswer()`
 * @return {string}
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.getSdpAnswer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSdpAnswer()));
};


/**
 * optional bytes sdp_answer = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSdpAnswer()`
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.getSdpAnswer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSdpAnswer()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sfu.api.v1.StreamsAnswerPacket} returns this
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.setSdpAnswer = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * repeated StreamInfo add_requests = 2;
 * @return {!Array<!proto.sfu.api.v1.StreamInfo>}
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.getAddRequestsList = function() {
  return /** @type{!Array<!proto.sfu.api.v1.StreamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sfu.api.v1.StreamInfo, 2));
};


/**
 * @param {!Array<!proto.sfu.api.v1.StreamInfo>} value
 * @return {!proto.sfu.api.v1.StreamsAnswerPacket} returns this
*/
proto.sfu.api.v1.StreamsAnswerPacket.prototype.setAddRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sfu.api.v1.StreamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sfu.api.v1.StreamInfo}
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.addAddRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sfu.api.v1.StreamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sfu.api.v1.StreamsAnswerPacket} returns this
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.clearAddRequestsList = function() {
  return this.setAddRequestsList([]);
};


/**
 * repeated StreamInfo removal_requests = 3;
 * @return {!Array<!proto.sfu.api.v1.StreamInfo>}
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.getRemovalRequestsList = function() {
  return /** @type{!Array<!proto.sfu.api.v1.StreamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sfu.api.v1.StreamInfo, 3));
};


/**
 * @param {!Array<!proto.sfu.api.v1.StreamInfo>} value
 * @return {!proto.sfu.api.v1.StreamsAnswerPacket} returns this
*/
proto.sfu.api.v1.StreamsAnswerPacket.prototype.setRemovalRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sfu.api.v1.StreamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sfu.api.v1.StreamInfo}
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.addRemovalRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sfu.api.v1.StreamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sfu.api.v1.StreamsAnswerPacket} returns this
 */
proto.sfu.api.v1.StreamsAnswerPacket.prototype.clearRemovalRequestsList = function() {
  return this.setRemovalRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.OfferPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.OfferPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.OfferPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.OfferPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    sdpAnswer: msg.getSdpAnswer_asB64(),
    sfuType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.OfferPacket}
 */
proto.sfu.api.v1.OfferPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.OfferPacket;
  return proto.sfu.api.v1.OfferPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.OfferPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.OfferPacket}
 */
proto.sfu.api.v1.OfferPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSdpAnswer(value);
      break;
    case 3:
      var value = /** @type {!proto.sfu.api.v1.SfuType} */ (reader.readEnum());
      msg.setSfuType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.OfferPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.OfferPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.OfferPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.OfferPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSdpAnswer_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getSfuType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional bytes sdp_answer = 1;
 * @return {string}
 */
proto.sfu.api.v1.OfferPacket.prototype.getSdpAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes sdp_answer = 1;
 * This is a type-conversion wrapper around `getSdpAnswer()`
 * @return {string}
 */
proto.sfu.api.v1.OfferPacket.prototype.getSdpAnswer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSdpAnswer()));
};


/**
 * optional bytes sdp_answer = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSdpAnswer()`
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.OfferPacket.prototype.getSdpAnswer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSdpAnswer()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sfu.api.v1.OfferPacket} returns this
 */
proto.sfu.api.v1.OfferPacket.prototype.setSdpAnswer = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional SfuType sfu_type = 3;
 * @return {!proto.sfu.api.v1.SfuType}
 */
proto.sfu.api.v1.OfferPacket.prototype.getSfuType = function() {
  return /** @type {!proto.sfu.api.v1.SfuType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sfu.api.v1.SfuType} value
 * @return {!proto.sfu.api.v1.OfferPacket} returns this
 */
proto.sfu.api.v1.OfferPacket.prototype.setSfuType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.MigrationRequiredPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.MigrationRequiredPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.MigrationRequiredPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.MigrationRequiredPacket.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.MigrationRequiredPacket}
 */
proto.sfu.api.v1.MigrationRequiredPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.MigrationRequiredPacket;
  return proto.sfu.api.v1.MigrationRequiredPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.MigrationRequiredPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.MigrationRequiredPacket}
 */
proto.sfu.api.v1.MigrationRequiredPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.MigrationRequiredPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.MigrationRequiredPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.MigrationRequiredPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.MigrationRequiredPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.VideoCodecChangeRequiredPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.VideoCodecChangeRequiredPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.VideoCodecChangeRequiredPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.VideoCodecChangeRequiredPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    newCodec: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.VideoCodecChangeRequiredPacket}
 */
proto.sfu.api.v1.VideoCodecChangeRequiredPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.VideoCodecChangeRequiredPacket;
  return proto.sfu.api.v1.VideoCodecChangeRequiredPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.VideoCodecChangeRequiredPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.VideoCodecChangeRequiredPacket}
 */
proto.sfu.api.v1.VideoCodecChangeRequiredPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sfu.api.v1.Codec} */ (reader.readEnum());
      msg.setNewCodec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.VideoCodecChangeRequiredPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.VideoCodecChangeRequiredPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.VideoCodecChangeRequiredPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.VideoCodecChangeRequiredPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewCodec();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Codec new_codec = 1;
 * @return {!proto.sfu.api.v1.Codec}
 */
proto.sfu.api.v1.VideoCodecChangeRequiredPacket.prototype.getNewCodec = function() {
  return /** @type {!proto.sfu.api.v1.Codec} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sfu.api.v1.Codec} value
 * @return {!proto.sfu.api.v1.VideoCodecChangeRequiredPacket} returns this
 */
proto.sfu.api.v1.VideoCodecChangeRequiredPacket.prototype.setNewCodec = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.UnsupportedActionPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.UnsupportedActionPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.UnsupportedActionPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.UnsupportedActionPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.UnsupportedActionPacket}
 */
proto.sfu.api.v1.UnsupportedActionPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.UnsupportedActionPacket;
  return proto.sfu.api.v1.UnsupportedActionPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.UnsupportedActionPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.UnsupportedActionPacket}
 */
proto.sfu.api.v1.UnsupportedActionPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sfu.api.v1.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.UnsupportedActionPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.UnsupportedActionPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.UnsupportedActionPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.UnsupportedActionPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Action action = 1;
 * @return {!proto.sfu.api.v1.Action}
 */
proto.sfu.api.v1.UnsupportedActionPacket.prototype.getAction = function() {
  return /** @type {!proto.sfu.api.v1.Action} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sfu.api.v1.Action} value
 * @return {!proto.sfu.api.v1.UnsupportedActionPacket} returns this
 */
proto.sfu.api.v1.UnsupportedActionPacket.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.DisconnectedPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.DisconnectedPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.DisconnectedPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.DisconnectedPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    reason: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.DisconnectedPacket}
 */
proto.sfu.api.v1.DisconnectedPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.DisconnectedPacket;
  return proto.sfu.api.v1.DisconnectedPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.DisconnectedPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.DisconnectedPacket}
 */
proto.sfu.api.v1.DisconnectedPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.DisconnectedPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.DisconnectedPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.DisconnectedPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.DisconnectedPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reason = 1;
 * @return {string}
 */
proto.sfu.api.v1.DisconnectedPacket.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sfu.api.v1.DisconnectedPacket} returns this
 */
proto.sfu.api.v1.DisconnectedPacket.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.DisconnectPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.DisconnectPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.DisconnectPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.DisconnectPacket.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.DisconnectPacket}
 */
proto.sfu.api.v1.DisconnectPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.DisconnectPacket;
  return proto.sfu.api.v1.DisconnectPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.DisconnectPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.DisconnectPacket}
 */
proto.sfu.api.v1.DisconnectPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.DisconnectPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.DisconnectPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.DisconnectPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.DisconnectPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.ConferenceModePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.ConferenceModePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.ConferenceModePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.ConferenceModePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    mode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.ConferenceModePacket}
 */
proto.sfu.api.v1.ConferenceModePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.ConferenceModePacket;
  return proto.sfu.api.v1.ConferenceModePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.ConferenceModePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.ConferenceModePacket}
 */
proto.sfu.api.v1.ConferenceModePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sfu.api.v1.ConferenceMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.ConferenceModePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.ConferenceModePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.ConferenceModePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.ConferenceModePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ConferenceMode mode = 1;
 * @return {!proto.sfu.api.v1.ConferenceMode}
 */
proto.sfu.api.v1.ConferenceModePacket.prototype.getMode = function() {
  return /** @type {!proto.sfu.api.v1.ConferenceMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sfu.api.v1.ConferenceMode} value
 * @return {!proto.sfu.api.v1.ConferenceModePacket} returns this
 */
proto.sfu.api.v1.ConferenceModePacket.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.PingPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.PingPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.PingPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.PingPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rtt: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.PingPacket}
 */
proto.sfu.api.v1.PingPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.PingPacket;
  return proto.sfu.api.v1.PingPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.PingPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.PingPacket}
 */
proto.sfu.api.v1.PingPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRtt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.PingPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.PingPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.PingPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.PingPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRtt();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.sfu.api.v1.PingPacket.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sfu.api.v1.PingPacket} returns this
 */
proto.sfu.api.v1.PingPacket.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 rtt = 2;
 * @return {number}
 */
proto.sfu.api.v1.PingPacket.prototype.getRtt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sfu.api.v1.PingPacket} returns this
 */
proto.sfu.api.v1.PingPacket.prototype.setRtt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.SubscriptionRTTPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.SubscriptionRTTPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.SubscriptionRTTPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.SubscriptionRTTPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    subId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rtt: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.SubscriptionRTTPacket}
 */
proto.sfu.api.v1.SubscriptionRTTPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.SubscriptionRTTPacket;
  return proto.sfu.api.v1.SubscriptionRTTPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.SubscriptionRTTPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.SubscriptionRTTPacket}
 */
proto.sfu.api.v1.SubscriptionRTTPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRtt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.SubscriptionRTTPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.SubscriptionRTTPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.SubscriptionRTTPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.SubscriptionRTTPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRtt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string sub_id = 1;
 * @return {string}
 */
proto.sfu.api.v1.SubscriptionRTTPacket.prototype.getSubId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sfu.api.v1.SubscriptionRTTPacket} returns this
 */
proto.sfu.api.v1.SubscriptionRTTPacket.prototype.setSubId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 rtt = 2;
 * @return {number}
 */
proto.sfu.api.v1.SubscriptionRTTPacket.prototype.getRtt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sfu.api.v1.SubscriptionRTTPacket} returns this
 */
proto.sfu.api.v1.SubscriptionRTTPacket.prototype.setRtt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.PongPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.PongPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.PongPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.PongPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.PongPacket}
 */
proto.sfu.api.v1.PongPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.PongPacket;
  return proto.sfu.api.v1.PongPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.PongPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.PongPacket}
 */
proto.sfu.api.v1.PongPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServerTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.PongPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.PongPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.PongPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.PongPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 server_timestamp = 1;
 * @return {number}
 */
proto.sfu.api.v1.PongPacket.prototype.getServerTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sfu.api.v1.PongPacket} returns this
 */
proto.sfu.api.v1.PongPacket.prototype.setServerTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.MCUTestPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.MCUTestPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.MCUTestPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.MCUTestPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    resetencoder: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.MCUTestPacket}
 */
proto.sfu.api.v1.MCUTestPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.MCUTestPacket;
  return proto.sfu.api.v1.MCUTestPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.MCUTestPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.MCUTestPacket}
 */
proto.sfu.api.v1.MCUTestPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResetencoder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.MCUTestPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.MCUTestPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.MCUTestPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.MCUTestPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResetencoder();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool resetEncoder = 1;
 * @return {boolean}
 */
proto.sfu.api.v1.MCUTestPacket.prototype.getResetencoder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sfu.api.v1.MCUTestPacket} returns this
 */
proto.sfu.api.v1.MCUTestPacket.prototype.setResetencoder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.LocalInputReadyPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.LocalInputReadyPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.LocalInputReadyPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.LocalInputReadyPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamtype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.LocalInputReadyPacket}
 */
proto.sfu.api.v1.LocalInputReadyPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.LocalInputReadyPacket;
  return proto.sfu.api.v1.LocalInputReadyPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.LocalInputReadyPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.LocalInputReadyPacket}
 */
proto.sfu.api.v1.LocalInputReadyPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sfu.api.v1.StreamType} */ (reader.readEnum());
      msg.setStreamtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.LocalInputReadyPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.LocalInputReadyPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.LocalInputReadyPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.LocalInputReadyPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional StreamType streamType = 1;
 * @return {!proto.sfu.api.v1.StreamType}
 */
proto.sfu.api.v1.LocalInputReadyPacket.prototype.getStreamtype = function() {
  return /** @type {!proto.sfu.api.v1.StreamType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sfu.api.v1.StreamType} value
 * @return {!proto.sfu.api.v1.LocalInputReadyPacket} returns this
 */
proto.sfu.api.v1.LocalInputReadyPacket.prototype.setStreamtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.NoDataPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.NoDataPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.NoDataPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.NoDataPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamtype: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.NoDataPacket}
 */
proto.sfu.api.v1.NoDataPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.NoDataPacket;
  return proto.sfu.api.v1.NoDataPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.NoDataPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.NoDataPacket}
 */
proto.sfu.api.v1.NoDataPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sfu.api.v1.StreamType} */ (reader.readEnum());
      msg.setStreamtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.NoDataPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.NoDataPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.NoDataPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.NoDataPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional StreamType streamType = 1;
 * @return {!proto.sfu.api.v1.StreamType}
 */
proto.sfu.api.v1.NoDataPacket.prototype.getStreamtype = function() {
  return /** @type {!proto.sfu.api.v1.StreamType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sfu.api.v1.StreamType} value
 * @return {!proto.sfu.api.v1.NoDataPacket} returns this
 */
proto.sfu.api.v1.NoDataPacket.prototype.setStreamtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.IceCandidatePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.IceCandidatePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.IceCandidatePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.IceCandidatePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    iceCandidate: (f = msg.getIceCandidate()) && sfu_models_pb.IceCandidate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.IceCandidatePacket}
 */
proto.sfu.api.v1.IceCandidatePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.IceCandidatePacket;
  return proto.sfu.api.v1.IceCandidatePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.IceCandidatePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.IceCandidatePacket}
 */
proto.sfu.api.v1.IceCandidatePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sfu_models_pb.IceCandidate;
      reader.readMessage(value,sfu_models_pb.IceCandidate.deserializeBinaryFromReader);
      msg.setIceCandidate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.IceCandidatePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.IceCandidatePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.IceCandidatePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.IceCandidatePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIceCandidate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sfu_models_pb.IceCandidate.serializeBinaryToWriter
    );
  }
};


/**
 * optional IceCandidate ice_candidate = 1;
 * @return {?proto.sfu.api.v1.IceCandidate}
 */
proto.sfu.api.v1.IceCandidatePacket.prototype.getIceCandidate = function() {
  return /** @type{?proto.sfu.api.v1.IceCandidate} */ (
    jspb.Message.getWrapperField(this, sfu_models_pb.IceCandidate, 1));
};


/**
 * @param {?proto.sfu.api.v1.IceCandidate|undefined} value
 * @return {!proto.sfu.api.v1.IceCandidatePacket} returns this
*/
proto.sfu.api.v1.IceCandidatePacket.prototype.setIceCandidate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sfu.api.v1.IceCandidatePacket} returns this
 */
proto.sfu.api.v1.IceCandidatePacket.prototype.clearIceCandidate = function() {
  return this.setIceCandidate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sfu.api.v1.IceCandidatePacket.prototype.hasIceCandidate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.ActiveStreamsRequestPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.ActiveStreamsRequestPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.ActiveStreamsRequestPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.ActiveStreamsRequestPacket.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.ActiveStreamsRequestPacket}
 */
proto.sfu.api.v1.ActiveStreamsRequestPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.ActiveStreamsRequestPacket;
  return proto.sfu.api.v1.ActiveStreamsRequestPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.ActiveStreamsRequestPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.ActiveStreamsRequestPacket}
 */
proto.sfu.api.v1.ActiveStreamsRequestPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.ActiveStreamsRequestPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.ActiveStreamsRequestPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.ActiveStreamsRequestPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.ActiveStreamsRequestPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.prototype.toObject = function(opt_includeInstance) {
  return proto.sfu.api.v1.ActiveStreamsResponsePacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sfu.api.v1.ActiveStreamsResponsePacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamsList: jspb.Message.toObjectList(msg.getStreamsList(),
    proto.sfu.api.v1.StreamInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sfu.api.v1.ActiveStreamsResponsePacket}
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sfu.api.v1.ActiveStreamsResponsePacket;
  return proto.sfu.api.v1.ActiveStreamsResponsePacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sfu.api.v1.ActiveStreamsResponsePacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sfu.api.v1.ActiveStreamsResponsePacket}
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sfu.api.v1.StreamInfo;
      reader.readMessage(value,proto.sfu.api.v1.StreamInfo.deserializeBinaryFromReader);
      msg.addStreams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sfu.api.v1.ActiveStreamsResponsePacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sfu.api.v1.ActiveStreamsResponsePacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sfu.api.v1.StreamInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StreamInfo streams = 1;
 * @return {!Array<!proto.sfu.api.v1.StreamInfo>}
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.prototype.getStreamsList = function() {
  return /** @type{!Array<!proto.sfu.api.v1.StreamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sfu.api.v1.StreamInfo, 1));
};


/**
 * @param {!Array<!proto.sfu.api.v1.StreamInfo>} value
 * @return {!proto.sfu.api.v1.ActiveStreamsResponsePacket} returns this
*/
proto.sfu.api.v1.ActiveStreamsResponsePacket.prototype.setStreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sfu.api.v1.StreamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sfu.api.v1.StreamInfo}
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.prototype.addStreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sfu.api.v1.StreamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sfu.api.v1.ActiveStreamsResponsePacket} returns this
 */
proto.sfu.api.v1.ActiveStreamsResponsePacket.prototype.clearStreamsList = function() {
  return this.setStreamsList([]);
};


goog.object.extend(exports, proto.sfu.api.v1);
