import {EnvVars} from './env';

export const stageEnv: EnvVars = {
	pageShortUrl: 'stage.zu.casa',
	pageUrl: 'https://stage.zu.casa',
	apiUsersUrl: 'https://api.stage.zu.casa',
	apiRoomsUrl: 'https://api.stage.zu.casa',
	apiWsUrl: 'wss://ws.stage.zu.casa/v1/connect',
	apiPaymentsUrl: 'https://api.stage.zu.casa',
	apiBabySfuUrl: 'https://babysfu.stage.zu.casa',
	stripeSecret: 'pk_test_51HcDguD2alF1xQ1GEUEHjXAobdCdU4lPDU6MoIoRDov6AcaikaC4p1lehpHhwfj2p2OdZAKCg0lIXG1210DHhHnP00XXMUJ4YM',
	firebaseConfig: {
		apiKey: 'AIzaSyAdoRmuv9sH1qz9B0rtEaanT3Vi61uhrAY',
		authDomain: 'zucasa-dev.firebaseapp.com',
		projectId: 'zucasa-dev',
		storageBucket: 'zucasa-dev.appspot.com',
		messagingSenderId: '287008035481',
		appId: '1:287008035481:web:facaaf6514d80545141e08'
	},
	supportedUsersVersion: '1.0.0',
	supportedRoomsVersion: '1.0.0',
	supportedPaymentsVersion: '1.0.0',
	chefsUnitedCasaId: 'b795028a-a212-4b0f-971f-bd656a749c98',
	demoEventIds: ['cf77dae8-0409-4e05-b331-028b80c214a0'],
	mainPageEventId: 'cf77dae8-0409-4e05-b331-028b80c214a0',
	apiYoutubeKey: 'AIzaSyAq0u1fXYyuQU1pLWI-764sWxxewV7FhP8',
	apiYoutubeUrl: 'https://www.googleapis.com/youtube/v3/videos',
	playlistConfig: {
		TikTok: false,
		YouTube: true,
		ZuCasa: true
	}
};
