import {createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit';
import {UserTicketInfo} from '../../services/gRPC/rooms/models_pb';
import {RootState} from '../store';

const ticketsAdapter = createEntityAdapter<UserTicketInfo.AsObject>({
	selectId: (ticket) => ticket.id
});

export const ticketsSlice = createSlice({
	name: 'tickets',
	initialState: ticketsAdapter.getInitialState(),
	reducers: {
		setTickets: ticketsAdapter.setAll,
		addTickets: ticketsAdapter.addMany,
		addTicket: ticketsAdapter.addOne
	}
});

export const ticketsActions = ticketsSlice.actions;
export const selectClientTickets = ticketsAdapter.getSelectors<RootState>((state) => state.tickets).selectAll;
export const selectClientTicketByEventId = (eventId?: string) => {
	return createSelector(
		selectClientTickets,
		tickets => tickets.find(ticket => ticket.eventId === eventId)
	);
};
