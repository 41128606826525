import {BrowserType, retrieveBrowserType} from './browserTypeDetection';

export enum DeviceType {
	MICROPHONE = 'audioinput',
	CAMERA = 'videoinput',
}

interface Option {
	label: string,
	value: string
}

export interface AvailableDevices {
	[DeviceType.MICROPHONE]: Option[],
	[DeviceType.CAMERA]: Option[],
}

const REGEX_REMOVE_DEFAULT = /^(.)+\s-\s/;
const REGEX_REMOVE_ID = /\s\((.){4}:(.){4}\)$/;

const filterByType = (devices: MediaDeviceInfo[], type: DeviceType): Option[] => {
	const devicesOfType = devices.filter(device => {
		return device.kind === type && device.deviceId !== 'default';
	});
	if (retrieveBrowserType() === BrowserType.SAFARI) {
		return devicesOfType.map(device => ({label: device.label.replace(REGEX_REMOVE_DEFAULT, '').replace(REGEX_REMOVE_ID, ''), value: device.deviceId}));
	} else {
		const uniqueGroupIds = [...new Set(devicesOfType.map(r => r.groupId))];
		return uniqueGroupIds.map(id => {
			const device = devicesOfType.find(d => d.groupId === id);
			return {label: device!.label.replace(REGEX_REMOVE_DEFAULT, '').replace(REGEX_REMOVE_ID, ''), value: device!.deviceId};
		});
	}
};

export const getAvailableDevices = (): Promise<AvailableDevices> => {

	return navigator.mediaDevices.enumerateDevices().then((devices) => {
		const result = {
			[DeviceType.MICROPHONE]: filterByType(devices, DeviceType.MICROPHONE),
			[DeviceType.CAMERA]: filterByType(devices, DeviceType.CAMERA),
		};
		return {...result};
	});
};
