function replaceAll(str: any, find: any, replace: any) {
	return str.replace(new RegExp(find, 'g'), replace);
}

export function updateSDP(sdp: string | undefined, minVP9: number, startVP9: number, maxVP9: number, minVP8: number, startVP8: number, maxVP8: number) {

	if (!sdp) {
		console.error('sdp is undefined');
		return;
	}

	const sdpLines = sdp.split('\n');
	let codecs = new Map();
	let codec: any = null;

	sdpLines.forEach(line => {
		if (line.includes('a=rtpmap:')) {
			if (codec) {
				codec.lines = codec.lines.slice(0, codec.lines.length - 1);
				codecs.set(codec.codecId, codec);
			}
			codec = {
				lines: '',
				codecId: line.split('a=rtpmap:')[1].split(' ')[0],
				codecName: line.split('a=rtpmap:')[1].split(' ')[1].split('/')[0]
			};
		}

		if (line.startsWith('m=')) {
			if (codec) {
				codec.lines = codec.lines.slice(0, codec.lines.length - 1);
				codecs.set(codec.codecId, codec);
			}
			codec = null;
		}

		if (codec) {
			codec.lines += line + '\n';
		}

	});

	codecs.forEach(codec => {
		if (codec.codecName === 'VP9') {
			const newLines = codec.lines.replace(`a=fmtp:${codec.codecId} `, `a=fmtp:${codec.codecId} x-google-max-bitrate=${maxVP9};x-google-min-bitrate=${minVP9};x-google-start-bitrate=${startVP9};`);
			sdp = replaceAll(sdp, codec.lines, newLines);
		} else if (codec.codecName === 'VP8') {
			let newLines;
			if (codec.lines.includes(`a=fmtp:${codec.codecId} `)) {
				newLines = codec.lines.replace(`a=fmtp:${codec.codecId} `, `a=fmtp:${codec.codecId} x-google-max-bitrate=${maxVP8};x-google-min-bitrate=${minVP8};x-google-start-bitrate=${startVP8};`);
			} else {
				newLines = codec.lines + `\na=fmtp:${codec.codecId} x-google-max-bitrate=${maxVP8};x-google-min-bitrate=${minVP8};x-google-start-bitrate=${startVP8};`;
			}

			sdp = replaceAll(sdp, codec.lines, newLines);
		}
	});

	/// screen share specific changes

	let arr = sdp.split('\r\n');

	let isScreenShareMid = false;
	arr.forEach((str, i) => {
		if (/^a=mid:2/.test(str)) {
			arr[i] += '\r\nb=AS:100000';
			isScreenShareMid = true
		}

		if (/^a=mid:3/.test(str)) {
			isScreenShareMid = false
		}

		if (/^a=fmtp:\d*/.test(str) && isScreenShareMid) {
			arr[i] = arr[i].replace(/x-google-max-bitrate=\d+;/, 'x-google-max-bitrate=3000;')
			arr[i] = arr[i].replace(/x-google-min-bitrate=\d+;/, 'x-google-min-bitrate=1500;')
			arr[i] = arr[i].replace(/x-google-start-bitrate=\d+;/, 'x-google-start-bitrate=2250;')
		}
	});
	sdp = arr.join('\r\n');

	return sdp;
}
