import {roomGlobalRef} from '../roomGlobalRef';
import {changeCodec} from '../updaters/codecChanging';
import {RoomType} from '../../../../services/gRPC/rooms/enums_pb';
import {stringToByteArray} from '../../../../utils/utils';
import {SfuType} from '../../../../services/gRPC/sfu/enums_pb';
import {sendICECandidatesWS$} from '../../../../services/ws/senders.ws';

export const initPcReceiver = (roomType: RoomType) => {
	roomGlobalRef.pcMediaReceiver = new RTCPeerConnection();
	//@ts-ignore
	window.pc1 = roomGlobalRef.pcMediaReceiver;

	roomGlobalRef.pcMediaReceiver.addTransceiver('video', {direction: 'recvonly'}); // for mcu

	roomGlobalRef.pcMediaReceiver.onicecandidate = (ev) => {
		const candidate = stringToByteArray(JSON.stringify(ev.candidate));
		if (candidate) {
			const isReceiverOfferReady = roomGlobalRef.queuedCandidates[SfuType.STYPE_ROUTER].isOfferReady;
			if (isReceiverOfferReady) {
				sendICECandidatesWS$(candidate, SfuType.STYPE_ROUTER).subscribe();
			} else {
				// console.log('storing unsent receiver candidate');
				roomGlobalRef.queuedCandidates.addUnsent(SfuType.STYPE_ROUTER, JSON.stringify(ev.candidate));
			}
		}
	};
};

export const initPcSender = (clientId: string) => {
	roomGlobalRef.pcMediaSender = new RTCPeerConnection();
	//@ts-ignore
	window.pc2 = roomGlobalRef.pcMediaSender;

	const currentCameraTransceiver = roomGlobalRef.pcMediaSender.addTransceiver('video', {direction: 'sendonly'}); // sender
	const currentAudioTransceiver = roomGlobalRef.pcMediaSender.addTransceiver('audio', {direction: 'sendonly'}); // sender
	const currentScreenVideoTransceiver = roomGlobalRef.pcMediaSender.addTransceiver('video', {direction: 'sendonly'}); // sender
	const currentScreenAudioTransceiver = roomGlobalRef.pcMediaSender.addTransceiver('audio', {direction: 'sendonly'}); // sender

	changeCodec(currentCameraTransceiver);
	changeCodec(currentScreenVideoTransceiver);

	roomGlobalRef.cameraTransceiversMap.set(clientId, currentCameraTransceiver);
	roomGlobalRef.audioTransceiversMap.set(clientId, currentAudioTransceiver);
	roomGlobalRef.cameraTransceiversMap.set(`screen-${clientId}`, currentScreenVideoTransceiver);
	roomGlobalRef.audioTransceiversMap.set(`screen-audio-${clientId}`, currentScreenAudioTransceiver);

	roomGlobalRef.pcMediaSender.onicecandidate = (ev) => {
		const candidate = stringToByteArray(JSON.stringify(ev.candidate));
		if (candidate) {
			const isSenderOfferReady = roomGlobalRef.queuedCandidates[SfuType.STYPE_GATEWAY].isOfferReady;
			if (isSenderOfferReady) {
				sendICECandidatesWS$(candidate, SfuType.STYPE_GATEWAY).subscribe();
			} else {
				// console.log('storing unsent sender candidate');
				roomGlobalRef.queuedCandidates.addUnsent(SfuType.STYPE_GATEWAY, JSON.stringify(ev.candidate));
			}
		}
	};
};

