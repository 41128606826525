import {RoomUserStatus} from '../../../services/gRPC/rooms/enums_pb';
import {store} from '../../../store/store';
import {roomUsersActions, selectRoomUsers} from '../../../store/slices/roomUsers';
import {UpdateRoomUserStatusPacket} from '../../../services/gRPC/rooms/packets.public_pb';
import {EMPTY, merge, of} from 'rxjs';
import {concatMap, filter, map, mergeMap, tap} from 'rxjs/operators';
import {roomGlobalRef, RoomMode} from './roomGlobalRef';
import {selectClientId} from '../../../store/slices/user';
import {modalsSystem} from '../../../singleComponents/modals/modalSystem';
import {ModalType} from '../../../singleComponents/modals/modal.types';
import {getUserInfoService$} from '../../../services/userServices';
import {getRoomUserInfoService$} from '../../../services/roomServices';
import {EMIT} from '../../../utils/utils';

export class RoomUsersStatusesQueue {

	private isOpen = false;

	private webSocketStatusesUpdatesStore: UpdateRoomUserStatusPacket.AsObject[] = [];

	applyRoomUserStatusUpdate(packet: UpdateRoomUserStatusPacket.AsObject) {
		if (this.isOpen) {
			this.processWebSocketStatus(packet).subscribe();
		} else {
			this.webSocketStatusesUpdatesStore.push(packet);
		}
	}

	private processWebSocketStatus(packet: UpdateRoomUserStatusPacket.AsObject) {
		return merge(
			of(packet).pipe(
				filter(packet => packet.status === RoomUserStatus.RUSTATUS_DISCONNECTED),
				map((msg) => msg.userId),
				tap((userId) => {
					console.log(`%c USER_LEFT_ROOM_WS ${userId}`, 'color: red; font-weight: 900; background: black');
					roomGlobalRef.usersIdsThatLeftRoom.add(userId);
				}),
				tap((userId) => {
					if (userId === selectClientId(store.getState())) {
						const data = {
							title: 'Connection Error',
							text: `Server sent USER_LEFT_ROOM packet for your user id.\n
						Please refresh the page.`,
							preventCoverClick: true,
							hideButton: true
						};
						modalsSystem(ModalType.INFO, data).subscribe();
					} else {
						roomGlobalRef.videoStreamMuteManager.removeStream(userId);
						store.dispatch(roomUsersActions.removeUser(userId));
						if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
							roomGlobalRef.currentSubscriptionState.unsubAllForUser(userId);
							roomGlobalRef.subscriptionManager!.applySubs();
						}
					}
				})),
			// USER JOINED ROOM
			of(packet).pipe(
				filter(packet => packet.status === RoomUserStatus.RUSTATUS_CONNECTED),
				map((msg) => msg.userId),
				tap((userId) => {
					console.log(`%c USER_JOINED_ROOM_WS ${userId}`, 'color: green; font-weight: 900; background: black');
				}),
				concatMap((userId) => userId !== selectClientId(store.getState()) ? of(userId) : EMPTY),
				concatMap((userId) => getUserInfoService$(userId).pipe(
					concatMap((userInfo) => getRoomUserInfoService$(userId).pipe(
						map((roomUserInfo) => ({userInfo, roomUserInfo}))
					))
				)),
				tap(({userInfo, roomUserInfo}) => {
					store.dispatch(roomUsersActions.upsertUser({userInfo, roomUserInfo}));
				})
			),
			/// UPDATE ROOM USER STATUS
			of(packet).pipe(
				// play sound
				mergeMap((packet) => merge(
					EMIT.pipe(
						filter(() => packet.status === RoomUserStatus.RUSTATUS_CONNECTED),
						tap(() => {
							const currentUser = selectRoomUsers(store.getState()).find(user => user.id === packet.userId);
							const clientId = selectClientId(store.getState());
							if (!currentUser && clientId !== packet.userId) {
								// userJoinedRoomRef.current?.play();
							}
						})
					),
					EMIT.pipe(
						filter(() => packet.status === RoomUserStatus.RUSTATUS_DISCONNECTED),
						tap(() => {
							// userLeftRoomUrlRef.current?.play();
						})
					),
					EMIT.pipe(
						// when user is connected we consider it as "USER JOINED ROOM" and we fetch all data about him.
						filter(() => packet.status !== RoomUserStatus.RUSTATUS_CONNECTED),
						tap(() => {
							const clientId = selectClientId(store.getState());
							if (clientId !== packet.userId) {
								store.dispatch(roomUsersActions.updateRoomUserStatus({userId: packet.userId, status: packet.status}));
							}
						})
					)
				))
			));
	}

	open(date: number) {
		this.isOpen = true;

		this.webSocketStatusesUpdatesStore.forEach((packet) => {
			if (packet.timestamp > date) {
				this.processWebSocketStatus(packet).subscribe();
			} else {
				console.log(date, 'OLD PACKET: ', packet);
			}
		});
		this.webSocketStatusesUpdatesStore = [];
	}

	close() {
		this.isOpen = false;
	}
}
