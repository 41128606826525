import {catchError, filter, map, mergeMap, take, tap} from 'rxjs/operators';
import {BehaviorSubject, EMPTY, forkJoin, of, throwError} from 'rxjs';
import {
	authStateChangeDetection,
	isLoggedFirebase$,
	loginAsGuestFirebase$,
	logoutFirebase$,
	refreshTokenResultsFirebase$,
	setPersistenceFirebase$
} from '../../services/firebaseAuth';
import {createGuestService$} from '../../services/userServices';
import {EMIT} from '../utils';
import {websocketListenersSubscription} from './useSetListeners';
import {userActions} from '../../store/slices/user';
import {initWebSocketMessageTransmitter, setWebSocket$} from '../../services/ws/webSocket';
import {loadGuestData$} from './useLoadLoggedGuestData';
import {loadUserDataIfCan$} from './useLoadLoggedUserData';
import {store} from '../../store/store';
import {authProcess$} from '../../pages/TestPage/useAuthProcess';

const createFirebaseGuestAccount$ = () => {
	return loginAsGuestFirebase$().pipe(
		mergeMap(() => refreshTokenResultsFirebase$()),
		mergeMap(() => createGuestService$()),
		catchError(err => {
			logoutFirebase$().subscribe();
			return throwError(err);
		})
	);
};

let appLoadedStarted = false;
const appLoaderFinishedCore$$ = new BehaviorSubject(false);
export const appLoaderFinished$ = appLoaderFinishedCore$$.pipe(
	filter(v => v),
	take(1)
);
export const appLoader$ = () => {
	if (appLoadedStarted) {
		return EMPTY;
	} else {
		appLoadedStarted = true;
		return setPersistenceFirebase$().pipe(
			mergeMap(() => isLoggedFirebase$()),
			mergeMap(isLogged => isLogged ? EMIT : createFirebaseGuestAccount$()),
			mergeMap(() => refreshTokenResultsFirebase$()),
			mergeMap(tokenResults => tokenResults.claims._user_id ? of(tokenResults) : createGuestService$().pipe(
				tap(() => refreshTokenResultsFirebase$()),
				map(() => tokenResults)
			)),
			tap(tokenResults => {
				store.dispatch(userActions.setUserFirebaseTokenResult({...tokenResults}));
			}),
			tap(() => authStateChangeDetection.start()),
			mergeMap(() => forkJoin([
				loadGuestData$(),
				loadUserDataIfCan$()
			])),
			tap(() => appLoaderFinishedCore$$.next(true)),
			tap(() => initWebSocketMessageTransmitter()),
			mergeMap(() => setWebSocket$()),
			tap(() => websocketListenersSubscription()),
			mergeMap(() => authProcess$({forceAuthCompletion: true})),
		);
	}
};

