import styled from 'styled-components';
import {ErrorTextS} from '../ErrorText/ErrorTextS';

export const UserSearchS = styled.div`
	padding: 0 8px;
	width: 100%;
	
	.UserSearchS_ResultEntry {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	
	${ErrorTextS} {
		display: none;
	}
`
