import styled from 'styled-components';
import {GenericAvatarProps} from './GenericAvatar';

export const GenericAvatarS = styled.div<GenericAvatarProps>`

	.GenericAvatarS_UserImage {
		background-image: ${props => `url("${props.imageUrl}")`};
		background-size: cover;
		background-position: center;
		border-radius: ${({layout}) => layout === 'circle' ? '50%' : '20px'};
		
		width: ${props => props.width};
		height: ${props => props.height};
	}

	.GenericAvatarS_DefaultImage {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: ${({layout}) => layout === 'circle' ? '50%' : '20px'};
		overflow: hidden;
		
		width: ${props => props.width};
		height: ${props => props.height};
		color: ${props => props.iconColor};
		
		i {
			font-size: calc(${props => props.height} * 0.5)
		}
	}
`
