import {BehaviorSubject} from 'rxjs';

export const isTooltipShowed$$ = new BehaviorSubject<boolean>(false);
export const listenToTooltips = () => {

	const bodyObs = new MutationObserver((mutations) => {
		const mutationWithPopupRoot = mutations.find(mutation => {
			return [...mutation.addedNodes].find(node => {
				if (node instanceof HTMLElement && node.id === 'popup-root') {
					return true;
				}
			});
		});
		if (mutationWithPopupRoot) {
			if (mutationWithPopupRoot.target.childNodes.length > 0) {
				console.log("mutationWithPopupRoot: ", mutationWithPopupRoot)
				isTooltipShowed$$.next(true);
			}
			bodyObs.disconnect();
			const popupRootEl = document.getElementById('popup-root')!;
			const popupRootObs = new MutationObserver((mutation) => {
				if (mutation.find(m => m.target.childNodes.length > 0)) {
					isTooltipShowed$$.next(true);
				} else {
					isTooltipShowed$$.next(false);
				}
			});
			popupRootObs.observe(popupRootEl, {childList: true});
		}
	});

	bodyObs.observe(document.querySelector('body')!, {childList: true});

};

