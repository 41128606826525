import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

interface ViewState {
	isMouseMoving: boolean,
	isHoveringUi: boolean,
	mobileMode: boolean,
	dashboardMode: boolean,
	notification: Notification,
	openedLeftDrawer: DrawerState,
	openedRightDrawer: DrawerState,
}

export enum DrawerType {
	NONE,
	GRID_PANEL,
	CASA_USERS,
	CASA_SETTINGS,
	CHATS_PANEL,
	INVITE_PEOPLE,
	QUEUE,
	QUEUE_SEARCH,
}

export interface DrawerState {
	type: DrawerType
	tab?: string
	entityId?: string
}

export type NotifyType = 'PRIVATE_CHAT' | 'CASA_CHAT' | 'EVENT_CHAT'
interface Notification {
	active: boolean
	type?: NotifyType
	entryId?: string
}

const initialState: ViewState = {
	isMouseMoving: false,
	isHoveringUi: false,
	mobileMode: false,
	dashboardMode: false,
	notification: {active: false},
	openedLeftDrawer: {type: DrawerType.NONE},
	openedRightDrawer: {type: DrawerType.NONE},
};

export const viewSlice = createSlice({
	name: 'view',
	initialState,
	reducers: {
		toggleIsHoveringUi: (state, action: PayloadAction<boolean | undefined>) => {
			const newValue = action.payload;
			state.isHoveringUi = newValue === undefined ? !state.isHoveringUi : newValue;
		},
		toggleIsMouseMoving: (state, action: PayloadAction<boolean | undefined>) => {
			const newValue = action.payload;
			state.isMouseMoving = newValue === undefined ? !state.isMouseMoving : newValue;
		},
		setMobileMode: (state, action: PayloadAction<boolean>) => {
			state.mobileMode = action.payload;
		},
		setDashboardMode: (state, action: PayloadAction<boolean>) => {
			state.dashboardMode = action.payload;
		},
		setNotification: (state, action: PayloadAction<Notification>) => {
			state.notification = action.payload
		},
		clearNotification: (state) => {
			state.notification = {active: false}
		},
		toggleOpenedLeftDrawer: (state, action: PayloadAction<DrawerState>) => {
			if (state.openedLeftDrawer.type === DrawerType.NONE || state.openedLeftDrawer.type !== action.payload.type) {
				state.openedLeftDrawer = action.payload;
			} else {
				state.openedLeftDrawer = {type: DrawerType.NONE}
			}
		},
		toggleOpenedRightDrawer: (state, action: PayloadAction<DrawerState>) => {
			if (state.openedRightDrawer.type === DrawerType.NONE || state.openedRightDrawer.type !== action.payload.type) {
				state.openedRightDrawer = action.payload;
			} else {
				state.openedRightDrawer = {type: DrawerType.NONE}
			}
		},
		setOpenedLeftDrawer: (state, action: PayloadAction<DrawerState>) => {
			state.openedLeftDrawer = action.payload;
		},
		setOpenedRightDrawer: (state, action: PayloadAction<DrawerState>) => {
			state.openedRightDrawer = action.payload;
		},
	  clearLeftDrawerState: (state) => {
			state.openedLeftDrawer.tab = undefined
			state.openedLeftDrawer.entityId = undefined
		},
		clearRightDrawerState: (state) => {
			state.openedRightDrawer.tab = undefined
			state.openedRightDrawer.entityId = undefined
		},
	}
});

export const viewActions = viewSlice.actions;

/// open
export const selectIsMobileMode = (state: RootState) => state.view.mobileMode;
export const selectNotification = (state: RootState) => state.view.notification;
export const selectOpenedLeftDrawer = (state: RootState) => state.view.openedLeftDrawer;
export const selectOpenedRightDrawer = (state: RootState) => state.view.openedRightDrawer;
