import styled from 'styled-components';

export const ChatListS = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: 10px 0 10px 0;
	
	.ChatListS_EntriesWrapper {
		width: 100%;
		height: 100%;
		margin-top: 10px;
		overflow-y: auto;
	}
`
