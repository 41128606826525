import {VideoData} from '../../../services/roomStreamHelpers';
import {RoomStream} from '../../../store/slices/roomStreams';

export const createRoomStream = (video: VideoData, position: number, clientId: string) => {
	return {
		stream: {
			id: String(Date.now()),
			type: video.type,
			roomId: String(Date.now()),
			ownerId: clientId,
			position: position,
			metadata: {
				thumbnailUrl: video.thumbnailUrl,
				duration: video.duration,
				sid: video.id,
				title: video.title,
				paused: true,
				offset: video.offset ?? 0,
				live: false
			},
			videoUrl: video.downloadUrl
		} as RoomStream
	}
}

export interface Result {
	input: string,
	youtubeToken: string,
	results: VideoData[],
	tiktokTotal: number,
	tiktokCurrent: number,
	eventTotal: number,
	eventCurrent: number
}

export const initialResult = {
	input: '',
	youtubeToken: '',
	results: [],
	tiktokTotal: 0,
	tiktokCurrent: 0,
	eventTotal: 0,
	eventCurrent: 0
};
