import {EMPTY, merge, Observable} from 'rxjs';
import {EMIT} from './utils';
import {mergeMap, tap} from 'rxjs/operators';

export const subscribeThenDo = <T2>(observable: Observable<T2>, fun: () => any) => {
	return <T>(source: Observable<T>): Observable<T2> => {
		return new Observable(subscriber => {
			source.pipe(
				mergeMap(() => merge(
					observable,
					EMIT.pipe(
						tap(fun),
						mergeMap(() => EMPTY)
					)
				))
			).subscribe({
				next: (action) => {
					subscriber.next(action);
				},
				error: (action) => {
					subscriber.error(action);
				},
				complete: () => {
					subscriber.complete();
				}
			});
		});
	};
};
