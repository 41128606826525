import React, {FC} from 'react';
import {TooltipS} from './TooltipS';
import {PopupProps} from 'reactjs-popup/dist/types';
import {useAppSelector} from '../../store/hooks';
import {selectIsMobileMode} from '../../store/slices/view';

export interface TooltipProps extends PopupProps {
	text: string | JSX.Element
}

export const Tooltip: FC<TooltipProps> = ({text, children, ...props}) => {
	const isMobile = useAppSelector(selectIsMobileMode);

	return (
		<TooltipS
			trigger={() => (
				<div>
					{children}
				</div>
			)}
			position="right center"
			closeOnDocumentClick
			mouseEnterDelay={0}
			mouseLeaveDelay={0}
			arrow={false}
			disabled={isMobile}
			on={isMobile ? [] : ['hover']}
			{...props}
		>
			<span style={{whiteSpace: 'pre-line'}}> {text} </span>
		</TooltipS>
	);
};
