import React, {ReactText} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RoomStreamEvent} from '../store/slices/roomStreams';
import {EventComingSoon} from '../singleComponents/Notifications/EventComingSoon';
import {EventStarts} from '../singleComponents/Notifications/EventStarts';
import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';
import {darken} from 'polished';
import {Button} from '../sharedComponents/Button/Button';
import {remm} from './remm';
import {getCopyUrl} from '../pages/Room/relatedComponents/RoomStreams/PlaylistElement';
import {RoomStreamType} from '../services/gRPC/rooms/enums_pb';

/*
 * Generic
 */

export enum Notification {
	SUCCESS = 'success',
	INFO = 'info',
	ERROR = 'error',
	WARNING = 'warning'
}

interface ChoiceNotificationProps {
	type?: Notification
	text: JSX.Element,
	onConfirm: () => void,
	onDeny?: () => void,
	notificationId?: string,
}

export const showNotification = (type: Notification, text = '', id?: string) => {
	toast[type](text, {autoClose: 5000, toastId: id});
};

export const showPermanentNotification = (type: Notification, text = '', id?: string) => {
	toast[type](text, {
		closeButton: false,
		autoClose: false,
		closeOnClick: false,
		draggable: false,
		toastId: id
	});
};

export const closeNotification = (id: ReactText) => {
	toast.dismiss(id);
};

export const checkIsActiveNotification = (id: ReactText) => {
	return toast.isActive(id);
};

/*
 * Events
 */

export const showEventStartsNotification = (type: Notification, title = '', currentPosition: number) => {
	return toast[type](<EventStarts title={title} currentPosition={currentPosition}/>, {
		closeButton: true,
		autoClose: 60000,
		closeOnClick: true,
		draggable: false
	});
};

export const showComingSoonNotification = (stream: RoomStreamEvent) => {
	return toast[Notification.INFO](<EventComingSoon stream={stream}/>, {
		closeButton: true,
		autoClose: 50000,
		closeOnClick: false,
		draggable: false,
		toastId: 'comingSoon'
	});
};

/*
 * Relations & Invites
 */

interface ChoiceNotificationProps {
	type?: Notification
	text: JSX.Element,
	onConfirm: () => void,
	onDeny?: () => void,
	notificationId?: string,
}

export const showErrorNotification = (text: string, errorText: string) => {
	return toast[Notification.ERROR](
		<div>
			{text}
			<div style={{display: 'block', marginTop: '10px'}}>Error details: {errorText}</div>
		</div>, {
			closeButton: true,
			closeOnClick: false,
			draggable: false
		});
};

export const showChoiceNotification = ({type, text, onConfirm, onDeny}: ChoiceNotificationProps) => {
	const notificationId = uuidv4();
	toast[type!](<ChoiceNotificationWrapper type={type} text={text} onConfirm={onConfirm} onDeny={onDeny}
	                                        notificationId={notificationId}/>, {
		toastId: notificationId,
		closeOnClick: false,
		autoClose: false
	});
};

export const ChoiceNotificationJoinRoomText = (userName: string, casaName: string) => <div>
	<span className="ChoiceNotificationWrapperS_textBold">{userName} </span>
	invited you to join <span className="ChoiceNotificationWrapperS_textBold">{casaName}</span>
</div>;

/*
 * Devices
 */

export const showDeviceSwitchedNotification = (deviceName: string, deviceType: string) => {
	toast[Notification.INFO](
		<div style={{textAlign: 'center'}}>Your current {deviceType} has been unplugged from the computer.
			<div style={{display: 'block'}}>We switched you to</div>
			<span style={{display: 'block', fontWeight: 700}}>{deviceName} </span>
		</div>,
		{
			toastId: `switchedDevice${deviceName}`,
			autoClose: 10000
		});
};

export const showAcceptableNotification = (text: string, onConfirm: () => void, id: string) => {
	toast[Notification.INFO](
		<ChoiceNotificationWrapper
			text={
				<div>{text}</div>
			}
			onConfirm={onConfirm}
		/>,
		{
			toastId: id,
			closeOnClick: false,
			autoClose: false
		}
	);
};

export const showNewDeviceNotification = (deviceName: string, deviceType: string, onConfirm: () => void) => {
	toast[Notification.INFO](
		<ChoiceNotificationWrapper
			text={
				<div>We detected that new {deviceType} has been plugged in to your computer:
					<span style={{display: 'block', fontWeight: 700}}>{deviceName} </span>
					Would you like to switch to it?
				</div>
			}
			onConfirm={onConfirm}
		/>,
		{
			toastId: `newDevice${deviceName}`,
			closeOnClick: false,
			autoClose: false
		}
	);
};

export const showTrailerNotification = (id: string) => {
	const url = `${getCopyUrl(RoomStreamType.RSTYPE_YOUTUBE)}${id}`
	toast[Notification.INFO](
				<div>You've just watched a movie trailer. Click <a target={'_blank'} href={url}>here</a> to get access to full movie.
				</div>,
		{
			toastId: `trailer`,
			closeOnClick: false,
			autoClose: false
		}
	);
};

/*
 * Components
 */

const ChoiceNotificationWrapper = ({text, onConfirm, onDeny, notificationId}: ChoiceNotificationProps) => {
	return <ChoiceNotificationWrapperS className="d-flex flex-column w-100 h-100 justify-content-around">
		<div className="d-flex justify-content-center ChoiceNotificationWrapperS_text">{text}</div>
		<div className={'d-flex'}>

			<Button onClick={() => {
				onDeny && onDeny();
				closeNotification(notificationId!);
			}}
			        className={'primary'}
			        buttonHeight={'26px'}
			        buttonWidth={'90px'}>
				DECLINE
			</Button>

			<Button onClick={() => {
				onConfirm && onConfirm();
				closeNotification(notificationId!);
			}}
			        className={'primary'}
			        buttonHeight={'26px'}
			        buttonWidth={'90px'}>
				ACCEPT
			</Button>

		</div>
	</ChoiceNotificationWrapperS>;
};

const ChoiceNotificationWrapperS = styled.div`
	height: auto !important;
	cursor: default !important;

	div:nth-child(2) {
		justify-content: space-evenly;
	}

	.ChoiceNotificationWrapperS_text {
		text-align: center;
		font-weight: 400;
		font-size: ${remm('14px')};

		.ChoiceNotificationWrapperS_textBold {
			font-weight: 700;
		}
	}

	.ChoiceNotificationWrapperS_iconWrapper {
		display: flex;
		justify-content: center;
		padding: 4px 20px;
		border: 1px solid ${darken(0.2, '#6AE0A0')};
		cursor: pointer;
		transition: all 0.15s;
		font-weight: 800;
		font-size: ${remm('12px')};
		letter-spacing: normal;
		border-radius: 4px;

		&:hover {
			background: ${darken(0.1, '#6AE0A0')};
			border-color: transparent;
		}
	}

	.ChoiceNotificationWrapperS_text {
		margin: 5px 0 10px 0;
	}
`;
