import {roomGlobalRef} from '../roomGlobalRef';
import {reconnectPcSender} from './reconnectPcSender';
import {selectIsConnectedWithWs} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {resendClientFlags$} from '../resendClientFlags';

let lastConnectionState = '';

export const initPcSenderListeners = () => {

		roomGlobalRef.pcMediaSender!.onconnectionstatechange = (ev) => {
			console.log(ev);
			console.log(`%cSender: ${roomGlobalRef.pcMediaSender?.connectionState}`, 'color: black; font-weight: 900; background: #8f95ff');
			switch (roomGlobalRef.pcMediaSender?.connectionState) {
				case 'failed': {
					lastConnectionState = roomGlobalRef.pcMediaSender?.connectionState;
					if (selectIsConnectedWithWs(store.getState())) {
						console.log('pcMediaSender FAILED, reconnecting');
						reconnectPcSender(true);
					}
					return;
				}
				case 'connected': {
					if (lastConnectionState === 'disconnected') {
						console.log('SHOULD RESEND FLAGS');
						roomGlobalRef.shouldResendFlags = true;

					if (selectIsConnectedWithWs(store.getState())) {
						resendClientFlags$().subscribe();
					}
				}
				lastConnectionState = roomGlobalRef.pcMediaSender?.connectionState;
				return;
			}
			default : {
				lastConnectionState = roomGlobalRef.pcMediaSender?.connectionState || '';
			}
		};
	};

	roomGlobalRef.pcMediaSender!.oniceconnectionstatechange = (ev) => {
		console.log(`%cSender ice connection: ${roomGlobalRef.pcMediaSender?.iceConnectionState}`, 'color: black; font-weight: 900; background: pink');
	};

	roomGlobalRef.pcMediaSender!.onicegatheringstatechange = (ev) => {
		console.log(`%cSender ice gathering: ${roomGlobalRef.pcMediaSender?.iceGatheringState}`, 'color: black; font-weight: 900; background: white');
	};
};
