import styled from 'styled-components';
import {remm} from '../../../../../../../utils/remm';
import {CYAN_600} from '../../../../../../../styles/constants';

export const ChatListEntryS = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
	padding: 5px 10px;
	
	.ChatListEntryS_Username {
		font-size: ${remm('15px')};
		font-weight: 700;
		text-overflow: ellipsis;
		max-width: 240px;
		overflow: hidden;
		white-space: nowrap;
	}

	.ChatListEntryS_LastMessage {
		font-size: ${remm('12px')};
		color: ${({theme}) => theme.mediumTextColor};
		text-overflow: ellipsis;
		max-width: 220px;
		overflow: hidden;
		white-space: nowrap;
	}

	.ChatListEntryS_Notification {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background: ${CYAN_600};
		right: 3px;
		top: 2px;
	}
	
	:hover {
		background-color: ${({theme}) => theme.color3};
	}
`
