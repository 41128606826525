import {map, mergeMap, tap} from 'rxjs/operators';
import {getUserInfoService$} from '../../services/userServices';
import {listUserTicketsService$, UserRole} from '../../services/roomServices';
import {EMIT} from '../utils';
import {store} from '../../store/store';
import {selectClientRoleFire, userActions} from '../../store/slices/user';
import {ticketsActions} from '../../store/slices/tickets';

const loadUserDataCore$ = () => EMIT.pipe(
	/// user
	mergeMap(() => getUserInfoService$().pipe(
		tap((userInfo) => {
			store.dispatch(userActions.setUserInfo(userInfo));
		})
	)),

	// load async data that is not needed just on start
	tap(() => {

		/// active events
		listUserTicketsService$().pipe(
			tap((tickets) => {
				store.dispatch(ticketsActions.setTickets(tickets));
			})
		).subscribe();

	})
);

export const loadUserDataIfCan$ = () => EMIT.pipe(
	map(() => selectClientRoleFire(store.getState())),
	mergeMap((clientRoleFire) => clientRoleFire === UserRole.GUEST ? EMIT : loadUserDataCore$())
);
