import {Observable, of, throwError} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {EventTicketInfo} from '../../../services/gRPC/payments/models_pb';
import {EMIT} from '../../../utils/utils';
import {ModalType} from '../modal.types';
import {modalsSystem} from '../modalSystem';
import {selectActiveEvents} from '../../../store/slices/events';
import {store} from '../../../store/store';
import {selectClientTicketByEventId} from '../../../store/slices/tickets';
import {authProcess$} from '../../../pages/TestPage/useAuthProcess';
import {Notification, showNotification} from '../../../utils/showNotification';

type ProcessType = 'DONE' | 'BACK'

interface useTicketProcessProps {
	eventId: string
}

let eventId: string;

const ticketModal$: () => Observable<ProcessType> = () => modalsSystem(ModalType.TICKET_MODAL, {eventId: eventId}).pipe(
	mergeMap((output) => {
		switch (output.next) {
			case 'DISCOUNT_CODE': {
				return discountModal$();
			}
			case 'CARD_PAYMENT': {
				return paymentModal$(output.ticket);
			}
		}
		return of(output.next);
	}));


const discountModal$: () => Observable<ProcessType> = () => modalsSystem(ModalType.APPLY_VOUCHER_MODAL, {eventId: eventId}).pipe(
	mergeMap((output) => {
		switch (output.next) {
			case 'CARD_PAYMENT': {
				return paymentModal$(output.ticket, output.voucher?.id);
			}
			case 'DONE': {
				return of(output.next);
			}
			default: {
				return ticketModal$();
			}
		}

	})
);

const paymentModal$ = (ticket: EventTicketInfo.AsObject, voucherId?: string) =>
	modalsSystem(ModalType.PAYMENT_MODAL, {ticket, voucherId}).pipe(
		mergeMap(({next}) => {
			if (next === 'BACK') {
				return ticketModal$();
			}
			return of(next);
		})
	);

const checkIfRequired$ = () => EMIT.pipe(
	mergeMap(() => {
		const isAnyTicketForTheEvent = selectClientTicketByEventId(eventId)(store.getState());
		const activeEvents = selectActiveEvents(store.getState());
		const currentEvent = activeEvents.find(event => event.id === eventId);
		// if found event in store && dont have any ticket for that event && event has available tickets
		if (currentEvent && !isAnyTicketForTheEvent && currentEvent?.ticketsList) {
			return ticketModal$();
		} else if (isAnyTicketForTheEvent) {
			return of('DONE');
		} else {
			return throwError(() => new Error('CurrentEvent or ticketsList don\'t exist.'));
		}
	})
) as Observable<ProcessType>;

const authIfRequired$ = () => EMIT.pipe(
	mergeMap(() => {
		return authProcess$();
	}),
	mergeMap(() => checkIfRequired$())
);

export const ticketProcess$ = (args: useTicketProcessProps) => EMIT.pipe(
	tap(() => eventId = args.eventId),
	mergeMap(() => authIfRequired$()),
	tap(() => showNotification(Notification.SUCCESS, 'The ticket has been assigned to your account.')),
);

