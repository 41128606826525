import {createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit';
import {EventInfo} from '../../services/gRPC/rooms/models_pb';
import {RootState} from '../store';
import {EventStatus} from '../../services/gRPC/rooms/enums_pb';

const eventsAdapter = createEntityAdapter<EventInfo.AsObject>({
	selectId: (event) => event.id
});

export const eventsSlice = createSlice({
	name: 'events',
	initialState: eventsAdapter.getInitialState(),
	reducers: {
		addEvents: eventsAdapter.addMany,
		addEvent: eventsAdapter.addOne,
		updateEvent: eventsAdapter.updateOne,
		removeEvent: eventsAdapter.removeOne
	}
});

export const eventsActions = eventsSlice.actions;
export const selectEvents = eventsAdapter.getSelectors<RootState>((state) => state.events).selectAll;
export const selectEvent = eventsAdapter.getSelectors<RootState>((state) => state.events).selectById;
export const selectEventTickets = createSelector(
	selectEvent,
	event => event === undefined ? [] : event.ticketsList
);
export const selectActiveEvents = createSelector(
	selectEvents,
	event => event.filter(event => !(event.status === EventStatus.ESTATUS_FINISHED ||
		event.status === EventStatus.ESTATUS_CANCELED || event.status === EventStatus.ESTATUS_UNKNOWN))
);
export const selectEventsSorted = createSelector(
	selectEvents,
	event => event.sort((a, b) => a.startsAt > b.startsAt ? 1 : -1)
);
export const selectFutureEvents = createSelector(
	selectEvents,
	event => event.filter(item => [EventStatus.ESTATUS_CREATED, EventStatus.ESTATUS_PLANNED].includes(item.status))
);
export const selectPastEvents = createSelector(
	selectEvents,
	event => event.filter(event => event.startsAt * 1000 < Date.now()).sort((a, b) => a.startsAt > b.startsAt ? 1 : -1)
);
export const selectEventsByIds = (ids: string[]) => {
	return createSelector(
		selectEvents,
		events => events.filter(event => ids.includes(event.id))
	);
};
