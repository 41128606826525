import {roomGlobalRef} from '../roomGlobalRef';
import {selectClientId} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {from} from 'rxjs';
import {updateSDP} from './updateSdp';
import {streamsSenderSFU} from '../../../../services/sfu/senders.sfu';
import pako from 'pako';

export const changeCodecVP8 = () => {
  const cameraTransceiver = roomGlobalRef.cameraTransceiversMap.get(selectClientId(store.getState()))!;
  let codecsList = RTCRtpReceiver.getCapabilities('video')?.codecs!;
  codecsList = skipUnsupportedCodecs(codecsList);
  codecsList = codecsList.filter(codec => codec.mimeType.toLowerCase() !== 'video/vp9');
  //@ts-ignore unfortunately typescript doesn't have type definition for this (shame!)
  cameraTransceiver.setCodecPreferences(codecsList);
};
//@ts-ignore
window.codecVP8 = changeCodecVP8;

export const changeCodecVP9 = () => {
  const cameraTransceiver = roomGlobalRef.cameraTransceiversMap.get(selectClientId(store.getState()))!;
  let codecsList = RTCRtpReceiver.getCapabilities('video')?.codecs!;
  codecsList = skipUnsupportedCodecs(codecsList);
  codecsList = codecsList.filter(codec => codec.mimeType.toLowerCase() !== 'video/vp8');
  //@ts-ignore unfortunately typescript doesn't have type definition for this (shame!)
  cameraTransceiver.setCodecPreferences(codecsList);
};
//@ts-ignore
window.codecVP9 = changeCodecVP9;

export const changeCodec = (transceiver: RTCRtpTransceiver) => {
  let codecsList = RTCRtpReceiver.getCapabilities('video')?.codecs!;
  codecsList = skipUnsupportedCodecs(codecsList);
  const VP9index = codecsList.findIndex(codec => codec.mimeType.toLowerCase() === 'video/vp9' && codec.sdpFmtpLine === 'profile-id=0');
  codecsList.splice(0, 0, codecsList.splice(VP9index, 1)[0]);
  const VP8index = codecsList.findIndex(codec => codec.mimeType.toLowerCase() === 'video/vp8');
  codecsList.splice(1, 0, codecsList.splice(VP8index, 1)[0]);
  //@ts-ignore unfortunately typescript doesn't have type definition for this (shame!)
  transceiver.setCodecPreferences(codecsList);
};

export const renegotiation = (minVP9: number, startVP9: number, maxVP9: number,
  minVP8: number, startVP8: number, maxVP8: number) => {
  from(roomGlobalRef.pcMediaSender!.createOffer()).subscribe(offer => {
    offer.sdp = updateSDP(offer.sdp, minVP9, startVP9, maxVP9, minVP8, startVP8, maxVP8);
    from(roomGlobalRef.pcMediaSender!.setLocalDescription(offer)).subscribe(() => {
      streamsSenderSFU(pako.deflate(JSON.stringify(offer)), [], [], true);
    });
  });
};
//@ts-ignore
window.renegotiation = renegotiation;

const skipUnsupportedCodecs = (codecsList: RTCRtpCodecCapability[]) => {
  const list = codecsList
    .filter(codec => codec.mimeType !== 'video/H264' && codec.mimeType !== 'video/ulpfec' && codec.mimeType !== 'video/red')
    //if AV1 select only with profile0
    .filter(codec => codec.mimeType !== 'video/AV1' ? true : codec.sdpFmtpLine?.includes("profile=0"));
  console.log(list);
  return list;
};
