import {Subject} from 'rxjs';
import {ImageCropperProps} from './ImageCropper/ImageCropper';
import {CreateRoomModalProps} from './Room/Create/CreateRoomModal';
import {EventTicketInfo, EventTicketVoucherInfo} from '../../services/gRPC/payments/models_pb';
import {emit} from '../../utils/utils';
import {UpdateProfileModalProps} from './User/profileProcess';
import {CreateEventDataModalProps} from './Event/Create/CreateEventDataModal';
import {UpdateEventDataModalProps} from './Event/Update/UpdateEventDataModal';
import {CreateEventDetailsModalProps} from './Event/Create/CreateEventDetailsModal';
import {UpdateEventDetailsModalProps} from './Event/Update/UpdateEventDetailsModal';
import {RoomStreamType} from '../../services/gRPC/rooms/enums_pb';
import {UpdateRoomModalProps} from './Room/Update/UpdateRoomModal';
import {EventInfo, RoomInfo} from '../../services/gRPC/rooms/models_pb';

//////
////// Specify modal name here
//////

export enum ModalType {
	/// Auth
	AUTH_SELECT,
	LOGIN,
	FIREBASE_SIGN_UP,
	PROFILE_SET_UP,
	RESET_PASSWORD,
	/// Event
	CREATE_EVENT_DATA,
	CREATE_EVENT_DETAILS,
	UPDATE_EVENT_DATA,
	UPDATE_EVENT_DETAILS,
	EVENT_LINK,
	/// User
	PROFILE,
	ADD_USER,
	BLOCK_USER,
	/// Room
	LEAVE_ROOM,
	CREATE_ROOM_WITH_STREAM,
	ROOM_CREATE,
	ROOM_SELECT_STREAM,
	ROOM_SELECT_ROOM,
	ROOM_UPDATE,
	ROOM_SELECT_DEVICES,
	ROOM_PASSWORD,
	/// Tickets
	PAYMENT_MODAL,
	TICKET_MODAL,
	APPLY_VOUCHER_MODAL,
	CREATE_VOUCHER_MODAL,
	UPDATE_VOUCHER_MODAL,
	/// Generic
	INFO,
	SPINNING,
	IMAGE_CROPPER,
	PLAYLIST,
}

//////
////// Specify modals observables & modals inputs  - used to properly open modal and to get proper results from the observable
////// fn arguments - incoming data
////// Subject type - outgoing data
//////

export type ModalSubjectOverload = {
	/// Auth
	(modal: ModalType.AUTH_SELECT, data?: { preventCoverClick?: boolean }):
		Subject<{ next: 'FIREBASE_SIGN_UP' | 'LOGIN' }>;
	(modal: ModalType.LOGIN, data?: { email?: string, preventCoverClick?: boolean }):
		Subject<{ next: 'LOGGED_EXIT' | 'FIREBASE_SIGN_UP' | 'RESET_PASSWORD', email?: string, preventCoverClick?: boolean }>;
	(modal: ModalType.FIREBASE_SIGN_UP, data?: { email?: string, preventCoverClick?: boolean }):
		Subject<{ next: 'LOGIN' | 'PROFILE_SET_UP' | 'FINISHED'}>;
	(modal: ModalType.PROFILE_SET_UP):
		Subject<undefined>;
	(modal: ModalType.RESET_PASSWORD, data?: { email?: string, preventCoverClick?: boolean }):
		Subject<undefined>;

	/// Event
	(modal: ModalType.CREATE_EVENT_DATA, data?: CreateEventDataModalProps):
		Subject<{ next: 'GO_EVENT_DETAILS' }>;
	(modal: ModalType.CREATE_EVENT_DETAILS, data?: CreateEventDetailsModalProps):
		Subject<{ next: 'GO_EVENT_DATA' } | {next: 'EVENT_CREATED', info: EventInfo.AsObject} | { next: 'CROP_THUMBNAIL' | 'CROP_BACKGROUND', image: string }>;
	(modal: ModalType.UPDATE_EVENT_DATA, data?: UpdateEventDataModalProps):
		Subject<{ next: 'GO_EVENT_DETAILS' }>;
	(modal: ModalType.UPDATE_EVENT_DETAILS, data?: UpdateEventDetailsModalProps):
		Subject<{ next: 'GO_EVENT_DATA' } | {next: 'EVENT_UPDATED', info: EventInfo.AsObject} | { next: 'CROP_THUMBNAIL' | 'CROP_BACKGROUND', image: string }>;
	(modal: ModalType.EVENT_LINK):
		Subject<undefined>;

	/// User
	(modal: ModalType.PROFILE, data: UpdateProfileModalProps):
		Subject<{ next: 'CROP_AVATAR', updateProfileData: UpdateProfileModalProps, newImage: string } | { next: 'DONE' }>;
	(modal: ModalType.ADD_USER):
		Subject<undefined>;
	(modal: ModalType.BLOCK_USER):
		Subject<undefined>;

	/// Room
	(modal: ModalType.ROOM_CREATE, data?: CreateRoomModalProps):
		Subject<{ next: 'ROOM_CREATED', info: RoomInfo.AsObject } | { next: 'SELECT_STREAM', data?: CreateRoomModalProps }>;
	(modal: ModalType.ROOM_SELECT_STREAM, data?: { eventId?: string }):
		Subject<{ eventId?: string } | undefined>;
	(modal: ModalType.ROOM_UPDATE, data: UpdateRoomModalProps):
		Subject<{ next: 'ROOM_UPDATED', info: RoomInfo.AsObject } | { next: 'CROP_THUMBNAIL' | 'CROP_BACKGROUND', image: string }>;
	(modal: ModalType.LEAVE_ROOM, data: { text: string, }):
		Subject<undefined>;
	(modal: ModalType.CREATE_ROOM_WITH_STREAM, data: { type: RoomStreamType }):
		Subject<undefined>;
	(modal: ModalType.ROOM_CREATE, data?: { formState?: Partial<CreateRoomModalProps> }):
		Subject<{ next: 'DONE' | 'SELECT_STREAM', formState?: CreateRoomModalProps }>;
	(modal: ModalType.ROOM_SELECT_STREAM, data?: { eventId?: string }):
		Subject<{ eventId?: string } | undefined>;
	(modal: ModalType.ROOM_SELECT_ROOM, data?: { eventId?: string }):
		Subject<{ eventId?: string } | undefined>;
	(modal: ModalType.ROOM_SELECT_DEVICES, data?: { isInsideRoom?: boolean }):
		Subject<undefined>;
	(modal: ModalType.ROOM_PASSWORD):
		Subject<{ password: string }>;
	(modal: ModalType.PLAYLIST, data: { type: RoomStreamType }):
		Subject<undefined>;

	/// Tickets
	(modal: ModalType.PAYMENT_MODAL, data: { ticket: EventTicketInfo.AsObject, voucherId?: string }):
		Subject<{ next: 'DONE' | 'BACK' }>;
	(modal: ModalType.TICKET_MODAL, data: { eventId: string }):
		Subject<{ next: 'BACK' | 'DISCOUNT_CODE' | 'DONE' } | { next: 'CARD_PAYMENT', ticket: EventTicketInfo.AsObject }>;
	(modal: ModalType.APPLY_VOUCHER_MODAL, data: { eventId: string }):
		Subject<{ next: 'BACK' | 'DONE' } | { next: 'CARD_PAYMENT', voucher?: EventTicketVoucherInfo.AsObject, ticket: EventTicketInfo.AsObject }>;
	(modal: ModalType.CREATE_VOUCHER_MODAL, data: { eventId: string }):
		Subject<undefined>;
	(modal: ModalType.UPDATE_VOUCHER_MODAL, data: { voucherId: string }):
		Subject<undefined>;

	/// Generic
	(modal: ModalType.INFO, data: {
		text: string,
		title?: string,
		preventCoverClick?: boolean,
		cancelButton?: boolean,
		hideButton?: boolean
		btnText?: string,
		btnClass?: string,
		cancelButtonAction?: () => void
	}): Subject<typeof emit>;
	(modal: ModalType.SPINNING, data: {
		title: string,
		topText: string,
		bottomText?: string,
		preventCoverClick?: boolean,
	}): Subject<typeof emit>;
	(modal: ModalType.IMAGE_CROPPER, data: ImageCropperProps):
		Subject<{ image: string } | undefined>;
}

//////
////// Specify close function - used to properly close modal with results
////// data - outgoing data (should be the same as above)
//////

export type PassDataAndCloseModalOverload = {

/// Auth
	modal: ModalType.AUTH_SELECT,
	data: { next: 'FIREBASE_SIGN_UP' | 'LOGIN' }
} | {
	modal: ModalType.LOGIN,
	data: { next: 'LOGGED_EXIT' | 'FIREBASE_SIGN_UP' | 'RESET_PASSWORD', email?: string }
} | {
	modal: ModalType.FIREBASE_SIGN_UP,
	data: { next: 'LOGIN' | 'PROFILE_SET_UP' | 'FINISHED' }
} | {
	modal: ModalType.PROFILE_SET_UP
} | {
	modal: ModalType.RESET_PASSWORD

/// Event
} | {
	modal: ModalType.CREATE_EVENT_DATA,
	data: { next: 'EVENT_DETAILS' }
} | {
	modal: ModalType.CREATE_EVENT_DETAILS,
	data: { next: 'GO_EVENT_DATA'} | { next: 'EVENT_CREATED', info: EventInfo.AsObject } | { next: 'CROP_THUMBNAIL' | 'CROP_BACKGROUND', image: string },
} | {
	modal: ModalType.UPDATE_EVENT_DATA,
	data: { next: 'EVENT_DETAILS' }
} | {
	modal: ModalType.UPDATE_EVENT_DETAILS,
	data: { next: 'GO_EVENT_DATA' } | { next: 'EVENT_UPDATED', info: EventInfo.AsObject } | { next: 'CROP_THUMBNAIL' | 'CROP_BACKGROUND', image: string },
} | {
	modal: ModalType.EVENT_LINK,
/// Room
} | {
	modal: ModalType.ROOM_CREATE,
	data: { next: 'ROOM_CREATED', info: RoomInfo.AsObject } | { next: 'SELECT_STREAM', data?: CreateRoomModalProps }
} | {
	modal: ModalType.ROOM_SELECT_STREAM,
	data: { eventId?: string }
} | {
	modal: ModalType.ROOM_UPDATE,
	data: { next: 'ROOM_UPDATED', info: RoomInfo.AsObject } | { next: 'CROP_THUMBNAIL' | 'CROP_BACKGROUND', image: string },
} | {
	modal: ModalType.LEAVE_ROOM,
} | {
	modal: ModalType.CREATE_ROOM_WITH_STREAM,
	data: { type: RoomStreamType }
} | {
	modal: ModalType.ROOM_SELECT_ROOM,
	data: { eventId: string }
} | {
	modal: ModalType.ROOM_SELECT_DEVICES,
	data?: { isInsideRoom?: boolean }
} | {
	modal: ModalType.ROOM_PASSWORD,
	data?: { password: string }
} | {
	modal: ModalType.PLAYLIST,
	data: { type: RoomStreamType }

/// User
} | {
	modal: ModalType.PROFILE,
	data: { next: 'CROP_AVATAR', updateProfileData: UpdateProfileModalProps, newImage: string } | { next: 'DONE' }
} | {
	modal: ModalType.ADD_USER,
} | {
	modal: ModalType.BLOCK_USER
/// Tickets
} | {
	modal: ModalType.PAYMENT_MODAL,
	data: { next: 'DONE' | 'BACK' }
} | {
	modal: ModalType.TICKET_MODAL,
	data: { next: 'BACK' | 'DISCOUNT_CODE' | 'DONE' } | { next: 'CARD_PAYMENT', ticket: EventTicketInfo.AsObject }
} | {
	modal: ModalType.APPLY_VOUCHER_MODAL,
	data: { next: 'BACK' | 'DONE' } | { next: 'CARD_PAYMENT', voucher?: EventTicketVoucherInfo.AsObject, ticket: EventTicketInfo.AsObject }
} | {
	modal: ModalType.CREATE_VOUCHER_MODAL,
} | {
	modal: ModalType.UPDATE_VOUCHER_MODAL,

/// Generic
} | {
	modal: ModalType.INFO
} | {
	modal: ModalType.SPINNING
} | {
	modal: ModalType.IMAGE_CROPPER,
	data?: { image: string }
}
