import {Error as GrpcError} from '../services/gRPC/error/models_pb';
import {cancelModal} from '../singleComponents/modals/modalSystem';
import {appNavigate} from '../App';

export const ErrMaintenanceModeEnabled = 'you cannot perform this request in maintenance mode';
export const ErrIncorrectManifest = 'this client version is not compatible with current API';
export const LOAD_BALANCER_ERROR = 'Http response at 400 or 500 level, http status code: 0';
export const retryConfig = {count: 3, delay: 300};


export const interceptError = (err: GrpcError.AsObject) => {

	handleIfMaintenanceError(err);
	handleIfManifestError(err);

	return () => err;
};

const handleIfMaintenanceError = (err: GrpcError.AsObject) => {
	if (err.message.includes(ErrMaintenanceModeEnabled)) {

		const parts = err.message.split('|');
		let startsAt: number | undefined;
		let duration: number | undefined;

		parts.forEach(part => {
			const params = part.replaceAll(' ', '').split('=');
			if (params.length === 2 && params[0] === 'startsAt') {
				startsAt = parseInt(params[1]);
			} else if (params.length === 2 && params[0] === 'duration') {
				duration = parseInt(params[1]);
			}
		});

		if (duration) {
			appNavigate('/maintenance', {
				state: {
					active: true,
					startsAt: startsAt,
					duration: duration
				}
			});
		} else {
			appNavigate('/maintenance', {
				state: {
					active: true
				}
			});
		}
	}
};

const handleIfManifestError = (err: GrpcError.AsObject) => {
	if (err.message.includes(ErrIncorrectManifest)) {
		cancelModal();
		appNavigate('/manifest', {
			state: {
				active: true
			}
		});
	}
};
