import React, {useEffect, useRef, useState} from 'react';
import {PlaylistElementS} from './PlaylistElementS';
import {RoomStreamType} from '../../../../services/gRPC/rooms/enums_pb';
import {ScrollableVerticalText} from '../../../../sharedComponents/ScrollableText/ScrollableText';
import {Tooltip} from '../../../../sharedComponents/Tooltip/Tooltip';
import {formatTime} from '../../../../utils/formatTime';
import {ThemeType} from '../../../../styles/theme';
import defaultThumbnail from '../../../../assets/defaultThumbnail.svg';
import defaultThumbnailLight from '../../../../assets/defaultThumbnailLight.svg';
import {useAppSelector} from '../../../../store/hooks';
import {selectTheme} from '../../../../store/slices/user';
import {envVars} from '../../../../environments/env';
import {Subscription, timer} from 'rxjs';

export interface PlaylistElementProps {
	thumbnail: string,
	title?: string,
	duration?: number,
	id: string,
	index?: number,
	livestream?: boolean,
	type: RoomStreamType,
	sid?: string,
	ownerId?: string,
	leftSlot?: React.ReactNode,
	rightSlot?: (open: boolean, setOpen: (open: boolean) => void) => React.ReactNode,
	disabled?: string,
}

const YOUTUBE_URL = 'https://www.youtube.com/watch?v=';
const TIKTOK_URL = 'https://www.tiktok.com/@unknown/video/';
const ZUCASA_URL = `${envVars.pageUrl}/e/`;

export const getCopyUrl = (type: RoomStreamType) => {
	switch (type) {
		case RoomStreamType.RSTYPE_YOUTUBE:
			return YOUTUBE_URL;
		case RoomStreamType.RSTYPE_TIKTOK:
			return TIKTOK_URL;
		case RoomStreamType.RSTYPE_EVENT:
			return ZUCASA_URL;
		default:
			return '';
	}
};

export const getIcon = (type: RoomStreamType, livestream?: boolean) => {
	if (type === RoomStreamType.RSTYPE_YOUTUBE) {
		return livestream ? 'icon-youtubeLive' : 'icon-youtube';
	} else if (type === RoomStreamType.RSTYPE_TIKTOK) {
		return 'icon-tiktok';
	}
	return 'icon-zucasa';
};


export const PlaylistElement = ({
	thumbnail,
	title,
	duration,
	id,
	type,
	livestream,
	rightSlot,
	leftSlot,
	disabled = ''
}: PlaylistElementProps) => {
	const videoTitleSpan = useRef<HTMLSpanElement>(null);
	const [scrollDuration, setScrollDuration] = useState(0);
	const [open, setOpen] = useState(false);
	const theme = useAppSelector(selectTheme);
	const renderTimeout = useRef<Subscription>();

	useEffect(() => {
		// waiting for element to render
		renderTimeout.current = timer(100).subscribe(() => {
			if (videoTitleSpan.current && title) {
				setScrollDuration((videoTitleSpan.current.offsetHeight - 40) / 10);
			}
		});

		return () => renderTimeout.current?.unsubscribe();
	}, [title]);

	return (
		<PlaylistElementS thumbnailUrl={thumbnail || (theme === ThemeType.DARK ? defaultThumbnail : defaultThumbnailLight)}
		                  disabled={!!disabled}>
			{!!leftSlot ? leftSlot : <div style={{width: '10px'}}/>}

			{!!disabled ? <Tooltip nested text={disabled} disabled={!disabled}>
				<div className="PlaylistS_element-thumbnail">
					<div className="PlaylistS_element-type">
						<i className={getIcon(type, livestream)} style={{fontSize: '16px'}}/>
					</div>
				</div>
			</Tooltip> : <div className="PlaylistS_element-thumbnail">
				<div className="PlaylistS_element-type">
					<i className={getIcon(type, livestream)} style={{fontSize: '16px'}}/>
				</div>
			</div>}

			<div className="PlaylistS_element-description">
				<ScrollableVerticalText lines={2} duration={scrollDuration} height={40} id={id}>
					<span ref={videoTitleSpan}>{title || 'No title'}</span>
				</ScrollableVerticalText>

				<div className="PlaylistS_element-duration">
					{duration ? formatTime(duration) :
						<div className="d-flex align-items-center">Duration not specifed</div>
					}
				</div>
			</div>

			<div className={`${open ? 'PlaylistS_element-rightSlotVisible' : ''} PlaylistS_element-rightSlot`}>
				{(!!rightSlot && !disabled) ? rightSlot(open, setOpen) : null}
			</div>
		</PlaylistElementS>);
};
