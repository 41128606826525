import {ServerPacket} from '../gRPC/sfu/packets_pb';
import {Overwrite} from '../../utils/utils';

///
/// Message Types
///
/// * not related with backend stuff
/// * names based on beginning of Ws guards names
/// * arrangement based on Ws guards
///

export enum SfuMessageType {
	LAYOUT,
	LOCAL_INPUT_READY,
	NO_DATA_PACKET,
	STREAMS_ANSWER_PACKET,
	SUBSCRIPTION_RTT,
	VIDEO_CODEC_CHANGE_REQUIRED,
	MIGRATION_REQUIRED,
	DISCONNECTED,
	ACTIVE_STREAMS
}

///
///
/// Guarder
///
///

export type PacketSfuGuarderOverload = {
	(param: SfuMessageType.LAYOUT, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
	(param: SfuMessageType.LOCAL_INPUT_READY, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
	(param: SfuMessageType.NO_DATA_PACKET, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
	(param: SfuMessageType.STREAMS_ANSWER_PACKET, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
	(param: SfuMessageType.SUBSCRIPTION_RTT, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
	(param: SfuMessageType.VIDEO_CODEC_CHANGE_REQUIRED, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
	(param: SfuMessageType.MIGRATION_REQUIRED, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
	(param: SfuMessageType.DISCONNECTED, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
	(param: SfuMessageType.ACTIVE_STREAMS, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
	(param: SfuMessageType, packet: ServerPacket.AsObject): packet is Overwrite<ServerPacket.AsObject, { data: Uint8Array }>;
}

export const packetSfuGuarder = ((sfuMessageType: SfuMessageType, packet: ServerPacket.AsObject) => {

	/// Layout Packet
	if (sfuMessageType === SfuMessageType.LAYOUT) {
		if (packet.type === 'mcu.api.v1.LayoutPacket') {
			return true;
		}
	}

	/// Local Input Ready Packet
	else if (sfuMessageType === SfuMessageType.LOCAL_INPUT_READY) {
		if (packet.type === 'sfu.api.v1.LocalInputReadyPacket') {
			return true;
		}
	}

	/// No Data Packet
	else if (sfuMessageType === SfuMessageType.NO_DATA_PACKET) {
		if (packet.type === 'sfu.api.v1.NoDataPacket') {
			return true;
		}
	}

	/// Streams Answer Packet
	else if (sfuMessageType === SfuMessageType.STREAMS_ANSWER_PACKET) {
		if (packet.type === 'sfu.api.v1.StreamsAnswerPacket') {
			return true;
		}
	}

	/// Subscription RTT Packet
	else if (sfuMessageType === SfuMessageType.SUBSCRIPTION_RTT) {
		if (packet.type === 'sfu.api.v1.SubscriptionRTTPacket') {
			return true;
		}
	}

	/// Video Codec Change Required Packet
	else if (sfuMessageType === SfuMessageType.VIDEO_CODEC_CHANGE_REQUIRED) {
		if (packet.type === 'sfu.api.v1.VideoCodecChangeRequiredPacket') {
			return true;
		}
	}

	/// Migration Required Packet
	else if (sfuMessageType === SfuMessageType.MIGRATION_REQUIRED) {
		if (packet.type === 'sfu.api.v1.MigrationRequiredPacket') {
			return true;
		}
	}

	/// Disconnected Packet
	else if (sfuMessageType === SfuMessageType.DISCONNECTED) {
		if (packet.type === 'sfu.api.v1.DisconnectedPacket') {
			return true;
		}
	}

	/// Disconnected Packet
	else if (sfuMessageType === SfuMessageType.ACTIVE_STREAMS) {
		if (packet.type === 'sfu.api.v1.ActiveStreamsResponsePacket') {
			return true;
		}
	}

	return false;

}) as PacketSfuGuarderOverload;

///
///
/// Packet Deserializer
///
///
export const channelMessageDeserializer = (msgEvent: MessageEvent) => {
	return ServerPacket.deserializeBinary(msgEvent.data).toObject();
};
