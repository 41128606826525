import {from, Observable, of} from 'rxjs';
import * as SparkMD5 from 'spark-md5';
import {ThemeType} from '../styles/theme';
import {EventType} from '../services/gRPC/rooms/enums_pb';

///
/// EMIT - RxJS addon next to NEVER & EMPTY
/// it emits and complete immediately
///
export const emit = Symbol('emit');
export const EMIT = of(emit);

///
/// Difference TypeScript
///
export type Diff<T extends keyof any, U extends keyof any> =
	({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T];

///
/// Overwrite TypeScript
///
export type Overwrite<T, U> = Pick<T, Diff<keyof T, keyof U>> & U;


///
/// Make some fields in object required
///
export type WithRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>

///
/// Add StyledComponent Children to StyledComponent Parent
///

export function componentChildren<T1, T2 extends Object>(parentComponent: T1, childComponent: T2) {
	for (const [key, value] of Object.entries(childComponent)) {
		(parentComponent as unknown as { [K in keyof string]: T2 })[key as any] = value;
	}
	return (parentComponent as unknown) as { [K in keyof T2]: T2[K] } & T1;
}

///
/// MD5 Converter
///

export const computeChecksumMd5 = (file: File) => {
	const promise1: Promise<string> = new Promise((resolve, reject) => {
		const chunkSize = 2097152; // Read in chunks of 2MB
		const spark = new SparkMD5.ArrayBuffer();
		const fileReader = new FileReader();

		let cursor = 0; // current cursor in file

		fileReader.onerror = function (): void {
			reject('MD5 computation failed - error reading the file');
		};

		// read chunk starting at `cursor` into memory
		function processChunk(chunk_start: number): void {
			const chunk_end = Math.min(file.size, chunk_start + chunkSize);
			fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
		}

		// when it's available in memory, process it
		// If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
		// of `any` for `e` variable, otherwise stick with `any`
		// See https://github.com/Microsoft/TypeScript/issues/25510
		fileReader.onload = function (e: any): void {
			spark.append(e.target.result); // Accumulate chunk to md5 computation
			cursor += chunkSize; // Move past this chunk

			if (cursor < file.size) {
				// Enqueue next chunk to be accumulated
				processChunk(cursor);
			} else {
				// Computation ended, last chunk has been processed. Return as Promise value.
				// This returns the base64 encoded md5 hash, which is what
				// Rails ActiveStorage or cloud services expect
				resolve(btoa(spark.end(true)));

				// If you prefer the hexdigest form (looking like
				// '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
				// resolve(spark.end());
			}
		};

		processChunk(0);
	});
	return from(promise1);
};


///
/// Blob To Array Converter
///
export const blobToArray$ = ((blob: Blob) => new Observable<any>(obs => {
	if (!(blob instanceof Blob)) {
		obs.error('blobToArray error - wrong type of argument');
	}
	const reader = new FileReader();
	reader.onloadend = () => {
		obs.next(reader.result);
		obs.complete();
	};
	reader.onerror = function (error) {
		console.error(error);
		obs.error('readAsArrayBuffer error -');
	};
	reader.readAsArrayBuffer(blob);
})) as (blob: Blob) => Observable<Uint8Array>;

///
/// Replace URL
///
export const replaceUrl = (string: string) => {
	window.history.replaceState(null, '', '/' + string);
};

/**
 * Shortens user nickname
 * @param string user nickname
 */

export const getUserName = (userName: string) => {
	return userName.length > 20 ? `${userName.substring(0, 17)}...` : userName;
};

export const countAverage = (numbers: Uint8Array) => {
	return numbers.reduce((a: number, b: number) => (a + b)) / numbers.length;
};
export const getLocalTheme = () => {
	const localTheme = localStorage.getItem('theme');
	return localTheme === ThemeType.DARK ? ThemeType.DARK : ThemeType.LIGHT;
};

export const getOrdinalNum = (n: number) => n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');

export const getHourDiffBetweenCurrAndFutureDate = (futureDate: number) => (futureDate - Date.now()) / 36e5;

// example date - 17th Feb 2022
export const convertTimestampToDate = (timestamp: number) => {
	let options: Intl.DateTimeFormatOptions = {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric'
	};
	const date = Intl.DateTimeFormat('en-US', options).format(timestamp * 1000)
		.split(',').join('').split(' ')
		.map((e, index) => index === 1 ? getOrdinalNum(Number(e)) : e);
	return {date: `${date[1]} ${date[0]} ${date[2]}`, time: `${date[3]} ${date[4]}`};
};

export const isImageBright = (imageSrc: string, cb: (t: boolean) => void) => {
	const img = document.createElement('img');
	img.src = imageSrc;
	img.style.display = 'none';
	img.crossOrigin = 'Anonymous';
	document.body.appendChild(img);

	let colorSum = 0;

	img.onload = function () {
		// create canvas
		const canvas = document.createElement('canvas') as HTMLCanvasElement;
		//@ts-ignore
		canvas.width = this.width;
		//@ts-ignore
		canvas.height = this.height;

		const ctx = canvas.getContext('2d');
		if (ctx) {

			//@ts-ignore
			ctx.drawImage(this, 0, 0);

			const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			const data = imageData.data;
			let r, g, b, avg;

			for (let x = 0, len = data.length; x < len; x += 4) {
				r = data[x];
				g = data[x + 1];
				b = data[x + 2];

				avg = Math.floor((r + g + b) / 3);
				colorSum += avg;
			}

			//@ts-ignore
			const brightness = Math.floor(colorSum / (this.width * this.height));
			cb(brightness > 155);
		}

	};
};

/**
 * Changes ms to MM m  ;  HH h MM m DD d  ;  HH h MM m format
 * e.g output:
 * - for minutes only: 49m
 * - for hours and minutes: 13h 22m
 * - for days hours and minutes: 1d 2h 10m
 */
export const parseTime = (milliseconds: number) => {
	const HOUR_IN_MS = 3_600_000;
	const minutes = Math.floor(milliseconds / 60 / 1000);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	if (milliseconds < HOUR_IN_MS) { // time less than 1hour
		return `${minutes} min`;
	} else if (milliseconds < 24 * HOUR_IN_MS) { // time less than 1 day
		return `${hours % 60}h ${(minutes % 60) !== 0 ? `${(minutes % 60)}m` : ''}`;
	} else { // streamed time is longer than day
		return `${days}d ${hours % 24}h ${minutes % 60}m`;
	}
};

export const eventTypeToString = (type: EventType) => {
	if (type === EventType.ETYPE_PAID) return 'Paid';
	if (type === EventType.ETYPE_FREE) return 'Free';
	else return 'Unknown';
};

export const stringToByteArray = (str: string) => {
	const enc = new TextEncoder(); // always utf-8
	return enc.encode(str);
};

export const byteArrayToString = (byteArray: Uint8Array) => {
	const dec = new TextDecoder();
	return dec.decode(byteArray);
};

export const convertPingToDashes = (ping: number) => {
	if (ping < 150) return 3;
	if (ping < 300) return 2;
	return 1;
};

export const isArrayEquivalent = (arr1: string[], arr2: string[]) => {
	if(arr1.length !== arr2.length) return false;

	return arr1.every(a => arr2.includes(a));
}
