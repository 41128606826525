import {EventSource} from '../../../../services/gRPC/rooms/enums_pb';

///
/// EventSources List
///

export const eventSources: EventSource[] = [
	EventSource.ESOURCE_MCU,
	EventSource.ESOURCE_STATICSTREAM,
	EventSource.ESOURCE_LIVESTREAM,
];

///
/// EventSource to String
///

export const sourceToString = (source: EventSource) => {
	switch (source) {
		case EventSource.ESOURCE_MCU: {
			return 'Live Performance from Camera';
		}
		case EventSource.ESOURCE_STATICSTREAM: {
			return 'Recorded Performance';
		}
		case EventSource.ESOURCE_LIVESTREAM: {
			return 'Live feed from Venue or TV';
		}
		case EventSource.ESOURCE_UNKNOWN: {
			return 'Unknown';
		}
		default: {
			return 'Unknown';
		}
	}
};

///
/// EventSources String List Type
///

export type EventSourceName = ReturnType<typeof sourceToString>

///
/// Source string to eventSource
///

export const sourceStringToEnum = (source: EventSourceName) => {
	switch (source) {
		case 'Live Performance from Camera': {
			return EventSource.ESOURCE_MCU;
		}
		case 'Recorded Performance': {
			return EventSource.ESOURCE_STATICSTREAM;
		}
		case 'Live feed from Venue or TV': {
			return EventSource.ESOURCE_LIVESTREAM;
		}
		case 'Unknown': {
			return EventSource.ESOURCE_UNKNOWN;
		}
		default: {
			return EventSource.ESOURCE_UNKNOWN;
		}
	}
};


