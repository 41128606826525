import {useEffect, useRef} from 'react';

export const useIsFirstRender = () => {

	const firstUpdate = useRef(true);

	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
		}
	});

	return firstUpdate.current;
};
