import {EventSource} from '../../services/gRPC/rooms/enums_pb';
import {createEventTokenService$} from '../../services/roomServices';
import {store} from '../../store/store';
import {roomStreamsActions, selectCurrentStream, selectEventId, selectEventSource} from '../../store/slices/roomStreams';

export const tokenizeMpdStreamEvent = () => {
	const currentStream = selectCurrentStream(store.getState());
	const eventSource = selectEventSource(store.getState());

	if (eventSource || (currentStream && 'info' in currentStream && [EventSource.ESOURCE_LIVESTREAM, EventSource.ESOURCE_STATICSTREAM].includes(currentStream.info.source))) {
		const eventId = selectEventId(store.getState());
		createEventTokenService$(eventId).subscribe((token) => {
			const {url, offset, timestamp} = JSON.parse(window.atob(token));
			store.dispatch(roomStreamsActions.setDashItems({streamLink: url, startedAt: timestamp, offset}));
		});
	}
};
