import React, {useEffect} from 'react';
import {MainViewS} from './MainViewS';
import {GlobalStyle} from '../../styles/globalStyle';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import preval from 'preval.macro';
import {isBrowserSupported} from '../../utils/browserTypeDetection';
import {BrowserNotSupported} from '../../pages/BrowserNotSupported/BrowserNotSupported';
import {AppRouter} from './AppRouter';
import loadable from '@loadable/component';

const ModalsProviderLazy = loadable(async () => {
	const { ModalsProvider } = await import('../../utils/ModalsProvider');
	return ModalsProvider;
})

const GuidesProviderLazy = loadable(async () => {
	const { GuidesProvider } = await import('../guides/Guides');
	return GuidesProvider;
})


export const MainView = () => {

	useEffect(() => {
		const dateTimeStamp: string = preval`module.exports = new Date().toLocaleString();`;
		console.log(`%c ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION} ${dateTimeStamp} `,
			'color: #0A316B; font-weight: 900; background: #FDFCB0; padding: 2px; border: 1px solid #0076F5');
	}, []);

	return (
		<>
			<GlobalStyle/>
			<MainViewS>
				{
					(!isBrowserSupported()) ?
						<BrowserNotSupported/>
						:
						<>
							<ModalsProviderLazy/>
							<GuidesProviderLazy/>
							<AppRouter/>
						</>
				}
			</MainViewS>
		</>
	);
};
