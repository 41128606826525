import {roomGlobalRef} from '../roomGlobalRef';
import {store} from '../../../../store/store';
import {roomUsersActions} from '../../../../store/slices/roomUsers';
import {reconnectPcReceiver} from './reconnectPcReceiver';
import {roomActions} from '../../../../store/slices/room';
import {selectIsConnectedWithWs} from '../../../../store/slices/user';

export const initPcReceiverListeners = () => {
	///
	/// on new audio or new video appear ON-TRACK [pcMediaReceiver]
	///
	roomGlobalRef.pcMediaReceiver!.ontrack = (event) => {

		if (event.streams.length < 1) {
			console.error('received on track event with empty stream list, skipping');
			return;
		}
		const stream = event.streams[0];
		const userId = stream.id.replaceAll('screen-audio-', '').replaceAll('screen-', '');
		const kind = event.track.kind;

		const isMcu = stream.id === 'mcu';
		const isCamera = kind === 'video' && !stream.id.startsWith('screen');
		const isAudio = kind === 'audio' && !stream.id.startsWith('screen-audio');
		const isScreen = kind === 'video' && stream.id.startsWith('screen');
		const isScreenAudio = kind === 'audio' && stream.id.startsWith('screen-audio');

		if (isMcu) {
			roomGlobalRef.mcuVideoMediaStream = event.streams[0];
			store.dispatch(roomActions.setMcuVideoStreamTrackId(event.streams[0].getVideoTracks()[0].id));
		} else if (isCamera) {
			roomGlobalRef.cameraTransceiversMap.set(userId, event.transceiver);
			roomGlobalRef.unusedVideoTransceivers = roomGlobalRef.unusedVideoTransceivers.filter(t => t.mid !== event.transceiver.mid);

			roomGlobalRef.usersCameraMediaStreams.set(userId, stream);
			store.dispatch(roomUsersActions.setUserMediaTrackId({userId, trackId: stream.getVideoTracks()[0].id, type: 'camera'}));
		} else if (isAudio) {
			roomGlobalRef.audioTransceiversMap.set(userId, event.transceiver);
			roomGlobalRef.unusedAudioTransceivers = roomGlobalRef.unusedAudioTransceivers.filter(t => t.mid !== event.transceiver.mid);
			roomGlobalRef.usersAudioMediaStreams.set(userId, stream);
			store.dispatch(roomUsersActions.setUserMediaTrackId({userId, trackId: stream.getAudioTracks()[0].id, type: 'audio'}));
		} else if (isScreen) {
			roomGlobalRef.cameraTransceiversMap.set(stream.id, event.transceiver);
			roomGlobalRef.unusedVideoTransceivers = roomGlobalRef.unusedVideoTransceivers.filter(t => t.mid !== event.transceiver.mid);
			roomGlobalRef.usersScreenVideoMediaStreams.set(userId, stream);
			store.dispatch(roomUsersActions.setUserMediaTrackId({userId, trackId: stream.getVideoTracks()[0].id, type: 'screenVideo'}));
		} else if (isScreenAudio) {
			roomGlobalRef.audioTransceiversMap.set(stream.id, event.transceiver);
			roomGlobalRef.unusedAudioTransceivers = roomGlobalRef.unusedAudioTransceivers.filter(t => t.mid !== event.transceiver.mid);
			roomGlobalRef.usersScreenAudioMediaStreams.set(userId, stream);
			store.dispatch(roomUsersActions.setUserMediaTrackId({userId, trackId: stream.getAudioTracks()[0].id, type: 'screenAudio'}));
		} else {
			console.error(new Error('it should not happen but received unknown track type'));
		}
	};

	roomGlobalRef.pcMediaReceiver!.onconnectionstatechange = () => {
		console.log(`%cReceiver: ${roomGlobalRef.pcMediaReceiver?.connectionState}`, 'color: black; font-weight: 900; background: #8f95ff');

		switch (roomGlobalRef.pcMediaReceiver?.connectionState) {
			case 'failed': {
				if (selectIsConnectedWithWs(store.getState())) {
					reconnectPcReceiver(true);
				}
				return;
			}
		}
	};

	roomGlobalRef.pcMediaReceiver!.oniceconnectionstatechange = (ev) => {
		console.log(`%cReceiver ice connection: ${roomGlobalRef.pcMediaReceiver?.iceConnectionState}`, 'color: black; font-weight: 900; background: pink');
	};

	roomGlobalRef.pcMediaReceiver!.onicegatheringstatechange = (ev) => {
		console.log(`%cReceiver ice gathering: ${roomGlobalRef.pcMediaReceiver?.iceGatheringState}`, 'color: black; font-weight: 900; background: white');
	};
};
