import styled, {css} from 'styled-components';
import {remm} from '../../utils/remm';
import React from 'react';

export interface Props extends React.ComponentPropsWithoutRef<'input'> {
	error?: string;
	extraPadding?: number;
}

export const InputS = styled('input')<Props>`
	display: block;
	width: 100%;
	padding: ${remm('12px')};
	${({extraPadding}) => extraPadding && css`padding-left: ${extraPadding - 11}px;`}
	border: none;
	background: ${({theme}) => theme.inputBackground};
	color: ${({theme}) => theme.inputColor};
	font-weight: 600;
	font-size: ${remm('15px')};
	letter-spacing: normal;
	border-radius: 38px;
	border: 1px solid ${({theme}) => theme.inputBorderColor};
	${props => props.error && css`box-shadow: 0 0 0 2px ${props.theme.inputErrorShadow}`};
	background-clip: padding-box;

	:-webkit-autofill {
		border: 1px solid ${({theme}) => theme.inputBorderColor};
		-webkit-box-shadow: 0 0 0 1000px ${({theme}) => theme.color2} inset !important;
		-webkit-text-fill-color: ${({theme}) => theme.inputColor} !important;

		&:focus {

		}

		&:active {

		}
	}

	::placeholder {
		color: ${({theme}) => theme.inputPlaceholderColor};
	}

	:focus {
		outline: none;
		background: ${({theme}) => theme.inputBackgroundHover};
	}

	:hover {
		border: 1px solid ${({theme}) => theme.inputBorderFocusColor};
	}

	:disabled {
		color: ${props => props.theme.inputDisabledColor};
		background: ${({theme}) => theme.inputDisabledBackground};
		cursor: not-allowed;
	}
`;
