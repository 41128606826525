import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {UserInfo} from '../../services/gRPC/users/models_pb';
import {AvailableDevices} from '../../utils/getAvailableDevices';
import {localStore} from '../../localStorage/localStore';
import {UserRole} from '../../services/roomServices';
import {Resolution} from '../../pages/Room/utils/getResolution.types';
import {IdTokenResult} from 'firebase/auth';
import {ThemeType} from '../../styles/theme';
import {Layout, Ratio} from '../../pages/Room/relatedComponents/RoomGrid/RoomGrid';

/*
 ** Statuses Explanation
 *  Unavailable: there is no device connected to the computer (default)
 *  Init:        there is a device connected to the computer but not acquired yet
 *  Ready:       device is acquired by browser and ready to use
 *  Denied:      device is available in system but user denied permission to use it
 *  Dismissed:   device is available in system but user dismissed permission request to use it
 *
 * allowed state changes:
 * Unavailable -> Init                     (when devices list is ready and not empty)
 * Init        -> Ready, Denied, Dismissed (respectively when device is acquired or permission denied)
 * Ready       -> Init                     (when acquireMediaDevices() is called again)
 * Denied      -> Init                     (when acquireMediaDevices() is called again)
 * Dismissed   -> Init                     (when acquireMediaDevices() is called again)
 */
export enum Status {
	Unavailable,
	Init,
	Ready,
	Denied,
	Dismissed,
}

export interface DevicesStatus {
	camera?: Status,
	microphone?: Status,
}

export interface AudioOptions {
	echoCancellation: boolean,
	noiseSuppression: boolean,
	autoGainControl: boolean
}

export interface UserFirebaseTokenResult extends IdTokenResult {
	claims: {
		aud: string
		auth_time: string | undefined,
		email: string
		email_verified: string | object | undefined,
		exp: string | undefined,
		firebase: {
			identities: Record<string, string>;
			sign_in_provider: string
		}
		role?: string
		iat: string | undefined,
		iss: string
		sub: string
		user_id: string
		_user_id: string
		_user_role: string | number | undefined,
	};
}

export interface UserState {
	userFirebaseTokenResult?: UserFirebaseTokenResult
	userInfo?: UserInfo.AsObject
	isPartner?: boolean
	areBitratesShown: boolean,
	areStatsDisabled: boolean,
	areStatsShowed: boolean,
	isLayoutShowed: boolean,
	isMuted: boolean
	isCamOff: boolean
	isSharingScreenVideo: boolean
	isSharingScreenAudio: boolean
	selectedDevices: {
		audioinput: string,
		videoinput: string,
	},
	audioOptions: AudioOptions
	resolution: Resolution,
	availableDevices: AvailableDevices,
	devicesStatus: DevicesStatus,
	theme: ThemeType,
	volume: number,
	areAudioOptionsOpen: boolean
	areVideoOptionsOpen: boolean
	inputSensitivity: number
	determineAutomatically: boolean
	isSpeaking: boolean
	inputGain: number
	ratio: Ratio
	layout: Layout,
	isConnectedWithWs: boolean;
}

export const initialState: UserState = {
	areBitratesShown: localStore.getItem('areBitratesShown') || false,
	areStatsDisabled: localStore.getItem('areStatsDisabled') || false,
	areStatsShowed: false,
	isLayoutShowed: false,
	isMuted: localStore.getItem('isMuted') || false,
	isCamOff: localStore.getItem('isCamOff') || false,
	isSharingScreenVideo: false,
	isSharingScreenAudio: false,
	selectedDevices: {
		audioinput: localStore.getItem('audioInput') || '',
		videoinput: localStore.getItem('videoInput') || ''
	},
	audioOptions: localStore.getItem('audioOptions') || {
		echoCancellation: true,
		noiseSuppression: true,
		autoGainControl: true
	},
	resolution: localStore.getItem('resolution') || Resolution.HIGH,
	availableDevices: {
		audioinput: [],
		videoinput: []
	},
	devicesStatus: {camera: Status.Unavailable, microphone: Status.Unavailable},
	theme: localStore.getItem('theme') || ThemeType.LIGHT,
	volume: -140,
	areAudioOptionsOpen: false,
	areVideoOptionsOpen: false,
	inputSensitivity: localStore.getItem('inputSensitivity') || -125,
	isSpeaking: false,
	determineAutomatically: localStore.getItem('determineAutomatically') || true,
	inputGain: localStore.getItem('inputGain') || 100,
	ratio: '1:1',
	layout: 'circle',
	isConnectedWithWs: false,
};


export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserFirebaseTokenResult: (state, action: PayloadAction<UserFirebaseTokenResult>) => {
			state.userFirebaseTokenResult = action.payload;
		},
		setUserInfo: (state, action: PayloadAction<UserInfo.AsObject>) => {
			state.userInfo = action.payload;
		},
		setIsPartner: (state, action: PayloadAction<boolean>) => {
			state.isPartner = action.payload;
		},
		setBitratesShown: (state, action: PayloadAction<boolean>) => {
			const areBitratesShown = action.payload;
			localStore.setItem('areBitratesShown', areBitratesShown);
			state.areBitratesShown = areBitratesShown;
		},
		toggleStatsDisabled: (state) => {
			localStore.setItem('areStatsDisabled', !state.areStatsDisabled);
			state.areStatsDisabled = !state.areStatsDisabled;
		},
		toggleStatsShowed: (state) => {
			state.areStatsShowed = !state.areStatsShowed;
		},
		toggleLayoutShowed: (state) => {
			state.isLayoutShowed = !state.isLayoutShowed;
		},
		setIsMuted: (state, action: PayloadAction<boolean>) => {
			const isMuted = action.payload;
			localStore.setItem('isMuted', isMuted);
			state.isMuted = isMuted;
		},
		setIsCamOff: (state, action: PayloadAction<boolean>) => {
			const isCamOff = action.payload;
			localStore.setItem('isCamOff', isCamOff);
			state.isCamOff = isCamOff;
		},
		setIsSharingScreenVideo: (state, action: PayloadAction<boolean>) => {
			state.isSharingScreenVideo = action.payload;
		},
		setIsSharingScreenAudio: (state, action: PayloadAction<boolean>) => {
			state.isSharingScreenAudio = action.payload;
		},
		setAudioInput: (state, action: PayloadAction<string>) => {
			const audioInput = action.payload;
			localStore.setItem('audioInput', audioInput);
			state.selectedDevices.audioinput = audioInput;
		},
		setVideoInput: (state, action: PayloadAction<string>) => {
			const videoInput = action.payload;
			localStore.setItem('videoInput', videoInput);
			state.selectedDevices.videoinput = videoInput;
		},
		setResolution: (state, action: PayloadAction<Resolution>) => {
			const resolution = action.payload;
			localStore.setItem('resolution', resolution);
			state.resolution = resolution;
		},
		setAvailableDevices: (state, action: PayloadAction<AvailableDevices>) => {
			state.availableDevices = action.payload;
		},
		setSelectedDevices: (state, action: PayloadAction<{ audioinput: string, videoinput: string, audiooutput: string }>) => {
			state.selectedDevices = action.payload;
		},
		setTheme: (state, action: PayloadAction<ThemeType>) => {
			const theme = action.payload;
			localStore.setItem('theme', theme);
			state.theme = theme;
		},
		setAudioOptions: (state, action: PayloadAction<AudioOptions>) => {
			state.audioOptions = action.payload;
			localStore.setItem('audioOptions', action.payload);
		},
		setDevicesStatus: (state, action: PayloadAction<DevicesStatus>) => {
			state.devicesStatus = {...state.devicesStatus, ...action.payload};
		},
		setAudioOptionsOpen: (state, action: PayloadAction<boolean>) => {
			state.areAudioOptionsOpen = action.payload;
		},
		setVideoOptionsOpen: (state, action: PayloadAction<boolean>) => {
			state.areVideoOptionsOpen = action.payload;
		},
		setVolume: (state, action: PayloadAction<number>) => {
			state.volume = action.payload;
		},
		setIsClientSpeaking: (state, action: PayloadAction<boolean>) => {
			state.isSpeaking = action.payload;
		},
		setInputSensitivity: (state, action: PayloadAction<number>) => {
			state.inputSensitivity = action.payload;
			localStore.setItem('inputSensitivity', action.payload);
		},
		setDetermineAutomatically: (state, action: PayloadAction<boolean>) => {
			state.determineAutomatically = action.payload;
			localStore.setItem('determineAutomatically', action.payload);
		},
		setInputGain: (state, action: PayloadAction<number>) => {
			state.inputGain = action.payload;
			localStore.setItem('inputGain', action.payload);
		},
		setRatio: (state, action: PayloadAction<Ratio>) => {
			state.ratio = action.payload;
		},
		setLayout: (state, action: PayloadAction<Layout>) => {
			state.layout = action.payload;
		},
		setIsConnectedWithWs: (state, action: PayloadAction<boolean>) => {
			state.isConnectedWithWs = action.payload;
		}
	}
});

export const userActions = userSlice.actions;
export const selectClientUserInfo = (state: RootState) => state.user.userInfo as UserInfo.AsObject;
export const selectClientId = (state: RootState) => (state.user.userInfo?.id as string);
export const selectClientName = (state: RootState) => (state.user.userInfo?.name as string);
export const selectAreBitratesShown = (state: RootState) => state.user.areBitratesShown;
export const selectAreStatsDisabled = (state: RootState) => state.user.areStatsDisabled;
export const selectAreStatsShowed = (state: RootState) => state.user.areStatsShowed;
export const selectIsLayoutShowed = (state: RootState) => state.user.isLayoutShowed;
export const selectClientAvatar = (state: RootState) => (state.user.userInfo?.avatarUrl as string);
export const selectClientIsPartner = (state: RootState) => (state.user.isPartner);
export const selectClientResolution = (state: RootState) => (state.user.resolution);
export const selectClientSelectedDevices = (state: RootState) => (state.user.selectedDevices);
export const selectClientAvailableDevices = (state: RootState) => (state.user.availableDevices);
export const selectIsClientMuted = (state: RootState) => (state.user.isMuted);
export const selectHasClientCamOff = (state: RootState) => (state.user.isCamOff);
export const selectIsSharingScreenVideo = (state: RootState) => (state.user.isSharingScreenVideo);
export const selectIsSharingScreenAudio = (state: RootState) => (state.user.isSharingScreenAudio);
export const selectTheme = (state: RootState) => (state.user.theme);
export const selectIsAuthNotFinished = (state: RootState) => {
	const firebase = state.user.userFirebaseTokenResult?.claims.firebase;
	return (state.user.userInfo?.role === UserRole.GUEST && firebase?.identities.email?.length);
}
//#REDUX todo merge 2 selectors below to 1 ask nesti for advice
export const selectClientType = (state: RootState) => (state.user.userInfo?.type);
export const selectClientRole = (state: RootState) => (state.user.userInfo?.role as UserRole);
export const selectClientRoleFire = (state: RootState) => ((state.user.userFirebaseTokenResult?.claims._user_role || NaN) as UserRole);
export const selectAudioOptions = (state: RootState) => (state.user.audioOptions);
export const selectDevicesStatus = (state: RootState) => state.user.devicesStatus;
export const selectAreAudioOptionsOpen = (state: RootState) => state.user.areAudioOptionsOpen;
export const selectAreVideoOptionsOpen = (state: RootState) => state.user.areVideoOptionsOpen;
export const selectClientVolume = (state: RootState) => state.user.volume;
export const selectInputSensitivity = (state: RootState) => state.user.inputSensitivity;
export const selectInputGain = (state: RootState) => state.user.inputGain;
export const selectDetermineAutomatically = (state: RootState) => state.user.determineAutomatically;
export const selectIsClientSpeaking = (state: RootState) => state.user.isSpeaking;
export const selectRatio = (state: RootState) => state.user.ratio;
export const selectLayout = (state: RootState) => state.user.layout;
export const selectIsConnectedWithWs = (state: RootState) => state.user.isConnectedWithWs;
