import platform from 'platform';
import {ClientType} from '../services/gRPC/sfu/enums_pb';

export const LOWEST_CHROMIUM_VERSION = 90;
export const LOWEST_SAFARI_VERSION = 13;

export enum BrowserType {
	UNKNOWN = 'Unknown',
	CHROME = 'Chrome',
	EDGE = 'Edge',
	OPERA = 'Opera',
	FIREFOX = 'Firefox',
	SAFARI = 'Safari',
}

export const isChromiumVersionSupported = () => {
	return retrieveClientType() !== ClientType.CHROMIUM || getBrowserVersion() >= LOWEST_CHROMIUM_VERSION;
};

export const isChromiumMobile = () => {
	return retrieveClientType() !== ClientType.CHROMIUM && platform.name?.includes('Mobile');
};

export const isiOS = () => {
	return platform.os?.family?.includes('iOS');
}

export const isBrowserSupported = () => {
	const clientType = retrieveClientType();
	const browserVersion = getBrowserVersion();

	if (platform.os?.family?.includes('iOS')) {
		return (clientType === ClientType.SAFARI) && browserVersion >= LOWEST_SAFARI_VERSION;
	}

	if(clientType === ClientType.CHROMIUM) {
		return browserVersion >= LOWEST_CHROMIUM_VERSION;
	}

	if (clientType === ClientType.SAFARI) {
		return browserVersion >= LOWEST_SAFARI_VERSION;
	}

	return false;
};

export const getBrowserVersion = () => {
	const majorVersionStr = platform.version?.split('.')[0];
	return parseInt(majorVersionStr ? majorVersionStr : '0');
};

export const retrieveBrowserType = () => {
	if (platform.name?.includes('Chrome')) {
		return BrowserType.CHROME;
	} else if (platform.name?.includes('Microsoft Edge')) {
		return BrowserType.EDGE;
	} else if (platform.name?.includes('Opera')) {
		return BrowserType.OPERA;
	} else if (platform.name?.includes('Firefox')) {
		return BrowserType.FIREFOX;
	} else if (platform.name?.includes('Safari')) {
		return BrowserType.SAFARI;
	} else {
		return BrowserType.UNKNOWN;
	}
};

export const retrieveClientType = () => {
	if (platform.name?.includes('Chrome')) {
		return ClientType.CHROMIUM;
	} else if (platform.name?.includes('Microsoft Edge')) {
		return ClientType.CHROMIUM;
	} else if (platform.name?.includes('Opera')) {
		return ClientType.CHROMIUM;
	} else if (platform.name?.includes('Firefox')) {
		return ClientType.FIREFOX;
	} else if (platform.name?.includes('Safari')) {
		return ClientType.SAFARI;
	} else {
		return ClientType.CLIENTTYPE_UNKNOWN;
	}
};
