import {selectTheme} from '../../store/slices/user';
import {store} from '../../store/store';
import {ThemeType} from '../../styles/theme';
import {darken} from 'polished';

export const hexifyString = (text: string) => {
	const currentTheme = selectTheme(store.getState());

	const maxLength = 6
	const hexValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
	let result = '#'

	if (text.length < maxLength) {
		return '#ffffff'
	}

	for (let i = 0; i < maxLength; i++) {
		result += hexValues[text.charCodeAt(i) % hexValues.length]
	}

	return currentTheme === ThemeType.LIGHT ? darken(0.1, result) : result;
}
