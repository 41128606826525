import {createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {ChatStatus} from '../../services/gRPC/rooms/enums_pb';
import {UserInfo} from '../../services/gRPC/users/models_pb';
import {Message} from './messages';

type ChatType = 'PRIVATE' | 'ROOM' | 'EVENT'

export interface Chat {
	id: string
	type: ChatType
	status: ChatStatus
	createdAt: number,
	updatedAt: number,
	members?: UserInfo.AsObject[]
	lastMessage?: Message
}

const chatsAdapter = createEntityAdapter<Chat>({
	selectId: (chat) => chat.id
});

export const chatsSlice = createSlice({
	name: 'chats',
	initialState: chatsAdapter.getInitialState(),
	reducers: {
		setChats: chatsAdapter.setAll,
		addChats: chatsAdapter.addMany,
		addChat: chatsAdapter.addOne,
		setChat: chatsAdapter.setOne,
		updateChat: chatsAdapter.updateOne,
	}
});

export const chatsActions = chatsSlice.actions;
export const selectChats = chatsAdapter.getSelectors<RootState>((state) => state.chats).selectAll;
export const selectChatById = (id: string) => createSelector(
	selectChats,
	(chats) => chats.find((chat) => chat.id === id)
)
export const selectChatByMemberId = (id: string) => createSelector(
	selectChats,
	(chats) => chats.find((chat) => chat.members?.map((member) => member.id).includes(id))
)
export const selectChatsByTypeWithLastMessage = (type: ChatType) => createSelector(
	selectChats,
	(chats) => chats
		.filter((chat) => chat.type === type && chat.lastMessage)
		.sort((a, b) => a.lastMessage!.updatedAt < b.lastMessage!.updatedAt ? 1 : -1)
)
