import {StreamInfo} from '../../../services/gRPC/sfu/packets_pb';
import {StreamType} from '../../../services/gRPC/sfu/enums_pb';
import {streamsToggleSenderSFU} from '../../../services/sfu/senders.sfu';
import {roomGlobalRef} from './roomGlobalRef';

// used to correctly switch between mcu and sfu mode - app mutes / un-mutes all video stream.
export class VideoStreamMuteManager {
	private videoStreamsArr: { stream: StreamInfo.AsObject, isMuted: boolean }[] = [];

	addStreams(streamsInfo: StreamInfo.AsObject[]) {
		streamsInfo.forEach(stream => {
			if (stream.type === StreamType.CAMERA) {
				this.videoStreamsArr.push({stream, isMuted: false});
			}
		});
	}

	removeStream(userId: string) {
		const index = this.videoStreamsArr.findIndex(obj => obj.stream.userId === userId);
		if (index >= 0) {
			this.videoStreamsArr.splice(index, 1);
		}
	}

	muteAllStreams() {
		const notMutedStreamObjs = this.videoStreamsArr.filter(obj => !obj.isMuted);
		notMutedStreamObjs.forEach(streamObj => streamObj.isMuted = true);
		const notMutedStreams = notMutedStreamObjs.map(obj => obj.stream);
		if (notMutedStreams.length) {
			streamsToggleSenderSFU({
				channel: roomGlobalRef.mediaReceiverServerChannel,
				muteStreams: notMutedStreams
			});
		}
	}

	unmuteAllStreams() {
		const mutedStreamObjs = this.videoStreamsArr.filter(obj => obj.isMuted);
		mutedStreamObjs.forEach(streamObj => streamObj.isMuted = false);
		const mutedStreams = mutedStreamObjs.map(obj => obj.stream);
		if (mutedStreams.length) {
			streamsToggleSenderSFU({
				channel: roomGlobalRef.mediaReceiverServerChannel,
				unmuteStreams: mutedStreams
			});
		}
	}
}
