import React, {FC} from 'react';
import {GenericAvatarS} from './GenericAvatarS';
import {Layout} from '../../../pages/Room/relatedComponents/RoomGrid/RoomGrid';

export interface GenericAvatarProps {
	width?: string;
	height?: string;
	imageUrl?: string;
	iconColor?: string;
	layout?: Layout;
}

const DEFAULT_WIDTH = '52px';
const DEFAULT_HEIGHT = '52px';
const DEFAULT_LAYOUT = 'circle'

export const GenericAvatar: FC<GenericAvatarProps> = ({
	width,
	height,
	imageUrl,
	iconColor,
	layout,
}) => {

	return (
		<GenericAvatarS width={width ?? DEFAULT_WIDTH}
		                height={height ?? DEFAULT_HEIGHT}
		                imageUrl={imageUrl}
		                iconColor={iconColor}
		                layout={layout ?? DEFAULT_LAYOUT}
		>
			{
				imageUrl ?
					<div className={'GenericAvatarS_UserImage'}/>
					:
					<div className={'GenericAvatarS_DefaultImage'}>
						<i className="icon-avatar"/>
					</div>
			}
		</GenericAvatarS>
	);
};
