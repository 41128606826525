import {BehaviorSubject, EMPTY, of, Subject} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {EMIT} from '../../utils/utils';
import {ModalSubjectOverload, ModalType, PassDataAndCloseModalOverload} from './modal.types';

const modalOpened = Symbol('Modal opened');

// event to manipulate modals views
// it returns 'undefined' when all modal should be hidden
// it returns 'modal type' to open specific modal with optionally passed 'data'
export const onModalChange$$ = new BehaviorSubject<any>(undefined);
export let currentlyOpenedModal: ModalType | undefined = undefined;
let currentlyOpenedModalId: string | undefined = undefined;

onModalChange$$.subscribe((data) => {
	const modalType = (data?.modal as ModalType | undefined);
	currentlyOpenedModal = modalType;
});

const modalObservableCore = (() => {
	let sub: Subject<any | undefined>;
	let isModalOpened = false;
	return () => {
		return Object.freeze({
			getCurrentModal: () => sub,
			completeCurrentModal: () => {
				if (isModalOpened) {
					onModalChange$$.next(undefined);
					currentlyOpenedModalId = undefined;
					sub && sub.complete();
				}
				isModalOpened = false;
			},
			setNewModal: function () {
				this.completeCurrentModal();
				isModalOpened = true;
				sub = new BehaviorSubject<any | typeof modalOpened>(modalOpened) as Subject<any | undefined>;
				return sub;
			}
		});
	};
})();

export const closeModalWithResult = (obj: PassDataAndCloseModalOverload) => {
	modalObservableCore().getCurrentModal().next('data' in obj ? obj.data : undefined);
};

export const closeModal = () => {
	modalObservableCore().getCurrentModal().next(undefined);
};

export const cancelModal = () => {
	modalObservableCore().completeCurrentModal();
};

export const cancelModalWithId = (id: string) => {
	if (currentlyOpenedModalId === id) {
		modalObservableCore().completeCurrentModal();
	}
};

export const cancelModalExceptWithId = (id: string) => {
	if (currentlyOpenedModalId !== id) {
		modalObservableCore().completeCurrentModal();
	}
};

export const cancelModalWithType = (type: ModalType) => {
	if (currentlyOpenedModal === type) {
		modalObservableCore().completeCurrentModal();
	}
};

export const cancelModalExceptWithType = (type: ModalType) => {
	if (currentlyOpenedModal !== type) {
		modalObservableCore().completeCurrentModal();
	}
};

export const modalsSystem = ((modal: ModalType, openModalData?: any, id?: string) => {
	currentlyOpenedModalId = id;
	return modalObservableCore().setNewModal().pipe(
		mergeMap(resultsOrSymbol => resultsOrSymbol === modalOpened ?
			// modal has been opened
			// don't pass next to modalSubject
			// return EMPTY so the modalSubject will be paused
			// show modal view with passed data
			EMIT.pipe(
				mergeMap(() => {
					onModalChange$$.next({modal, data: openModalData});
					return EMPTY;
				})
			)
			:
			// modal has been closed and results (resultsOrSymbol) will be passed
			// complete existing Subject and hide modal view
			of(resultsOrSymbol).pipe(
				tap(() => modalObservableCore().completeCurrentModal())
			)
		)
	);
}) as ModalSubjectOverload;
