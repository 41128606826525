import {webSocketOutput$$} from '../../../../services/ws/webSocket';
import {WsMessageType} from '../../../../services/ws/incomingMessagesTypes.ws';
import {mergeMap, tap} from 'rxjs/operators';
import {roomGlobalRef} from '../roomGlobalRef';
import {SfuType} from '../../../../services/gRPC/sfu/enums_pb';
import {
	EventSource,
	EventStatus,
	RoomStreamType,
	RoomUserReceiverConnectionStatus,
	RoomUserSenderConnectionStatus
} from '../../../../services/gRPC/rooms/enums_pb';
import {
	confirmEventPerformerService$,
	createEventTokenService$,
	getEventInfoService$,
	getEventStateService$,
	getRoomStateService$
} from '../../../../services/roomServices';
import {store} from '../../../../store/store';
import {selectClientId} from '../../../../store/slices/user';
import {selectClientTicketByEventId, selectClientTickets} from '../../../../store/slices/tickets';
import {roomUsersActions} from '../../../../store/slices/roomUsers';
import {roomActions, selectIsPerformerRoom} from '../../../../store/slices/room';
import {merge} from 'rxjs';
import {tokenizeMpdStreamEvent} from '../../../../utils/customHooks/useTokenizeMpdStreamEvent';
import {startPerformerRtcListeners, stopPerformerRtcListeners} from './usePerformerRtcListeners';
import {roomsActions} from '../../../../store/slices/rooms';
import {Notification, showNotification} from '../../../../utils/showNotification';
import {batchActions} from 'redux-batched-actions';
import {eventsActions} from '../../../../store/slices/events';
import React from 'react';
import {Resolution} from '../getResolution.types';
import {reconnectPcReceiver} from './reconnectPcReceiver';
import {reconnectPcSender} from './reconnectPcSender';
import {applyConstraintsToActiveStream, CameraMode} from '../updaters/userMediaVideo';
import {useNavigate} from 'react-router-dom';
import {
	RoomStreamNotEvent,
	roomStreamsActions,
	selectCurrentStream,
	selectEventId,
	selectEventSource,
	selectIsEvent
} from '../../../../store/slices/roomStreams';
import {EMIT} from '../../../../utils/utils';
import {resendClientFlags$} from '../resendClientFlags';
import {isiOS} from '../../../../utils/browserTypeDetection';

export const useRoomWsListeners = () => {
	const navigate = useNavigate();

	const bothTypeRoomWsListeners$$ = () => {
		/// USER LEFT ROOM
		return merge(
			webSocketOutput$$(WsMessageType.UPDATE_ROOM_USER_STATUS).pipe(
				tap((packet) => {
					console.log(packet.userId, packet.status);
					roomGlobalRef.roomUsersStatusesQueue.applyRoomUserStatusUpdate(packet);
				})
			),
			webSocketOutput$$(WsMessageType.UPDATE_ROOM_USER_RECEIVER_CONNECTION_STATUS).pipe(
				tap(({userId, receiverConnectionStatus, timestamp}) => {
					console.log(userId, receiverConnectionStatus, timestamp);
					roomGlobalRef.statusesQueue.applyReceiverStatusUpdate(receiverConnectionStatus, userId, timestamp);

					if (userId === selectClientId(store.getState()) && receiverConnectionStatus === RoomUserReceiverConnectionStatus.RURCSTATUS_DISCONNECTED) {
						reconnectPcReceiver();
					} else if (userId === selectClientId(store.getState()) && receiverConnectionStatus === RoomUserReceiverConnectionStatus.RURCSTATUS_RECONNECTING && isiOS()) {
						reconnectPcReceiver();
					}})
			),
			webSocketOutput$$(WsMessageType.UPDATE_ROOM_USER_SENDER_CONNECTION_STATUS).pipe(
				mergeMap(({userId, senderConnectionStatus, timestamp}) => {
					console.log(userId, senderConnectionStatus, timestamp);
					roomGlobalRef.statusesQueue.applySenderStatusUpdate(senderConnectionStatus, userId, timestamp);

					if (userId === selectClientId(store.getState()) && senderConnectionStatus === RoomUserSenderConnectionStatus.RUSCSTATUS_DISCONNECTED) {
						reconnectPcSender();
					} else if (userId === selectClientId(store.getState()) && senderConnectionStatus === RoomUserSenderConnectionStatus.RUSCSTATUS_RECONNECTING && isiOS()) {
						reconnectPcSender();
					} else if (userId !== selectClientId(store.getState()) && senderConnectionStatus === RoomUserSenderConnectionStatus.RUSCSTATUS_CONNECTING) {
						store.dispatch(roomUsersActions.clearRoomUserMediaTracks({userId}));
					} else if (userId === selectClientId(store.getState()) && senderConnectionStatus === RoomUserSenderConnectionStatus.RUSCSTATUS_CONNECTED) {
						return resendClientFlags$()
					}
					return EMIT;
				})
			),
			/// USER FLAGS
			webSocketOutput$$(WsMessageType.UPDATE_ROOM_USER_FLAGS).pipe(
				tap(({
					userId,
					flagsList,
					timestamp
				}) => {
					console.log(`%c Incoming Flags Update for userId: ${userId} `, 'color: #fcf237; font-weight: 900; background: black');
					console.log(flagsList);
					roomGlobalRef.flagsQueue.applyFlagsUpdate(flagsList, userId, timestamp);
				})
			),
			/// STREAMS ANSWER
			webSocketOutput$$(WsMessageType.STREAMS_ANSWER).pipe(
				tap(({sdpAnswer, addRequestsList, removalRequestsList}) => {
					console.log(`%c Subscription response (add, remove): `, 'color: #EF722F; font-weight: 900; background: black');
					console.log(addRequestsList);
					console.log(removalRequestsList);
					roomGlobalRef.pcMediaReceiver!.setRemoteDescription(JSON.parse(atob(sdpAnswer as string)));
				})
			),
			/// CREATE EVENT OBSERVER
			webSocketOutput$$(WsMessageType.CREATE_EVENT_OBSERVER).pipe(
				tap(({observer, timestamp}) => {
					getRoomStateService$().subscribe(({info, event}) => {
						const hasUserTicketForEvent = selectClientTickets(store.getState()).find(i => i.eventId === event!.info!.id);

						if (hasUserTicketForEvent && event!.info!.status === EventStatus.ESTATUS_STARTED) {
							tokenizeMpdStreamEvent();
						}

						store.dispatch(batchActions([
							roomStreamsActions.setEvent(event!),
							roomsActions.updateRoomEvent({eventInfo: event!.info!, roomId: info!.id})
						]));
					});
				})
			),
			/// DELETE EVENT OBSERVER
			webSocketOutput$$(WsMessageType.DELETE_EVENT_OBSERVER).pipe(
				tap(({timestamp}) => {
					store.dispatch(roomStreamsActions.setEvent(undefined));
				})
			),
			// REFRESH EVENT BROADCAST
			webSocketOutput$$(WsMessageType.REFRESH_EVENT_BROADCAST).pipe(
				tap(({id, timestamp}) => {
					const eventId = selectEventId(store.getState());
					const isAnyTicketForTheEvent = selectClientTicketByEventId(eventId)(store.getState());
					if (isAnyTicketForTheEvent || selectIsPerformerRoom(store.getState())) {
						createEventTokenService$().subscribe((token) => {
							const {url, offset, timestamp} = JSON.parse(window.atob(token));
							store.dispatch(roomStreamsActions.setDashItems({startedAt: timestamp, offset, streamLink: url}));
						});
					}
				})
			),
			/// UPDATE EVENT AUDIENCE
			webSocketOutput$$(WsMessageType.UPDATE_EVENT_AUDIENCE).pipe(
				tap(({audience}) => {
					store.dispatch(roomStreamsActions.setAudience(audience));
				})
			),
			/// PAUSE YOUTUBE STREAM
			webSocketOutput$$(WsMessageType.PAUSE_ROOM_STREAM).pipe(
				tap(({id, paused}) => {
					store.dispatch(roomStreamsActions.updateRoomStreamPaused({roomStreamId: id, paused}));
				})
			),
			/// CHANGE OFFSET ROOM STREAM
			webSocketOutput$$(WsMessageType.CHANGE_OFFSET_ROOM_STREAM).pipe(
				tap(({id, offset, timestamp}) => {
					store.dispatch(roomStreamsActions.updateRoomStreamOffset({roomStreamId: id, offset, timestamp: timestamp * 1000}));
				})
			),
			/// CREATE ROOM STREAM
			webSocketOutput$$(WsMessageType.CREATE_ROOM_STREAM).pipe(
				tap(({stream}) => {
					console.log(stream);
					if (stream!.type === RoomStreamType.RSTYPE_EVENT) {
						getEventInfoService$({eventId: stream!.metadata!.sid}).subscribe((event) => {
							store.dispatch(roomStreamsActions.addRoomStream({stream: {...stream!, info: event}}));
							store.dispatch(eventsActions.addEvent(event));
						});
					} else {
						store.dispatch(roomStreamsActions.addRoomStream({stream: stream as RoomStreamNotEvent}));
					}

				})
			),
			/// DELETE ROOM STREAM
			webSocketOutput$$(WsMessageType.DELETE_ROOM_STREAM).pipe(
				tap(({id}) => {
					const currentStreamId = selectCurrentStream(store.getState())?.id;
					if (currentStreamId === id) {
						store.dispatch(roomStreamsActions.setDashItems({streamLink: ''}));
					}
					store.dispatch(roomStreamsActions.deleteRoomStream({roomStreamId: id}));
				})
			),
			/// CLEAR ROOM STREAMS
			webSocketOutput$$(WsMessageType.CLEAR_ROOM_STREAMS).pipe(
				tap(({roomId, idsList}) => {
					store.dispatch(batchActions([
						roomStreamsActions.clearRoomStreams({idsList}),
						roomsActions.updateRoomEvent({eventInfo: undefined, roomId: roomId})
					]));
				})
			),
			/// UPDATE ROOM STREAM POSITION
			webSocketOutput$$(WsMessageType.UPDATE_ROOM_STREAM_POSITON).pipe(
				tap(({id, position}) => {
					store.dispatch(roomStreamsActions.updateRoomStreamPosition({id, position}));
				})
			),
			/// UPDATE ROOM STREAM SUGGEST
			webSocketOutput$$(WsMessageType.SUGGEST_ROOM_STREAMS).pipe(
				tap(({id, suggest}) => {
					store.dispatch(roomStreamsActions.updateRoomStreamSuggest({id, suggest}));
				})
			),
			/// UPDATE ROOM STREAM RELATED
			webSocketOutput$$(WsMessageType.RELATED_ROOM_STREAMS).pipe(
				tap(({id, related}) => {
					store.dispatch(roomStreamsActions.updateRoomStreamRelated({id, related}));
				})
			),
			/// UPDATE ROOM STREAM STACK
			webSocketOutput$$(WsMessageType.STACK_ROOM_STREAMS).pipe(
				tap(({id, stack}) => {
					store.dispatch(roomStreamsActions.updateRoomStreamStack({id, stack}));
				})
			),
			/// UPDATE ROOM NAME
			webSocketOutput$$(WsMessageType.UPDATE_ROOM_NAME).pipe(
				tap(({name}) => {
					store.dispatch(roomActions.setRoomName(name));
				})
			),
			/// UPDATE ROOM BACKGROUND
			webSocketOutput$$(WsMessageType.UPDATE_ROOM_BACKGROUND).pipe(
				tap(({backgroundUrl}) => {
					store.dispatch(roomActions.setRoomBackground(backgroundUrl));
				})
			),
			/// UPDATE ROOM PID
			webSocketOutput$$(WsMessageType.UPDATE_ROOM_PID).pipe(
				tap(({pid}) => {
					window.history.replaceState(null, '/c/', pid);
					store.dispatch(roomActions.setRoomPid({pid}));
				})
			),
			/// DELETE ROOM
			webSocketOutput$$(WsMessageType.DELETE_ROOM).pipe(
				tap(({id}) => {
					navigate('/');
					store.dispatch(roomsActions.deleteRoom({id: id}));
					showNotification(Notification.WARNING, 'Your casa has been deleted');
				})
			),
			/// UPDATE ROOM USER ROLE
			webSocketOutput$$(WsMessageType.UPDATE_ROOM_USER_ROLE).pipe(
				tap(({userId, role}) => {
					store.dispatch(roomUsersActions.changeUserRole({userId, role}));
				})
			),
			/// DISCONNECT ROOM USER
			webSocketOutput$$(WsMessageType.DISCONNECT_ROOM_USER).pipe(
				tap(() => {
					navigate('/');
					showNotification(Notification.INFO, 'You were switched to a casa on a different device.');
				})
			),
			/// ICE CANDIDATE
			webSocketOutput$$(WsMessageType.ICE_CANDIDATE).pipe(
				tap((packet) => {
					if (typeof packet.iceCandidate?.candidate === 'string') {
						const candidate = JSON.parse(atob(packet.iceCandidate.candidate));
						switch (packet.iceCandidate.sfuType) {
							case SfuType.STYPE_ROUTER:
								const isReceiverOfferReady = roomGlobalRef.queuedCandidates[SfuType.STYPE_ROUTER].isOfferReady;
								if (isReceiverOfferReady) {
									roomGlobalRef.pcMediaReceiver?.addIceCandidate(candidate).catch((err) => {
										console.error('Failed to send receiver ice candidate', err);
									});
								} else {
									roomGlobalRef.queuedCandidates.addNotAdded(SfuType.STYPE_ROUTER, candidate);
								}
								return;
							case SfuType.STYPE_GATEWAY:
								const isSenderOfferReady = roomGlobalRef.queuedCandidates[SfuType.STYPE_GATEWAY].isOfferReady;
								if (isSenderOfferReady) {
									roomGlobalRef.pcMediaSender?.addIceCandidate(candidate).catch((err) => {
										console.error('Failed to send sender ice candidate', err);
									});
								} else {
									roomGlobalRef.queuedCandidates.addNotAdded(SfuType.STYPE_GATEWAY, candidate);
								}
								return;
							default: {
								console.error('Unknown sfu type');
							}
						}

					}
				})
			)
		);
	};

	const onlyPerformerRoomWsListeners$$ = () => {
		return merge(
			/// UPDATE EVENT STATUS
			webSocketOutput$$(WsMessageType.UPDATE_EVENT_STATUS).pipe(
				tap(({status}) => {
					if (selectIsEvent(store.getState())) {
						store.dispatch(roomStreamsActions.setEventStatus(status));
						if (status === EventStatus.ESTATUS_STARTED) {
							console.log(`%cEvent STARTED`, 'color: aqua; font-weight: 900; background: black');
							store.dispatch(roomStreamsActions.setEventStartedAt(new Date().getTime() / 1000));
							if (selectEventSource(store.getState()) !== EventSource.ESOURCE_MCU) {
								tokenizeMpdStreamEvent();
							} else {
								console.log('testing - tokenizeMpdStreamEvent would run 1');
							}
						} else if (status === EventStatus.ESTATUS_FINISHED) {
							console.log(`%cEvent FINISHED`, 'color: red; font-weight: 900; background: black');
							store.dispatch(roomActions.removePerformer());
							getEventStateService$().subscribe({
								next: (eventState) => {
									store.dispatch(batchActions([
										roomActions.setPerformerMediaStream({type: 'camera', value: false}),
										roomActions.removePerformer(),
										roomStreamsActions.setEvent(eventState)
									]));
								},
								error: () => {
									store.dispatch(roomStreamsActions.removeEvent());
								}
							});
						} else if (status === EventStatus.ESTATUS_CANCELED) {
							console.log(`%cEvent CANCELED`, 'color: red; font-weight: 900; background: black');
						}
					} else {
						getEventStateService$().subscribe({
							next: (eventState) => {
								store.dispatch(roomStreamsActions.setEvent(eventState));
								const eventStatus = eventState.info?.status;
								if (eventStatus === EventStatus.ESTATUS_FINISHED || eventStatus === EventStatus.ESTATUS_CANCELED) {
									store.dispatch(roomActions.removePerformer());
								}
							},
							error: () => {
								console.error('UPDATE_EVENT_STATUS received but no event before and after packet');
								store.dispatch(roomStreamsActions.removeEvent());
							}
						});
					}
				})
			),
			/// PREPARE EVENT PERFORMER
			webSocketOutput$$(WsMessageType.PREPARE_EVENT_PERFORMER).pipe(
				mergeMap(() =>
					applyConstraintsToActiveStream(CameraMode.PERFORMER, Resolution.HIGH, {width: 1920, height: 1080})
				),
				mergeMap(() => confirmEventPerformerService$())
			),
			/// CREATE EVENT PERFORMER
			webSocketOutput$$(WsMessageType.CREATE_EVENT_PERFORMER).pipe(
				tap(({performer, timestamp}) => {
					store.dispatch(roomActions.setPerformer(performer));
				})
			),
			/// DELETE EVENT PERFORMER
			webSocketOutput$$(WsMessageType.DELETE_EVENT_PERFORMER).pipe(
				tap(({userId, timestamp}) => {
					store.dispatch(roomActions.removePerformer());
				})
			),
			/// UPDATE EVENT PERFORMER POSITION
			webSocketOutput$$(WsMessageType.UPDATE_EVENT_PERFORMER_POSITION).pipe(
				//TODO
			)
		);
	};

	const onlyWatchRoomWsListeners$$ = () => {
		return merge(
			/// START EVENT PACKET
			webSocketOutput$$(WsMessageType.START_EVENT).pipe(
				tap((packet) => {
					console.log(`%cEvent STARTED`, 'color: aqua; font-weight: 900; background: black');
					store.dispatch(roomStreamsActions.setEventStatus(EventStatus.ESTATUS_STARTED));
					store.dispatch(roomStreamsActions.setEventStartedAt(packet.timestamp));
					const eventId = selectEventId(store.getState());
					const isAnyTicketForTheEvent = selectClientTicketByEventId(eventId)(store.getState());
					if (isAnyTicketForTheEvent) {
						if (selectEventSource(store.getState()) === EventSource.ESOURCE_MCU) {
							startPerformerRtcListeners();
						} else {
							tokenizeMpdStreamEvent();
						}
					}
				})
			),
			/// FINISH EVENT PACKET
			webSocketOutput$$(WsMessageType.FINISH_EVENT).pipe(
				tap(() => {
					console.log(`%cEvent FINISHED`, 'color: red; font-weight: 900; background: black');
					store.dispatch(roomStreamsActions.setEventStatus(EventStatus.ESTATUS_FINISHED));
					stopPerformerRtcListeners();
				})
			),
			/// CANCEL EVENT PACKET
			webSocketOutput$$(WsMessageType.CANCEL_EVENT).pipe(
				tap(() => {
					console.log(`%cEvent CANCELED`, 'color: red; font-weight: 900; background: black');
					store.dispatch(roomStreamsActions.setEventStatus(EventStatus.ESTATUS_CANCELED));
				})
			)
		);
	};

	return {
		bothTypeRoomWsListeners$$,
		onlyPerformerRoomWsListeners$$,
		onlyWatchRoomWsListeners$$
	};

};
