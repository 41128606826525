import React, {FC} from 'react';
import {GenericAvatar, GenericAvatarProps} from '../Generic/GenericAvatar';
import {ChatAvatarS} from './ChatAvatarS';
import {UserStatus} from '../../../services/gRPC/users/enums_pb';
import {hexifyString} from '../utils';

const DEFAULT_WIDTH = '40px';
const DEFAULT_HEIGHT = '40px';

export interface ChatAvatarProps extends GenericAvatarProps, React.ComponentPropsWithRef<'div'> {
	userId: string;
	pointer?: boolean;
	status?: UserStatus;
}

export const ChatAvatar: FC<ChatAvatarProps> = ({
	width,
	height,
	imageUrl,
	userId,
	status,
	pointer
}) => {
	const isUserConnected = status === UserStatus.USERSTATUS_CONNECTED;
	const color = hexifyString(userId);

	return (
		<ChatAvatarS height={height ?? DEFAULT_HEIGHT} roundCut={isUserConnected} pointer={pointer}>
			<GenericAvatar width={width ?? DEFAULT_WIDTH} height={height ?? DEFAULT_HEIGHT} imageUrl={imageUrl} iconColor={color}/>
			{isUserConnected &&
				<div className="ChatAvatarS_Status">
					<svg className="svg">
						<clipPath id="circle" clipPathUnits="objectBoundingBox">
							<path
								d="M 0.5 0 C 0.776 0 1 0.224 1 0.5 C 1 0.603 0.989 0.627 0.986 0.674 C 0.822 0.59 0.634 0.797 0.726 0.954 C 0.687 0.972 0.597 1 0.5 1 C 0.224 1 0 0.776 0 0.5 C 0 0.224 0.224 0 0.5 0"/>
						</clipPath>
					</svg>
				</div>
			}
		</ChatAvatarS>
	);
};
