import styled, {css} from 'styled-components';
import {GenericAvatarS} from '../Generic/GenericAvatarS';
import {CYAN_LIGHTNING} from '../../../styles/constants';

export const ChatAvatarS = styled.div<{ roundCut?: boolean, pointer?: boolean, height: string }>`
	position: relative;
	cursor: ${props => props.pointer ? 'pointer' : 'default'};

	.ChatAvatarS_Status {
		width: 24%;
		height: 24%;
		background: ${CYAN_LIGHTNING};
		position: absolute;
		right: -2%;
		top: 76%;
		border-radius: 50%;
	}

	${GenericAvatarS} {
		border-radius: 50%;
		background-color: ${({theme}) => theme.color1};

		${({roundCut}) => roundCut && css`
	clip-path: url("#circle");
`};

		i {
			font-size: calc(${props => props.height} * 0.7);
			padding-top: calc(${props => props.height} * 0.3);
		}
	}
`;
