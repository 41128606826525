import React, {FC} from 'react';
import { ChatListEntryS } from './ChatListEntryS';
import {Message} from '../../../../../../../store/slices/messages';
import {ChatAvatar} from '../../../../../../Avatars/Chat/ChatAvatar';

interface ChatListEntryProps {
	userId: string
	userName: string
	onClick: () => void
	avatarUrl?: string
	lastMessage?: Message
	notification?: boolean
}

export const ChatListEntry: FC<ChatListEntryProps> = (props) => {
	const {userId, userName, avatarUrl, lastMessage, notification} = props

	return (
		<ChatListEntryS {...props}>
			<div className='d-flex w-100'>
				<ChatAvatar userId={userId} imageUrl={avatarUrl}/>
				<div className='d-flex flex-column ml-2'>
					<div className='ChatListEntryS_Username'>
						{userName}
					</div>
					<div className='ChatListEntryS_LastMessage'>
						{lastMessage ? lastMessage.content : `Start conversation with ${userName}!`}
					</div>
				</div>
			</div>
			{notification &&
				<div className='ChatListEntryS_Notification'/>
			}
		</ChatListEntryS>
	);
};
