import React, {FC, useEffect, useState} from 'react';
import {interval} from 'rxjs';
import {changeRoomStreamService$} from '../../services/roomServices';

export const EventStarts: FC<{ title: string, currentPosition: number }> = (props: any) => {

	const [time, setTime] = useState(60);

	useEffect(() => {
		const eventInterval = interval(1000).subscribe(() => {
			setTime(t => t - 1);
		});

		return () => eventInterval.unsubscribe();
	});

	const clickHandler = () => {
		changeRoomStreamService$(props.currentPosition, 0).subscribe();
	};

	return (
		<div className="w-100 h-100 d-flex align-items-center justify-content-start p-1" onClick={clickHandler}>
			{`Event "${props.title}" starts at ${time} seconds. Click here to switch to it.`}</div>
	);
};
