/* eslint-disable */
// source: mcu/models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var mcu_enums_pb = require('../mcu/enums_pb.js');
goog.object.extend(proto, mcu_enums_pb);
goog.exportSymbol('proto.mcu.api.v1.CellConfig', null, global);
goog.exportSymbol('proto.mcu.api.v1.Empty', null, global);
goog.exportSymbol('proto.mcu.api.v1.KeyFrameRequest', null, global);
goog.exportSymbol('proto.mcu.api.v1.Layout', null, global);
goog.exportSymbol('proto.mcu.api.v1.LayoutCell', null, global);
goog.exportSymbol('proto.mcu.api.v1.ManagementCommand', null, global);
goog.exportSymbol('proto.mcu.api.v1.RTPPacket', null, global);
goog.exportSymbol('proto.mcu.api.v1.Resolution', null, global);
goog.exportSymbol('proto.mcu.api.v1.Room', null, global);
goog.exportSymbol('proto.mcu.api.v1.Sample', null, global);
goog.exportSymbol('proto.mcu.api.v1.VideoResolution', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcu.api.v1.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.Empty.displayName = 'proto.mcu.api.v1.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.Resolution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcu.api.v1.Resolution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.Resolution.displayName = 'proto.mcu.api.v1.Resolution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.LayoutCell = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcu.api.v1.LayoutCell, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.LayoutCell.displayName = 'proto.mcu.api.v1.LayoutCell';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.CellConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcu.api.v1.CellConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.CellConfig.displayName = 'proto.mcu.api.v1.CellConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.RTPPacket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcu.api.v1.RTPPacket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.RTPPacket.displayName = 'proto.mcu.api.v1.RTPPacket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.Sample = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcu.api.v1.Sample, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.Sample.displayName = 'proto.mcu.api.v1.Sample';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.VideoResolution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcu.api.v1.VideoResolution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.VideoResolution.displayName = 'proto.mcu.api.v1.VideoResolution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.KeyFrameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcu.api.v1.KeyFrameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.KeyFrameRequest.displayName = 'proto.mcu.api.v1.KeyFrameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.ManagementCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mcu.api.v1.ManagementCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.ManagementCommand.displayName = 'proto.mcu.api.v1.ManagementCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.Layout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcu.api.v1.Layout.repeatedFields_, null);
};
goog.inherits(proto.mcu.api.v1.Layout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.Layout.displayName = 'proto.mcu.api.v1.Layout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mcu.api.v1.Room = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mcu.api.v1.Room.repeatedFields_, null);
};
goog.inherits(proto.mcu.api.v1.Room, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mcu.api.v1.Room.displayName = 'proto.mcu.api.v1.Room';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.Empty}
 */
proto.mcu.api.v1.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.Empty;
  return proto.mcu.api.v1.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.Empty}
 */
proto.mcu.api.v1.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.Resolution.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.Resolution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.Resolution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Resolution.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.Resolution}
 */
proto.mcu.api.v1.Resolution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.Resolution;
  return proto.mcu.api.v1.Resolution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.Resolution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.Resolution}
 */
proto.mcu.api.v1.Resolution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.Resolution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.Resolution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.Resolution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Resolution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 width = 1;
 * @return {number}
 */
proto.mcu.api.v1.Resolution.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Resolution} returns this
 */
proto.mcu.api.v1.Resolution.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 height = 2;
 * @return {number}
 */
proto.mcu.api.v1.Resolution.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Resolution} returns this
 */
proto.mcu.api.v1.Resolution.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.LayoutCell.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.LayoutCell.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.LayoutCell} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.LayoutCell.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.LayoutCell}
 */
proto.mcu.api.v1.LayoutCell.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.LayoutCell;
  return proto.mcu.api.v1.LayoutCell.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.LayoutCell} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.LayoutCell}
 */
proto.mcu.api.v1.LayoutCell.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.LayoutCell.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.LayoutCell.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.LayoutCell} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.LayoutCell.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.mcu.api.v1.LayoutCell.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcu.api.v1.LayoutCell} returns this
 */
proto.mcu.api.v1.LayoutCell.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 position = 2;
 * @return {number}
 */
proto.mcu.api.v1.LayoutCell.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.LayoutCell} returns this
 */
proto.mcu.api.v1.LayoutCell.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.CellConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.CellConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.CellConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.CellConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cellResolution: (f = msg.getCellResolution()) && proto.mcu.api.v1.Resolution.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.CellConfig}
 */
proto.mcu.api.v1.CellConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.CellConfig;
  return proto.mcu.api.v1.CellConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.CellConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.CellConfig}
 */
proto.mcu.api.v1.CellConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 2:
      var value = new proto.mcu.api.v1.Resolution;
      reader.readMessage(value,proto.mcu.api.v1.Resolution.deserializeBinaryFromReader);
      msg.setCellResolution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.CellConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.CellConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.CellConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.CellConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCellResolution();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcu.api.v1.Resolution.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.mcu.api.v1.CellConfig.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.CellConfig} returns this
 */
proto.mcu.api.v1.CellConfig.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Resolution cell_resolution = 2;
 * @return {?proto.mcu.api.v1.Resolution}
 */
proto.mcu.api.v1.CellConfig.prototype.getCellResolution = function() {
  return /** @type{?proto.mcu.api.v1.Resolution} */ (
    jspb.Message.getWrapperField(this, proto.mcu.api.v1.Resolution, 2));
};


/**
 * @param {?proto.mcu.api.v1.Resolution|undefined} value
 * @return {!proto.mcu.api.v1.CellConfig} returns this
*/
proto.mcu.api.v1.CellConfig.prototype.setCellResolution = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcu.api.v1.CellConfig} returns this
 */
proto.mcu.api.v1.CellConfig.prototype.clearCellResolution = function() {
  return this.setCellResolution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcu.api.v1.CellConfig.prototype.hasCellResolution = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.RTPPacket.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.RTPPacket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.RTPPacket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.RTPPacket.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64(),
    keyFrame: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.RTPPacket}
 */
proto.mcu.api.v1.RTPPacket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.RTPPacket;
  return proto.mcu.api.v1.RTPPacket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.RTPPacket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.RTPPacket}
 */
proto.mcu.api.v1.RTPPacket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKeyFrame(value);
      break;
    case 3:
      var value = /** @type {!proto.mcu.api.v1.SampleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.RTPPacket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.RTPPacket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.RTPPacket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.RTPPacket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getKeyFrame();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional bytes data = 1;
 * @return {string}
 */
proto.mcu.api.v1.RTPPacket.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.mcu.api.v1.RTPPacket.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.RTPPacket.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mcu.api.v1.RTPPacket} returns this
 */
proto.mcu.api.v1.RTPPacket.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bool key_frame = 2;
 * @return {boolean}
 */
proto.mcu.api.v1.RTPPacket.prototype.getKeyFrame = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcu.api.v1.RTPPacket} returns this
 */
proto.mcu.api.v1.RTPPacket.prototype.setKeyFrame = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional SampleType type = 3;
 * @return {!proto.mcu.api.v1.SampleType}
 */
proto.mcu.api.v1.RTPPacket.prototype.getType = function() {
  return /** @type {!proto.mcu.api.v1.SampleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mcu.api.v1.SampleType} value
 * @return {!proto.mcu.api.v1.RTPPacket} returns this
 */
proto.mcu.api.v1.RTPPacket.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.Sample.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.Sample.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.Sample} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Sample.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64(),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isKeyFrame: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    resolution: (f = msg.getResolution()) && proto.mcu.api.v1.VideoResolution.toObject(includeInstance, f),
    pictureId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 7, 0),
    prevDroppedPackets: jspb.Message.getFieldWithDefault(msg, 8, 0),
    spatialLayerId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    temporalLayerId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.Sample}
 */
proto.mcu.api.v1.Sample.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.Sample;
  return proto.mcu.api.v1.Sample.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.Sample} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.Sample}
 */
proto.mcu.api.v1.Sample.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {!proto.mcu.api.v1.SampleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsKeyFrame(value);
      break;
    case 5:
      var value = new proto.mcu.api.v1.VideoResolution;
      reader.readMessage(value,proto.mcu.api.v1.VideoResolution.deserializeBinaryFromReader);
      msg.setResolution(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPictureId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDuration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrevDroppedPackets(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpatialLayerId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTemporalLayerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.Sample.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.Sample.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.Sample} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Sample.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIsKeyFrame();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getResolution();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mcu.api.v1.VideoResolution.serializeBinaryToWriter
    );
  }
  f = message.getPictureId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPrevDroppedPackets();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getSpatialLayerId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTemporalLayerId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional bytes data = 1;
 * @return {string}
 */
proto.mcu.api.v1.Sample.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.mcu.api.v1.Sample.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.Sample.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.mcu.api.v1.Sample.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SampleType type = 3;
 * @return {!proto.mcu.api.v1.SampleType}
 */
proto.mcu.api.v1.Sample.prototype.getType = function() {
  return /** @type {!proto.mcu.api.v1.SampleType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mcu.api.v1.SampleType} value
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_key_frame = 4;
 * @return {boolean}
 */
proto.mcu.api.v1.Sample.prototype.getIsKeyFrame = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.setIsKeyFrame = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional VideoResolution resolution = 5;
 * @return {?proto.mcu.api.v1.VideoResolution}
 */
proto.mcu.api.v1.Sample.prototype.getResolution = function() {
  return /** @type{?proto.mcu.api.v1.VideoResolution} */ (
    jspb.Message.getWrapperField(this, proto.mcu.api.v1.VideoResolution, 5));
};


/**
 * @param {?proto.mcu.api.v1.VideoResolution|undefined} value
 * @return {!proto.mcu.api.v1.Sample} returns this
*/
proto.mcu.api.v1.Sample.prototype.setResolution = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.clearResolution = function() {
  return this.setResolution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcu.api.v1.Sample.prototype.hasResolution = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 picture_id = 6;
 * @return {number}
 */
proto.mcu.api.v1.Sample.prototype.getPictureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.setPictureId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 duration = 7;
 * @return {number}
 */
proto.mcu.api.v1.Sample.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 prev_dropped_packets = 8;
 * @return {number}
 */
proto.mcu.api.v1.Sample.prototype.getPrevDroppedPackets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.setPrevDroppedPackets = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 spatial_layer_id = 9;
 * @return {number}
 */
proto.mcu.api.v1.Sample.prototype.getSpatialLayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.setSpatialLayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 temporal_layer_id = 10;
 * @return {number}
 */
proto.mcu.api.v1.Sample.prototype.getTemporalLayerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Sample} returns this
 */
proto.mcu.api.v1.Sample.prototype.setTemporalLayerId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.VideoResolution.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.VideoResolution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.VideoResolution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.VideoResolution.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.VideoResolution}
 */
proto.mcu.api.v1.VideoResolution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.VideoResolution;
  return proto.mcu.api.v1.VideoResolution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.VideoResolution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.VideoResolution}
 */
proto.mcu.api.v1.VideoResolution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.VideoResolution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.VideoResolution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.VideoResolution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.VideoResolution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 width = 1;
 * @return {number}
 */
proto.mcu.api.v1.VideoResolution.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.VideoResolution} returns this
 */
proto.mcu.api.v1.VideoResolution.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 height = 2;
 * @return {number}
 */
proto.mcu.api.v1.VideoResolution.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.VideoResolution} returns this
 */
proto.mcu.api.v1.VideoResolution.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.KeyFrameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.KeyFrameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.KeyFrameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.KeyFrameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.KeyFrameRequest}
 */
proto.mcu.api.v1.KeyFrameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.KeyFrameRequest;
  return proto.mcu.api.v1.KeyFrameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.KeyFrameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.KeyFrameRequest}
 */
proto.mcu.api.v1.KeyFrameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.KeyFrameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.KeyFrameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.KeyFrameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.KeyFrameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.mcu.api.v1.KeyFrameRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcu.api.v1.KeyFrameRequest} returns this
 */
proto.mcu.api.v1.KeyFrameRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.ManagementCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.ManagementCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.ManagementCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.ManagementCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    resetencoder: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.ManagementCommand}
 */
proto.mcu.api.v1.ManagementCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.ManagementCommand;
  return proto.mcu.api.v1.ManagementCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.ManagementCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.ManagementCommand}
 */
proto.mcu.api.v1.ManagementCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResetencoder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.ManagementCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.ManagementCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.ManagementCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.ManagementCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResetencoder();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool resetEncoder = 1;
 * @return {boolean}
 */
proto.mcu.api.v1.ManagementCommand.prototype.getResetencoder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcu.api.v1.ManagementCommand} returns this
 */
proto.mcu.api.v1.ManagementCommand.prototype.setResetencoder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcu.api.v1.Layout.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.Layout.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.Layout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.Layout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Layout.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resolution: (f = msg.getResolution()) && proto.mcu.api.v1.Resolution.toObject(includeInstance, f),
    configList: jspb.Message.toObjectList(msg.getConfigList(),
    proto.mcu.api.v1.LayoutCell.toObject, includeInstance),
    cellConfig: (f = msg.getCellConfig()) && proto.mcu.api.v1.CellConfig.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 5, 0),
    seed: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.Layout}
 */
proto.mcu.api.v1.Layout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.Layout;
  return proto.mcu.api.v1.Layout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.Layout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.Layout}
 */
proto.mcu.api.v1.Layout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.mcu.api.v1.Resolution;
      reader.readMessage(value,proto.mcu.api.v1.Resolution.deserializeBinaryFromReader);
      msg.setResolution(value);
      break;
    case 3:
      var value = new proto.mcu.api.v1.LayoutCell;
      reader.readMessage(value,proto.mcu.api.v1.LayoutCell.deserializeBinaryFromReader);
      msg.addConfig(value);
      break;
    case 4:
      var value = new proto.mcu.api.v1.CellConfig;
      reader.readMessage(value,proto.mcu.api.v1.CellConfig.deserializeBinaryFromReader);
      msg.setCellConfig(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.Layout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.Layout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.Layout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Layout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResolution();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mcu.api.v1.Resolution.serializeBinaryToWriter
    );
  }
  f = message.getConfigList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mcu.api.v1.LayoutCell.serializeBinaryToWriter
    );
  }
  f = message.getCellConfig();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mcu.api.v1.CellConfig.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSeed();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mcu.api.v1.Layout.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcu.api.v1.Layout} returns this
 */
proto.mcu.api.v1.Layout.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Resolution resolution = 2;
 * @return {?proto.mcu.api.v1.Resolution}
 */
proto.mcu.api.v1.Layout.prototype.getResolution = function() {
  return /** @type{?proto.mcu.api.v1.Resolution} */ (
    jspb.Message.getWrapperField(this, proto.mcu.api.v1.Resolution, 2));
};


/**
 * @param {?proto.mcu.api.v1.Resolution|undefined} value
 * @return {!proto.mcu.api.v1.Layout} returns this
*/
proto.mcu.api.v1.Layout.prototype.setResolution = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcu.api.v1.Layout} returns this
 */
proto.mcu.api.v1.Layout.prototype.clearResolution = function() {
  return this.setResolution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcu.api.v1.Layout.prototype.hasResolution = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LayoutCell config = 3;
 * @return {!Array<!proto.mcu.api.v1.LayoutCell>}
 */
proto.mcu.api.v1.Layout.prototype.getConfigList = function() {
  return /** @type{!Array<!proto.mcu.api.v1.LayoutCell>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mcu.api.v1.LayoutCell, 3));
};


/**
 * @param {!Array<!proto.mcu.api.v1.LayoutCell>} value
 * @return {!proto.mcu.api.v1.Layout} returns this
*/
proto.mcu.api.v1.Layout.prototype.setConfigList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mcu.api.v1.LayoutCell=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mcu.api.v1.LayoutCell}
 */
proto.mcu.api.v1.Layout.prototype.addConfig = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mcu.api.v1.LayoutCell, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcu.api.v1.Layout} returns this
 */
proto.mcu.api.v1.Layout.prototype.clearConfigList = function() {
  return this.setConfigList([]);
};


/**
 * optional CellConfig cell_config = 4;
 * @return {?proto.mcu.api.v1.CellConfig}
 */
proto.mcu.api.v1.Layout.prototype.getCellConfig = function() {
  return /** @type{?proto.mcu.api.v1.CellConfig} */ (
    jspb.Message.getWrapperField(this, proto.mcu.api.v1.CellConfig, 4));
};


/**
 * @param {?proto.mcu.api.v1.CellConfig|undefined} value
 * @return {!proto.mcu.api.v1.Layout} returns this
*/
proto.mcu.api.v1.Layout.prototype.setCellConfig = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcu.api.v1.Layout} returns this
 */
proto.mcu.api.v1.Layout.prototype.clearCellConfig = function() {
  return this.setCellConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcu.api.v1.Layout.prototype.hasCellConfig = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 version = 5;
 * @return {number}
 */
proto.mcu.api.v1.Layout.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Layout} returns this
 */
proto.mcu.api.v1.Layout.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 seed = 6;
 * @return {number}
 */
proto.mcu.api.v1.Layout.prototype.getSeed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Layout} returns this
 */
proto.mcu.api.v1.Layout.prototype.setSeed = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mcu.api.v1.Room.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mcu.api.v1.Room.prototype.toObject = function(opt_includeInstance) {
  return proto.mcu.api.v1.Room.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mcu.api.v1.Room} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Room.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seed: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    layout: (f = msg.getLayout()) && proto.mcu.api.v1.Layout.toObject(includeInstance, f),
    isstreaming: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mcu.api.v1.Room}
 */
proto.mcu.api.v1.Room.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mcu.api.v1.Room;
  return proto.mcu.api.v1.Room.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mcu.api.v1.Room} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mcu.api.v1.Room}
 */
proto.mcu.api.v1.Room.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeed(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 4:
      var value = new proto.mcu.api.v1.Layout;
      reader.readMessage(value,proto.mcu.api.v1.Layout.deserializeBinaryFromReader);
      msg.setLayout(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsstreaming(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mcu.api.v1.Room.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mcu.api.v1.Room.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mcu.api.v1.Room} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mcu.api.v1.Room.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeed();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getLayout();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mcu.api.v1.Layout.serializeBinaryToWriter
    );
  }
  f = message.getIsstreaming();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.mcu.api.v1.Room.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mcu.api.v1.Room} returns this
 */
proto.mcu.api.v1.Room.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 seed = 2;
 * @return {number}
 */
proto.mcu.api.v1.Room.prototype.getSeed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mcu.api.v1.Room} returns this
 */
proto.mcu.api.v1.Room.prototype.setSeed = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string user_ids = 3;
 * @return {!Array<string>}
 */
proto.mcu.api.v1.Room.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mcu.api.v1.Room} returns this
 */
proto.mcu.api.v1.Room.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mcu.api.v1.Room} returns this
 */
proto.mcu.api.v1.Room.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mcu.api.v1.Room} returns this
 */
proto.mcu.api.v1.Room.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * optional Layout layout = 4;
 * @return {?proto.mcu.api.v1.Layout}
 */
proto.mcu.api.v1.Room.prototype.getLayout = function() {
  return /** @type{?proto.mcu.api.v1.Layout} */ (
    jspb.Message.getWrapperField(this, proto.mcu.api.v1.Layout, 4));
};


/**
 * @param {?proto.mcu.api.v1.Layout|undefined} value
 * @return {!proto.mcu.api.v1.Room} returns this
*/
proto.mcu.api.v1.Room.prototype.setLayout = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mcu.api.v1.Room} returns this
 */
proto.mcu.api.v1.Room.prototype.clearLayout = function() {
  return this.setLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mcu.api.v1.Room.prototype.hasLayout = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool isStreaming = 5;
 * @return {boolean}
 */
proto.mcu.api.v1.Room.prototype.getIsstreaming = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mcu.api.v1.Room} returns this
 */
proto.mcu.api.v1.Room.prototype.setIsstreaming = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


goog.object.extend(exports, proto.mcu.api.v1);
