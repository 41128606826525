import {EnvVars} from './env';

export const stageMiniEnv: EnvVars = {
	pageShortUrl: 'dev.zu.casa',
	pageUrl: 'https://dev.zu.casa',
	apiUsersUrl: 'https://users.stage-mini.zu.casa',
	apiRoomsUrl: 'https://rooms.stage-mini.zu.casa',
	apiWsUrl: 'wss://ws.stage-mini.zu.casa/v1/connect',
	apiPaymentsUrl: 'https://payments.stage-mini.zu.casa',
	apiBabySfuUrl: 'https://babysfu.stage-mini.zu.casa',
	stripeSecret: 'pk_test_51HcDguD2alF1xQ1GEUEHjXAobdCdU4lPDU6MoIoRDov6AcaikaC4p1lehpHhwfj2p2OdZAKCg0lIXG1210DHhHnP00XXMUJ4YM',
	firebaseConfig: {
		apiKey: 'AIzaSyDYgpH9OZ0YJJ-66wS7oZ9u9m7WkIfP43I',
		authDomain: 'zucasa-stage-mini.firebaseapp.com',
		projectId: 'zucasa-stage-mini',
		storageBucket: 'zucasa-stage-mini.appspot.com',
		messagingSenderId: '474115093926',
		appId: '1:474115093926:web:781b79c48f6ac6d1c24b67'
	},
	supportedUsersVersion: '1.0.0',
	supportedRoomsVersion: '1.0.0',
	supportedPaymentsVersion: '1.0.0',
	chefsUnitedCasaId: 'b795028a-a212-4b0f-971f-bd656a749c98',
	demoEventIds: ['fe337443-4eb3-429a-a71f-5451c2852451'],
	mainPageEventId: '6beb5c39-8e77-4430-875c-259ee96ea9e8',
	apiYoutubeKey: 'AIzaSyAq0u1fXYyuQU1pLWI-764sWxxewV7FhP8',
	apiYoutubeUrl: 'https://www.googleapis.com/youtube/v3/videos',
	playlistConfig: {
		TikTok: false,
		YouTube: true,
		ZuCasa: true
	}
};
