import {EventInfo, RoomInfo} from '../../services/gRPC/rooms/models_pb';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {selectEvents} from './events';
import {RoomUserRole} from '../../services/roomServices';
import {RoomType} from '../../services/gRPC/rooms/enums_pb';

const ItemsPerPage = 5;

export interface RoomsState {
	visitedRooms: RoomInfo.AsObject[]
	ownRooms: RoomInfo.AsObject[]
	broadcastRoom: RoomInfo.AsObject | undefined
	totalVisitedRooms: number,
	totalOwnRooms: number,
	currentVisitedRoomsPage: number,
	currentOwnRoomsPage: number
	alreadyLoadedVisitedRooms: boolean
	alreadyLoadedOwnRooms: boolean
}

export const initialState: RoomsState = {
	visitedRooms: [],
	ownRooms: [],
	broadcastRoom: undefined,
	totalVisitedRooms: 0,
	totalOwnRooms: 0,
	currentVisitedRoomsPage: 0,
	currentOwnRoomsPage: 0,
	alreadyLoadedVisitedRooms: false,
	alreadyLoadedOwnRooms: false,
};

const distinctRoomObjects = (rooms: RoomInfo.AsObject[]) => {
	return [...new Map(rooms.map(item =>
		[item.id, item])).values()];
};

export const roomsSlice = createSlice({
	name: 'rooms',
	initialState,
	reducers: {
		addRoom: (state, action: PayloadAction<RoomInfo.AsObject>) => {
			if (action.payload.user?.role !== RoomUserRole.HOST) {
				const updatedVisitedRooms = distinctRoomObjects([...state.visitedRooms, action.payload]);
				if (updatedVisitedRooms.length > state.totalVisitedRooms) {
					state.totalVisitedRooms = updatedVisitedRooms.length;
				}
				state.visitedRooms = updatedVisitedRooms;
			}

			if (action.payload.user?.role === RoomUserRole.HOST) {
				const updatedOwnRooms = distinctRoomObjects([...state.ownRooms, action.payload]);
				if (updatedOwnRooms.length > state.totalOwnRooms) {
					state.totalOwnRooms = updatedOwnRooms.length;
				}
				state.ownRooms = updatedOwnRooms;
			}
		},
		addRooms: (state, action: PayloadAction<RoomInfo.AsObject[]>) => {
			const newVisitedRooms = action.payload.filter((room) => room.user?.role !== RoomUserRole.HOST);
			if (newVisitedRooms.length) {
				const updatedVisitedRooms = distinctRoomObjects([...state.visitedRooms, ...newVisitedRooms]);
				if (updatedVisitedRooms.length > state.totalVisitedRooms) {
					state.totalVisitedRooms = updatedVisitedRooms.length;
				}
				state.visitedRooms = updatedVisitedRooms;
			}

			const newOwnRooms = action.payload.filter((room) => room.user?.role === RoomUserRole.HOST);
			if (newOwnRooms.length) {
				const updatedOwnRooms = distinctRoomObjects([...state.ownRooms, ...newOwnRooms]);
				if (updatedOwnRooms.length > state.totalOwnRooms) {
					state.totalOwnRooms = updatedOwnRooms.length;
				}
				state.ownRooms = updatedOwnRooms;
			}
		},
		updateRoom: (state, action: PayloadAction<RoomInfo.AsObject>) => {
			if (action.payload.user?.role !== RoomUserRole.HOST) {
				state.visitedRooms = state.visitedRooms.filter((room) => room.id !== action.payload.id);
				state.visitedRooms.push(action.payload);
			}

			if (action.payload.user?.role === RoomUserRole.HOST) {
				state.ownRooms = state.ownRooms.filter((room) => room.id !== action.payload.id);
				state.ownRooms.push(action.payload);
			}
		},
		updateRoomEvent: (state, action: PayloadAction<{ eventInfo: EventInfo.AsObject | undefined, roomId: string }>) => {
			let object = state.visitedRooms.find((room) => room.id !== action.payload.roomId);
			if (object) {
				object.event = action.payload.eventInfo;
				state.visitedRooms.push(object);
			}

			object = state.ownRooms.find((room) => room.id !== action.payload.roomId);
			if (object) {
				object.event = action.payload.eventInfo;
				state.ownRooms.push(object);
			}
		},
		deleteRoom: (state, action: PayloadAction<{ id: string }>) => {
			const filteredVisitedRooms = state.visitedRooms.filter((room) => room.id !== action.payload.id);
			if (filteredVisitedRooms.length < state.visitedRooms.length) {
				state.visitedRooms = filteredVisitedRooms
				if (state.totalVisitedRooms) {
					state.totalVisitedRooms -= 1;
				}
			}

			/// if there is no items on current page, go back to previous one
			if (state.currentVisitedRoomsPage > 0 && Math.ceil(state.visitedRooms.length / ItemsPerPage) - 1 !== state.currentVisitedRoomsPage) {
				state.currentVisitedRoomsPage -= 1;
			}

			const filteredOwnRooms = state.ownRooms.filter((room) => room.id !== action.payload.id);
			if (filteredOwnRooms.length < state.ownRooms.length) {
				state.ownRooms = filteredOwnRooms
				if (state.totalOwnRooms) {
					state.totalOwnRooms -= 1;
				}
			}

			/// if there is no items on current page, go back to previous one
			if (state.currentOwnRoomsPage > 0 && Math.ceil(state.visitedRooms.length / ItemsPerPage) - 1 !== state.currentOwnRoomsPage) {
				state.currentOwnRoomsPage -= 1;
			}
		},
		setTotalVisitedRooms: (state, action: PayloadAction<number>) => {
			state.totalVisitedRooms = action.payload;
		},
		setTotalOwnRooms: (state, action: PayloadAction<number>) => {
			state.totalOwnRooms = action.payload;
		},
		setCurrentVisitedRoomsPage: (state, action: PayloadAction<number>) => {
			state.currentVisitedRoomsPage = action.payload;
		},
		setCurrentOwnRoomsPage: (state, action: PayloadAction<number>) => {
			state.currentOwnRoomsPage = action.payload;
		},
		setBroadcastRoom: (state, action: PayloadAction<RoomInfo.AsObject>) => {
			state.broadcastRoom = action.payload
		},
		setAlreadyLoadedVisitedRooms: (state, action: PayloadAction<boolean>) => {
			state.alreadyLoadedVisitedRooms = action.payload
		},
		setAlreadyLoadedOwnRooms: (state, action: PayloadAction<boolean>) => {
			state.alreadyLoadedOwnRooms = action.payload
		},
	}
});

export const roomsActions = roomsSlice.actions;
export const selectVisitedRooms = (state: RootState) => [...state.rooms.visitedRooms];
export const selectOwnRooms = (state: RootState) => [...state.rooms.ownRooms];
export const selectTotalVisitedRooms = (state: RootState) => state.rooms.totalVisitedRooms;
export const selectTotalOwnRooms = (state: RootState) => state.rooms.totalOwnRooms;
export const selectCurrentVisitedRoomsPage = (state: RootState) => state.rooms.currentVisitedRoomsPage;
export const selectCurrentOwnRoomsPage = (state: RootState) => state.rooms.currentOwnRoomsPage;
export const selectClientBroadcastRoom = (state: RootState) => state.rooms.broadcastRoom;
export const selectAlreadyLoadedVisitedRooms = (state: RootState) => state.rooms.alreadyLoadedVisitedRooms;
export const selectAlreadyLoadedOwnRooms = (state: RootState) => state.rooms.alreadyLoadedOwnRooms;

export const selectRooms = (state: RootState) => {
	const result = distinctRoomObjects([...state.rooms.visitedRooms, ...state.rooms.ownRooms]);
	if (state.rooms.broadcastRoom) {
		result.push(state.rooms.broadcastRoom)
	}
	return result
}

export const selectRoomById = (id: string) => {
	return createSelector(
		selectRooms,
		rooms => rooms.find((room) => room.id === id)
	);
};

export const selectRecentVisitedRoomsByIndexes = (start: number, end: number) => createSelector(
	selectVisitedRooms,
	rooms => rooms.sort((a, b) => a!.user!.updatedAt > b!.user!.updatedAt ? -1 : 1)
		.filter((room) => room.type !== RoomType.RTYPE_BROADCAST)
		.slice(start, end)
);
export const selectRecentOwnRoomsByIndexes = (start: number, end: number) => {
	return createSelector(
		selectOwnRooms,
		rooms => rooms.sort((a, b) => a!.user!.updatedAt > b!.user!.updatedAt ? -1 : 1)
			.filter((room) => room.type !== RoomType.RTYPE_BROADCAST)
			.slice(start, end)
	);
};

export const selectRoomWithEventId = (eventId: string) => {
	return createSelector(
		selectRooms,
		selectEvents,
		rooms => rooms.find(room => room.user?.role === RoomUserRole.HOST && room.event?.id === eventId)
	);
};
