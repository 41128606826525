import {EventAccess} from '../../../../services/gRPC/rooms/enums_pb';

///
/// EventAccesses List
///

export const eventAccesses: EventAccess[] = [
	EventAccess.EACCESS_PUBLIC,
	EventAccess.EACCESS_PRIVATE,
];

///
/// EventAccess to String
///

export const accessToString = (genre: EventAccess) => {
	switch (genre) {
		case EventAccess.EACCESS_PUBLIC: {
			return 'Public Event';
		}
		case EventAccess.EACCESS_PRIVATE: {
			return 'Private Event';
		}
		case EventAccess.EACCESS_UNKNOWN: {
			return 'Unknown';
		}
		default: {
			return 'Unknown';
		}
	}
};

///
/// EventAccesses String List Type
///

export type EventAccessName = ReturnType<typeof accessToString>

///
/// Access string to eventAccess
///

export const accessStringToEnum = (access: EventAccessName) => {
	switch (access) {
		case 'Public Event': {
			return EventAccess.EACCESS_PUBLIC;
		}
		case 'Private Event': {
			return EventAccess.EACCESS_PRIVATE;
		}
		case 'Unknown': {
			return EventAccess.EACCESS_UNKNOWN;
		}
		default: {
			return EventAccess.EACCESS_UNKNOWN;
		}
	}
};


