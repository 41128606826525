import {createEntityAdapter, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
	RoomStreamType,
	RoomUserFlag,
	RoomUserReceiverConnectionStatus,
	RoomUserSenderConnectionStatus,
	RoomUserStatus
} from '../../services/gRPC/rooms/enums_pb';
import {RoomUserRole, UserRole} from '../../services/roomServices';
import {AppEpic, RootState} from '../store';
import {roomGlobalRef, RoomMode} from '../../pages/Room/utils/roomGlobalRef';
import {RoomState, RoomUserInfo} from '../../services/gRPC/rooms/models_pb';
import {UserInfo} from '../../services/gRPC/users/models_pb';
import {selectClientId, selectClientRole, userActions} from './user';
import {batchedFilter} from '../utlis';
import {filter, map} from 'rxjs/operators';
import {batchActions} from 'redux-batched-actions';
import {LayoutCell} from '../../services/gRPC/mcu/models_pb';
import {convertPingToDashes} from '../../utils/utils';
import {selectCurrentRoomStreamType, selectHasEventStarted, selectIsPlaylistActive} from './roomStreams';
import {selectIsGridLayoutForced, selectIsPerformerRoom} from './room';
import {objDiff} from '../../utils/objDiff';

const createEmptyUser = (id: string, override?: Partial<RoomUser>) => {
	const {connection, media, ...rest} = override || {};
	return {
		profileImage: '',
		nickname: '',
		globalRole: UserRole.GUEST,
		roomRole: RoomUserRole.USER,
		id,
		flags: {},
		appliedFlags: {},
		alreadyJoined: false,
		isLocallyMuted: false,
		isLocallyVideoHidden: false,
		media: {
			cameraTrackId: '',
			audioTrackId: '',
			screenVideoTrackId: '',
			screenAudioTrackId: '',
			...(media && {...media})
		},
		connection: {
			websocket: {
				isConnected: false,
				isReconnecting: false,
				...(connection?.websocket && {...connection.websocket})
			},
			receiver: {
				isConnected: false,
				isReconnecting: false,
				...(connection?.receiver && {...connection.receiver})
			},
			sender: {
				isConnected: false,
				isReconnecting: false,
				...(connection?.sender && {...connection.sender})
			}
		},
		...(rest && {...rest})
	};
};

const createUser = (userInfo: UserInfo.AsObject, roomUserInfo: RoomUserInfo.AsObject, override?: Partial<RoomUser>) => {
	const {connection, media, flags: oldFlags, appliedFlags: oldAppliedFlags, ...rest} = override || {};
	const flags: { [key in RoomUserFlag]?: number } = {};
	const appliedFlags: { [key in RoomUserFlag]?: number } = {};

	roomUserInfo.flagsList.forEach(flag => {
		flags[flag] = Date.now();
		if ([RoomUserFlag.RUFLAG_DISABLED_AUDIO, RoomUserFlag.RUFLAG_DISABLED_VIDEO].includes(flag)) {
			appliedFlags[flag] = Date.now();
		}
	});

	return {
		...createEmptyUser(userInfo.id, {media}),
		profileImage: userInfo.avatarUrl,
		nickname: userInfo.role === UserRole.GUEST ? 'Guest' : userInfo.name,
		globalRole: userInfo.role,
		roomRole: roomUserInfo.role,
		flags,
		appliedFlags,
		alreadyJoined: true,
		connection: {
			websocket: {
				isConnected: true,
				isReconnecting: roomUserInfo.status === RoomUserStatus.RUSTATUS_RECONNECTING
			},
			receiver: {
				isConnected: roomUserInfo.receiverConnectionStatus === RoomUserReceiverConnectionStatus.RURCSTATUS_CONNECTED || roomUserInfo.receiverConnectionStatus === RoomUserReceiverConnectionStatus.RURCSTATUS_RECONNECTING,
				isReconnecting: roomUserInfo.receiverConnectionStatus === RoomUserReceiverConnectionStatus.RURCSTATUS_RECONNECTING
			},
			sender: {
				isConnected: roomUserInfo.senderConnectionStatus === RoomUserSenderConnectionStatus.RUSCSTATUS_CONNECTED || roomUserInfo.senderConnectionStatus === RoomUserSenderConnectionStatus.RUSCSTATUS_RECONNECTING,
				isReconnecting: roomUserInfo.senderConnectionStatus === RoomUserSenderConnectionStatus.RUSCSTATUS_RECONNECTING
			}
		},
		...(rest && {...rest})
	};
};

export interface RoomUserConnection {
	websocket: {
		isConnected: boolean
		isReconnecting: boolean
	};
	receiver: {
		isConnected: boolean
		isReconnecting: boolean
	};
	sender: {
		isConnected: boolean
		isReconnecting: boolean
	};
}

export interface RoomUserMedia {
	cameraTrackId: string,
	audioTrackId: string,
	screenVideoTrackId: string,
	screenAudioTrackId: string,
}

export type MediaStreamType = 'audio' | 'camera' | 'screenVideo' | 'screenAudio';

export interface RoomUser {
	id: string,
	nickname: string,
	profileImage: string,
	globalRole: UserRole,
	roomRole: RoomUserRole,
	media: RoomUserMedia,
	flags: { [key in RoomUserFlag]?: number }
	appliedFlags: { [key in RoomUserFlag]?: number }
	alreadyJoined: boolean
	isLocallyMuted: boolean
	isLocallyVideoHidden: boolean,
	connection: RoomUserConnection
	//if grid position is set it means that mcu mode is active
	gridPosition?: number
}

const roomUserAdapter = createEntityAdapter<RoomUser>({
	selectId: (user) => user.id
});

const initialState = {
	...roomUserAdapter.getInitialState(),
	speakDetections: {} as { [key: string]: boolean },
	volumes: {} as { [key: string]: number },
	bitRates: {} as { [key: string]: number },
	pings: {} as { [key: string]: number }
};

export const roomUsersSlice = createSlice({
	name: 'roomUser',
	initialState,
	reducers: {
		clearSlice: () => {
			return initialState;
		},
		upsertUsersBasedOnResponses: (state, action: PayloadAction<{ roomState: RoomState.AsObject, userInfoList: UserInfo.AsObject[] }>) => {
			console.log(JSON.parse(JSON.stringify(state.entities)), 'entities');
			const roomState = action.payload.roomState;
			const userInfoList = action.payload.userInfoList;

			if (state.entities) { // TODO: check why sometimes list of user ids doesn't have all records from 'entities'
				Object.keys(state.entities).forEach((id) => {
					console.log(id);
					if (!roomState.usersList.find(u => u.userId === id)) {
						console.log(`deleting ${id}`);
						delete state.speakDetections[id];
						delete state.bitRates[id];
						delete state.entities[id];
						state.ids.splice(state.ids.indexOf(id), 1);
					}
				});
			}

			// state.ids.forEach(id => {
			// 	console.log(id);
			// 	if(!roomState.usersList.find(u => u.userId === id)) {
			// 		console.log(`deleting ${id}`)
			// 		delete state.speakDetections[id];
			// 		delete state.bitRates[id];
			// 		delete state.entities[id];
			// 		state.ids.splice(state.ids.indexOf(id), 1);a
			// 	}
			// })

			const roomUsers = roomState.usersList.reduce((acc: RoomUser[], roomUserInfo) => {
				const userInfo = userInfoList.find(userInfo => userInfo.id === roomUserInfo.userId);
				if (userInfo) {
					const override = state.entities[userInfo.id] ? objDiff(createEmptyUser(userInfo.id), state.entities[userInfo.id]) : undefined;
					const user = createUser(userInfo, roomUserInfo, override);
					return [...acc, user];
				} else {
					return acc;
				}
			}, []);

			roomUsers.forEach((newUser) => {
				const user = !!state.entities[newUser.id];
				if (user) {
					state.entities[newUser.id] = newUser;
				} else {
					state.speakDetections[newUser.id] = false;
					state.bitRates[newUser.id] = 0;
					state.volumes[newUser.id] = 100;
					state.entities[newUser.id] = newUser;
					state.ids.push(newUser.id);
				}
			});
		},
		upsertUser: (state, action: PayloadAction<{ userInfo: UserInfo.AsObject, roomUserInfo: RoomUserInfo.AsObject }>) => {
			const {userInfo, roomUserInfo} = action.payload;

			const override = state.entities[userInfo.id] ? objDiff(createEmptyUser(userInfo.id), state.entities[userInfo.id]) : undefined;
			const user = createUser(userInfo, roomUserInfo, override);
			console.log(user);

			if (state.entities[userInfo.id]) {
				// NO IDEA WHY WE HAVE THIS LINE, UNCOMMENT IF NEEDED
				// user.flags[RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO] = undefined;
				state.entities[userInfo.id] = user;
			} else {
				state.speakDetections[user.id] = false;
				state.bitRates[user.id] = 0;
				state.volumes[user.id] = 100;
				state.entities[user.id] = user;
				state.ids.push(user.id);
			}
		},
		removeUser: (state, action: PayloadAction<string>) => {
			const id = action.payload;
			const user = state.entities[id];
			if (user) {
				delete state.speakDetections[id];
				delete state.bitRates[id];
				delete state.entities[id];
				state.ids.splice(state.ids.indexOf(id), 1);
			} else {
				console.error('Cannot remove not existing user');
			}
		},
		//TODO timestamp is no needed more remove it
		addUserFlags: (state, action: PayloadAction<{ userId: string, flags: RoomUserFlag[], timestamp: number }>) => {
			const {userId, flags, timestamp} = action.payload;
			const user = state.entities[userId];
			if (user) {
				flags.forEach(flagToAdd => {
					user.flags[flagToAdd] = timestamp;
				});
			} else {
				const user = createEmptyUser(userId) as RoomUser;
				flags.forEach(flagToAdd => {
					user.flags[flagToAdd] = timestamp;
				});
				state.speakDetections[user.id] = false;
				state.bitRates[user.id] = 0;
				state.volumes[user.id] = 100;
				state.entities[user.id] = user;
				!state.ids.includes(user.id) && state.ids.push(user.id);
			}
		},
		removeUserFlags: (state, action: PayloadAction<{ userId: string, flags: RoomUserFlag[] }>) => {
			const {userId, flags} = action.payload;
			const user = state.entities[userId];
			if (user) {
				flags.forEach(flagToRemove => {
					delete user.flags[flagToRemove];
				});
			} else {
				console.error('Cannot remove flags for not existing user');
			}
		},
		applyUserFlags: (state, action: PayloadAction<{ userId: string, flags: RoomUserFlag[], timestamp: number }>) => {
			const {userId, flags, timestamp} = action.payload;
			const user = state.entities[userId];
			if (user) {
				flags.forEach(flagToAdd => {
					user.appliedFlags[flagToAdd] = timestamp;
				});
			} else {
				console.error('Cannot apply flags for not existing user');
			}
		},
		rejectUserFlags: (state, action: PayloadAction<{ userId: string, flags: RoomUserFlag[] }>) => {
			const {userId, flags} = action.payload;
			const user = state.entities[userId];
			if (user) {
				flags.forEach(flagToRemove => {
					delete user.appliedFlags[flagToRemove];
				});
			} else {
				console.error('Cannot reject flags for not existing user');
			}
		},
		clearUserFlags: (state, action: PayloadAction<{ userId: string }>) => {
			const {userId} = action.payload;
			const user = state.entities[userId];
			if (user) {
				user.flags = {};
				user.appliedFlags = {};
			} else {
				console.error('Cannot remove flags for not existing user');
			}
		},
		setUserMediaTrackId: (state, action: PayloadAction<{
			userId: string,
			trackId: string,
			type: 'audio' | 'camera' | 'screenVideo' | 'screenAudio'
		}>) => {
			const {userId, trackId, type} = action.payload;
			const user = state.entities[userId];
			if (user) {
				const {media} = user;
				if (type === 'audio') {
					media.audioTrackId = trackId;
				} else if (type === 'camera') {
					media.cameraTrackId = trackId;
				} else if (type === 'screenVideo') {
					media.screenVideoTrackId = trackId;
				} else {
					media.screenAudioTrackId = trackId;
				}
			} else {
				console.error('Cannot set mediaTrackId for not existing user');
			}
		},
		clearRoomUsersMediaTracks: (state, action: PayloadAction<{ excludedId: string }>) => {
			state.ids.forEach(id => {
				const user = state.entities[id];
				if (user && user.id !== action.payload.excludedId) {
					user.media = {
						audioTrackId: '',
						cameraTrackId: '',
						screenAudioTrackId: '',
						screenVideoTrackId: ''
					};
				}
			});
		},
		clearRoomUserMediaTracks: (state, action: PayloadAction<{ userId: string }>) => {
			const {userId} = action.payload;
			const user = state.entities[userId];
			if (user) {
				user.media = {
					audioTrackId: '',
					cameraTrackId: '',
					screenAudioTrackId: '',
					screenVideoTrackId: ''
				};
			} else {
				console.error('Cannot clear user media tracks for not existing user');
			}
		},
		removeUserMediaTrack: (state, action: PayloadAction<{ userId: string, type: 'audio' | 'camera' | 'screenVideo' | 'screenAudio' }>) => {
			const {userId, type} = action.payload;
			const user = state.entities[userId];
			if (user) {
				if (type === 'audio') {
					user.media.audioTrackId = '';
				} else if (type === 'camera') {
					user.media.cameraTrackId = '';
				} else if (type === 'screenVideo') {
					user.media.screenVideoTrackId = '';
				} else {
					user.media.screenAudioTrackId = '';
				}
			} else {
				console.error('Cannot remove mediaStreams for not existing user');
			}
		},
		toggleLocalVideoOff: (state, action: PayloadAction<string>) => {
			const userId = action.payload;
			const user = state.entities[userId];
			if (user) {
				user.isLocallyVideoHidden = !user.isLocallyVideoHidden;
			} else {
				console.error('Cannot toggle local video for not existing user');
			}
		},
		toggleLocalMute: (state, action: PayloadAction<string>) => {
			const userId = action.payload;
			const user = state.entities[userId];
			if (user) {
				user.isLocallyMuted = !user.isLocallyMuted;
			} else {
				console.error('Cannot toggle local mute for not existing user');
			}
		},
		setBitrates: (state, action: PayloadAction<{ [key: string]: number }>) => {
			state.bitRates = action.payload;
		},
		setUserVolume: (state, action: PayloadAction<{ userId: string, volume: number }>) => {
			const {userId, volume} = action.payload;
			if (state.volumes[userId] !== undefined) {
				state.volumes[userId] = volume;
			} else {
				console.error('Cannot set volume for not existing user');
			}
		},
		setUserSpeakDetection: (state, action: PayloadAction<{ userId: string, isSpeaking: boolean }>) => {
			const {userId, isSpeaking} = action.payload;
			if (state.speakDetections[userId] !== undefined) {
				state.speakDetections[userId] = isSpeaking;
			} else {
				console.error('Cannot set speak detection for not existing user');
			}
		},
		setUserPing: (state, action: PayloadAction<{ userId: string, ping: number }>) => {
			const {userId, ping} = action.payload;
			state.pings[userId] = ping;
		},
		changeUserRole: (state, action: PayloadAction<{ userId: string, role: RoomUserRole }>) => {
			const {userId, role} = action.payload;
			const user = state.entities[userId];
			if (user) {
				user.roomRole = role;
			}
		},
		updateRoomUserStatus: (state, action: PayloadAction<{ userId: string, status: RoomUserStatus }>) => {
			const {userId, status} = action.payload;
			const user = state.entities[userId];
			if (user) {
				if (status === RoomUserStatus.RUSTATUS_CONNECTED) {
					user.connection.websocket.isConnected = true;
					user.connection.websocket.isReconnecting = false;
				} else if (status === RoomUserStatus.RUSTATUS_RECONNECTING) {
					user.connection.websocket.isConnected = true;
					user.connection.websocket.isReconnecting = true;
				} else if (user.connection.websocket.isConnected) {
					user.connection.websocket.isReconnecting = true;
				}
			} else {
				console.error('Cannot change roomStatus for not existing user');
			}
		},
		updateRoomUserReceiverConnectionStatus: (state, action: PayloadAction<{ userId: string, receiverConnectionStatus: RoomUserReceiverConnectionStatus }>) => {
			const {userId, receiverConnectionStatus} = action.payload;
			const user = state.entities[userId];
			if (user) {
				if (receiverConnectionStatus === RoomUserReceiverConnectionStatus.RURCSTATUS_CONNECTED) {
					user.connection.receiver.isConnected = true;
					user.connection.receiver.isReconnecting = false;
				} else if (receiverConnectionStatus === RoomUserReceiverConnectionStatus.RURCSTATUS_RECONNECTING) {
					user.connection.receiver.isConnected = true;
					user.connection.receiver.isReconnecting = true;
				} else if (user.connection.receiver.isConnected) {
					user.connection.receiver.isReconnecting = true;
				}
			} else {
				console.error('Cannot change receiverRoomStatus for not existing user');
			}
		},
		updateRoomUserSenderConnectionStatus: (state, action: PayloadAction<{ userId: string, senderConnectionStatus: RoomUserSenderConnectionStatus }>) => {
			const {userId, senderConnectionStatus} = action.payload;
			const user = state.entities[userId];
			if (user) {
				if (senderConnectionStatus === RoomUserSenderConnectionStatus.RUSCSTATUS_CONNECTED) {
					user.connection.sender.isConnected = true;
					user.connection.sender.isReconnecting = false;
				} else if (senderConnectionStatus === RoomUserSenderConnectionStatus.RUSCSTATUS_RECONNECTING) {
					user.connection.sender.isConnected = true;
					user.connection.sender.isReconnecting = true;
				} else if (user.connection.sender.isConnected) {
					user.connection.sender.isReconnecting = true;
				}
			} else {
				console.error('Cannot change senderRoomStatus for not existing user');
			}
		},
		setUsersPositions: (state, action: PayloadAction<LayoutCell.AsObject[] | undefined>) => {
			const layoutCells = action.payload;
			if (layoutCells) {
				layoutCells.forEach(cell => {
					const user = state.entities[cell.userId];
					if (user) {
						user.gridPosition = cell.position;
					} else {
						console.error('Cannot set grid position for not existing user');
					}
				});
			} else {
				state.ids.forEach(id => {
					state.entities[id]!.gridPosition = undefined;
				});
			}
		}
	}
});

export const setOnClientMediaInUserStoreEpic: AppEpic = (action$, state$) => {
	return action$.pipe(
		batchedFilter(roomUsersActions.addUserFlags),
		filter(action => action.payload.userId === selectClientId(state$.value)),
		map((action) => {

			const mute = action.payload.flags.includes(RoomUserFlag.RUFLAG_DISABLED_AUDIO);
			const camOff = action.payload.flags.includes(RoomUserFlag.RUFLAG_DISABLED_VIDEO);
			const sharingScreenVideo = action.payload.flags.includes(RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO);
			const sharingScreenAudio = action.payload.flags.includes(RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO);
			const actions = [];

			mute && actions.push(userActions.setIsMuted(true));
			camOff && actions.push(userActions.setIsCamOff(true));
			sharingScreenVideo && actions.push(userActions.setIsSharingScreenVideo(true));
			sharingScreenAudio && actions.push(userActions.setIsSharingScreenAudio(true));
			return batchActions(actions);
		})
	);
};

export const setOffClientMediaInUserStoreEpic: AppEpic = (action$, state$) => {
	return action$.pipe(
		batchedFilter(roomUsersActions.removeUserFlags),
		filter(action => action.payload.userId === selectClientId(state$.value)),
		map((action) => {
			const mute = action.payload.flags.includes(RoomUserFlag.RUFLAG_DISABLED_AUDIO);
			const camOff = action.payload.flags.includes(RoomUserFlag.RUFLAG_DISABLED_VIDEO);
			const sharingScreenVideo = action.payload.flags.includes(RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO);
			const sharingScreenAudio = action.payload.flags.includes(RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO);
			const actions = [];
			mute && actions.push(userActions.setIsMuted(false));
			camOff && actions.push(userActions.setIsCamOff(false));
			sharingScreenVideo && actions.push(userActions.setIsSharingScreenVideo(false));
			sharingScreenAudio && actions.push(userActions.setIsSharingScreenAudio(false));
			return batchActions(actions);
		})
	);
};

export const selectIsDashboardMode = (state: RootState) => state.view.dashboardMode;

export const roomUsersActions = roomUsersSlice.actions;
export const selectRoomUsers = roomUserAdapter.getSelectors<RootState>((state) => state.roomUsers).selectAll;
export const selectRoomUsersAmount = roomUserAdapter.getSelectors<RootState>((state) => state.roomUsers).selectTotal;
export const selectRoomUsersEntities = roomUserAdapter.getSelectors<RootState>((state) => state.roomUsers).selectEntities;
export const selectRoomUser = roomUserAdapter.getSelectors<RootState>((state) => state.roomUsers).selectById;

export const selectRoomUsersSorted = createSelector(
	selectRoomUsers,
	users => (users.sort((a, b) => {
		if (a.id < b.id) return -1;
		if (a.id > b.id) return 1;
		return 0;
	}))
);

export const selectRoomUsersWithoutHostAndGuestsAndClient = createSelector(
	selectClientId,
	selectRoomUsers,
	(clientId, users) => (users.filter((user) => user.roomRole !== RoomUserRole.HOST && user.globalRole !== UserRole.GUEST && user.id !== clientId))
);

export const selectRoomRole = (userId: string) => {
	return createSelector(
		selectRoomUsersEntities,
		users => {
			return users[userId]?.roomRole;
		}
	);
};
export const selectUserFlags = (userId: string) => {
	return createSelector(
		selectRoomUsersEntities,
		users => {
			return users[userId]?.flags;
		}
	);
};

export const selectAppliedFlags = (userId: string) => {
	return createSelector(
		selectRoomUsersEntities,
		users => {
			return users[userId]?.appliedFlags;
		}
	);
};
export const selectClientFlags = createSelector(
	selectClientId,
	selectRoomUsersEntities,
	(id, users) => {
		return users[id]?.flags;
	}
);
export const selectIsUserMuted = (userId: string) => createSelector(
	selectRoomUsersEntities,
	(users) => {
		return !!users[userId]?.appliedFlags[RoomUserFlag.RUFLAG_DISABLED_AUDIO];
	}
);
export const selectHasUserCamOff = (userId: string) => createSelector(
	selectRoomUsersEntities,
	(users) => {
		return !!users[userId]?.appliedFlags[RoomUserFlag.RUFLAG_DISABLED_VIDEO];
	}
);
export const selectScreenSharingUserId = createSelector(
	selectRoomUsers,
	users => users.find(user => !!user.appliedFlags[RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO] && user.alreadyJoined)?.id
);
export const selectScreenSharingUserName = createSelector(
	selectRoomUsers,
	users => users.find(user => !!user.appliedFlags[RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO] && user.alreadyJoined)?.nickname
);
export const selectIsUserSpeaking = (userId: string) => {
	return (state: RootState) => {
		return state.roomUsers.speakDetections[userId];
	};
};

export const selectUserPing = (userId: string) => {
	return (state: RootState) => {
		return state.roomUsers.pings[userId];
	};
};

export const selectUserPingRange = (userId: string) => {
	return (state: RootState) => {
		const ping = state.roomUsers.pings[userId];
		if (state.user.userInfo?.id !== userId) {
			return convertPingToDashes(ping);
		}

		return convertPingToDashes(Math.max(ping, state.room.serverPing));
	};
};

export const selectLeftPanelHiddenUsers = createSelector(
	selectRoomUsers,
	roomUsers => {
		return roomUsers.filter(user => (!user.media.cameraTrackId ||
			user.appliedFlags[RoomUserFlag.RUFLAG_DISABLED_VIDEO] ||
			user.isLocallyVideoHidden) && user.alreadyJoined).sort((a, b) => a.id < b.id ? -1 : 1);
	});

export const selectLeftPanelAllUsers = createSelector(
	selectRoomUsers,
	roomUsers => {
		return roomUsers.filter(user => user.alreadyJoined).sort((a, b) => a.id < b.id ? -1 : 1);
	});

export const selectUserBitrates = (userId: string) => {
	return (state: RootState) => {
		return state.roomUsers.bitRates[userId];
	};
};
export const selectRoomUserVolume = (userId: string) => {
	return (state: RootState) => {
		return state.roomUsers.volumes[userId];
	};
};

export const selectRoomUserMediaTrackId = (userId: string, type: MediaStreamType) => createSelector(
	selectRoomUsers,
	roomUsers => roomUsers.find(u => u.id === userId)?.media?.[`${type}TrackId`] || ''
);

export const selectIsRoomUserConnecting = (userId: string) => createSelector(
	selectRoomUsers,
	(users) => {
		const user = users.find((user) => user.id === userId);
		if (user) {
			if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
				return !user.connection.receiver.isConnected || !user.connection.sender.isConnected;
			} else {
				return !user.connection.sender.isConnected;

			}
		}
	}
);

export const selectIsRoomUserReconnecting = (userId: string) => createSelector(
	selectRoomUsers,
	(users) => {
		const user = users.find((user) => user.id === userId);
		if (user) {
			if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
				return user.connection.receiver.isReconnecting
					|| user.connection.sender.isReconnecting
					|| user.connection.websocket.isReconnecting;
			} else {
				return user.connection.sender.isReconnecting
					|| user.connection.websocket.isReconnecting;
			}
		}
	}
);

export const selectIsRoomUserSenderReconnecting = (userId: string) => createSelector(
	selectRoomUsers,
	(users) => {
		const user = users.find((user) => user.id === userId);
		if (user) {
			return user.connection.sender.isReconnecting;
		}
	}
);

export const selectRoomUserPosition = (userId: string) => {
	return createSelector(
		selectRoomUsersEntities,
		users => {
			return users[userId]?.gridPosition;
		}
	);
};

export const selectClientVideoStreamId = createSelector(
	selectClientId,
	selectRoomUsers,
	(clientId, users) => {
		const client = users.find((user) => user.id === clientId);
		return client?.media.cameraTrackId;
	}
);

export const selectIsAdminOrRoomHost = createSelector(
	selectClientId,
	selectRoomUsersEntities,
	selectClientRole,
	(clientId, roomusers, globalRole) => {
		const roomRole = roomusers[clientId]?.roomRole;
		return (roomRole !== undefined ? roomRole >= RoomUserRole.ADMIN : false) || globalRole >= UserRole.ADMIN;
	}
);

export const selectCanControlPlaylist = createSelector(
	selectClientId,
	selectRoomUsersEntities,
	selectClientRole,
	(clientId, roomUsers, globalRole) => {
		const roomRole = roomUsers[clientId]?.roomRole;
		return (roomRole !== undefined ? roomRole >= RoomUserRole.MODERATOR : false) || globalRole >= UserRole.ADMIN;
	}
);

export const selectIsWatchingMode = createSelector(selectIsGridLayoutForced, selectIsPlaylistActive, selectScreenSharingUserId, selectHasEventStarted, selectIsDashboardMode,
	(isGridLayoutForced, isPlaylistActive, screenSharingUserId, hasEventStarted, isDashboardMode) => {
		return !isGridLayoutForced && !isDashboardMode && (isPlaylistActive || !!screenSharingUserId || hasEventStarted);
	}
);

export const selectIsGridRendered = createSelector(selectIsGridLayoutForced, selectScreenSharingUserId, selectIsPlaylistActive, selectCurrentRoomStreamType, selectHasEventStarted, selectIsPerformerRoom,
	(isGridLayoutForced, screenSharingUserId, isPlaylistActive, currentRoomStreamType, hasEventStarted, isPerformerRoom) => {
		return isGridLayoutForced
			|| (!screenSharingUserId && (!isPlaylistActive || (isPlaylistActive && currentRoomStreamType === RoomStreamType.RSTYPE_EVENT && !hasEventStarted))) && !isPerformerRoom
			|| (isPerformerRoom && !hasEventStarted);
	}
);

export const selectRoomUsersWithCamsAmount = createSelector(
	selectRoomUsers,
	users => {
		return users.reduce((acc, x) => acc + Number(!!x.appliedFlags[RoomUserFlag.RUFLAG_SHARING_VIDEO] && !x.appliedFlags[RoomUserFlag.RUFLAG_DISABLED_VIDEO]), 0);
	}
);
