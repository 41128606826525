import {StreamType} from '../../../services/gRPC/sfu/enums_pb';
import {RoomUserFlag} from '../../../services/gRPC/rooms/enums_pb';
import {RoomUser} from '../../../store/slices/roomUsers';
import {ActiveStreamsResponsePacket} from '../../../services/gRPC/sfu/packets_pb';

export class SubscriptionState {

	private readonly waitForBase: boolean;
	private isBaseSet = false;
	private audioSubsSet = new Set<string>();
	private cameraSubsSet = new Set<string>();
	private screenSubsSet = new Set<string>();
	private screenAudioSubsSet = new Set<string>();
	private actionQueue: { action: 'sub' | 'unsub', streamType: StreamType, userId: string }[] = []; // used when base is not set

	constructor(waitForBase = false) {
		this.waitForBase = waitForBase;
		this.isBaseSet = !waitForBase;
	}

	private addToSet(streamType: StreamType, userId: string) {
		switch (streamType) {
			case StreamType.AUDIO:
				this.audioSubsSet.add(userId);
				break;
			case StreamType.CAMERA:
				this.cameraSubsSet.add(userId);
				break;
			case StreamType.SCREEN:
				this.screenSubsSet.add(userId);
				break;
			case StreamType.SCREEN_AUDIO:
				this.screenAudioSubsSet.add(userId);
				break;
			default:
				throw new Error('The stream type: ' + streamType + ' is not supported in SubscriptionState.');
		}
	}

	private removeFromSet(streamType: StreamType, userId: string) {
		switch (streamType) {
			case StreamType.AUDIO:
				this.audioSubsSet.delete(userId);
				break;
			case StreamType.CAMERA:
				this.cameraSubsSet.delete(userId);
				break;
			case StreamType.SCREEN:
				this.screenSubsSet.delete(userId);
				break;
			case StreamType.SCREEN_AUDIO:
				this.screenAudioSubsSet.delete(userId);
				break;
			default:
				throw new Error('The stream type: ' + streamType + ' is not supported in SubscriptionState.');
		}
	}

	applyBase(subsData: { [userId: string]: StreamType[] }) {
		if (!this.waitForBase) {
			throw new Error('this SubscriptionState doesn\'t support base.');
		}
		if (this.isBaseSet) {
			throw new Error('base for this SubscriptionState is set, reset it to do it again.');
		}
		// apply base
		for (const userId in subsData) {
			subsData[userId].forEach(streamType => {
				this.addToSet(streamType, userId);
			});
		}
		// apply actionQueue
		while (this.actionQueue.length) {
			let action = this.actionQueue.shift()!;
			if (action.action === 'sub') {
				this.addToSet(action.streamType, action.userId);
			} else {
				this.removeFromSet(action.streamType, action.userId);
			}
		}
		// base is set
		this.isBaseSet = true;
	}

	unsubAllForUser(userId: string) {
		if (this.isBaseSet) {
			this.audioSubsSet.delete(userId);
			this.cameraSubsSet.delete(userId);
			this.screenSubsSet.delete(userId);
			this.screenAudioSubsSet.delete(userId);
		} else {
			this.actionQueue.push({action: 'unsub', streamType: StreamType.AUDIO, userId});
			this.actionQueue.push({action: 'unsub', streamType: StreamType.CAMERA, userId});
			this.actionQueue.push({action: 'unsub', streamType: StreamType.SCREEN, userId});
			this.actionQueue.push({action: 'unsub', streamType: StreamType.SCREEN_AUDIO, userId});
		}
	}

	unsub(streamTypes: StreamType[], userId: string) {
		if (this.isBaseSet) {
			streamTypes.forEach(streamType => {
				this.removeFromSet(streamType, userId);
			});
		} else {
			streamTypes.forEach(streamType => {
				this.actionQueue.push({action: 'unsub', streamType, userId});
			});
		}
	}

	sub(streamTypes: StreamType[], userId: string) {
		if (this.isBaseSet) {
			streamTypes.forEach(streamType => {
				this.addToSet(streamType, userId);
			});
		} else {
			streamTypes.forEach(streamType => {
				this.actionQueue.push({action: 'sub', streamType, userId});
			});
		}
	}

	isSub(streamType: StreamType, userId: string) {
		if (this.isBaseSet) {
			switch (streamType) {
				case StreamType.AUDIO:
					return this.audioSubsSet.has(userId);
				case StreamType.CAMERA:
					return this.cameraSubsSet.has(userId);
				case StreamType.SCREEN:
					return this.screenSubsSet.has(userId);
				case StreamType.SCREEN_AUDIO:
					return this.screenAudioSubsSet.has(userId);
				default:
					throw new Error('The stream type: ' + streamType + ' is not supported in SubscriptionState.');
			}
		} else {
			throw new Error('this SubscriptionState base is not set - you should not read any data yet.');
		}
	}

	applyTransceiversSubs(subs: ActiveStreamsResponsePacket.AsObject) {
		const transceivers = subs.streamsList;

		this.audioSubsSet.clear();
		transceivers
			.filter(t => t.type === StreamType.AUDIO && !!t.userId)
			.forEach(t => this.audioSubsSet.add(t.userId))

		this.cameraSubsSet.clear();
		transceivers
			.filter(t => t.type === StreamType.CAMERA && !!t.userId)
			.forEach(t => this.cameraSubsSet.add(t.userId))

		this.screenAudioSubsSet.clear();
		transceivers
			.filter(t => t.type === StreamType.SCREEN_AUDIO && !!t.userId)
			.forEach(t => this.screenAudioSubsSet.add(t.userId))

		this.screenSubsSet.clear();
		transceivers
			.filter(t => t.type === StreamType.SCREEN && !!t.userId)
			.forEach(t => this.screenSubsSet.add(t.userId))

		console.log(transceivers, this);
	}

	static roomUsersToBase(roomUsers: RoomUser[], clientId: string) {
		return roomUsers.reduce((obj: { [userId: string]: StreamType[] }, user) => {
			if (clientId !== user.id) {
				obj[user.id] = SubscriptionState.roomUserFlagsToStreamTypes(user.flags);
			}
			return obj;
		}, {});
	}

	static roomUserFlagsToStreamTypes(roomUserFlags?: RoomUser['flags']) {
		if (!roomUserFlags) {
			return [];
		}
		const arr: StreamType[] = [];
		const isSharingAudioFlag = !!roomUserFlags[RoomUserFlag.RUFLAG_SHARING_AUDIO];
		const isSharingCameraFlag = !!roomUserFlags[RoomUserFlag.RUFLAG_SHARING_VIDEO];
		const isSharingScreenFlag = !!roomUserFlags[RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO];
		const isSharingScreenAudioFlag = !!roomUserFlags[RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO];
		isSharingAudioFlag && arr.push(StreamType.AUDIO);
		isSharingCameraFlag && arr.push(StreamType.CAMERA);
		isSharingScreenFlag && arr.push(StreamType.SCREEN);
		isSharingScreenAudioFlag && arr.push(StreamType.SCREEN_AUDIO);
		return arr;
	}

	static compare(currentStage: SubscriptionState, clientStage: SubscriptionState) {
		const missingItems: CompareSubscriptionStatesResults['missingItems'] = [];
		const extraItems: CompareSubscriptionStatesResults['extraItems'] = [];
		// camera
		currentStage.cameraSubsSet.forEach(id => {
			if (!clientStage.cameraSubsSet.has(id)) {
				missingItems.push({type: StreamType.CAMERA, userId: id});
			}
		});
		clientStage.cameraSubsSet.forEach(id => {
			if (!currentStage.cameraSubsSet.has(id)) {
				extraItems.push({type: StreamType.CAMERA, userId: id});
			}
		});
		// audio
		currentStage.audioSubsSet.forEach(id => {
			if (!clientStage.audioSubsSet.has(id)) {
				missingItems.push({type: StreamType.AUDIO, userId: id});
			}
		});
		clientStage.audioSubsSet.forEach(id => {
			if (!currentStage.audioSubsSet.has(id)) {
				extraItems.push({type: StreamType.AUDIO, userId: id});
			}
		});
		// screen
		currentStage.screenSubsSet.forEach(id => {
			if (!clientStage.screenSubsSet.has(id)) {
				missingItems.push({type: StreamType.SCREEN, userId: id});
			}
		});
		clientStage.screenSubsSet.forEach(id => {
			if (!currentStage.screenSubsSet.has(id)) {
				extraItems.push({type: StreamType.SCREEN, userId: id});
			}
		});
		// screen audio
		currentStage.screenAudioSubsSet.forEach(id => {
			if (!clientStage.screenAudioSubsSet.has(id)) {
				missingItems.push({type: StreamType.SCREEN_AUDIO, userId: id});
			}
		});
		clientStage.screenAudioSubsSet.forEach(id => {
			if (!currentStage.screenAudioSubsSet.has(id)) {
				extraItems.push({type: StreamType.SCREEN_AUDIO, userId: id});
			}
		});
		const results: CompareSubscriptionStatesResults = {missingItems, extraItems};
		return results;
	}

}

export interface CompareSubscriptionStatesResults {
	extraItems: { type: StreamType, userId: string }[];
	missingItems: { type: StreamType, userId: string }[];
}
