import styled from 'styled-components';
import React, {FC, memo, useEffect, useRef, useState} from 'react';
import {componentChildren} from '../../utils/utils';
import {Button as ButtonOriginal, ButtonProps} from '../Button/Button';
import {RED_LIGHT} from '../../styles/constants';
import {remm} from '../../utils/remm';
import {ScrollableHorizontalText} from '../ScrollableText/ScrollableText';

///
/// Context Menu
///
interface ContextMenuProps extends React.ComponentPropsWithoutRef<'div'> {
	width?: number
}

const ContextMenuP: FC<ContextMenuProps> = memo(({
	children,
	...restProps
}) => {
	return (
		<ContextMenuS {...restProps}>
			<div className="ContextMenuS_Container">
				{children}
			</div>
		</ContextMenuS>
	);
});

export const ContextMenuS = styled.div<ContextMenuProps>`
	background-color: ${({theme}) => theme.dark ? theme.color4 : theme.color3};
	border-color: ${({theme}) => theme.borderWeak};
	${({theme}) => theme.boxShadow};
	min-width: ${({width}) => width ? remm(width) : remm(190)};
	max-width: ${remm(320)};
	border-radius: ${remm(6)};

	.ContextMenuS_Container {
		overflow: hidden auto;
		padding: ${remm(6)};

	}

	.ContextMenuS_description {
		padding: ${remm(4)} ${remm(8)} 0;
		font-weight: 600;
		font-size: ${remm('14px')};
		letter-spacing: normal;
		color: ${({theme}) => (theme.mediumTextColor)};
	}

	.ContextMenuS_checkboxContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

///
/// Context Menu Button
///
const Button: FC<ButtonProps> = memo(({
	children,
	className,
	...restProps
}) => {

	const [duration, setDuration] = useState(0);
	const contentSpan = useRef<HTMLSpanElement>(null);

	useEffect(() => {

		if (contentSpan.current) {
			setDuration((contentSpan.current.offsetWidth - contentSpan.current.parentElement!.offsetWidth) / 20);
		}
	}, []);

	return (
		<ButtonS>
			<ButtonOriginal {...restProps} className={'transparent ' + className} buttonWidth="100%" alignLeft={true}>
				<ScrollableHorizontalText duration={duration} width={292}>
					<span ref={contentSpan}>
						{children}
					</span>
				</ScrollableHorizontalText>
			</ButtonOriginal>
		</ButtonS>
	);
});

const ButtonS = styled.div`
	button {
		font-weight: 700;
		font-size: ${remm('15px')};
		letter-spacing: normal;
		min-width: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		border-radius: ${remm(2)};

		&.hasRedText {
			color: ${RED_LIGHT} !important;

			&:hover {
				color: ${RED_LIGHT} !important;
			}
		}

		&:not([disabled]):hover {
			background: ${({theme}) => theme.dropdownHighlight};

			&.selected {
				background: ${({theme}) => theme.highlight2};
			}
		}

		&.selected {
			background: ${({theme}) => theme.highlight2};
		}
	}

	span {
		font-weight: 700;
		font-size: ${remm('15px')};
		letter-spacing: normal;
		text-align: left;
		min-width: 0;
	}
`;


///
/// Context Menu Separator
///
const SeparatorS = styled.hr`
	margin: 5px 0;
	border: 0;
	border-bottom: 1px solid ${({theme}) => theme.borderNormal};
`;

///
/// Exporting
///
const ContextMenu = componentChildren(ContextMenuP, {Button, SeparatorS});
export {ContextMenu};
