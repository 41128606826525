import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EventInfo, EventState, RoomStreamInfo, RoomStreamMetadata} from '../../services/gRPC/rooms/models_pb';
import {RootState} from '../store';
import {EventStatus, RoomStreamType} from '../../services/gRPC/rooms/enums_pb';
import {selectClientId} from './user';
import {Result} from '../../singleComponents/drawers/Playlist/helpers';
import {isArrayEquivalent} from '../../utils/utils';
import {Suggestion} from './playlistSearch';

export type RoomStreamEvent = RoomStreamInfo.AsObject & {
	type: RoomStreamType.RSTYPE_EVENT
	info: EventInfo.AsObject
}

export type RoomStreamNotEvent = RoomStreamInfo.AsObject & {
	type: Exclude<RoomStreamType, RoomStreamType.RSTYPE_EVENT>;
	videoUrl?: string,
	startedAt?: number,
}

export type RoomStream = RoomStreamEvent | RoomStreamNotEvent

export const isRoomStreamEventGuard = ((obj: RoomStream) => {
	return obj.type === RoomStreamType.RSTYPE_EVENT;
}) as (obj: RoomStream) => obj is RoomStreamEvent;

interface RoomStreamsState {
	streamVolume: number,
	streamMuted: boolean,
	event?: EventState.AsObject
	dash: {
		streamLink?: string
		startedAt?: number,
		offset?: number,
	},
	streamsList: RoomStream[]
	isVideoUnavailable: boolean,
	lastSearch: {
		search: string,
		filters: Suggestion[]
	},
	searches: { result: Result, filters: Suggestion[] }[]
}

const initialState: RoomStreamsState = {
	streamVolume: 40,
	streamMuted: false,
	dash: {
		streamLink: undefined,
		startedAt: undefined,
		offset: undefined
	},
	streamsList: [],
	isVideoUnavailable: false,
	lastSearch: {
		search: '',
		filters: [Suggestion.TIKTOK]
	},
	searches: []
};

export const roomStreamsSlice = createSlice({
	name: 'roomStreams',
	initialState,
	reducers: {
		clearSlice: () => {
			return initialState;
		},
		setEvent: (state, action: PayloadAction<EventState.AsObject | undefined>) => {
			state.event = action.payload;
		},
		removeEvent: (state) => {
			state.event = undefined;
		},
		setAudience: (state, action: PayloadAction<number>) => {
			if (state.event?.info?.audience !== undefined) {
				state.event.info.audience = action.payload;
			}
		},
		setEventStatus: (state, action: PayloadAction<EventStatus>) => {
			if (state.event?.info?.status !== undefined) {
				state.event.info.status = action.payload;
			}
		},
		setEventStartedAt: (state, action: PayloadAction<number>) => {
			state.event!.info!.startedAt = action.payload;
		},
		setDashItems: (state, action: PayloadAction<{ streamLink?: string, startedAt?: number, offset?: number }>) => {
			if ('streamLink' in action.payload) {
				state.dash.streamLink = action.payload.streamLink;
			}
			if ('startedAt' in action.payload) {
				state.dash.startedAt = action.payload.startedAt;
			}
			if ('offset' in action.payload) {
				state.dash.offset = action.payload.offset;
			}
		},
		setRoomStreams: (state, action: PayloadAction<RoomStream[]>) => {
			state.streamsList = action.payload;
			console.log(state.streamsList.map(x => ({ p: x.position, id: x.id })))
		},
		addRoomStream: (state, action: PayloadAction<{ stream: RoomStream }>) => {
			if (state.streamsList.find(s => s.metadata?.sid === action.payload?.stream?.metadata?.sid)) {
				const stream = state.streamsList.find(s => s.metadata?.sid === action.payload?.stream?.metadata?.sid)!;
				stream.id = action.payload?.stream.id;
				stream.roomId = action.payload?.stream.roomId;
				stream.metadata = action.payload?.stream.metadata;
				stream.timedata = action.payload?.stream.timedata;
				// console.log(stream.position, action.payload?.stream.position);
				stream.position = action.payload?.stream.position;
				state.streamsList.sort((a, b) => a.position - b.position);
			}
			if (!state.streamsList.find(s => s.id === action.payload.stream.id)) {
				state.streamsList.push(action.payload.stream);
				state.streamsList.sort((a, b) => a.position - b.position);
				// console.log(state.streamsList.map(x => ({ p: x.position, id: x.metadata!.title })))
			}
		},
		updateRoomStreamVideoUrl: (state, action: PayloadAction<{ roomStreamId: string, videoUrl: string }>) => {
			const roomStreamIndex = state.streamsList.findIndex(s => s.id === action.payload.roomStreamId);
			if (roomStreamIndex > -1) {
				const roomStream = state.streamsList[roomStreamIndex];
				if (roomStream.type !== RoomStreamType.RSTYPE_EVENT) {
					roomStream.videoUrl = action.payload.videoUrl;
				}
			}
		},
		updateRoomStreamThumbnail: (state, action: PayloadAction<{ roomStreamId: string, thumbnailUrl: string }>) => {
			const roomStreamIndex = state.streamsList.findIndex(s => s.id === action.payload.roomStreamId);
			if (roomStreamIndex > -1) {
				state.streamsList[roomStreamIndex].metadata!.thumbnailUrl = action.payload.thumbnailUrl;
			}
		},
		updateRoomStreamPaused: (state, action: PayloadAction<{ roomStreamId: string, paused: boolean }>) => {
			const roomStreamIndex = state.streamsList.findIndex(s => s.id === action.payload.roomStreamId);
			if (roomStreamIndex > -1) {
				state.streamsList[roomStreamIndex].metadata!.paused = action.payload.paused;
			}
		},
		updateRoomStreamOffset: (state, action: PayloadAction<{ roomStreamId: string, offset: number, timestamp?: number }>) => {
			const {roomStreamId, offset, timestamp} = action.payload;
			const roomStreamIndex = state.streamsList.findIndex(s => s.id === roomStreamId);
			if (roomStreamIndex > -1) {
				const roomStream = state.streamsList[roomStreamIndex];
				roomStream.metadata!.offset = offset;
				if (roomStream.timedata) {
					roomStream.timedata!.offset = timestamp ? Math.round((Date.now() - timestamp) / 1000) : 0;
					roomStream.timedata!.timestamp = timestamp || Date.now();
				}
			}
		},
		updateRoomStreamDifference: (state, action: PayloadAction<{ roomStreamId: string, difference: number, timestamp: number }>) => {
			const {roomStreamId, timestamp, difference} = action.payload;
			const roomStreamIndex = state.streamsList.findIndex(s => s.id === roomStreamId);
			if (roomStreamIndex > -1) {
				const roomStream = state.streamsList[roomStreamIndex];
				if (roomStream.timedata) {
					roomStream.timedata!.offset = difference;
					roomStream.timedata!.timestamp = timestamp;
				}
			}
		},
		deleteRoomStream: (state, action: PayloadAction<{ roomStreamId: string }>) => {
			state.streamsList = state.streamsList.filter(s => action.payload.roomStreamId !== s.id).map((s, index) => ({...s, position: index}));
		},
		clearRoomStreams: (state, action: PayloadAction<{ idsList: string[] }>) => {
			if (!action.payload.idsList.length) {
				state.streamsList = [];
			} else {
				state.streamsList = state.streamsList.filter(s => !action.payload.idsList.includes(s.id));
			}
		},
		updateRoomStreamPosition: (state, action: PayloadAction<{ id: string, position: number }>) => {
			const {id, position: newIndex} = action.payload;
			const prevIndex = state.streamsList.findIndex(s => s.id === id);

			const min = Math.min(newIndex, prevIndex);
			const max = Math.max(newIndex, prevIndex);

			const x = state.streamsList.map((s) =>
				s.position > max || s.position < min ? (
					s) : ({
					...s,
					timedata: undefined,
					metadata: {
						...s.metadata as RoomStreamMetadata.AsObject,
						paused: false
					},
					position: s.position === prevIndex ? newIndex : (newIndex > prevIndex ? s.position - 1 : s.position + 1)
				})).sort((a, b) => {
				return a.position - b.position;
			});
			// console.log(x.map(a => ({ p: a.position, id: a.metadata!.title })));
			state.streamsList = x;
		},
		updateRoomStreamSuggest: (state, action: PayloadAction<{ id: string, suggest: boolean }>) => {
			const {id, suggest} = action.payload;
			const roomStreamIndex = state.streamsList.findIndex(s => s.id === id);
			if (roomStreamIndex > -1) {
				const roomStream = state.streamsList[roomStreamIndex];
				roomStream.metadata!.suggest = suggest;
			}
		},
		updateRoomStreamRelated: (state, action: PayloadAction<{ id: string, related: boolean }>) => {
			const {id, related} = action.payload;
			const roomStreamIndex = state.streamsList.findIndex(s => s.id === id);
			if (roomStreamIndex > -1) {
				const roomStream = state.streamsList[roomStreamIndex];
				roomStream.metadata!.related = related;
			}
		},
		updateRoomStreamStack: (state, action: PayloadAction<{ id: string, stack: string }>) => {
			const {id, stack} = action.payload;
			const roomStreamIndex = state.streamsList.findIndex(s => s.id === id);
			if (roomStreamIndex > -1) {
				const roomStream = state.streamsList[roomStreamIndex];
				roomStream.metadata!.stack = stack;
			}
		},
		setIsVideoUnavailable: (state, action: PayloadAction<boolean>) => {
			state.isVideoUnavailable = action.payload;
		},
		setStreamVolume: (state, action: PayloadAction<number>) => {
			state.streamVolume = action.payload;
		},
		setStreamMuted: (state, action: PayloadAction<boolean>) => {
			state.streamMuted = action.payload;
		},
		addSearch: (state, action: PayloadAction<{filters: Suggestion[], result: Result }>) => {
			const { filters, result } = action.payload;
			const search = state.searches.find(s => isArrayEquivalent(s.filters, filters) && s.result.input === result.input);

			if(search) {
				search.result = result;
			} else {
				state.searches.push({ filters, result });
			}

			if(state.searches.reduce((a, b) => (a + b.result.results.length), 0) > 400 && state.searches.length > 1) {
				const toDeleteIndex = state.searches.findIndex(s => isArrayEquivalent(s.filters, filters) && s.result.input === result.input);
				state.searches = state.searches.filter((s, index) => index !== toDeleteIndex)
			}
		},
		setLastSearch: (state, action: PayloadAction<{filters: Suggestion[], search: string }>) => {
			const { filters, search } = action.payload;
			state.lastSearch = {search, filters};
		}
	}
});


export const roomStreamsActions = roomStreamsSlice.actions;

export const selectHasEventStarted = (state: RootState) => (state.roomStreams.event?.info?.status === EventStatus.ESTATUS_STARTED);
export const selectEventStartsAt = (state: RootState) => (state.roomStreams.event?.info?.startsAt);
export const selectEventStartedAt = (state: RootState) => (state.roomStreams.event?.info?.startedAt);
export const selectEventFinishesAt = (state: RootState) => (state.roomStreams.event?.info?.finishesAt);
export const selectEventFinishedAt = (state: RootState) => (state.roomStreams.event?.info?.finishedAt);
export const selectIsEvent = (state: RootState) => !!(state.roomStreams.event);
export const selectEventName = (state: RootState) => (state.roomStreams.event?.info?.name);
export const selectEventId = (state: RootState) => (state.roomStreams.event?.info?.id);
export const selectStreamVolume = (state: RootState) => (state.roomStreams.streamVolume);
export const selectStreamMuted = (state: RootState) => (state.roomStreams.streamMuted);
export const selectEventSource = (state: RootState) => (state.roomStreams.event?.info?.source);
export const selectEventStatus = (state: RootState) => (state.roomStreams.event?.info?.status);
export const selectAudienceAmount = (state: RootState) => (state.roomStreams.event?.info?.audience);
export const selectDashLink = (state: RootState) => (state.roomStreams.dash.streamLink);
export const selectDashOffset = (state: RootState) => (state.roomStreams.dash.offset);
export const selectDashStartedAt = (state: RootState) => (state.roomStreams.dash.startedAt);
export const selectRoomStreams = (state: RootState) => (state.roomStreams.streamsList);
export const selectIsPlaylistActive = (state: RootState) => !!state.roomStreams.streamsList.length;
export const selectPlaylistLength = (state: RootState) => state.roomStreams.streamsList.length;
export const selectCurrentRoomStreamType = (state: RootState) => state.roomStreams.streamsList[0]?.type;
export const selectCurrentRoomStreamId = (state: RootState) => state.roomStreams.streamsList[0]?.id;
export const selectCurrentRoomStreamOwnerId = (state: RootState) => state.roomStreams.streamsList[0]?.ownerId;
export const selectCurrentStream = (state: RootState) => state.roomStreams.streamsList[0] as RoomStream | undefined;
export const selectNextPlaylistRoomStream = (state: RootState) => state.roomStreams.streamsList[1] as RoomStream | undefined;
export const selectSearches = (state: RootState) => state.roomStreams.searches;
export const selectLastSearch = (state: RootState) =>
	state.roomStreams.searches.find(s => s.filters.every(f => state.roomStreams.lastSearch.filters.find(x => x === f)) && s.result.input === state.roomStreams.lastSearch.search);
export const selectSearchResult = (state: RootState, search: { input: string, filters: Suggestion[] }) =>
	state.roomStreams.searches.find(s => s.filters.every(f => search.filters.find(x => x === f)) && s.result.input === search.input);

export const selectEventChatId = (state: RootState) => state.roomStreams.event?.chat?.id;
export const selectUserRoomStreamsLength = createSelector(
	selectClientId,
	selectRoomStreams,
	(clientId, roomStreams) => {
		return roomStreams.filter(stream => stream.ownerId === clientId).length;
	}
);
export const selectIsVideoUnavailable = (state: RootState) => state.roomStreams.isVideoUnavailable;

export const selectPlaylistSids = (state: RootState) => state.roomStreams.streamsList.map(rs => rs.metadata?.sid);

export const selectRoomEvents = (state: RootState) => state.roomStreams.streamsList.filter(s => s.type === RoomStreamType.RSTYPE_EVENT) as RoomStreamEvent[];

export const selectNothing = (state: RootState) => !!state;

export const selectPlaylistSuggest = (state: RootState) => state.roomStreams.streamsList[state.roomStreams.streamsList.length - 1]?.metadata?.suggest ?? false;
export const selectPlaylistRelated = (state: RootState) => state.roomStreams.streamsList[state.roomStreams.streamsList.length - 1]?.metadata?.related ?? false;


