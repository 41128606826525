import {createGlobalStyle} from 'styled-components';
import {normalize} from 'styled-normalize';
import {
	CYAN_600,
	GREEN_2,
	ORANGE,
	RED_LIGHT,
	WHITE
} from './constants';
import {remm, ROOT_FONT_SIZE} from '../utils/remm';

export const GlobalStyle = createGlobalStyle`

	${normalize}

	html {
		line-height: normal;
	}

	body {
		margin: 0;
		font-family: 'Greycliff CF', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		font-weight: 500;
		letter-spacing: 0.01em;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-overflow-scrolling: touch;
		font-size: ${remm(ROOT_FONT_SIZE)};
		color: ${props => props.theme.textColor};
		background-color: ${props => props.theme.color1};
		line-height: normal;
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	}

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
		color: ${WHITE};
	}

	ul,
	ul li,
	ul ul li {
		margin: 0;
		padding: 0;
		text-indent: 0;
		list-style-type: none;
	}

	//fix for icons
	i[class^="icon-"] {
		display: flex;
	}

	//focus visible
	[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
		outline: none;
	}

	.js-focus-visible :focus:not(.focus-visible) {
		outline: none;
	}

	//links

	a {
		cursor: pointer;
		text-decoration: underline;
		color: ${CYAN_600};
	}

	a:link {
		color: ${CYAN_600};
	}

	/* visited link */
	a:visited {
		color: ${CYAN_600};
	}

	/* mouse over link */
	a:hover {
		color: ${ORANGE};
	}

	/* selected link */
	a:active {
		color: ${ORANGE};
	}

	.pointer {
		cursor: pointer;
	}

	.double-line-breaker {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.single-line-breaker {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.text-red {
		color: ${RED_LIGHT};
	}

	.text-orange {
		color: ${ORANGE};
	}

	.text-cyan {
		color: ${CYAN_600};
	}

	.text-green {
		color: ${GREEN_2};
	}

	.flex-basic-0 {
		flex-basis: 0;
	}

	.firebase-emulator-warning {
		display: none;
	}

	.popup-content > div:first-child {
		z-index: 0 !important;
	}

	.popup-arrow {
		color: ${({theme}) => theme.dark ? theme.color4 : theme.color3};
	}

	.pe-none {
		pointer-events: none;
	}

	.opacity-0 {
		opacity: 0;
	}

	/*
   ** Scroll
   */

	::-webkit-scrollbar {
		width: 8px;
	}

	::-webkit-scrollbar-button {
		width: 0;
		height: 0;
	}

	::-webkit-scrollbar-thumb {
		background: ${({theme}) => theme.scroll};
		transition: .5s;
		border-radius: 22px;
		background-clip: padding-box;
	}

	::-webkit-scrollbar-track {
		background: transparent;
	}

	::-webkit-scrollbar-corner {
		background: transparent;
	}

`;
