import styled, {css} from 'styled-components';
import {lighten} from 'polished';
import {ButtonProps} from './Button';
import {remm} from '../../utils/remm';
import {CYAN_700, CYAN_800, DARK3, DARK4, LIGHT2, RED_LIGHT, WHITE_MEDIUM, WHITE_STRONG} from '../../styles/constants';
import {componentChildren} from '../../utils/utils';

const BodyS = styled.span<ButtonProps>`
	display: flex;
	align-items: center;
	justify-content: ${({alignLeft}) => alignLeft ? 'flex-start' : 'center'};
	transition: transform 300ms;
	transform: ${(props) => props.isLoading ? 'translate3d(0, -' + props.buttonHeight + ', 0)' : 'translate3d(0, 0, 0)'};
	margin: 0 auto;

	> svg {
		width: 16px;
		height: 16px;
		vertical-align: middle;
		margin-top: -3px;
		margin-right: 12px;
	}
`;

const ButtonP = styled.button<ButtonProps>`
	outline: none;
	border: none;
	overflow: hidden;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	height: ${(props) => props.buttonHeight};
	width: ${(props) => props.buttonWidth};
	padding: ${({alignLeft}) => alignLeft ? `0 ${remm('8px')}` : `0 ${remm('16px')}`};
	border-radius: ${remm(23)};
	font-weight: 800;
	font-size: ${remm('14px')};
	${({buttonFontSize}) => buttonFontSize && css`font-size: ${buttonFontSize}`};

	// default colors
	background-color: ${({theme}) => theme.highlight};
	color: ${({theme}) => theme.textColor};

	&:not([disabled]):hover {
		background-color: ${({theme}) => theme.highlight2};
	}

	&:not([disabled]):focus {
		box-shadow: 0 0 0 1px ${({theme}) => theme.borderStrong} inset;
	}

	&:disabled {
		color: ${({theme}) => theme.weakTextColor};
		cursor: not-allowed;
		opacity: .6;
	}

	// primary colors
	&.primary {
		background-color: ${CYAN_800};
		color: ${WHITE_STRONG};

		&:not([disabled]):hover {
			background-color: ${CYAN_700};
		}

		&:disabled {
			color: ${WHITE_MEDIUM};
		}
	}

	// danger colors
	&.danger {
		background-color: ${RED_LIGHT};
		color: ${WHITE_STRONG};

		&:not([disabled]):hover {
			background-color: ${lighten(0.09, RED_LIGHT)};
			color: ${WHITE_STRONG};
		}

		&:disabled {
			color: ${WHITE_MEDIUM};
		}
	}

	// transparent colors
	&.transparent {
		background: transparent;

		&:not([disabled]):hover {
			background-color: ${({theme}) => theme.highlight};
		}

		&:not([disabled]):focus {
			box-shadow: 0 0 0 1px ${({theme}) => theme.borderNormal} inset;
		}
	}

	// solid colors
	&.solid {
		background-color: ${({theme}) => theme.dark ? DARK3 : LIGHT2};
		color: ${({theme}) => theme.dark ? theme.textColor : 'rgba(0,0,0,0.75)'};

		&:not([disabled]):hover {
			background-color: ${({theme}) => theme.dark ? DARK4 : LIGHT2};
			color: ${({theme}) => theme.dark ? theme.textColor : 'rgba(0,0,0,1)'};
		}
	}

	&.solidDarkLighter {
		background-color: ${({theme}) => theme.dark ? DARK4 : LIGHT2};
		color: ${({theme}) => theme.dark ? theme.textColor : 'rgba(0,0,0,0.75)'};

		&:not([disabled]):hover {
			background-color: ${({theme}) => theme.dark ? lighten(0.09, DARK4) : LIGHT2};
			color: ${({theme}) => theme.dark ? theme.textColor : 'rgba(0,0,0,1)'};
		}
	}

`;


const SpinnerContainerS = styled.span<ButtonProps>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	transition-duration: 300ms;
	position: absolute;
	top: 0;
	left: 0;
	height: ${(props) => props.buttonHeight};
	transform: ${(props) => props.isLoading ? 'translate3d(0, 0, 0)' : 'translate3d(0, ' + props.buttonHeight + ', 0)'};
`;

const ButtonS = componentChildren(ButtonP, {BodyS, SpinnerContainerS});

export {ButtonS};
