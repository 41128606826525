import {createEntityAdapter, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppEpic, RootState} from '../store';
import {UserInfo} from '../../services/gRPC/users/models_pb';
import {combineEpics} from 'redux-observable';
import {concatMap, map} from 'rxjs/operators';
import {getUserInfoService$} from '../../services/userServices';
import {EMPTY, of} from 'rxjs';
import {batchedFilter} from '../utlis';

const usersAdapter = createEntityAdapter<UserInfo.AsObject>({
	selectId: (user) => user.id
});

export const usersSlice = createSlice({
	name: 'users',
	initialState: usersAdapter.getInitialState(),
	reducers: {
		addUsers: usersAdapter.addMany,
		addUser: usersAdapter.addOne,
		setUser: usersAdapter.setOne,
		updateUser: usersAdapter.updateOne,
		removeUser: usersAdapter.removeOne,
		fetchUserIfNeed: (state, action: PayloadAction<{ userId: string }>) => {
			//redux-observable
		}
	}
});

const fetchUserIfNeedEpic: AppEpic = (action$, state$) => {
	return action$.pipe(
		batchedFilter(usersActions.fetchUserIfNeed),
		concatMap((action) => state$.value.users.entities[action.payload.userId] ? EMPTY : of(action)),
		concatMap((action) => getUserInfoService$(action.payload.userId)),
		map((user) => usersActions.addUser(user))
	);
};

export const usersEpics = combineEpics(
	fetchUserIfNeedEpic
);
export const usersActions = usersSlice.actions;
export const selectUsersEntities = usersAdapter.getSelectors<RootState>((state) => state.users).selectEntities;
export const selectUsers = usersAdapter.getSelectors<RootState>((state) => state.users).selectAll;
export const selectUser = usersAdapter.getSelectors<RootState>((state) => state.users).selectById;
export const selectUsersByStatus = createSelector(
	selectUsersEntities,
	(users) => Object.keys(users).sort((a, b) => users[a]!.status < users[b]!.status ? -1 : 1)
);
