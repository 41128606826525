import * as Color from './constants';
import {
	BLACK_MEDIUM,
	BORDER_BLACK_NORMAL,
	BORDER_BLACK_STRONG,
	BORDER_BLACK_WEAK,
	BORDER_WHITE_NORMAL,
	BORDER_WHITE_STRONG,
	BORDER_WHITE_WEAK,
	DARK1,
	DARK2,
	DARK3,
	DARK4,
	DARK_HIGHLIGHT,
	DARK_HIGHLIGHT2, DARK_SCROLL,
	DARK_THEME_BOX_SHADOW,
	DARK_THEME_SHADOW,
	LIGHT1,
	LIGHT2,
	LIGHT3,
	LIGHT4,
	LIGHT_HIGHLIGHT,
	LIGHT_HIGHLIGHT2, LIGHT_SCROLL,
	LIGHT_THEME_BOX_SHADOW,
	LIGHT_THEME_SHADOW, WHITE_MEDIUM
} from './constants';
import {transparentize} from 'polished';
import {localStore} from '../localStorage/localStore';

export type CustomTheme = typeof darkTheme;

declare module 'styled-components' {
	interface DefaultTheme extends CustomTheme {
	}
}

export const lightTheme: CustomTheme = {
	// text
	textColor: Color.BLACK_STRONG,
	mediumTextColor: Color.BLACK_MEDIUM,
	weakTextColor: Color.BLACK_WEAK,

	// input, dropdown, textarea, timepicker, datepicker, autocomplete
	inputColor: Color.BLACK_STRONG,
	inputPlaceholderColor: Color.BLACK_WEAK,
	inputBackground: 'transparent',
	inputBackgroundHover: 'rgba(0,0,0,0.02)',
	inputBorderColor: BORDER_BLACK_NORMAL,
	inputBorderFocusColor: BORDER_BLACK_STRONG,
	inputDisabledBackground: 'rgba(187,187,187,0.25)',
	inputDisabledColor: Color.BLACK_WEAK,
	inputErrorShadow: transparentize(0.4, Color.RED_LIGHT),

	// dropdown, contextmenu, autocomplete, calendar - all the most raised layers clickable highlight
	dropdownHighlight: LIGHT_HIGHLIGHT,
	dropdownActive: LIGHT_HIGHLIGHT2,

	// scroll
	scroll: LIGHT_SCROLL,

	// shadows, highlights
	shadow: LIGHT_THEME_SHADOW,
	boxShadow: LIGHT_THEME_BOX_SHADOW,
	highlight: LIGHT_HIGHLIGHT,
	highlight2: LIGHT_HIGHLIGHT2,

	// borders
	borderWeak: BORDER_BLACK_WEAK,
	borderNormal: BORDER_BLACK_NORMAL,
	borderStrong: BORDER_BLACK_STRONG,

	// colors
	color1: LIGHT1,
	color2: LIGHT2,
	color3: LIGHT3,
	color4: LIGHT4,
	warningColor: '#f96f06',
	labelColor: BLACK_MEDIUM,

	// theme mode
	dark: false
};

export const darkTheme = {
	// text
	textColor: Color.WHITE_STRONG,
	mediumTextColor: Color.WHITE_MEDIUM,
	weakTextColor: Color.WHITE_WEAK,

	//input, dropdown, textarea, timepicker, datepicker, autocomplete
	inputColor: Color.WHITE_STRONG,
	inputPlaceholderColor: Color.WHITE_WEAK,
	inputBackground: 'transparent',
	inputBackgroundHover: 'rgba(255,255,255,0.02)',
	inputBorderColor: BORDER_WHITE_NORMAL,
	inputBorderFocusColor: BORDER_WHITE_STRONG,
	inputDisabledBackground: 'rgba(255,255,255,0.08)',
	inputDisabledColor: Color.WHITE_WEAK,
	inputErrorShadow: transparentize(0.4, Color.RED_LIGHT),

	//dropdown, contextmenu, autocomplete, calendar, transparent icon highlight - all the most raised layers clickable highlight
	dropdownHighlight: 'rgba(226,240,255,0.04)',
	dropdownActive: 'rgba(159,176,196,0.16)',

	// scroll
	scroll: DARK_SCROLL,

	// shadows, highlights
	shadow: DARK_THEME_SHADOW,
	boxShadow: DARK_THEME_BOX_SHADOW,
	highlight: DARK_HIGHLIGHT,
	highlight2: DARK_HIGHLIGHT2,

	// borders
	borderWeak: BORDER_WHITE_WEAK,
	borderNormal: BORDER_WHITE_NORMAL,
	borderStrong: BORDER_WHITE_STRONG,

	// colors
	color1: DARK1,
	color2: DARK2,
	color3: DARK3,
	color4: DARK4,
	warningColor: '#E55908',
	labelColor: WHITE_MEDIUM,

	// theme mode
	dark: true
};

export enum ThemeType {
	DARK = 'dark',
	LIGHT = 'light'
}

export function getThemeCtx() {
	return localStore.getItem('theme') === ThemeType.DARK ? darkTheme : lightTheme;
}

export function getTheme(theme: ThemeType) {
	return theme === ThemeType.DARK ? darkTheme : lightTheme;
}
export const getThemeType = () => localStore.getItem('theme');
