import {merge} from 'rxjs';
import {delay, mergeMap, take, tap} from 'rxjs/operators';
import {webSocketOutput$$} from '../../services/ws/webSocket';
import {WsMessageType} from '../../services/ws/incomingMessagesTypes.ws';
import {getChatStateService$, getUserInfoService$} from '../../services/userServices';
import {refreshTokenResultsFirebase$} from '../../services/firebaseAuth';
import {store} from '../../store/store';
import {usersActions} from '../../store/slices/users';
import {selectClientId, selectClientRole} from '../../store/slices/user';
import {loadGuestData$} from './useLoadLoggedGuestData';
import {
	ChoiceNotificationJoinRoomText,
	Notification,
	showChoiceNotification,
	showNotification
} from '../showNotification';
import {modalsSystem} from '../../singleComponents/modals/modalSystem';
import {ModalType} from '../../singleComponents/modals/modal.types';
import {listRoomsService$, UserRole} from '../../services/roomServices';
import {roomGlobalRef} from '../../pages/Room/utils/roomGlobalRef';
import {selectRoomId} from '../../store/slices/room';
import {ticketsActions} from '../../store/slices/tickets';
import {appNavigate} from '../../App';
import {messagesActions, selectMessageById, selectMessagesByChatId} from '../../store/slices/messages';
import {chatsActions, selectChatById} from '../../store/slices/chats';
import {selectNotification, viewActions} from '../../store/slices/view';
import {convertChatMessages} from '../../singleComponents/drawers/ChatsPanel/common';
import {convertChats} from '../../singleComponents/drawers/ChatsPanel/tabs/PrivateChatTab/ChatList/ChatList';
import {listUserChatMessages$} from '../../services/chat/chatServices';

export const websocketListenersSubscription = () => {
	return merge(
		merge(
			webSocketOutput$$(WsMessageType.DISCONNECT_SESSION),
			webSocketOutput$$(WsMessageType.DISCONNECT_USER)
		).pipe(
			take(1),
			//@ts-ignore
			tap(() => window.wsReconnection = true),
			mergeMap(() => modalsSystem(ModalType.INFO, {
				text: 'You\'ve been disconnected. Please refresh.',
				title: 'Connection Error',
				btnText: 'REFRESH'
			})),
			tap(() => window.location.reload())
		),
		webSocketOutput$$(WsMessageType.DELIVER_ROOM_INVITE).pipe(tap((message) => {
			const isUserInThatRoom = selectRoomId(store.getState()) === message.invite?.roomId;
			if (isUserInThatRoom) return;
			listRoomsService$({idsList: [message.invite!.roomId]}).subscribe((data) => {
				getUserInfoService$(message.invite!.ownerId).subscribe((user) => {
					showChoiceNotification({
						type: Notification.SUCCESS, text: ChoiceNotificationJoinRoomText(user.name, data.roomsList[0].name), onConfirm: () => {
							const userIsInRoom = !!roomGlobalRef.currentRoomPid;
							if (userIsInRoom) {
								modalsSystem(ModalType.INFO, {
									title: 'Warning',
									text: `If you accept the invite your current connection will be terminated`,
									cancelButton: true
								}).pipe(
									// TODO change this dirty hack if someone has idea.
									tap(() => appNavigate({pathname: `/`})),
									delay(1),
									tap(() => appNavigate({pathname: `/i/${message.invite?.pid}`}))
								).subscribe();
							} else {
								appNavigate({pathname: `/i/${message.invite?.pid}`});
							}
						}
					});
				});
			});
		})),
		webSocketOutput$$(WsMessageType.REFRESH_TOKEN).pipe(
			mergeMap(() => refreshTokenResultsFirebase$()),
			mergeMap(() => loadGuestData$())
		),
		webSocketOutput$$(WsMessageType.REMIND_MAINTENANCE_BREAK).pipe(tap((message) => {
			showNotification(Notification.INFO, `Maintenance break will start in ${message.minutesLeft} minutes`);
		})),
		webSocketOutput$$(WsMessageType.ENABLE_MAINTENANCE_BREAK).pipe(
			tap((message) => {
				modalsSystem(ModalType.SPINNING, {
					title: 'Maintenance Break',
					topText: 'We are switching application to maintenance mode to make some improvements.',
					bottomText: message.duration ? `Approximate duration is ${Math.round(message.duration / 60)}h` : undefined,
					preventCoverClick: true
				}).subscribe();
			}),
			delay(3000),
			tap(() => {
				const clientRole = selectClientRole(store.getState());
				if (clientRole < UserRole.ADMIN) {
					window.location.reload();
				}
			})
		),
		webSocketOutput$$(WsMessageType.REFRESH_WEBAPP).pipe(
			tap(() => {
				modalsSystem(ModalType.SPINNING, {
					title: 'Repair Tool',
					topText: `We detected problem with your instance. Our repair tool will refresh the page to fix it for you.`,
					preventCoverClick: true
				}).subscribe();
			}),
			delay(3000),
			tap(() => window.location.reload())
		),
		webSocketOutput$$(WsMessageType.CLEAR_CHAT).pipe(tap(({chatId}) => {
			const messageIds = selectMessagesByChatId(chatId)(store.getState()).map((msg) => msg.id)
			store.dispatch(messagesActions.removeMessages(messageIds))
			store.dispatch(viewActions.setNotification({active: false}))
		})),
		webSocketOutput$$(WsMessageType.UPDATE_CHAT_STATUS).pipe(tap(({id, status}) => {
			store.dispatch(chatsActions.updateChat({
				id,
				changes: {status}
			}));
		})),
		webSocketOutput$$(WsMessageType.ROOM_CHAT_MESSAGE).pipe(tap((msg) => {
			const clientId = selectClientId(store.getState())
			if (msg.message?.userId === clientId) {
				store.dispatch(messagesActions.updateMessage({id: msg.fid, changes: {id: msg.message?.id, status: 'SENT'}}))
			} else {
				store.dispatch(viewActions.setNotification({active: true, type: 'CASA_CHAT', entryId: msg.message?.id}))
				const convertedMessages = convertChatMessages([msg.message!])
				convertedMessages.length && store.dispatch(messagesActions.addMessage(convertedMessages[0]))
			}
		})),
		webSocketOutput$$(WsMessageType.EVENT_CHAT_MESSAGE).pipe(tap((msg) => {
			const clientId = selectClientId(store.getState())
			if (msg.message?.userId === clientId) {
				store.dispatch(messagesActions.updateMessage({id: msg.fid, changes: {id: msg.message?.id, status: 'SENT'}}))
			} else {
				store.dispatch(viewActions.setNotification({active: true, type: 'EVENT_CHAT', entryId: msg.message?.id}))
				const convertedMessages = convertChatMessages([msg.message!])
				convertedMessages.length && store.dispatch(messagesActions.addMessage(convertedMessages[0]))
			}
		})),
		webSocketOutput$$(WsMessageType.UPDATE_ROOM_CHAT_MESSAGE_CONTENT).pipe(tap(({id, content, timestamp}) => {
			store.dispatch(messagesActions.updateMessage({id: id, changes: {content: content, updatedAt: timestamp}}))
		})),
		webSocketOutput$$(WsMessageType.DELETE_ROOM_CHAT_MESSAGE).pipe(tap(({id}) => {
			store.dispatch(messagesActions.removeMessage(id))

			const notification = selectNotification(store.getState())
			if (notification.active && notification.entryId === id) {
				store.dispatch(viewActions.setNotification({active: false}))
			}
		})),
		webSocketOutput$$(WsMessageType.PRIVATE_CHAT_MESSAGE).pipe(tap((msg) => {
			const clientId = selectClientId(store.getState())
			const chat = selectChatById(msg.message!.chatId!)(store.getState())
			if (!chat) {
				getChatStateService$(msg.message?.userId!).subscribe((result) => {
					const convertedChats = convertChats([result.info!])
					convertedChats.length && store.dispatch(chatsActions.addChat(convertedChats[0]))
				})
			}
			const convertedMessages = convertChatMessages([msg.message!])
			if (msg.message?.userId === clientId) {
				const found = !!selectMessageById(store.getState(), msg.fid);
				if (found) {
					store.dispatch(messagesActions.updateMessage({id: msg.fid, changes: {id: msg.message?.id, status: 'SENT'}}))
				} else {
					convertedMessages.length && store.dispatch(messagesActions.addMessage(convertedMessages[0]))
				}
			} else {
				store.dispatch(viewActions.setNotification({active: true, type: 'PRIVATE_CHAT', entryId: msg.message?.chatId}))
				convertedMessages.length && store.dispatch(messagesActions.addMessage(convertedMessages[0]))
			}
			store.dispatch(chatsActions.updateChat({id: msg.message!.chatId, changes: {lastMessage: convertedMessages[0]}}))
		})),
		webSocketOutput$$(WsMessageType.UPDATE_PRIVATE_CHAT_MESSAGE_CONTENT).pipe(tap(({id, content, timestamp}) => {
			const message = selectMessageById(store.getState(), id)
			if (message) {
				const chat = selectChatById(message.chatId)(store.getState())
				if (chat && chat.lastMessage?.id === message.id) {
					store.dispatch(chatsActions.updateChat({id: chat.id, changes: {lastMessage: {...chat.lastMessage, content: content}}}))
				}
			}
			store.dispatch(messagesActions.updateMessage({id: id, changes: {content: content, updatedAt: timestamp}}))
		})),
		webSocketOutput$$(WsMessageType.DELETE_PRIVATE_CHAT_MESSAGE).pipe(tap(({id, chatId}) => {

			const chat = selectChatById(chatId)(store.getState())
			if (chat && chat.lastMessage?.id === id) {
				const messages = selectMessagesByChatId(chat.id)(store.getState())
				if (messages.length > 2) {
					store.dispatch(chatsActions.updateChat({id: chat.id, changes: {lastMessage: messages[1]}}))
				} else {
					 listUserChatMessages$(chatId).subscribe((result) => {
						 const messages = convertChatMessages(result.messagesList)
						 if (messages.length) {
							 store.dispatch(messagesActions.addMessages(messages))
							 store.dispatch(chatsActions.updateChat({id: chat.id, changes: {lastMessage: messages[0]}}))
						 } else {
							 store.dispatch(chatsActions.updateChat({id: chat.id, changes: {lastMessage: undefined}}))
						 }
					 })
				}
			}
			store.dispatch(messagesActions.removeMessage(id))

			const notification = selectNotification(store.getState())
			if (notification.active && notification.entryId === id) {
				store.dispatch(viewActions.setNotification({active: false}))
			}
		})),

		///
		/// Users
		///
		webSocketOutput$$(WsMessageType.CREATE_USER_TICKET).pipe(tap((response) => {
			store.dispatch(ticketsActions.addTicket(response.ticket));
		})),
		webSocketOutput$$(WsMessageType.UPDATE_USER_STATUS).pipe(tap((response) => {
			const {status, id} = response;
			store.dispatch(usersActions.updateUser({id, changes: {status}}));
		})),
		webSocketOutput$$(WsMessageType.UPDATE_USER_AVATAR).pipe(tap((response) => {
			const {avatarUrl, id} = response;
			store.dispatch(usersActions.updateUser({id, changes: {avatarUrl}}));
		})),
		webSocketOutput$$(WsMessageType.UPDATE_USER_NAME).pipe(tap((response) => {
			const {name, id} = response;
			store.dispatch(usersActions.updateUser({id, changes: {name}}));
		}))
	).subscribe();
};
