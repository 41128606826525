import {clearStreamsSubscriptions, roomGlobalRef} from '../roomGlobalRef';
import {selectClientId, selectIsConnectedWithWs} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {roomUsersActions, selectRoomUsers} from '../../../../store/slices/roomUsers';
import {mapTo, mergeMap, take, tap} from 'rxjs/operators';
import {selectRoomType} from '../../../../store/slices/room';
import {pcReceiverOfferAndChannelReady$, sfuReceiverChannelOutput$$} from './pcReceiverOfferAndChannelReady';
import {SfuType} from '../../../../services/gRPC/sfu/enums_pb';
import {initPcReceiverListeners} from './initPcReceiverListeners';
import {initPcReceiver} from './initPc';
import {EMIT} from '../../../../utils/utils';
import {forkJoin, Subscription, timer} from 'rxjs';
import {SfuMessageType} from '../../../../services/sfu/incomingMessagesTypes.sfu';
import {SubscriptionState} from '../SubscriptionState';
import {closeNotification, Notification, showNotification, showPermanentNotification} from '../../../../utils/showNotification';

let sub: Subscription | undefined;
let timerSub: Subscription | undefined;

export const reconnectPcReceiver = (forceReconnect?: boolean) => {

	if (forceReconnect) {
		sub?.unsubscribe();
	} else if (sub && !sub.closed) {
		return;
	}

	sub = EMIT.pipe(
		tap(() => {
			roomGlobalRef.pcMediaReceiver?.close();
			roomGlobalRef.unusedAudioTransceivers = [];
			roomGlobalRef.unusedVideoTransceivers = [];
			roomGlobalRef.queuedCandidates.clearCandidates(SfuType.STYPE_ROUTER);
			clearStreamsSubscriptions();

			const clientId = selectClientId(store.getState());
			store.dispatch(roomUsersActions.clearRoomUsersMediaTracks({excludedId: clientId}));

			closeNotification('connected');
			showPermanentNotification(Notification.WARNING, 'We detected some connection issues, reconnecting...', 'reconnection');
			initPcReceiver(selectRoomType(store.getState())!);
			initPcReceiverListeners();

			timerSub?.unsubscribe();
			timerSub = timer(40000).pipe(tap(() => {
				if(roomGlobalRef.pcMediaReceiver?.connectionState === 'new') {
					reconnectPcReceiver(true);
				}
			})).subscribe()
		}),
		mergeMap(() => forkJoin([
				sfuReceiverChannelOutput$$(SfuMessageType.LAYOUT).pipe(take(1)),
				pcReceiverOfferAndChannelReady$()
			])
		),
		tap(() => {
			const roomUsers = selectRoomUsers(store.getState());
			const clientId = selectClientId(store.getState());
			const a = SubscriptionState.roomUsersToBase(roomUsers, clientId);
			roomGlobalRef.currentSubscriptionState.applyBase(a);
			roomGlobalRef.subscriptionManager!.applySubs();
		}),
		tap(() => {
			if(roomGlobalRef.pcMediaSender?.connectionState === 'connected' && selectIsConnectedWithWs(store.getState())) {
				closeNotification('reconnection')
				showNotification(Notification.SUCCESS, 'You have been connected with a server correctly', 'connected')
			}
		}),
		mapTo('connected')
	).subscribe({
		error: (err) => {
			console.error('receiver reconnection err', err);
			timerSub?.unsubscribe();
		}
	});

};
