import {createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

type MsgStatus = 'SENDING' | 'SENT' | 'FAILED'

export interface Message {
	id: string;
	chatId: string;
	senderId: string
	content: string;
	avatarUrl: string
	senderName: string
	status: MsgStatus
	createdAt: number;
	updatedAt: number;
}

const messagesAdapter = createEntityAdapter<Message>({
	selectId: (msg) => msg.id
});

export const messagesSlice = createSlice({
	name: 'messages',
	initialState: messagesAdapter.getInitialState(),
	reducers: {
		setMessage: messagesAdapter.setOne,
		setMessages: messagesAdapter.setAll,
		addMessages: messagesAdapter.addMany,
		addMessage: messagesAdapter.addOne,
		removeMessage: messagesAdapter.removeOne,
		removeMessages: messagesAdapter.removeMany,
		updateMessage: messagesAdapter.updateOne,
	}
});

export const messagesActions = messagesSlice.actions;
export const selectMessages = messagesAdapter.getSelectors<RootState>((state) => state.messages).selectAll;
export const selectMessageById = messagesAdapter.getSelectors<RootState>((state) => state.messages).selectById;
export const selectMessagesByChatId = (chatId: string) => {
	return createSelector(
		selectMessages,
		messages => messages.filter(msg => msg.chatId === chatId).sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
	);
};
