import styled from 'styled-components';
import {remm} from '../../utils/remm';
import {RED, RED_LIGHT} from '../../styles/constants';

export const ErrorTextS = styled.div`
	min-height: ${remm(18)};
	margin: ${remm(2)} ${remm(12)};
	color: ${({theme}) => theme.dark ? RED_LIGHT : RED};
	font-weight: 600;
	font-size: ${remm('13px')};
`;
