import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {VideoData} from '../../services/roomStreamHelpers';
import {initialResult} from '../../singleComponents/drawers/Playlist/helpers';
import {envVars} from '../../environments/env';

export enum Suggestion {
  ZUCASA = 'ZuCasa',
  YOUTUBE = 'YouTube',
  TIKTOK = 'TikTok'
}

export interface Result {
  input: string,
  youtubeToken: string,
  results: VideoData[],
  tiktokTotal: number,
  tiktokCurrent: number,
  eventTotal: number,
  eventCurrent: number
}

interface PlaylistSearchState {
  result: Result
  loading: boolean
  currentlyAdding: string[]
  filteredSuggestions: Suggestion[]
  selectedSuggestions: Suggestion[]
}

export const suggestions = Object.values(Suggestion).filter(s => envVars.playlistConfig[s]);

const initialState: PlaylistSearchState = {
  result: initialResult,
  loading: false,
  currentlyAdding: [],
  filteredSuggestions: [],
  selectedSuggestions: []
};

export const playlistSearchSlice = createSlice({
  name: 'playlistSearch',
  initialState,
  reducers: {
    setResult: (state, action: PayloadAction<Result>) => {
      state.result = action.payload;
    },
    setVideo: (state, action: PayloadAction<VideoData>) => {
      state.result.results = [action.payload]
      state.result.youtubeToken = ''
      state.result.tiktokCurrent = 0
      state.result.tiktokTotal = 0
      state.result.eventCurrent = 0
      state.result.eventTotal = 0
    },
    addVideos: (state, action: PayloadAction<{ videos: VideoData[], result: Partial<Result> }>) => {
      state.result = {
        ...state.result,
        results: [ ...state.result.results, ...action.payload.videos ],
        ...action.payload.result
      }
    },
    setInput: (state, action: PayloadAction<string>) => {
      state.result.input = action.payload;
    },
    clearResult: (state) => {
      state.result.results = []
      state.result.youtubeToken = ''
      state.result.tiktokCurrent = 0
      state.result.tiktokTotal = 0
      state.result.eventCurrent = 0
      state.result.eventTotal = 0
    },
    addToCurrentlyAdding: (state, action: PayloadAction<string>) => {
      state.currentlyAdding.push(action.payload)
    },
    removeFromCurrentlyAdding: (state, action: PayloadAction<string>) => {
      const index = state.currentlyAdding.findIndex(ca => ca === action.payload)
      state.currentlyAdding.splice(index, 1);
    },
    setFilteredSuggestions: (state, action: PayloadAction<Suggestion[]>) => {
      state.filteredSuggestions = action.payload
    },
    toggleSelectedSuggestion: (state, action: PayloadAction<Suggestion>) => {
      if (state.selectedSuggestions.includes(action.payload)) {
        const index = state.selectedSuggestions.findIndex(ss => ss === action.payload);
        state.selectedSuggestions.splice(index, 1);
      } else {
        state.selectedSuggestions.push(action.payload);
      }
    }
  }
});

export const playlistSearchActions = playlistSearchSlice.actions;

export const selectSelectedSuggestions = (state: RootState) => state.playlistSearch.selectedSuggestions
export const selectFilteredSuggestions = (state: RootState) => state.playlistSearch.filteredSuggestions
export const selectCurrentlyAdding = (state: RootState) => state.playlistSearch.currentlyAdding
export const selectResult = (state: RootState) => state.playlistSearch.result
export const selectIsLoading = (state: RootState) => state.playlistSearch.loading;
export const selectIsMoreVideosAvailable = createSelector(
  selectSelectedSuggestions,
  selectResult,
  (selectedSuggestions, result) => {
    return !!((selectedSuggestions.includes(Suggestion.ZUCASA) && (result.eventTotal > result.eventCurrent))
      || (selectedSuggestions.includes(Suggestion.YOUTUBE) && result.youtubeToken)
      || (selectedSuggestions.includes(Suggestion.TIKTOK) && result.tiktokCurrent));
  }
);
