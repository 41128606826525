import styled, {css} from 'styled-components';
import {BoxProps} from './Box';
import {remm} from '../../utils/remm';

export const BoxS = styled.div<BoxProps>`
	display: flex;
	flex-direction: column;
	padding: ${props => (props.title ? '0' : remm('24px'))} ${remm('32px')} ${remm('32px')};
	${({width}) => width && 'width: ' + width};
	${({minHeight}) => minHeight && 'min-height: ' + minHeight};
	${({height}) => height && 'height: ' + height};
	background-color: ${({theme}) => theme.color2};
	margin: auto;
	border-radius: 36px;
	color: ${({theme}) => theme.textColor};
	${({theme}) => theme.boxShadow};

	.BoxS_header {
		position: relative;
		padding: 0 ${remm('12px')} ${remm('24px')};
		margin-bottom: ${remm('4px')};
	}

	.BoxS_body {
		${({height}) => height && css`
			flex-grow: 1;
			height: 0;
		`};
	}

	.BoxS_title {
		font-size: ${remm('30px')};
		font-weight: 600;
		letter-spacing: normal;
		color: ${({theme}) => theme.textColor};
	}
`;
