import React, {FC, useRef, useState} from 'react';
import {UserSearchS} from './UserSearchS';
import {searchUsersService$} from '../../services/userServices';
import debounce from 'lodash.debounce';
import {ContextMenu} from '../ContextMenu/ContextMenu';
import {ChatAvatar} from '../../singleComponents/Avatars/Chat/ChatAvatar';
import Popup from 'reactjs-popup';
import {Input} from '../Input/Input';
import {UserInfo} from '../../services/gRPC/users/models_pb';
import {remm} from '../../utils/remm';
import {useAppSelector} from '../../store/hooks';
import {selectClientId} from '../../store/slices/user';

interface UserSearchProps {
	onUserClick: (user: UserInfo.AsObject) => void
	onEmailSubmitted?: (email: string) => void
	ignoreEmailNotFound?: boolean
}
export const UserSearch: FC<UserSearchProps> = (props) => {
	const {onUserClick, onEmailSubmitted, ignoreEmailNotFound} = props

	const clientId = useAppSelector(selectClientId)

	const [users, setUsers] = useState<UserInfo.AsObject[]>([]);
	const [menuOpened, setMenuOpened] = useState(false)
	const inputRef = useRef<HTMLInputElement>(null)
	const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	const filterConfirmSigns = (text: string) => {
		return text.replace(',', '').replace(';', '')
	}

	const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (inputRef.current && [',', ';', 'Enter'].includes(e.key) && emailRegExp.test(filterConfirmSigns(inputRef.current.value))) {
			onEmailSubmitted && onEmailSubmitted(filterConfirmSigns(inputRef.current.value))
			setMenuOpened(false)
			inputRef.current.value = ''
		}

		if (inputRef.current && inputRef.current.value !== '') {
			searchUsersService$(inputRef.current.value).subscribe((users) => {
				const filteredUsers = users.filter((user) => user.id !== clientId)
				setUsers(filteredUsers);
				setMenuOpened(true)
			});
		}
	}

	const debounceSearchUsers = useRef(debounce(onKeyUp, 300));

	const prepareUsers = () => {

		if (inputRef.current && emailRegExp.test(inputRef.current.value) && ignoreEmailNotFound) {
			return (
				<div className='p-3' style={{fontSize: remm('13px')}}>
					press enter, comma or semicolon to confirm
				</div>
			)
		}

		if (!users.length) {
			return (
				<div className='p-3'>
					User Not found
				</div>
			)
		}

		return users.map((user) => (
			<ContextMenu.Button onFocus={() => {inputRef.current?.focus()}} key={user.id} buttonHeight='50px' autoFocus={false} onClick={() => {
				onUserClick(user)
				setMenuOpened(false)
			}}>
				<div className='d-flex align-items-center'>
					<ChatAvatar width='40px' height='40px' userId={user.id} imageUrl={user.avatarUrl}/>
					<div className='ml-2'>
						{user.name}
					</div>
				</div>
			</ContextMenu.Button>
		))
	}

	const onMenuClose = () => {
		setUsers([])
		setMenuOpened(false)
		inputRef.current && (inputRef.current.value = '')
	}

	return (
		<UserSearchS>
			<Popup position={'bottom left'}
			       offsetX={10}
			       open={menuOpened}
			       arrow={false}
			       on={[]}
			       onClose={onMenuClose}
			       trigger={() =>
				       <div>
					       <Input inputRef={inputRef} placeholder="Nickname" label="Find users" onKeyUp={(e) => debounceSearchUsers.current(e)}/>
				       </div>
			       }>
				<ContextMenu width={285}>
					{prepareUsers()}
				</ContextMenu>
			</Popup>
		</UserSearchS>
	);
};
