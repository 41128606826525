import {ActionCreatorWithPayload, AnyAction, PayloadAction} from '@reduxjs/toolkit';
import {defer, EMPTY, Observable, of} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';

export const batchedFilter = <T>(filter: ActionCreatorWithPayload<T>) => {
	return ((source) => defer(() => {
		return new Observable(subscriber => {
			source.pipe(
				mergeMap((action) => batchedFilterCore(filter)(action))
			).subscribe((action) => {
				subscriber.next(action);
			});
		});
	})) as (source: Observable<AnyAction>) => Observable<PayloadAction<T, typeof filter.type, never, never>>;
};

const batchedFilterCore = <T>(filter: ActionCreatorWithPayload<T>) => {
	return ((action) => {
		if (action.type === filter.type) {
			return of(action as PayloadAction<T, typeof filter.type, never, never>);
		} else {
			if (action?.meta?.batch) {
				let foundAction: AnyAction | undefined = undefined;
				action.payload?.forEach((action: AnyAction) => {
					batchedFilterCore(filter)(action).pipe(take(1)).subscribe(() => {
						foundAction = action;
					});
				});
				if (foundAction) {
					return of(foundAction as PayloadAction<T, typeof filter.type, never, never>);
				} else {
					return EMPTY;
				}
			} else {
				return EMPTY;
			}
		}
	}) as (action: AnyAction) => Observable<PayloadAction<T, typeof filter.type, never, never>>;
};

