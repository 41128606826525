import {EnvVars} from './env';

export const localEnv: EnvVars = {
	pageShortUrl: 'local.zu.casa',
	pageUrl: 'https://local.zu.casa',
	apiUsersUrl: 'https://local.zu.casa',
	apiRoomsUrl: 'https://local.zu.casa',
	apiWsUrl: 'wss://local.zu.casa/v1/connect',
	apiPaymentsUrl: 'https://local.zu.casa',
	apiBabySfuUrl: 'https://local.zu.casa',
	stripeSecret: 'pk_test_51HcDguD2alF1xQ1GEUEHjXAobdCdU4lPDU6MoIoRDov6AcaikaC4p1lehpHhwfj2p2OdZAKCg0lIXG1210DHhHnP00XXMUJ4YM',
	firebaseConfig: {
		apiKey: 'fake-api-key',
		authDomain: '',
		projectId: 'zucasa',
		storageBucket: '',
		messagingSenderId: '',
		appId: ''
	},
	supportedUsersVersion: '1.0.0',
	supportedRoomsVersion: '1.0.0',
	supportedPaymentsVersion: '1.0.0',
	chefsUnitedCasaId: 'b795028a-a212-4b0f-971f-bd656a749c98',
	demoEventIds: ['fe337443-4eb3-429a-a71f-5451c2852451'],
	mainPageEventId: '6beb5c39-8e77-4430-875c-259ee96ea9e8',
	apiYoutubeKey: 'AIzaSyAq0u1fXYyuQU1pLWI-764sWxxewV7FhP8',
	apiYoutubeUrl: 'https://www.googleapis.com/youtube/v3/videos',
	playlistConfig: {
		TikTok: true,
		YouTube: true,
		ZuCasa: true
	}
};
