import React, {useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {Slide} from 'react-toastify';
import {NotificationsContainer} from './utils/NotificationsContainer';
import {MainView} from './singleComponents/CoreViews/MainView';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {envVars} from './environments/env';
import {ThemeProvider} from 'styled-components';
import {darkTheme, lightTheme, ThemeType} from './styles/theme';
import {useAppSelector} from './store/hooks';
import {selectTheme} from './store/slices/user';
import {listenToTooltips} from './utils/tooltipDetector';

export let appNavigate: NavigateFunction;

listenToTooltips();

function App() {
	const theme = useAppSelector(selectTheme);

	const [stripePromise] = useState(() => loadStripe(envVars.stripeSecret));
	appNavigate = useNavigate();

	return (
		<ThemeProvider theme={theme === ThemeType.DARK ? darkTheme : lightTheme}>
			<Elements stripe={stripePromise} options={{
				fonts: [
					{cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&display=swap'}
				]
			}}>
				<MainView/>
				<NotificationsContainer limit={4} newestOnTop={false} hideProgressBar={true} transition={Slide} closeOnClick={true}/>
			</Elements>
		</ThemeProvider>
	);
}

export default App;

