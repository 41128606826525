import React from 'react';
import styled, {keyframes} from 'styled-components';
import {componentChildren} from '../../utils/utils';

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const SpinnerP = styled.div<{width?: string, height?: string}>`
	display: inline-block;
	position: relative;
	width: ${props => props.width ? props.width : '24px'};
	height: ${props => props.height ? props.height : '24px'};
`;

const Ring = styled.span<{width?: string, height?: string}>`
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: ${props => props.width ? `${parseInt(props.width) - parseInt(props.width) / 4}px` : '18px'};
	height: ${props => props.height ? `${parseInt(props.height) - parseInt(props.height) / 4}px` : '18px'};
	margin: 2px;
	border: 2px solid currentColor;
	border-radius: 50%;
	animation: ${rotate360} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: currentColor transparent transparent transparent;

	:nth-child(1) {
		animation-delay: -0.45s;
	}

	:nth-child(2) {
		animation-delay: -0.3s;
	}

	:nth-child(3) {
		animation-delay: -0.15s;
	}
`;
const SpinnerS = componentChildren(SpinnerP, {Ring});

export default function Spinner(props?: {width?: string, height?: string}) {
	return (
		<SpinnerS width={props?.width} height={props?.height}>
			<SpinnerS.Ring width={props?.width} height={props?.height}/>
			<SpinnerS.Ring width={props?.width} height={props?.height}/>
		</SpinnerS>
	);
}
