import React, {FC, useCallback} from 'react';
import {Box} from '../../sharedComponents/Box/Box';
import {BrowserNotSupportedStyled} from './BrowserNotSupportedStyled';
import {BrowserType, isiOS, retrieveBrowserType} from '../../utils/browserTypeDetection';
import {remm} from '../../utils/remm';

const chromeLink = <a href="https://www.google.com/chrome/">Google Chrome</a>;
const edgeLink = <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>;
const operaLink = <a href="https://www.opera.com">Opera</a>;
const safariLink = <a href="https://www.apple.com/safari/">Safari</a>;

export const BrowserNotSupported: FC = () => {
	const getProperContent = useCallback(() => {
		if (isiOS() && retrieveBrowserType() !== BrowserType.SAFARI) {
			return <BrowserNotSupportediOSInfo/>;
		}
		if (retrieveBrowserType() !== BrowserType.FIREFOX) {
			return <BrowserVersionNotSupportedInfo type={retrieveBrowserType()}/>;
		}
		return <BrowserNotSupportedInfo/>;
	}, []);


	return (
		<BrowserNotSupportedStyled className="d-flex justify-content-center align-items-center">
			<Box title="Browser not supported" width={remm(560)}>
				{getProperContent()}
			</Box>
		</BrowserNotSupportedStyled>
	);
};

const BrowserNotSupportediOSInfo: FC = () => {
	return (
		<p className={'mx-4 my-0'}>
			Unfortunately, your browser isn’t supported on ZuCasa yet, but in the meantime, please use Safari for best
			experience. See you at the show!
		</p>
	);
};

const BrowserNotSupportedInfo: FC = () => {
	return (
		<p className={'mx-4 my-0'}>
			Unfortunately, your browser isn’t supported on ZuCasa yet, but in the meantime, please use {chromeLink} or {edgeLink} for best
			experience. Don’t have it? It's free and takes less than a minute to install! See you at the show!
		</p>
	);
};

const BrowserVersionNotSupportedInfo: FC<{ type: BrowserType }> = ({type}) => {
	const getBrowserLink = useCallback(() => {
		switch (type) {
			case BrowserType.CHROME:
				return chromeLink;
			case BrowserType.EDGE:
				return edgeLink;
			case BrowserType.SAFARI:
				return safariLink;
			case BrowserType.OPERA:
				return operaLink;
		}
	}, []);

	return (
		<>
			Unfortunately your browser version is not supported anymore. Please visit {getBrowserLink()} page and download the newest version.
			See you at the show!
		</>
	);
};
