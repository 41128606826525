import {LocalStoreGetter, LocalStoreSetter} from './types';
import {Resolution} from '../pages/Room/utils/getResolution.types';
import {ThemeType} from '../styles/theme';
import {AudioOptions} from '../store/slices/user';

export interface LocalStoreState {
	areBitratesShown: boolean | undefined;
	areStatsDisabled: boolean | undefined;
	isMuted: boolean | undefined;
	isCamOff: boolean | undefined;
	audioInput: string | undefined;
	videoInput: string | undefined;
	resolution: Resolution | undefined;
	theme: ThemeType | undefined;
	maxRes: string | undefined;
	audioOptions: AudioOptions | undefined;
	determineAutomatically: boolean | undefined;
	inputSensitivity: number | undefined;
	inputGain: number | undefined;
}

export const localStore = {
	setItem: ((key: string, value: any) => {
		const valueJSON = JSON.stringify(value);
		localStorage.setItem(key, valueJSON);
	}) as LocalStoreSetter,

	getItem: ((key: string) => {
		const valueJSON = localStorage.getItem(key);
		try {
			return valueJSON ? JSON.parse(valueJSON) : undefined;
		} catch {
		}
	}) as LocalStoreGetter

};
