import {roomGlobalRef, RoomMode} from '../roomGlobalRef';
import {selectClientId, selectIsConnectedWithWs} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {pcSenderOfferAndChannelReady$} from './pcSenderOfferAndChannelReady';
import {initPcSenderListeners} from './initPcSenderListeners';
import {mapTo, mergeMap, tap} from 'rxjs/operators';
import {initPcSender} from './initPc';
import {EMIT} from '../../../../utils/utils';
import {Subscription, timer} from 'rxjs';
import {SfuType} from '../../../../services/gRPC/sfu/enums_pb';
import {closeNotification, Notification, showNotification, showPermanentNotification} from '../../../../utils/showNotification';
import {recalculateMaxBitrate} from '../updaters/useLimitMaxBitrate';

let sub: Subscription | undefined;
let timerSub: Subscription | undefined;

// TODO: sometimes user knows he is reconnecting but other users do not know and we have no idea why :( (appeared on iphone during long reconnect with turning wifi off and on)
// TODO: on client side synchronize reconnection notify with reconnecting message in video element
export const reconnectPcSender = (forceReconnect?: boolean) => {

	if (forceReconnect) {
		sub?.unsubscribe();
	} else if (sub && !sub.closed) {
		return;
	}
	sub = EMIT.pipe(
		tap(() => {
			roomGlobalRef.pcMediaSender!.close();
			roomGlobalRef.queuedCandidates.clearCandidates(SfuType.STYPE_GATEWAY);

			closeNotification('connected');
			showPermanentNotification(Notification.WARNING, 'We detected some connection issues, reconnecting...', 'reconnection')
			initPcSender(selectClientId(store.getState()));
			initPcSenderListeners();
			timerSub?.unsubscribe();
			timerSub = timer(40000).pipe(tap(() => {
				if(roomGlobalRef.pcMediaSender?.connectionState === 'new') {
					reconnectPcSender(true);
				}
			})).subscribe()
		}),
		mergeMap(() => pcSenderOfferAndChannelReady$()),
		tap(() => {
			const clientId = selectClientId(store.getState());

			if (roomGlobalRef.clientAudioDestinationTrack) {
				roomGlobalRef.audioTransceiversMap.get(clientId)?.sender.replaceTrack(roomGlobalRef.clientAudioDestinationTrack);
			}
			if (roomGlobalRef.clientCameraTrack) {
				roomGlobalRef.cameraTransceiversMap.get(clientId)?.sender.replaceTrack(roomGlobalRef.clientCameraTrack);
			}
			if (roomGlobalRef.clientScreenAudioTrack) {
				roomGlobalRef.audioTransceiversMap.get(`screen-audio-${clientId}`)?.sender.replaceTrack(roomGlobalRef.clientScreenAudioTrack);
			}
			if (roomGlobalRef.clientScreenVideoTrack) {
				roomGlobalRef.cameraTransceiversMap.get(`screen-${clientId}`)?.sender.replaceTrack(roomGlobalRef.clientScreenVideoTrack);
			}

			if(roomGlobalRef.clientCameraTrack || roomGlobalRef.clientScreenAudioTrack) {
				recalculateMaxBitrate(true);
			}
		}),
		tap(() => {
			if((roomGlobalRef.currentMode === RoomMode.NO_RECEIVER || roomGlobalRef.pcMediaReceiver?.connectionState === 'connected') && selectIsConnectedWithWs(store.getState())) {
				closeNotification('reconnection')
				showNotification(Notification.SUCCESS, 'You have been connected with a server correctly', 'connected')
			}
		}),
		mapTo('connected')
	).subscribe({
		error: (err) => {
			console.error('sender reconnection err', err);
			timerSub?.unsubscribe();
		}
	});

};
