import styled, {css} from 'styled-components';
import {remm} from '../../../../utils/remm';
import {BLACK_MEDIUM, WHITE_STRONG} from '../../../../styles/constants';


export const PlaylistElementS = styled.div<{ thumbnailUrl: string, disabled?: boolean }>`
	display: flex;
	align-items: center;
	margin-bottom: ${remm(10)};
	
	${({disabled}) => disabled && css`
		opacity: 0.3;
	`}
	
	&:hover {
		background-color: ${({theme}) => theme.color3};
		.PlaylistS_element-rightSlot {
			opacity: 1;
		}
	}

	.icon-menu {
		font-size: ${remm(20)};
	}

	.PlaylistS_element-thumbnail {
		width: ${remm(70)};
		height: ${remm(70)};
		background-size: cover;
		background-position: center;
		background-image: url("${({thumbnailUrl}) => thumbnailUrl}");
		position: relative;
	}
	
	.PlaylistS_element-leftSlot {
		width: 1.5rem;
		text-align: center;
	}

	.PlaylistS_element-rightSlot {
		text-align: center;
		opacity: 0;
		transition: opacity 0.1s linear;
		padding: ${remm(6)}
	}

	.PlaylistS_element-rightSlotVisible {
			opacity: 1;
	}

	.PlaylistS_element-type {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 2px;
		bottom: 2px;
		background-color: ${BLACK_MEDIUM};
		border-radius: ${remm(6)};
		min-height: 22px;
		
		i {
			 color: ${WHITE_STRONG};
			::before {
				display: flex;
				align-items: center;
				justify-content: center;
				min-width: 22px;
			}
		}
	}

	.PlaylistS_element-description {
		padding: 0 8px;
		text-align: left;
		font-weight: 600;
		flex: 1;
		height: 63px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		font-size: ${remm(15)};
		cursor: default;
	}

	.PlaylistS_element-title {
		font-weight: 700;
		font-size: ${remm('15px')};
		letter-spacing: normal;
		width: 100%;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		color: ${({theme}) => theme.textColor};
	}

	.PlaylistS_element-duration {
		font-weight: 700;
		font-size: ${remm('11px')};
		letter-spacing: normal;
		color: ${({theme}) => theme.weakTextColor};
	}
`;
