import {EnvVars} from './env';

export const prodEnv: EnvVars = {
	pageShortUrl: 'zu.casa',
	pageUrl: 'https://zu.casa',
	apiUsersUrl: 'https://api.prod.zu.casa',
	apiRoomsUrl: 'https://api.prod.zu.casa',
	apiWsUrl: 'wss://ws.prod.zu.casa/v1/connect',
	apiPaymentsUrl: 'https://api.prod.zu.casa',
	apiBabySfuUrl: 'https://babysfu.prod.zu.casa',
	stripeSecret: 'pk_live_51HcDguD2alF1xQ1GnKdqbXTjYU9fAbG52JnXFXwlqKdAA2kZievpx9z1JrPKSnS883C887oPBlahvRS0HiSaIzST00QnE5n9JQ',
	firebaseConfig: {
		apiKey: 'AIzaSyAq0u1fXYyuQU1pLWI-764sWxxewV7FhP8',
		authDomain: 'auth.zu.casa',
		projectId: 'zucasa',
		storageBucket: 'zucasa.appspot.com',
		messagingSenderId: '49396334407',
		appId: '1:49396334407:web:4268d44542be515a355662'
	},
	supportedUsersVersion: '1.0.0',
	supportedRoomsVersion: '1.0.0',
	supportedPaymentsVersion: '1.0.0',
	chefsUnitedCasaId: '55d9bd7d-b244-4f5c-a166-ae131e7c0c20',
	demoEventIds: ['f6447ba2-7644-4b58-939c-d0795fd6406a'],
	mainPageEventId: '497135ba-9d2b-4d12-8294-92c4494a9c50',
	apiYoutubeKey: 'AIzaSyAq0u1fXYyuQU1pLWI-764sWxxewV7FhP8',
	apiYoutubeUrl: 'https://www.googleapis.com/youtube/v3/videos',
	playlistConfig: {
		TikTok: false,
		YouTube: true,
		ZuCasa: true
	}
}
