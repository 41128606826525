import {useEffect} from 'react';
import {roomGlobalRef, RoomMode} from '../roomGlobalRef';
import {RoomUserFlag} from '../../../../services/gRPC/rooms/enums_pb';
import {useAppSelector} from '../../../../store/hooks';
import {selectClientId, selectIsSharingScreenVideo} from '../../../../store/slices/user';
import {selectIsEventSourceStream, selectPerformerId} from '../../../../store/slices/room';
import {selectIsGridRendered, selectRoomUsers, selectScreenSharingUserId} from '../../../../store/slices/roomUsers';
import {store} from '../../../../store/store';
import {selectCurrentRoomStreamType, selectHasEventStarted} from '../../../../store/slices/roomStreams';

export const MAX_BITRATE_TOTAL = 2000;

let videosAmountRef = 0;
let usersLengthRef = 0;
let areUsersOnLeftPanelRef = false;

const getMaxBitrate = (videosAmount: number, roomUsersLength: number) => {
	if(roomGlobalRef.currentMode === RoomMode.NO_RECEIVER) return Math.ceil((MAX_BITRATE_TOTAL * 1000) / (roomUsersLength));
	if (videosAmount <= 1 && roomUsersLength === 1) return 50 * 1000;
	if (videosAmount <= 1 && roomUsersLength > 1) return 1200 * 1000;
	return Math.ceil((MAX_BITRATE_TOTAL * 1000) / (videosAmount));
};

export const recalculateMaxBitrate = (force?: boolean) => {
	const roomUsers = selectRoomUsers(store.getState());
	const clientId = selectClientId(store.getState());
	const isClientPerformer = selectPerformerId(store.getState()) === clientId;
	const cameraSender = roomGlobalRef.pcMediaSender?.getSenders()[0];
	if (cameraSender) {
		const areUsersOnTheLeftPanel = !selectIsGridRendered(store.getState());
		const parameters = cameraSender.getParameters();
		if (areUsersOnTheLeftPanel) {
			if (isClientPerformer) {
				parameters.encodings = [{}];
			} else {
				if (parameters.encodings.length) {
					parameters.encodings[0].maxBitrate = 125 * 1000;
				} else {
					parameters.encodings = [{maxBitrate: 125 * 1000}];
				}
			}
			cameraSender.setParameters(parameters).catch((e: Error) => console.error(e));
		} else {
			const availableVideosAmount = roomUsers.filter((user) => !user.appliedFlags[RoomUserFlag.RUFLAG_DISABLED_VIDEO])
				.filter((user) => user.media.cameraTrackId).length;
			const hasAmountOfAvailableVideoChanged = videosAmountRef !== availableVideosAmount;
			const hasAmountOfRoomUsersChanged = usersLengthRef !== roomUsers.length;

			if (force || roomGlobalRef.maxVideoBitrate || (hasAmountOfAvailableVideoChanged) || hasAmountOfRoomUsersChanged || (areUsersOnTheLeftPanel !== areUsersOnLeftPanelRef)) {
				const maxBitrate = (roomGlobalRef.maxVideoBitrate && (roomGlobalRef.maxVideoBitrate * 1000)) || getMaxBitrate(availableVideosAmount, roomUsers.length);
				console.log('maxBitrate', maxBitrate, availableVideosAmount, roomUsers.length);
				if (parameters.encodings.length) {
					parameters.encodings[0].maxBitrate = maxBitrate;
				} else {
					parameters.encodings = [{maxBitrate: maxBitrate}];
				}
				videosAmountRef = availableVideosAmount;
				usersLengthRef = roomUsers.length;
				cameraSender.setParameters(parameters).catch((e: Error) => console.error(e));
			}
		}
		areUsersOnLeftPanelRef = areUsersOnTheLeftPanel;
	}

	const screenSenders = roomGlobalRef.pcMediaSender?.getSenders().filter((sender) =>
		sender.track?.label.startsWith('screen') || (sender.track?.contentHint && sender.track?.contentHint !== '')
	);

	if (screenSenders && screenSenders.length) {
		const screenSender = screenSenders[0];
		const parameters = screenSender.getParameters();
		const maxBitrate = (roomGlobalRef.maxScreenBitrate && roomGlobalRef.maxScreenBitrate * 1000) || 3 * 1000 * 1000;
		if (parameters.encodings.length) {
			parameters.encodings[0].priority = 'high';
			parameters.encodings[0].maxBitrate = maxBitrate;
		} else {
			parameters.encodings = [{priority: 'high', maxBitrate: maxBitrate}];
		}
		screenSender.setParameters(parameters).catch((e: Error) => console.error(e));
	}

}

export const useLimitMaxBitrate = () => {
	const screenSharingUserId = useAppSelector(selectScreenSharingUserId);
	const isClientSharingScreen = useAppSelector(selectIsSharingScreenVideo);
	const isEventSourceStream = useAppSelector(selectIsEventSourceStream);
	const hasEventStarted = useAppSelector(selectHasEventStarted);
	const performerId = useAppSelector(selectPerformerId);
	const roomUsers = useAppSelector(selectRoomUsers);
	const firstRoomStreamType = useAppSelector(selectCurrentRoomStreamType);

	useEffect(() => {
		recalculateMaxBitrate();
	}, [performerId, hasEventStarted, roomUsers, isEventSourceStream, screenSharingUserId, isClientSharingScreen, firstRoomStreamType]);
};
