import styled from 'styled-components';
import Popup from 'reactjs-popup';
import {remm} from '../../utils/remm';

export const TooltipS = styled(Popup)`

	&-content {
		font-size: ${remm(14)};
		padding: 8px;
		background-color: ${({theme}) => theme.dark ? theme.color4 : theme.color3};
		color: ${props => props.theme.textColor};
		border: 1px solid ${({theme}) => theme.borderWeak};
		border-radius: 12px;
		${({theme}) => theme.boxShadow};

		> div:first-child {
			filter: none !important;
		}


	}

	&-overlay {
		margin: auto;
		width: 50%;
		padding: 5px;
	}
`;
