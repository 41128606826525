import {roomGlobalRef} from '../roomGlobalRef';
import {SfuMessageType} from '../../../../services/sfu/incomingMessagesTypes.sfu';
import {Codec, StreamType} from '../../../../services/gRPC/sfu/enums_pb';
import pako from 'pako';
import {merge, race, Subject, timer} from 'rxjs';
import {changeCodecVP8, changeCodecVP9, renegotiation} from '../updaters/codecChanging';
import {
	selectClientId,
	selectHasClientCamOff,
	selectIsClientMuted,
	selectIsSharingScreenAudio,
	selectIsSharingScreenVideo
} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {selectClientFlags, selectScreenSharingUserId} from '../../../../store/slices/roomUsers';
import {closeNotification, Notification, showPermanentNotification} from '../../../../utils/showNotification';
import {mergeMap, tap} from 'rxjs/operators';
import {EMIT} from '../../../../utils/utils';
import {GridName} from '../gridLayoutUtlis';
import {sfuSenderChannelOutput$$} from './pcSenderOfferAndChannelReady';
import {reconnectPcSender} from './reconnectPcSender';
import {disconnectSFU} from '../../../../services/sfu/senders.sfu';
import {RoomUserFlag} from '../../../../services/gRPC/rooms/enums_pb';
import {updateClientFlagsService$} from '../updaters/useStoreServiceFlagUpdater';

type MediaType = 'camera video' | 'microphone audio' | 'screen video' | 'screen audio'
export type LayoutNames = GridName | 'sfu' | 'broadcast_one_16:9'
const noDataSubject = new Subject<MediaType>();
const stateMap = new Map<MediaType, number>();
const MIN_PACKETS = 5;

export const initPcSenderChannelListeners = () => {

	return merge(
		sfuSenderChannelOutput$$(SfuMessageType.LOCAL_INPUT_READY).pipe(
			tap((data) => {
				const clientFlags = selectClientFlags(store.getState());
				console.log('local input ready, ', data.streamtype);
				// send flags
				if (data.streamtype === StreamType.CAMERA) {
					if (clientFlags && !clientFlags[RoomUserFlag.RUFLAG_SHARING_VIDEO]) {
						updateClientFlagsService$({
							action: 'add',
							flags: [RoomUserFlag.RUFLAG_SHARING_VIDEO]
						}).subscribe();
					}
				} else if (data.streamtype === StreamType.AUDIO) {
					if (clientFlags && !clientFlags[RoomUserFlag.RUFLAG_SHARING_AUDIO]) {
						updateClientFlagsService$({
							action: 'add',
							flags: [RoomUserFlag.RUFLAG_SHARING_AUDIO]
						}).subscribe();
					}
				}
			})
		),

		sfuSenderChannelOutput$$(SfuMessageType.NO_DATA_PACKET).pipe(tap((data) => {
			//TODO check this thing
			const notify = (mediaType: MediaType) => {
				const current = stateMap.get(mediaType);
				if (current && current > MIN_PACKETS) {
					showPermanentNotification(
						Notification.ERROR,
						`We are not receiving your ${mediaType}. Please make sure if your device is set up correctly and try again`,
						mediaType
					);
					// pushes value to subject to cancel closing notification if packet constantly comes in
					noDataSubject.next(mediaType);
					scheduleCloseNotification(mediaType);
				}
				stateMap.set(mediaType, current === undefined ? 0 : current + 1);
			};

			const scheduleCloseNotification = (mediaType: MediaType) => {
				EMIT.pipe(
					mergeMap(() => race(
						noDataSubject.pipe(
							// if type is different from expected, force subject to lose the race - ensures that right notification will be closed
							mergeMap((val) => val === mediaType ? EMIT : timer(5000))
						),
						timer(4000).pipe(
							tap(() => {
								stateMap.set(mediaType, 0);
								closeNotification(mediaType);
							})
						)
					))
				).subscribe();
			};

			if (data.streamtype === StreamType.CAMERA && !selectHasClientCamOff(store.getState())) {
				notify('camera video');
			}
			if (data.streamtype === StreamType.AUDIO && !selectIsClientMuted(store.getState())) {
				notify('microphone audio');
			}
			if (data.streamtype === StreamType.SCREEN && selectIsSharingScreenVideo(store.getState())) {
				notify('screen video');
			}
			if (data.streamtype === StreamType.SCREEN_AUDIO && selectIsSharingScreenAudio(store.getState())) {
				notify('screen audio');
			}
		})),

		sfuSenderChannelOutput$$(SfuMessageType.VIDEO_CODEC_CHANGE_REQUIRED).pipe(tap((data) => {
			const newCodec = data.newCodec;
			if (roomGlobalRef.currentCodec === newCodec) {
				return;
			}
			switch (newCodec) {
				case Codec.VP8:
					console.log('codec changed to VP8');
					changeCodecVP8();
					renegotiation(300, 600, 1200, 500, 1000, 2000);
					break;
				case Codec.VP9:
					if (selectClientId(store.getState()) === selectScreenSharingUserId(store.getState())) {
						console.log('user is sharing screen, skipping codec change');
						break;
					}
					console.log('codec changed to VP9');
					changeCodecVP9();
					renegotiation(300, 600, 1200, 500, 1000, 2000);
					break;
				default:
					console.error(new Error(`Codec '${newCodec}' is not supported`));
					break;
			}
			roomGlobalRef.currentCodec = newCodec;
		})),

		sfuSenderChannelOutput$$(SfuMessageType.STREAMS_ANSWER_PACKET).pipe(tap((data) => {
			const uint8Array = Uint8Array.from(atob(data.sdpAnswer as string), c => c.charCodeAt(0));
			const offerSdpAnswer = JSON.parse(pako.inflate(uint8Array, {to: 'string'}));
			roomGlobalRef.pcMediaSender!.setRemoteDescription(offerSdpAnswer);
		})),

		sfuSenderChannelOutput$$(SfuMessageType.MIGRATION_REQUIRED).pipe(tap(() => {
			disconnectSFU(roomGlobalRef.mediaSenderServerChannel);
			reconnectPcSender();
		})),

		sfuSenderChannelOutput$$(SfuMessageType.DISCONNECTED).pipe(tap(() => {
			reconnectPcSender();
		}))
	);
};
