import {EMIT} from '../../../../utils/utils';
import {forkJoin, throwError} from 'rxjs';
import {mergeMap, take, tap} from 'rxjs/operators';
import {roomGlobalRef, RoomMode} from '../roomGlobalRef';
import {EventSource, EventStatus, RoomUserFlag} from '../../../../services/gRPC/rooms/enums_pb';
import {tokenizeMpdStreamEvent} from '../../../../utils/customHooks/useTokenizeMpdStreamEvent';
import {selectClientId, selectHasClientCamOff, selectIsClientMuted} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {selectRoomById} from '../../../../store/slices/rooms';
import {selectClientTicketByEventId} from '../../../../store/slices/tickets';
import {selectRoomUsers} from '../../../../store/slices/roomUsers';
import {selectIsPerformerRoom} from '../../../../store/slices/room';
import {startPerformerRtcListeners} from '../listeners/usePerformerRtcListeners';
import {initPcReceiverListeners} from '../listeners/initPcReceiverListeners';
import {initPcSenderListeners} from '../listeners/initPcSenderListeners';
import {SfuMessageType} from '../../../../services/sfu/incomingMessagesTypes.sfu';
import {disconnectPc} from '../listeners/disconnectPc';
import {initPcSenderChannelListeners} from '../listeners/initPcSenderChannelListeners';
import {initPcReceiverChannelListeners} from '../listeners/initPcReceiverChannelListeners';
import {pcReceiverOfferAndChannelReady$, sfuReceiverChannelOutput$$} from '../listeners/pcReceiverOfferAndChannelReady';
import {pcSenderOfferAndChannelReady$} from '../listeners/pcSenderOfferAndChannelReady';
import {SubscriptionState} from '../SubscriptionState';
import {updateClientFlagsService$} from '../updaters/useStoreServiceFlagUpdater';
import {selectEventId, selectEventSource, selectEventStatus} from '../../../../store/slices/roomStreams';

interface roomInitArgs {
	roomId: string;
}

export const roomInit$ = (args: roomInitArgs) => EMIT.pipe(
	mergeMap(() => {
		const roomId = args.roomId;
		const room = selectRoomById(roomId)(store.getState());

		if (!room) {
			return throwError(() => new Error('Room does not exist in store but it should be there. Error 2'));
		} else {
			return EMIT;
		}
	}),
	tap(() => {
		if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
			initPcReceiverListeners();
		}
		initPcSenderListeners();

		///
		/// disconnect when window.onunload [pcMediaReceiver, pcMediaSender]
		///
		window.onunload = () => {
			disconnectPc();
		};
	}),
	tap(() => {
		roomGlobalRef.roomSubscriptions.add(initPcSenderChannelListeners().subscribe());
		if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
			roomGlobalRef.roomSubscriptions.add(initPcReceiverChannelListeners().subscribe());
		}
	}),
	mergeMap(() => {
		if (roomGlobalRef.currentMode === RoomMode.NO_RECEIVER) {
			return forkJoin([
				EMIT,
				pcSenderOfferAndChannelReady$()
			]);
		}
		return forkJoin([
			sfuReceiverChannelOutput$$(SfuMessageType.LAYOUT).pipe(take(1)),
			pcReceiverOfferAndChannelReady$(),
			pcSenderOfferAndChannelReady$()
		]);
	}),
	tap(() => {
		/// When users set, add subscriptions based on flags
		const roomUsers = selectRoomUsers(store.getState());
		const clientId = selectClientId(store.getState());
		if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
			roomGlobalRef.currentSubscriptionState.applyBase(SubscriptionState.roomUsersToBase(roomUsers, clientId));
			roomGlobalRef.subscriptionManager!.applySubs();
		}

		/// When sfu connections ready, send (mute/camera off) flags in needed.
		const flags = [];
		selectIsClientMuted(store.getState()) && flags.push(RoomUserFlag.RUFLAG_DISABLED_AUDIO);
		selectHasClientCamOff(store.getState()) && flags.push(RoomUserFlag.RUFLAG_DISABLED_VIDEO);
		if (flags.length) {
			updateClientFlagsService$({action: 'add', flags}).subscribe({
				error: err => console.error(`error while updating flags: ${err}`)
			});
		}

		/// Event / Performer Room
		const eventSource = selectEventSource(store.getState());
		const eventStatus = selectEventStatus(store.getState());
		const isPerformerRoom = selectIsPerformerRoom(store.getState());
		if (eventStatus === EventStatus.ESTATUS_STARTED) {
			if (isPerformerRoom) {
				if (eventSource !== EventSource.ESOURCE_MCU) {
					tokenizeMpdStreamEvent();
				} else {
					console.log('testing - tokenizeMpdStreamEvent would run 2');
				}
			}
			console.log(`%cEvent STARTED`, 'color: aqua; font-weight: 900; background: black');
			if (!isPerformerRoom) {
				const eventId = selectEventId(store.getState());
				const isAnyTicketForTheEvent = selectClientTicketByEventId(eventId)(store.getState());
				if (isAnyTicketForTheEvent) {
					if (eventSource === EventSource.ESOURCE_MCU) {
						startPerformerRtcListeners();
					} else {
						tokenizeMpdStreamEvent();
					}
				} else {
					// do nothing - but we can show immediately modal to but ticket
				}
			}
		}
	})
);
