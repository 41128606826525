import React, {FC} from 'react';
import Spinner from '../../sharedComponents/Spinner/Spinner';
import {LoadingCoverS} from './LoadingCoverS';

export const LoadingCover: FC<{ withSpinner?: boolean }> = ({withSpinner}) => {
	return (
		<LoadingCoverS className="d-flex flex-column justify-content-center align-items-center vh-100">
			{withSpinner &&
			<div className="d-flex flex-column justify-content-center align-items-center">
				<Spinner/>
				<div>loading</div>
			</div>
			}

		</LoadingCoverS>
	);
};
