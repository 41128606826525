import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import {BrowserRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import './styles/bootstrap.scss';
import './styles/fonts/fontello/css/fontello.css';
import './styles/fonts/Greycliff CF/css/GreyCliffCf.css';
import './styles/fonts/Pumpkin Cheesecake/css/PumpkingCheesecake.css';
import 'focus-visible';
import {FocusVisible} from './singleComponents/FocusVisible/FocusVisible';
import {Provider} from 'react-redux';
import {store} from './store/store';
import {modalsSystem} from './singleComponents/modals/modalSystem';
import {ModalType} from './singleComponents/modals/modal.types';
import {tap} from 'rxjs/operators';
import {currentEnv, Environment} from './environments/env';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

export const mobileMinWidth = 1000;

if (window.location.href.includes('www.')) {
	const newLocation = window.location.href.replace('www.', '');
	window.location.replace(newLocation);
}

if (window.location.protocol !== 'https:' && !window.location.href.includes('localhost')) {
	window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

Sentry.init({
	dsn: 'https://06e571bb891946e8b273d88a462fa8c1@o880455.ingest.sentry.io/5834140',
	integrations: [new Integrations.BrowserTracing()],
	environment: process.env.REACT_APP_ENV,
	release: '1.0.0'
});

ReactDOM.render(
	<React.StrictMode>
		<Helmet>
			<meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests"/>
			// TODO remove unused fonts
			<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800;900&display=swap"
			      rel="stylesheet"/>
			<link href="https://fonts.googleapis.com/css2?family=Oswald&family=Pathway+Gothic+One&display=swap"
			      rel="stylesheet"/>
		</Helmet>
		<BrowserRouter>
			<FocusVisible className="js-focus-visible focus-visible">
				<Provider store={store}>
					<App/>
				</Provider>
			</FocusVisible>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

if (currentEnv !== Environment.Local) {
	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.register({
		onInit: (registration: ServiceWorkerRegistration) => {
			updateWorkerAndReload(registration);
			setInterval(() => {
				registration.update().catch(reason => {
					console.error(reason);
				});
				updateWorkerAndReload(registration);
			}, 60 * 1000);
		},
		onUpdate: (registration: ServiceWorkerRegistration) => {
			console.log('Updating zu.casa?');
			updateWorkerAndReload(registration);
		}
	});

	const initDate = Date.now();
	let canOpenUpdateModal = true;
	const updateWorkerAndReload = (registration: ServiceWorkerRegistration) => {
		if (registration.waiting) {
			const applyUpdateNow = () => {
				console.log('applying update!');
				registration.waiting!!.postMessage({type: 'SKIP_WAITING'});
				window.location.reload();
			};

			const sinceInit = Date.now() - initDate;
			//a new update is available
			if (sinceInit >= 10000) {
				if (!canOpenUpdateModal) {
					return;
				}

				canOpenUpdateModal = false;
				modalsSystem(ModalType.INFO, {
					title: 'Update Available',
					text: `A new zu.casa update is available.`,
					preventCoverClick: true,
					cancelButton: true,
					btnText: 'UPDATE NOW'
				}).pipe(
					tap(() => {
						applyUpdateNow();
					})
				).subscribe();
			} else {
				//not long has passed since init, force reload
				applyUpdateNow();
			}
		}
	};
}
