import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ChatInfo, EventPerformerInfo, RoomInfo} from '../../services/gRPC/rooms/models_pb';
import {RootState} from '../store';
import {EventSource, RoomType} from '../../services/gRPC/rooms/enums_pb';
import {Grid} from '../../pages/Room/utils/gridLayoutUtlis';
import {selectClientId} from './user';
import {selectEventSource} from './roomStreams';

interface RoomState {
	hasUserJoinRoomWs: boolean
	info?: RoomInfo.AsObject,
	chat?: ChatInfo.AsObject,
	isSfuScreenVideoInputReady: boolean
	isSfuScreenAudioInputReady: boolean
	performerHasAudioStream: boolean
	performerHasVideoStream: boolean
	performer?: EventPerformerInfo.AsObject,
	isGridLayoutForced: boolean,
	mcuVideoStreamData: {
		isMcuVideoStream: boolean
		grid?: Grid,
		trackId?: string // needed to refresh mcu video stream after sfu reconnect
	}
	userIdForReport?: string // for debugging tool
	upload?: number,
	download?: number,
	report?: string,
	serverPing: number,
}

const initialState: RoomState = {
	hasUserJoinRoomWs: false,
	isSfuScreenVideoInputReady: false,
	isSfuScreenAudioInputReady: false,
	performerHasVideoStream: false,
	performerHasAudioStream: false,
	isGridLayoutForced: false,
	mcuVideoStreamData: {
		isMcuVideoStream: false
	},
	serverPing: 0,
};

export const roomSlice = createSlice({
	name: 'room',
	initialState,
	reducers: {
		clearSlice: () => {
			return initialState;
		},
		setIsSfuScreenVideoInputReady: (state, action: PayloadAction<boolean>) => {
			state.isSfuScreenVideoInputReady = action.payload;
		},
		setIsSfuScreenAudioInputReady: (state, action: PayloadAction<boolean>) => {
			state.isSfuScreenAudioInputReady = action.payload;
		},
		setHasUserJoinRoomWs: (state, action: PayloadAction<boolean>) => {
			state.hasUserJoinRoomWs = action.payload;
		},
		setInfo: (state, action: PayloadAction<RoomInfo.AsObject>) => {
			state.info = action.payload;
		},
		setRoomName: (state, action: PayloadAction<string>) => {
			if (state.info?.name !== undefined) {
				state.info.name = action.payload;
			}
		},
		setRoomType: (state, action: PayloadAction<RoomType>) => {
			if (state.info?.type !== undefined) {
				state.info.type = action.payload;
			}
		},
		setRoomBackground: (state, action: PayloadAction<string>) => {
			if (state.info !== undefined) {
				state.info.backgroundUrl = action.payload;
			}
		},
		setChat: (state, action: PayloadAction<ChatInfo.AsObject>) => {
			state.chat = action.payload;
		},
		setPerformerMediaStream: (state, action: PayloadAction<{ type: 'audio' | 'camera' | 'screen', value: boolean }>) => {
			const type = action.payload.type;
			const value = action.payload.value;
			if (type === 'camera') {
				state.performerHasVideoStream = value;
			} else {
				state.performerHasAudioStream = value;

			}
		},
		setPerformer: (state, action: PayloadAction<EventPerformerInfo.AsObject | undefined>) => {
			state.performer = action.payload;
		},
		removePerformer: (state) => {
			state.performer = undefined;
		},
		setGridLayoutForced: (state, action: PayloadAction<boolean>) => {
			state.isGridLayoutForced = action.payload;
		},
		toggleGridLayoutForced: (state) => {
			state.isGridLayoutForced = !state.isGridLayoutForced;
		},
		setMcuVideoStreamEnabled: (state, action: PayloadAction<boolean>) => {
			state.mcuVideoStreamData.isMcuVideoStream = action.payload;
		},
		setMcuVideoStreamGrid: (state, action: PayloadAction<Grid | undefined>) => {
			state.mcuVideoStreamData.grid = action.payload;
		},
		setMcuVideoStreamTrackId: (state, action: PayloadAction<string | undefined>) => {
			state.mcuVideoStreamData.trackId = action.payload;
		},
		setRoomPid: (state, action: PayloadAction<{ pid: string }>) => {
			if (state.info?.pid) {
				state.info.pid = action.payload.pid;
			}
		},
		setUserIdForReport: (state, action: PayloadAction<string | undefined>) => {
			state.userIdForReport = action.payload;
		},
		setServerPing: (state, action: PayloadAction<number>) => {
			state.serverPing = action.payload;
		},
		setUpload: (state, action: PayloadAction<number>) => {
			state.upload = action.payload;
		},
		setDownload: (state, action: PayloadAction<number>) => {
			state.download = action.payload;
		},
		setReport: (state, action: PayloadAction<string>) => {
			state.report = action.payload;
		},
	}
});


export const roomActions = roomSlice.actions;

export const selectIsPerformerRoom = (state: RootState) => (state.room.info?.type === RoomType.RTYPE_BROADCAST);
export const selectIsEventSourceStream = createSelector(
	selectIsPerformerRoom,
	selectEventSource,
	(isPerformerRoom, source) => !!(
		isPerformerRoom && source && source !== EventSource.ESOURCE_MCU
	)
);
export const selectRoomPid = (state: RootState) => (state.room.info?.pid);
export const selectRoomAccess = (state: RootState) => (state.room.info?.access);
export const selectRoomId = (state: RootState) => (state.room.info?.id);
export const selectRoomName = (state: RootState) => (state.room.info?.name);
export const selectRoomInfo = (state: RootState) => (state.room?.info);
export const selectRoomLimit = (state: RootState) => (state.room.info?.limit);
export const selectRoomType = (state: RootState) => (state.room.info?.type);
export const selectRoomBackground = (state: RootState) => (state.room.info?.backgroundUrl);
export const selectIsSfuScreenVideoInputReady = (state: RootState) => (state.room.isSfuScreenVideoInputReady);
export const selectIsSfuScreenAudioInputReady = (state: RootState) => (state.room.isSfuScreenAudioInputReady);
export const selectHasPerformerVideo = (state: RootState) => (state.room.performerHasVideoStream);
export const selectHasPerformerAudio = (state: RootState) => (state.room.performerHasAudioStream);
export const selectIsMcuVideoStream = (state: RootState) => (state.room.mcuVideoStreamData.isMcuVideoStream);
export const selectMcuVideoStreamGrid = (state: RootState) => (state.room.mcuVideoStreamData.grid);
export const selectMcuTrackId = (state: RootState) => (state.room.mcuVideoStreamData.trackId);
export const selectPerformerId = (state: RootState) => (state.room.performer?.userId);
export const selectIsClientPerformer = createSelector(
	selectClientId,
	selectPerformerId,
	(id1, id2) => id1 === id2
);

export const selectRoomUserRole = (state: RootState) => state.room.info?.user?.role;
export const selectRoomUser = (state: RootState) => state.room.info?.user;
export const selectRoomChatId = (state: RootState) => state.room.chat?.id;
export const selectUserIdForReport = (state: RootState) => state.room.userIdForReport;
export const selectUpload = (state: RootState) => state.room.upload;
export const selectDownload = (state: RootState) => state.room.download;
export const selectReport = (state: RootState) => state.room.report;
export const selectServerPing = (state: RootState) => state.room.serverPing;
export const selectIsGridLayoutForced = (state: RootState) => state.room.isGridLayoutForced;
