export type GridName = 'grid_2x2' | 'grid_4x2' | 'grid_6x3' | 'grid_8x4' | 'grid_10x5'

export interface Grid {
	name: GridName
	columns: number,
	rows: number,
	cellSize: number,
}

export const grids: { [key in GridName]: Grid } = {
	grid_2x2: {
		name: 'grid_2x2',
		columns: 2,
		rows: 2,
		cellSize: 720
	},
	grid_4x2: {
		name: 'grid_4x2',
		columns: 4,
		rows: 2,
		cellSize: 544
	},
	grid_6x3: {
		name: 'grid_6x3',
		columns: 6,
		rows: 3,
		cellSize: 352
	},
	grid_8x4: {
		name: 'grid_8x4',
		columns: 8,
		rows: 4,
		cellSize: 270
	},
	grid_10x5: {
		name: 'grid_10x5',
		columns: 10,
		rows: 5,
		cellSize: 224
	}
};

export const gridNameToGridType: (name: GridName) => Grid = (name) => {
	return grids[name];
};

export const pixelColorToGridType: (color: PixelColor) => Grid = (color) => {
	switch (color) {
		case PixelColor.BLACK:
			return grids.grid_2x2;
		case PixelColor.WHITE:
			return grids.grid_4x2;
		case PixelColor.RED:
			return grids.grid_6x3;
		case PixelColor.BLUE:
			return grids.grid_8x4;
		case PixelColor.GREEN:
			return grids.grid_10x5;
	}
};

export enum PixelColor {
	WHITE,
	BLACK,
	RED,
	GREEN,
	BLUE,
}

export const colorDetector = (r: number, g: number, b: number) => {
	const delta = 255 - 100;
	const isRed = r > delta;
	const isGreen = g > delta;
	const isBlue = b > delta;
	if (isRed && isGreen && isBlue) {
		return PixelColor.WHITE;
	} else if (!isRed && !isGreen && !isBlue) {
		return PixelColor.BLACK;
	} else if (isRed) {
		return PixelColor.RED;
	} else if (isGreen) {
		return PixelColor.GREEN;
	} else if (isBlue) {
		return PixelColor.BLUE;
	} else {
		throw 'The pixel color is not recognizable.';
	}
};

