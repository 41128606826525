export const dataURItoBlob = (dataURI: any) => {
	// convert base64 to raw binary data held in a string
	let byteString = atob(dataURI.split(',')[1]);

	// separate out the mime component
	let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to an ArrayBuffer
	let ab = new ArrayBuffer(byteString.length);
	let ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], {type: mimeString});
};

export const dataURLtoFile = (dataUrl: string, filename: string) => {

	let arr = dataUrl.split(',')
	let res = arr[0].match(/:(.*?);/)
	let mime = res ? res[1] : ''
	let bStr = atob(arr[1])
	let n = bStr.length
	let u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bStr.charCodeAt(n);
	}

	return new File([u8arr], filename, {type: mime});
}

export const toBase64 = (file?: File) => (new Promise((resolve, reject) => {
	if (!file) {
		return '';
	}
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result as string);
	reader.onerror = error => reject(error);
})) as Promise<string>;
