import {EventOwnerReportInfo, EventReportInfo} from '../../services/gRPC/rooms/models_pb';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {WithRequired} from '../../utils/utils';

const ItemsPerPage = 5;

export type RequiredEventReportInfo = WithRequired<EventReportInfo.AsObject, 'info' | 'merchant'>

export interface PerformerState {
	statistics: EventOwnerReportInfo.AsObject | undefined
	futureEventReports: RequiredEventReportInfo[]
	pastEventReports: RequiredEventReportInfo[]
	totalFutureEventReports: number,
	totalPastEventReports: number,
	currentFutureEventsPage: number,
	currentPastEventsPage: number
}

export const initialState: PerformerState = {
	statistics: undefined,
	futureEventReports: [],
	pastEventReports: [],
	totalFutureEventReports: 0,
	totalPastEventReports: 0,
	currentFutureEventsPage: 0,
	currentPastEventsPage: 0,
}

const distinctReportObjects = (reports: EventReportInfo.AsObject[]) => {
	return [...new Map(reports.map(item =>
		[item.info!.id, item])).values()] as RequiredEventReportInfo[];
}

export const performerSlice = createSlice({
	name: 'performer',
	initialState,
	reducers: {
		setStatistics: (state, action: PayloadAction<EventOwnerReportInfo.AsObject>) => {
			state.statistics = action.payload;
		},
		addFutureEventReport: (state, action: PayloadAction<EventReportInfo.AsObject>) => {
			const updatedEventReports = distinctReportObjects([...state.futureEventReports, action.payload])
			if (updatedEventReports.length > state.totalFutureEventReports) {
				state.totalFutureEventReports = updatedEventReports.length
			}
			state.futureEventReports = updatedEventReports;
		},
		addFutureEventReports: (state, action: PayloadAction<EventReportInfo.AsObject[]>) => {
			const updatedEventReports = distinctReportObjects([...state.futureEventReports, ...action.payload])
			if (updatedEventReports.length > state.totalFutureEventReports) {
				state.totalFutureEventReports = updatedEventReports.length
			}
			state.futureEventReports = updatedEventReports
		},
		addPastEventReport: (state, action: PayloadAction<EventReportInfo.AsObject>) => {
			const updatedEventReports = distinctReportObjects([...state.pastEventReports, action.payload])
			if (updatedEventReports.length > state.totalPastEventReports) {
				state.totalPastEventReports = updatedEventReports.length;
			}
			state.pastEventReports = updatedEventReports;
		},
		addPastEventReports: (state, action: PayloadAction<EventReportInfo.AsObject[]>) => {
			const updatedEventReports = distinctReportObjects([...state.pastEventReports, ...action.payload]);
			if (updatedEventReports.length > state.totalPastEventReports) {
				state.totalPastEventReports = updatedEventReports.length;
			}
			state.pastEventReports = updatedEventReports;
		},
		updateFutureEventReport: (state, action: PayloadAction<RequiredEventReportInfo>) => {
			state.futureEventReports = state.futureEventReports.filter((report) => report.info!.id !== action.payload.info!.id);
			state.futureEventReports.push(action.payload);
		},
		deleteFutureEventReport: (state, action: PayloadAction<{ eventId: string }>) => {
			state.futureEventReports = state.futureEventReports.filter((report) => report.info!.id !== action.payload.eventId);
			state.totalFutureEventReports -= 1;

			/// if there is no items on current page, go back to previous one
			if (state.currentFutureEventsPage > 0 && Math.ceil(state.futureEventReports.length / ItemsPerPage) - 1 !== state.currentFutureEventsPage) {
				state.currentFutureEventsPage -= 1;
			}
		},
		setTotalFutureEventReports: (state, action: PayloadAction<number>) => {
			state.totalFutureEventReports = action.payload;
		},
		setTotalPastEventReports: (state, action: PayloadAction<number>) => {
			state.totalPastEventReports = action.payload;
		},
		setCurrentFutureEventsPage: (state, action: PayloadAction<number>) => {
			state.currentFutureEventsPage = action.payload;
		},
		setCurrentPastEventsPage: (state, action: PayloadAction<number>) => {
			state.currentPastEventsPage = action.payload;
		},
		reset: (state) => {
			state.statistics = undefined
			state.futureEventReports = []
			state.pastEventReports = []
			state.totalFutureEventReports = 0
			state.totalPastEventReports = 0
			state.currentFutureEventsPage = 0
			state.currentPastEventsPage = 0
		}
	}
});

export const performerActions = performerSlice.actions;
export const selectPerformerStatistics = (state: RootState) => state.performer.statistics;
export const selectEventReports = (state: RootState) => [...state.performer.futureEventReports, ...state.performer.pastEventReports]
export const selectFutureEventReports = (state: RootState) => [...state.performer.futureEventReports]
	.sort((a, b) => a.info!.startsAt > b.info!.startsAt ? 1 : -1);
export const selectPastEventReports = (state: RootState) => [...state.performer.pastEventReports]
	.sort((a, b) => a.info!.finishesAt < b.info!.finishesAt ? 1 : -1)
export const selectTotalFutureEvents = (state: RootState) => state.performer.totalFutureEventReports;
export const selectTotalPastEvents = (state: RootState) => state.performer.totalPastEventReports;
export const selectCurrentFutureEventsPage = (state: RootState) => state.performer.currentFutureEventsPage;
export const selectCurrentPastEventsPage = (state: RootState) => state.performer.currentPastEventsPage;

export const selectFutureEventReportById = (eventId: string) => {
	return createSelector(
		selectFutureEventReports,
		reports => reports.find((report) => report.info!.id === eventId)
	);
};


export const selectFutureEventReportsByIndexes = (start: number, end: number) => {
	return createSelector(
		selectFutureEventReports,
		reports => reports.slice(start, end)
	);
};

export const selectPastEventReportsByIndexes = (start: number, end: number) => {
	return createSelector(
		selectPastEventReports,
		reports => reports.slice(start, end)
	);
};
