import styled, {css, keyframes} from 'styled-components';
import React from 'react';

const ScrollXText = (width: number) => keyframes`
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(calc(-100% + ${width}px));
	}
`;

const ScrollYText = (height: number) => keyframes`
	0% {
		transform: translate3d(0, 0%, 0);
	}
	100% {
		transform: translate3d(0, calc(-100% + ${height}px), 0);
	}
`;

export const ScrollableHorizontalText = styled.div<{ duration: number, width: number }>`
	width: 100%;
	overflow: hidden;
	white-space: nowrap;

	${({duration, width}) => duration > 0 && css`
		&:not(:hover) {
			text-overflow: ellipsis;
		}

		&:hover,
		&:focus {
			span {
				display: inline-block;
				animation: ${ScrollXText(width)} ${duration || 10}s linear forwards;
			}
		}`
	}`;

interface ScrollableVerticalTextProps {
	duration: number,
	lines: number,
	height: number,
	id?: string,
	children: React.ReactNode;
}

export const ScrollableVerticalText = ({duration, lines, height, children, id}: ScrollableVerticalTextProps) => {

	return (<ScrollableVerticalTextContainerS height={height} id={id} duration={duration}>
		<ScrollableVerticalTextS lines={lines} duration={duration} height={height}>
			{children}
		</ScrollableVerticalTextS>
	</ScrollableVerticalTextContainerS>);
};

export const ScrollableVerticalTextContainerS = styled.div<{ height: number, id?: string, duration: number, }>`
	height: ${({height}) => `${height}px`};
	overflow: hidden;
	position: relative;
	-webkit-mask-image: linear-gradient(rgba(0, 0, 0, 1) 80%,ątransparent);


	${({duration}) => duration > 0 && css`
		&:before {
			content: " ";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			z-index: 2;
		}
	`}

`;


export const ScrollableVerticalTextS = styled.div<{ duration: number, lines: number, height: number }>`
	-webkit-box-orient: vertical;
	word-break: break-word;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	white-space: normal;

	${({duration, lines, height}) => lines && duration > 0 && css`
		&:hover,
		&:focus {
			display: block;
			animation: ${ScrollYText(height)} ${duration}s linear forwards;
		}
	`
	}`;
