import {ChatMessageInfo} from '../../../services/gRPC/rooms/models_pb';
import {Message, messagesActions} from '../../../store/slices/messages';
import {deleteRoomChatMessage$, updateRoomChatMessage$} from '../../../services/chat/chatServices';
import {Notification, showNotification} from '../../../utils/showNotification';
import {store} from '../../../store/store';
import {ChatMessageProps} from './GenericChat/ChatMessage/ChatMessage';

export const convertChatMessages = (messages: ChatMessageInfo.AsObject[]) => messages.map((msg) => {
	const result: Message = {
		id: msg.id,
		chatId: msg.chatId,
		senderId: msg.userId,
		content: msg.content,
		avatarUrl: msg.avatarUrl,
		senderName: msg.name,
		status: 'SENT',
		createdAt: msg.createdAt,
		updatedAt: msg.updatedAt,
	}
	return result
})

export const prepareTempMessage = (id: string, chatId: string, content: string) => {
	const tempMsg: Message = {
		id: id,
		chatId: chatId!,
		senderId: store.getState().user?.userInfo?.id ?? '',
		content: content,
		avatarUrl: store.getState().user?.userInfo?.avatarUrl ?? '',
		senderName: store.getState().user?.userInfo?.name ?? '',
		status: 'SENDING',
		createdAt: Date.now() / 1000,
		updatedAt: Date.now() / 1000,
	}

	return tempMsg
}

export const msgEditHandler = (id: string, content: string) => {
	updateRoomChatMessage$(id, content).subscribe(({
		next: () => store.dispatch(messagesActions.updateMessage({id: id, changes: {content: content}})),
		error: () => showNotification(Notification.ERROR, 'Failed to update a message, please try again.'),
	}))
}

export const msgDeleteHandler = (id: string) => {
	deleteRoomChatMessage$(id).subscribe({
		next: () => store.dispatch(messagesActions.removeMessage(id)),
		error: () => showNotification(Notification.ERROR, 'Failed to delete a message, please try again.'),
	})
}

export const prepareMessages = (messages: Message[], msgResendHandler: (id: string) => void, msgCounterUpdate: () => void) => {
	return messages.map((msg) => {
		const result: ChatMessageProps = {
			data: msg,
			msgEditHandler: (content: string) => {
				msgCounterUpdate()
				msgEditHandler(msg.id, content)
			},
			msgDeleteHandler: () => msgDeleteHandler(msg.id),
			msgResendHandler: () => msgResendHandler(msg.id)
		}
		return result
	})
}
