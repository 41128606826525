import {RoomUserFlag} from '../../../../services/gRPC/rooms/enums_pb';
import {store} from '../../../../store/store';
import {roomUsersActions} from '../../../../store/slices/roomUsers';

export const dispatchFlag = (flag: RoomUserFlag, hasFlag: boolean, userId: string, timestamp: number) => {
	if (hasFlag) {
		store.dispatch(roomUsersActions.addUserFlags({
			userId,
			flags: [flag],
			timestamp
		}));
	} else {
		store.dispatch(roomUsersActions.removeUserFlags({
			userId,
			flags: [flag]
		}));
	}
};

export const applyFlag = (flag: RoomUserFlag, hasFlag: boolean, userId: string, timestamp: number) => {
	if (hasFlag) {
		store.dispatch(roomUsersActions.applyUserFlags({
			userId,
			flags: [flag],
			timestamp
		}));
	} else {
		store.dispatch(roomUsersActions.rejectUserFlags({
			userId,
			flags: [flag]
		}));
	}
};
