import {updateSDP} from './updateSdp';
import {from} from 'rxjs';
import {roomGlobalRef} from '../roomGlobalRef';
import {map, mapTo, mergeMap} from 'rxjs/operators';

interface UseSetTransceiversSubArgs {
	isForPerformer?: boolean;
}

export const createAndSetOffer$ = (args?: UseSetTransceiversSubArgs) =>
	from(roomGlobalRef.pcMediaReceiver!.createOffer()).pipe(
		map((offer) => {
			if (args?.isForPerformer) {
				offer.sdp = updateSDP(offer.sdp, 300, 600, 2000, 500, 1000, 3000);
			} else {
				offer.sdp = updateSDP(offer.sdp, 300, 600, 1200, 500, 1000, 2000);
			}
			return offer;
		}),
		mergeMap(offer => from(roomGlobalRef.pcMediaReceiver!.setLocalDescription(offer)).pipe(
			mapTo(offer)
		))
	);
