import {Subject} from 'rxjs';

export const refreshCanvasEvent = new Subject<void>();
let requestID: number;
// todo remove fps parameter from global after tests
//@ts-ignore
window.fps = 24;

// todo when sfu mode dont call it and dont use it
export const refreshCanvasStart = () => {
	let then = performance.now();
	const tolerance = 0.1;

	const animateLoop = (now: number) => {
		requestID = requestAnimationFrame(animateLoop);
		//@ts-ignore
		const interval = 1000 / window.fps;
		const delta = now - then;

		if (delta >= interval - tolerance) {
			then = now - (delta % interval);
			refreshCanvasEvent.next();
		}
	};
	requestID = requestAnimationFrame(animateLoop);
};

export const refreshCanvasStop = () => {
	cancelAnimationFrame(requestID);
};
