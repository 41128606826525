import {EMPTY, from, of, throwError} from 'rxjs';
import {roomGlobalRef} from '../roomGlobalRef';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {getMaxRes} from '../getMaxRes';
import {AvailableDevices, DeviceType, getAvailableDevices} from '../../../../utils/getAvailableDevices';
import {getResolution} from '../getResolution';
import {RoomUserFlag} from '../../../../services/gRPC/rooms/enums_pb';
import {
	selectClientAvailableDevices,
	selectClientId,
	selectClientResolution,
	selectClientSelectedDevices,
	selectDevicesStatus,
	selectHasClientCamOff,
	Status,
	userActions
} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {roomUsersActions, selectClientFlags, selectRoomUsersWithCamsAmount} from '../../../../store/slices/roomUsers';
import {
	Notification,
	showDeviceSwitchedNotification,
	showNewDeviceNotification,
	showNotification
} from '../../../../utils/showNotification';
import {Resolution} from '../getResolution.types';
import {toggleClientCamOff} from './useStoreServiceFlagUpdater';

export enum CameraMode {
	MAIN_VIEW_USER,
	LEFT_PANEL_USER,
	PERFORMER
}

const createOptions = () => {
	const clientUserFlags = selectClientFlags(store.getState())!;
	const clientResolution = selectClientResolution(store.getState());
	const clientSelectedDevices = selectClientSelectedDevices(store.getState());
	const clientAvailableDevices = selectClientAvailableDevices(store.getState());
	const amount = selectRoomUsersWithCamsAmount(store.getState());
	const isCameraAvailable = clientAvailableDevices[DeviceType.CAMERA].length;
	const isCamOff = clientUserFlags[RoomUserFlag.RUFLAG_DISABLED_VIDEO];

	return {
		video: (isCameraAvailable && !isCamOff) ? {
			...getResolution(roomGlobalRef.cameraMode, clientResolution, getMaxRes(), amount),
			...(clientSelectedDevices.videoinput && {deviceId: clientSelectedDevices.videoinput}),
			...(roomGlobalRef.cameraMode !== CameraMode.PERFORMER && {aspectRatio: {exact: 1}}),
			frameRate: {ideal: 30}
		} : false
	};
};

export const refreshVideoStream = () => {
	const hasClientCamOff = selectHasClientCamOff(store.getState());
	const clientAvailableDevices = selectClientAvailableDevices(store.getState());
	const devicesStatus = selectDevicesStatus(store.getState());

	if (!hasClientCamOff && clientAvailableDevices[DeviceType.CAMERA].length && devicesStatus?.camera !== Status.Denied) {
		const options = createOptions();
		const clientId = selectClientId(store.getState());
		return from(navigator.mediaDevices.getUserMedia(options)).pipe(
			mergeMap((stream) => {
				const track = stream.getVideoTracks()[0];
				roomGlobalRef.clientCameraTrack?.stop();
				roomGlobalRef.clientCameraTrack = track;
				roomGlobalRef.clientCameraTrack.onended = (ev) => {
					console.log('VIDEO TRACK ENDED, refreshing', ev);
					refreshVideoStream();
				};
				roomGlobalRef.clientCameraTrack.onmute = (ev) => {
					console.log('VIDEO TRACK MUTED', ev);
				};
				roomGlobalRef.clientCameraTrack.onunmute = (ev) => {
					console.log('VIDEO TRACK UNMUTED', ev);
				};
				return of(roomGlobalRef.cameraTransceiversMap.get(clientId)!.sender.replaceTrack(track)).pipe(
					map(() => {
						roomGlobalRef.usersCameraMediaStreams.set(clientId, stream);
						store.dispatch(roomUsersActions.setUserMediaTrackId({userId: clientId, trackId: stream.getVideoTracks()[0].id, type: 'camera'}));
						return stream;
					})
				);
			}),
			catchError((err) => {
				showNotification(Notification.ERROR, 'We encountered a problem while accessing your camera. Please refresh page and try again.');
				console.error(new Error(`camera device exception: ${err.message}`));
				return throwError(err);
			})
		);
	} else if (hasClientCamOff) {
		return throwError('cannot refresh video stream if camera is turned off');
	} else {
		return throwError('permissions denied or no camera available');
	}
};

export const applyConstraintsToActiveStream = (cameraMode: CameraMode, resolution: Resolution, maxRes?: { width: number, height: number }, amount?: number) => {
	roomGlobalRef.cameraMode = cameraMode;
	if(roomGlobalRef.clientCameraTrack) {
		return from(roomGlobalRef.clientCameraTrack?.applyConstraints({
			...getResolution(cameraMode, resolution, maxRes ?? getMaxRes(), amount),
			...(cameraMode !== CameraMode.PERFORMER && {aspectRatio: {exact: 1}}),
			frameRate: {ideal: 30}
		}))
	} else {
		console.error('Cannot apply constraints. No camera stream available');
    return EMPTY;
	}
};

export const switchOnCamera = () => {
	return refreshVideoStream();
};

export const switchOffCamera = () => {
	const clientId = selectClientId(store.getState());
	roomGlobalRef.clientCameraTrack?.stop();
	// I'm not sure we really need to remove this stream, it may be needed to force refreshing stream
	store.dispatch(roomUsersActions.removeUserMediaTrack({userId: clientId, type: 'camera'}));
	roomGlobalRef.usersCameraMediaStreams.delete(clientId);
};

export const setupCameraDetection = () => {
	const hasClientCamOff = selectHasClientCamOff(store.getState());
	const selectedDevices = selectClientSelectedDevices(store.getState());
	const previousAvailableDevices = selectClientAvailableDevices(store.getState());

	from(getAvailableDevices()).subscribe({
		next: (devices: AvailableDevices) => {
			/// check if something changed in camera list and skip if not
			if (previousAvailableDevices[DeviceType.CAMERA].length === devices[DeviceType.CAMERA].length) {
				return;
			}
			store.dispatch(userActions.setAvailableDevices(devices));

			/// notify if current camera unplugged and there is not another one available
			if (devices[DeviceType.CAMERA].length === 0) {
				!hasClientCamOff && toggleClientCamOff();
				showNotification(Notification.INFO, `Your current camera has been unplugged from the computer and there is no more available devices. Please check your equipment.`, 'missingCamera');
			}

			/// refresh the stream if previously used camera was plugged in again
			if (devices[DeviceType.CAMERA].length === 1) {
				if (devices[DeviceType.CAMERA][0].value === selectedDevices.videoinput) {
					!hasClientCamOff && refreshVideoStream().subscribe();
					showNotification(Notification.INFO, `Your selected camera was plugged in and works correctly. Please use button on bottom bar to enable your video.`, 'restoredCamera');
				}
			}

			/// notify if current camera disconnected and selected another one
			previousAvailableDevices[DeviceType.CAMERA]
				.filter((device) => !devices[DeviceType.CAMERA]
					.map((el) => el.label)
					.includes(device.label)
				).forEach((device) => {
				if (device.value === selectedDevices.videoinput) {
					!hasClientCamOff && refreshVideoStream().subscribe(() => {
						if (devices[DeviceType.CAMERA].length > 0) {
							store.dispatch(userActions.setVideoInput(devices[DeviceType.CAMERA][0].value));
							showDeviceSwitchedNotification(devices[DeviceType.CAMERA][0].label, 'camera');
						}
					});
				}
			});

			/// ask to switch to new camera
			devices[DeviceType.CAMERA]
				.filter(() => !selectedDevices[DeviceType.CAMERA])
				.filter((device) => !previousAvailableDevices[DeviceType.CAMERA]
					.map((el) => el.label)
					.includes(device.label)
				).forEach((device) => {
				!hasClientCamOff && refreshVideoStream().subscribe(() => {
					showNewDeviceNotification(device.label, 'camera', () => store.dispatch(userActions.setVideoInput(device.value)));
				});
			});
		}
	});
};
