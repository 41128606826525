/* eslint-disable */
// source: rooms/services.public.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var rooms_enums_pb = require('../rooms/enums_pb.js');
goog.object.extend(proto, rooms_enums_pb);
var rooms_models_pb = require('../rooms/models_pb.js');
goog.object.extend(proto, rooms_models_pb);
var error_models_pb = require('../error/models_pb.js');
goog.object.extend(proto, error_models_pb);
goog.exportSymbol('proto.rooms.api.v1.ActivateRoomRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ActivateRoomResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CancelEventRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CancelEventResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ChangeRoomStreamRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ChangeRoomStreamResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ClearChatMessagesRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ClearChatMessagesResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ClearRoomStreamsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ClearRoomStreamsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ConfirmEventPerformerRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ConfirmEventPerformerResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountChatMessagesRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountChatMessagesResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountEventPerformersRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountEventPerformersResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountEventsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountEventsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRecommendationsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRecommendationsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRoomInvitesRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRoomInvitesResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRoomStreamsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRoomStreamsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRoomUsersRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRoomUsersResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRoomsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountRoomsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountUserTicketsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CountUserTicketsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CreateEventRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CreateEventResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CreateRoomInviteRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CreateRoomInviteResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CreateRoomRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.CreateRoomResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.CreatesRoomStream', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteChatMessageRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteChatMessageResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteEventPerformerRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteEventPerformerResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteEventRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteEventResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteRoomInviteRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteRoomInviteResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteRoomRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeleteRoomResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeliverRoomInviteRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeliverRoomInviteResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.DeliversRoomInvite', null, global);
goog.exportSymbol('proto.rooms.api.v1.DisconnectRoomUserRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.DisconnectRoomUserResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.FindRoomRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.FindRoomResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.FinishEventRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.FinishEventResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetChatInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetChatInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetChatMessageInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetChatMessageInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetChatStateRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetChatStateResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventOwnerReportInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventOwnerReportInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventPerformerInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventPerformerInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventReportInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventReportInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventStateRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetEventStateResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRecommendationInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRecommendationInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomInviteInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomInviteInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomStateRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomStateResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomStreamInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomStreamInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomUserInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetRoomUserInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetTiktokObjectInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetTiktokObjectInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetUserTicketInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetUserTicketInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetYoutubeObjectInfoRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.GetYoutubeObjectInfoResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.InsertRoomStreamRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.InsertRoomStreamResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.LeaveRoomRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.LeaveRoomResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListChatMessagesRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListChatMessagesResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListEventPerformersRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListEventPerformersResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListEventReportsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListEventReportsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListEventsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListEventsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRecommendationsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRecommendationsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRoomInvitesRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRoomInvitesResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRoomStreamsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRoomStreamsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRoomUsersRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRoomUsersResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRoomsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListRoomsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListUserTicketsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.ListUserTicketsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.RemoveRoomStreamRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.RemoveRoomStreamResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.RestartEventRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.RestartEventResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.SearchEventsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.SearchEventsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.SearchRoomsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.SearchRoomsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.SearchTiktokObjectsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.SearchTiktokObjectsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.SearchYoutubeObjectsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.SearchYoutubeObjectsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.StartEventRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.StartEventResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.SuggestEventsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.SuggestEventsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.SuggestRoomsRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.SuggestRoomsResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.SwapEventPerformersRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.SwapEventPerformersResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.SwitchEventPerformerRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.SwitchEventPerformerResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.TokenizeEventRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.TokenizeEventResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.TokenizeRoomUserRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.TokenizeRoomUserResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.TokenizeStorageObjectRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.TokenizeStorageObjectResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateChatMessageRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateChatMessageResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateChatRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateChatResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateEventRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateEventResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateRoomInviteRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateRoomInviteResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateRoomRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateRoomResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateRoomStreamRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateRoomStreamResponse', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateRoomUserRequest', null, global);
goog.exportSymbol('proto.rooms.api.v1.UpdateRoomUserResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetChatInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetChatInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetChatInfoRequest.displayName = 'proto.rooms.api.v1.GetChatInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetChatInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetChatInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetChatInfoResponse.displayName = 'proto.rooms.api.v1.GetChatInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetChatStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetChatStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetChatStateRequest.displayName = 'proto.rooms.api.v1.GetChatStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetChatStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetChatStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetChatStateResponse.displayName = 'proto.rooms.api.v1.GetChatStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateChatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateChatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateChatRequest.displayName = 'proto.rooms.api.v1.UpdateChatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateChatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateChatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateChatResponse.displayName = 'proto.rooms.api.v1.UpdateChatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetChatMessageInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetChatMessageInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetChatMessageInfoRequest.displayName = 'proto.rooms.api.v1.GetChatMessageInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetChatMessageInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetChatMessageInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetChatMessageInfoResponse.displayName = 'proto.rooms.api.v1.GetChatMessageInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListChatMessagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ListChatMessagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListChatMessagesRequest.displayName = 'proto.rooms.api.v1.ListChatMessagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListChatMessagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListChatMessagesResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListChatMessagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListChatMessagesResponse.displayName = 'proto.rooms.api.v1.ListChatMessagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountChatMessagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountChatMessagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountChatMessagesRequest.displayName = 'proto.rooms.api.v1.CountChatMessagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountChatMessagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountChatMessagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountChatMessagesResponse.displayName = 'proto.rooms.api.v1.CountChatMessagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateChatMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateChatMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateChatMessageRequest.displayName = 'proto.rooms.api.v1.UpdateChatMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateChatMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateChatMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateChatMessageResponse.displayName = 'proto.rooms.api.v1.UpdateChatMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteChatMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteChatMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteChatMessageRequest.displayName = 'proto.rooms.api.v1.DeleteChatMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteChatMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteChatMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteChatMessageResponse.displayName = 'proto.rooms.api.v1.DeleteChatMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ClearChatMessagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ClearChatMessagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ClearChatMessagesRequest.displayName = 'proto.rooms.api.v1.ClearChatMessagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ClearChatMessagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ClearChatMessagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ClearChatMessagesResponse.displayName = 'proto.rooms.api.v1.ClearChatMessagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetUserTicketInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetUserTicketInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetUserTicketInfoRequest.displayName = 'proto.rooms.api.v1.GetUserTicketInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetUserTicketInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetUserTicketInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetUserTicketInfoResponse.displayName = 'proto.rooms.api.v1.GetUserTicketInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListUserTicketsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListUserTicketsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListUserTicketsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListUserTicketsRequest.displayName = 'proto.rooms.api.v1.ListUserTicketsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListUserTicketsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListUserTicketsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListUserTicketsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListUserTicketsResponse.displayName = 'proto.rooms.api.v1.ListUserTicketsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountUserTicketsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.CountUserTicketsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.CountUserTicketsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountUserTicketsRequest.displayName = 'proto.rooms.api.v1.CountUserTicketsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountUserTicketsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountUserTicketsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountUserTicketsResponse.displayName = 'proto.rooms.api.v1.CountUserTicketsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomInfoRequest.displayName = 'proto.rooms.api.v1.GetRoomInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomInfoResponse.displayName = 'proto.rooms.api.v1.GetRoomInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomStateRequest.displayName = 'proto.rooms.api.v1.GetRoomStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomStateResponse.displayName = 'proto.rooms.api.v1.GetRoomStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.FindRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.FindRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.FindRoomRequest.displayName = 'proto.rooms.api.v1.FindRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.FindRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.FindRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.FindRoomResponse.displayName = 'proto.rooms.api.v1.FindRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRoomsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListRoomsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListRoomsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRoomsRequest.displayName = 'proto.rooms.api.v1.ListRoomsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRoomsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListRoomsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListRoomsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRoomsResponse.displayName = 'proto.rooms.api.v1.ListRoomsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRoomsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.CountRoomsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.CountRoomsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRoomsRequest.displayName = 'proto.rooms.api.v1.CountRoomsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRoomsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountRoomsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRoomsResponse.displayName = 'proto.rooms.api.v1.CountRoomsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SearchRoomsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.SearchRoomsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SearchRoomsRequest.displayName = 'proto.rooms.api.v1.SearchRoomsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SearchRoomsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.SearchRoomsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.SearchRoomsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SearchRoomsResponse.displayName = 'proto.rooms.api.v1.SearchRoomsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SuggestRoomsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.SuggestRoomsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SuggestRoomsRequest.displayName = 'proto.rooms.api.v1.SuggestRoomsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SuggestRoomsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.SuggestRoomsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.SuggestRoomsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SuggestRoomsResponse.displayName = 'proto.rooms.api.v1.SuggestRoomsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CreateRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CreateRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CreateRoomRequest.displayName = 'proto.rooms.api.v1.CreateRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CreateRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CreateRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CreateRoomResponse.displayName = 'proto.rooms.api.v1.CreateRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CreatesRoomStream = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CreatesRoomStream, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CreatesRoomStream.displayName = 'proto.rooms.api.v1.CreatesRoomStream';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeliversRoomInvite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.DeliversRoomInvite.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.DeliversRoomInvite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeliversRoomInvite.displayName = 'proto.rooms.api.v1.DeliversRoomInvite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateRoomRequest.displayName = 'proto.rooms.api.v1.UpdateRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateRoomResponse.displayName = 'proto.rooms.api.v1.UpdateRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteRoomRequest.displayName = 'proto.rooms.api.v1.DeleteRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteRoomResponse.displayName = 'proto.rooms.api.v1.DeleteRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.LeaveRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.LeaveRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.LeaveRoomRequest.displayName = 'proto.rooms.api.v1.LeaveRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.LeaveRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.LeaveRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.LeaveRoomResponse.displayName = 'proto.rooms.api.v1.LeaveRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ActivateRoomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ActivateRoomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ActivateRoomRequest.displayName = 'proto.rooms.api.v1.ActivateRoomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ActivateRoomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ActivateRoomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ActivateRoomResponse.displayName = 'proto.rooms.api.v1.ActivateRoomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomUserInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomUserInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomUserInfoRequest.displayName = 'proto.rooms.api.v1.GetRoomUserInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomUserInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomUserInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomUserInfoResponse.displayName = 'proto.rooms.api.v1.GetRoomUserInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRoomUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListRoomUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListRoomUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRoomUsersRequest.displayName = 'proto.rooms.api.v1.ListRoomUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRoomUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListRoomUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListRoomUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRoomUsersResponse.displayName = 'proto.rooms.api.v1.ListRoomUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRoomUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.CountRoomUsersRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.CountRoomUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRoomUsersRequest.displayName = 'proto.rooms.api.v1.CountRoomUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRoomUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountRoomUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRoomUsersResponse.displayName = 'proto.rooms.api.v1.CountRoomUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateRoomUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateRoomUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateRoomUserRequest.displayName = 'proto.rooms.api.v1.UpdateRoomUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateRoomUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateRoomUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateRoomUserResponse.displayName = 'proto.rooms.api.v1.UpdateRoomUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.TokenizeRoomUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.TokenizeRoomUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.TokenizeRoomUserRequest.displayName = 'proto.rooms.api.v1.TokenizeRoomUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.TokenizeRoomUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.TokenizeRoomUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.TokenizeRoomUserResponse.displayName = 'proto.rooms.api.v1.TokenizeRoomUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DisconnectRoomUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DisconnectRoomUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DisconnectRoomUserRequest.displayName = 'proto.rooms.api.v1.DisconnectRoomUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DisconnectRoomUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DisconnectRoomUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DisconnectRoomUserResponse.displayName = 'proto.rooms.api.v1.DisconnectRoomUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomInviteInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomInviteInfoRequest.displayName = 'proto.rooms.api.v1.GetRoomInviteInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomInviteInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomInviteInfoResponse.displayName = 'proto.rooms.api.v1.GetRoomInviteInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRoomInvitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ListRoomInvitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRoomInvitesRequest.displayName = 'proto.rooms.api.v1.ListRoomInvitesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRoomInvitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListRoomInvitesResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListRoomInvitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRoomInvitesResponse.displayName = 'proto.rooms.api.v1.ListRoomInvitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRoomInvitesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountRoomInvitesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRoomInvitesRequest.displayName = 'proto.rooms.api.v1.CountRoomInvitesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRoomInvitesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountRoomInvitesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRoomInvitesResponse.displayName = 'proto.rooms.api.v1.CountRoomInvitesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CreateRoomInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CreateRoomInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CreateRoomInviteRequest.displayName = 'proto.rooms.api.v1.CreateRoomInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CreateRoomInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CreateRoomInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CreateRoomInviteResponse.displayName = 'proto.rooms.api.v1.CreateRoomInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateRoomInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateRoomInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateRoomInviteRequest.displayName = 'proto.rooms.api.v1.UpdateRoomInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateRoomInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateRoomInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateRoomInviteResponse.displayName = 'proto.rooms.api.v1.UpdateRoomInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteRoomInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteRoomInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteRoomInviteRequest.displayName = 'proto.rooms.api.v1.DeleteRoomInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteRoomInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteRoomInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteRoomInviteResponse.displayName = 'proto.rooms.api.v1.DeleteRoomInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeliverRoomInviteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.DeliverRoomInviteRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.DeliverRoomInviteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeliverRoomInviteRequest.displayName = 'proto.rooms.api.v1.DeliverRoomInviteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeliverRoomInviteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeliverRoomInviteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeliverRoomInviteResponse.displayName = 'proto.rooms.api.v1.DeliverRoomInviteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomStreamInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomStreamInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomStreamInfoRequest.displayName = 'proto.rooms.api.v1.GetRoomStreamInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRoomStreamInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRoomStreamInfoResponse.displayName = 'proto.rooms.api.v1.GetRoomStreamInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRoomStreamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ListRoomStreamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRoomStreamsRequest.displayName = 'proto.rooms.api.v1.ListRoomStreamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRoomStreamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListRoomStreamsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListRoomStreamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRoomStreamsResponse.displayName = 'proto.rooms.api.v1.ListRoomStreamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRoomStreamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountRoomStreamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRoomStreamsRequest.displayName = 'proto.rooms.api.v1.CountRoomStreamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRoomStreamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountRoomStreamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRoomStreamsResponse.displayName = 'proto.rooms.api.v1.CountRoomStreamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateRoomStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateRoomStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateRoomStreamRequest.displayName = 'proto.rooms.api.v1.UpdateRoomStreamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateRoomStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateRoomStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateRoomStreamResponse.displayName = 'proto.rooms.api.v1.UpdateRoomStreamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.InsertRoomStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.InsertRoomStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.InsertRoomStreamRequest.displayName = 'proto.rooms.api.v1.InsertRoomStreamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.InsertRoomStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.InsertRoomStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.InsertRoomStreamResponse.displayName = 'proto.rooms.api.v1.InsertRoomStreamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ChangeRoomStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ChangeRoomStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ChangeRoomStreamRequest.displayName = 'proto.rooms.api.v1.ChangeRoomStreamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ChangeRoomStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ChangeRoomStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ChangeRoomStreamResponse.displayName = 'proto.rooms.api.v1.ChangeRoomStreamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.RemoveRoomStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.RemoveRoomStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.RemoveRoomStreamRequest.displayName = 'proto.rooms.api.v1.RemoveRoomStreamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.RemoveRoomStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.RemoveRoomStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.RemoveRoomStreamResponse.displayName = 'proto.rooms.api.v1.RemoveRoomStreamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ClearRoomStreamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ClearRoomStreamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ClearRoomStreamsRequest.displayName = 'proto.rooms.api.v1.ClearRoomStreamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ClearRoomStreamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ClearRoomStreamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ClearRoomStreamsResponse.displayName = 'proto.rooms.api.v1.ClearRoomStreamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventInfoRequest.displayName = 'proto.rooms.api.v1.GetEventInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventInfoResponse.displayName = 'proto.rooms.api.v1.GetEventInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventStateRequest.displayName = 'proto.rooms.api.v1.GetEventStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventStateResponse.displayName = 'proto.rooms.api.v1.GetEventStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListEventsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListEventsRequest.displayName = 'proto.rooms.api.v1.ListEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListEventsResponse.displayName = 'proto.rooms.api.v1.ListEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.CountEventsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.CountEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountEventsRequest.displayName = 'proto.rooms.api.v1.CountEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountEventsResponse.displayName = 'proto.rooms.api.v1.CountEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SearchEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.SearchEventsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.SearchEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SearchEventsRequest.displayName = 'proto.rooms.api.v1.SearchEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SearchEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.SearchEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.SearchEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SearchEventsResponse.displayName = 'proto.rooms.api.v1.SearchEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SuggestEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.SuggestEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SuggestEventsRequest.displayName = 'proto.rooms.api.v1.SuggestEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SuggestEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.SuggestEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.SuggestEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SuggestEventsResponse.displayName = 'proto.rooms.api.v1.SuggestEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CreateEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.CreateEventRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.CreateEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CreateEventRequest.displayName = 'proto.rooms.api.v1.CreateEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CreateEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CreateEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CreateEventResponse.displayName = 'proto.rooms.api.v1.CreateEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateEventRequest.displayName = 'proto.rooms.api.v1.UpdateEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.UpdateEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.UpdateEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.UpdateEventResponse.displayName = 'proto.rooms.api.v1.UpdateEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteEventRequest.displayName = 'proto.rooms.api.v1.DeleteEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteEventResponse.displayName = 'proto.rooms.api.v1.DeleteEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.StartEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.StartEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.StartEventRequest.displayName = 'proto.rooms.api.v1.StartEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.StartEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.StartEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.StartEventResponse.displayName = 'proto.rooms.api.v1.StartEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.FinishEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.FinishEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.FinishEventRequest.displayName = 'proto.rooms.api.v1.FinishEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.FinishEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.FinishEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.FinishEventResponse.displayName = 'proto.rooms.api.v1.FinishEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CancelEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CancelEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CancelEventRequest.displayName = 'proto.rooms.api.v1.CancelEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CancelEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CancelEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CancelEventResponse.displayName = 'proto.rooms.api.v1.CancelEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.RestartEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.RestartEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.RestartEventRequest.displayName = 'proto.rooms.api.v1.RestartEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.RestartEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.RestartEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.RestartEventResponse.displayName = 'proto.rooms.api.v1.RestartEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.TokenizeEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.TokenizeEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.TokenizeEventRequest.displayName = 'proto.rooms.api.v1.TokenizeEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.TokenizeEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.TokenizeEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.TokenizeEventResponse.displayName = 'proto.rooms.api.v1.TokenizeEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventPerformerInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventPerformerInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventPerformerInfoRequest.displayName = 'proto.rooms.api.v1.GetEventPerformerInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventPerformerInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventPerformerInfoResponse.displayName = 'proto.rooms.api.v1.GetEventPerformerInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListEventPerformersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ListEventPerformersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListEventPerformersRequest.displayName = 'proto.rooms.api.v1.ListEventPerformersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListEventPerformersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListEventPerformersResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListEventPerformersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListEventPerformersResponse.displayName = 'proto.rooms.api.v1.ListEventPerformersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountEventPerformersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountEventPerformersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountEventPerformersRequest.displayName = 'proto.rooms.api.v1.CountEventPerformersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountEventPerformersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountEventPerformersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountEventPerformersResponse.displayName = 'proto.rooms.api.v1.CountEventPerformersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SwapEventPerformersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.SwapEventPerformersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SwapEventPerformersRequest.displayName = 'proto.rooms.api.v1.SwapEventPerformersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SwapEventPerformersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.SwapEventPerformersResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.SwapEventPerformersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SwapEventPerformersResponse.displayName = 'proto.rooms.api.v1.SwapEventPerformersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SwitchEventPerformerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.SwitchEventPerformerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SwitchEventPerformerRequest.displayName = 'proto.rooms.api.v1.SwitchEventPerformerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SwitchEventPerformerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.SwitchEventPerformerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SwitchEventPerformerResponse.displayName = 'proto.rooms.api.v1.SwitchEventPerformerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteEventPerformerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteEventPerformerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteEventPerformerRequest.displayName = 'proto.rooms.api.v1.DeleteEventPerformerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.DeleteEventPerformerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.DeleteEventPerformerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.DeleteEventPerformerResponse.displayName = 'proto.rooms.api.v1.DeleteEventPerformerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ConfirmEventPerformerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ConfirmEventPerformerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ConfirmEventPerformerRequest.displayName = 'proto.rooms.api.v1.ConfirmEventPerformerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.ConfirmEventPerformerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ConfirmEventPerformerResponse.displayName = 'proto.rooms.api.v1.ConfirmEventPerformerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetTiktokObjectInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetTiktokObjectInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetTiktokObjectInfoRequest.displayName = 'proto.rooms.api.v1.GetTiktokObjectInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetTiktokObjectInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetTiktokObjectInfoResponse.displayName = 'proto.rooms.api.v1.GetTiktokObjectInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.SearchTiktokObjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SearchTiktokObjectsRequest.displayName = 'proto.rooms.api.v1.SearchTiktokObjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.SearchTiktokObjectsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.SearchTiktokObjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SearchTiktokObjectsResponse.displayName = 'proto.rooms.api.v1.SearchTiktokObjectsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetYoutubeObjectInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetYoutubeObjectInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetYoutubeObjectInfoRequest.displayName = 'proto.rooms.api.v1.GetYoutubeObjectInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetYoutubeObjectInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetYoutubeObjectInfoResponse.displayName = 'proto.rooms.api.v1.GetYoutubeObjectInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.SearchYoutubeObjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SearchYoutubeObjectsRequest.displayName = 'proto.rooms.api.v1.SearchYoutubeObjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.SearchYoutubeObjectsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.SearchYoutubeObjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.SearchYoutubeObjectsResponse.displayName = 'proto.rooms.api.v1.SearchYoutubeObjectsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.TokenizeStorageObjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.TokenizeStorageObjectRequest.displayName = 'proto.rooms.api.v1.TokenizeStorageObjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.TokenizeStorageObjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.TokenizeStorageObjectResponse.displayName = 'proto.rooms.api.v1.TokenizeStorageObjectResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventReportInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventReportInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventReportInfoRequest.displayName = 'proto.rooms.api.v1.GetEventReportInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventReportInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventReportInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventReportInfoResponse.displayName = 'proto.rooms.api.v1.GetEventReportInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListEventReportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListEventReportsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListEventReportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListEventReportsRequest.displayName = 'proto.rooms.api.v1.ListEventReportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListEventReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListEventReportsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListEventReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListEventReportsResponse.displayName = 'proto.rooms.api.v1.ListEventReportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventOwnerReportInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventOwnerReportInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventOwnerReportInfoRequest.displayName = 'proto.rooms.api.v1.GetEventOwnerReportInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetEventOwnerReportInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetEventOwnerReportInfoResponse.displayName = 'proto.rooms.api.v1.GetEventOwnerReportInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRecommendationInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRecommendationInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRecommendationInfoRequest.displayName = 'proto.rooms.api.v1.GetRecommendationInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.GetRecommendationInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.GetRecommendationInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.GetRecommendationInfoResponse.displayName = 'proto.rooms.api.v1.GetRecommendationInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRecommendationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListRecommendationsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListRecommendationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRecommendationsRequest.displayName = 'proto.rooms.api.v1.ListRecommendationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.ListRecommendationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.ListRecommendationsResponse.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.ListRecommendationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.ListRecommendationsResponse.displayName = 'proto.rooms.api.v1.ListRecommendationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRecommendationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.rooms.api.v1.CountRecommendationsRequest.repeatedFields_, null);
};
goog.inherits(proto.rooms.api.v1.CountRecommendationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRecommendationsRequest.displayName = 'proto.rooms.api.v1.CountRecommendationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.rooms.api.v1.CountRecommendationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.rooms.api.v1.CountRecommendationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.rooms.api.v1.CountRecommendationsResponse.displayName = 'proto.rooms.api.v1.CountRecommendationsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetChatInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetChatInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetChatInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetChatInfoRequest}
 */
proto.rooms.api.v1.GetChatInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetChatInfoRequest;
  return proto.rooms.api.v1.GetChatInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetChatInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetChatInfoRequest}
 */
proto.rooms.api.v1.GetChatInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetChatInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetChatInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetChatInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetChatInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetChatInfoRequest} returns this
 */
proto.rooms.api.v1.GetChatInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetChatInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetChatInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetChatInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.ChatInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetChatInfoResponse}
 */
proto.rooms.api.v1.GetChatInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetChatInfoResponse;
  return proto.rooms.api.v1.GetChatInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetChatInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetChatInfoResponse}
 */
proto.rooms.api.v1.GetChatInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.ChatInfo;
      reader.readMessage(value,rooms_models_pb.ChatInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetChatInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetChatInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetChatInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.ChatInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatInfo info = 1;
 * @return {?proto.rooms.api.v1.ChatInfo}
 */
proto.rooms.api.v1.GetChatInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.ChatInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.ChatInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.ChatInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetChatInfoResponse} returns this
*/
proto.rooms.api.v1.GetChatInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetChatInfoResponse} returns this
 */
proto.rooms.api.v1.GetChatInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetChatInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetChatInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetChatInfoResponse} returns this
*/
proto.rooms.api.v1.GetChatInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetChatInfoResponse} returns this
 */
proto.rooms.api.v1.GetChatInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetChatInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetChatStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetChatStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetChatStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetChatStateRequest}
 */
proto.rooms.api.v1.GetChatStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetChatStateRequest;
  return proto.rooms.api.v1.GetChatStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetChatStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetChatStateRequest}
 */
proto.rooms.api.v1.GetChatStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetChatStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetChatStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetChatStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetChatStateRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetChatStateRequest} returns this
 */
proto.rooms.api.v1.GetChatStateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetChatStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetChatStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetChatStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: (f = msg.getState()) && rooms_models_pb.ChatState.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetChatStateResponse}
 */
proto.rooms.api.v1.GetChatStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetChatStateResponse;
  return proto.rooms.api.v1.GetChatStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetChatStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetChatStateResponse}
 */
proto.rooms.api.v1.GetChatStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.ChatState;
      reader.readMessage(value,rooms_models_pb.ChatState.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetChatStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetChatStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetChatStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.ChatState.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatState state = 1;
 * @return {?proto.rooms.api.v1.ChatState}
 */
proto.rooms.api.v1.GetChatStateResponse.prototype.getState = function() {
  return /** @type{?proto.rooms.api.v1.ChatState} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.ChatState, 1));
};


/**
 * @param {?proto.rooms.api.v1.ChatState|undefined} value
 * @return {!proto.rooms.api.v1.GetChatStateResponse} returns this
*/
proto.rooms.api.v1.GetChatStateResponse.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetChatStateResponse} returns this
 */
proto.rooms.api.v1.GetChatStateResponse.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetChatStateResponse.prototype.hasState = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetChatStateResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetChatStateResponse} returns this
*/
proto.rooms.api.v1.GetChatStateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetChatStateResponse} returns this
 */
proto.rooms.api.v1.GetChatStateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetChatStateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateChatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateChatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateChatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateChatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: (f = msg.getStatus()) && rooms_models_pb.ChatStatusValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateChatRequest}
 */
proto.rooms.api.v1.UpdateChatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateChatRequest;
  return proto.rooms.api.v1.UpdateChatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateChatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateChatRequest}
 */
proto.rooms.api.v1.UpdateChatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new rooms_models_pb.ChatStatusValue;
      reader.readMessage(value,rooms_models_pb.ChatStatusValue.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateChatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateChatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateChatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateChatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      rooms_models_pb.ChatStatusValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.UpdateChatRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.UpdateChatRequest} returns this
 */
proto.rooms.api.v1.UpdateChatRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ChatStatusValue status = 2;
 * @return {?proto.rooms.api.v1.ChatStatusValue}
 */
proto.rooms.api.v1.UpdateChatRequest.prototype.getStatus = function() {
  return /** @type{?proto.rooms.api.v1.ChatStatusValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.ChatStatusValue, 2));
};


/**
 * @param {?proto.rooms.api.v1.ChatStatusValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateChatRequest} returns this
*/
proto.rooms.api.v1.UpdateChatRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateChatRequest} returns this
 */
proto.rooms.api.v1.UpdateChatRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateChatRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateChatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateChatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateChatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateChatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.ChatInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateChatResponse}
 */
proto.rooms.api.v1.UpdateChatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateChatResponse;
  return proto.rooms.api.v1.UpdateChatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateChatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateChatResponse}
 */
proto.rooms.api.v1.UpdateChatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.ChatInfo;
      reader.readMessage(value,rooms_models_pb.ChatInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateChatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateChatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateChatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateChatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.ChatInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatInfo info = 1;
 * @return {?proto.rooms.api.v1.ChatInfo}
 */
proto.rooms.api.v1.UpdateChatResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.ChatInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.ChatInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.ChatInfo|undefined} value
 * @return {!proto.rooms.api.v1.UpdateChatResponse} returns this
*/
proto.rooms.api.v1.UpdateChatResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateChatResponse} returns this
 */
proto.rooms.api.v1.UpdateChatResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateChatResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.UpdateChatResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.UpdateChatResponse} returns this
*/
proto.rooms.api.v1.UpdateChatResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateChatResponse} returns this
 */
proto.rooms.api.v1.UpdateChatResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateChatResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetChatMessageInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetChatMessageInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetChatMessageInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatMessageInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetChatMessageInfoRequest}
 */
proto.rooms.api.v1.GetChatMessageInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetChatMessageInfoRequest;
  return proto.rooms.api.v1.GetChatMessageInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetChatMessageInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetChatMessageInfoRequest}
 */
proto.rooms.api.v1.GetChatMessageInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetChatMessageInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetChatMessageInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetChatMessageInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatMessageInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetChatMessageInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetChatMessageInfoRequest} returns this
 */
proto.rooms.api.v1.GetChatMessageInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetChatMessageInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetChatMessageInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.ChatMessageInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetChatMessageInfoResponse}
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetChatMessageInfoResponse;
  return proto.rooms.api.v1.GetChatMessageInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetChatMessageInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetChatMessageInfoResponse}
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.ChatMessageInfo;
      reader.readMessage(value,rooms_models_pb.ChatMessageInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetChatMessageInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetChatMessageInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.ChatMessageInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatMessageInfo info = 1;
 * @return {?proto.rooms.api.v1.ChatMessageInfo}
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.ChatMessageInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.ChatMessageInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.ChatMessageInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetChatMessageInfoResponse} returns this
*/
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetChatMessageInfoResponse} returns this
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetChatMessageInfoResponse} returns this
*/
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetChatMessageInfoResponse} returns this
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetChatMessageInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListChatMessagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListChatMessagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListChatMessagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    order: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListChatMessagesRequest}
 */
proto.rooms.api.v1.ListChatMessagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListChatMessagesRequest;
  return proto.rooms.api.v1.ListChatMessagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListChatMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListChatMessagesRequest}
 */
proto.rooms.api.v1.ListChatMessagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListChatMessagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListChatMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListChatMessagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string chat_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.getChatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListChatMessagesRequest} returns this
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.setChatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListChatMessagesRequest} returns this
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListChatMessagesRequest} returns this
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string order = 4;
 * @return {string}
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListChatMessagesRequest} returns this
 */
proto.rooms.api.v1.ListChatMessagesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListChatMessagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListChatMessagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListChatMessagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListChatMessagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    rooms_models_pb.ChatMessageInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListChatMessagesResponse}
 */
proto.rooms.api.v1.ListChatMessagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListChatMessagesResponse;
  return proto.rooms.api.v1.ListChatMessagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListChatMessagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListChatMessagesResponse}
 */
proto.rooms.api.v1.ListChatMessagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.ChatMessageInfo;
      reader.readMessage(value,rooms_models_pb.ChatMessageInfo.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListChatMessagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListChatMessagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListChatMessagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.ChatMessageInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChatMessageInfo messages = 1;
 * @return {!Array<!proto.rooms.api.v1.ChatMessageInfo>}
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.ChatMessageInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.ChatMessageInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.ChatMessageInfo>} value
 * @return {!proto.rooms.api.v1.ListChatMessagesResponse} returns this
*/
proto.rooms.api.v1.ListChatMessagesResponse.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.ChatMessageInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ChatMessageInfo}
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.ChatMessageInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListChatMessagesResponse} returns this
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListChatMessagesResponse} returns this
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListChatMessagesResponse} returns this
*/
proto.rooms.api.v1.ListChatMessagesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListChatMessagesResponse} returns this
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListChatMessagesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountChatMessagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountChatMessagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountChatMessagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountChatMessagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountChatMessagesRequest}
 */
proto.rooms.api.v1.CountChatMessagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountChatMessagesRequest;
  return proto.rooms.api.v1.CountChatMessagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountChatMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountChatMessagesRequest}
 */
proto.rooms.api.v1.CountChatMessagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountChatMessagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountChatMessagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountChatMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountChatMessagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string chat_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.CountChatMessagesRequest.prototype.getChatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CountChatMessagesRequest} returns this
 */
proto.rooms.api.v1.CountChatMessagesRequest.prototype.setChatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountChatMessagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountChatMessagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountChatMessagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountChatMessagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountChatMessagesResponse}
 */
proto.rooms.api.v1.CountChatMessagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountChatMessagesResponse;
  return proto.rooms.api.v1.CountChatMessagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountChatMessagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountChatMessagesResponse}
 */
proto.rooms.api.v1.CountChatMessagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountChatMessagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountChatMessagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountChatMessagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountChatMessagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.rooms.api.v1.CountChatMessagesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CountChatMessagesResponse} returns this
 */
proto.rooms.api.v1.CountChatMessagesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CountChatMessagesResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CountChatMessagesResponse} returns this
*/
proto.rooms.api.v1.CountChatMessagesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CountChatMessagesResponse} returns this
 */
proto.rooms.api.v1.CountChatMessagesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CountChatMessagesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateChatMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateChatMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateChatMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateChatMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: (f = msg.getContent()) && rooms_models_pb.ChatMessageContentValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateChatMessageRequest}
 */
proto.rooms.api.v1.UpdateChatMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateChatMessageRequest;
  return proto.rooms.api.v1.UpdateChatMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateChatMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateChatMessageRequest}
 */
proto.rooms.api.v1.UpdateChatMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new rooms_models_pb.ChatMessageContentValue;
      reader.readMessage(value,rooms_models_pb.ChatMessageContentValue.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateChatMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateChatMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateChatMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateChatMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      rooms_models_pb.ChatMessageContentValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.UpdateChatMessageRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.UpdateChatMessageRequest} returns this
 */
proto.rooms.api.v1.UpdateChatMessageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ChatMessageContentValue content = 2;
 * @return {?proto.rooms.api.v1.ChatMessageContentValue}
 */
proto.rooms.api.v1.UpdateChatMessageRequest.prototype.getContent = function() {
  return /** @type{?proto.rooms.api.v1.ChatMessageContentValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.ChatMessageContentValue, 2));
};


/**
 * @param {?proto.rooms.api.v1.ChatMessageContentValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateChatMessageRequest} returns this
*/
proto.rooms.api.v1.UpdateChatMessageRequest.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateChatMessageRequest} returns this
 */
proto.rooms.api.v1.UpdateChatMessageRequest.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateChatMessageRequest.prototype.hasContent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateChatMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateChatMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateChatMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.ChatMessageInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateChatMessageResponse}
 */
proto.rooms.api.v1.UpdateChatMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateChatMessageResponse;
  return proto.rooms.api.v1.UpdateChatMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateChatMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateChatMessageResponse}
 */
proto.rooms.api.v1.UpdateChatMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.ChatMessageInfo;
      reader.readMessage(value,rooms_models_pb.ChatMessageInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateChatMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateChatMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateChatMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.ChatMessageInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChatMessageInfo info = 1;
 * @return {?proto.rooms.api.v1.ChatMessageInfo}
 */
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.ChatMessageInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.ChatMessageInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.ChatMessageInfo|undefined} value
 * @return {!proto.rooms.api.v1.UpdateChatMessageResponse} returns this
*/
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateChatMessageResponse} returns this
 */
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.UpdateChatMessageResponse} returns this
*/
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateChatMessageResponse} returns this
 */
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateChatMessageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteChatMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteChatMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteChatMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteChatMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteChatMessageRequest}
 */
proto.rooms.api.v1.DeleteChatMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteChatMessageRequest;
  return proto.rooms.api.v1.DeleteChatMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteChatMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteChatMessageRequest}
 */
proto.rooms.api.v1.DeleteChatMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteChatMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteChatMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteChatMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteChatMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.DeleteChatMessageRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.DeleteChatMessageRequest} returns this
 */
proto.rooms.api.v1.DeleteChatMessageRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteChatMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteChatMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteChatMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteChatMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteChatMessageResponse}
 */
proto.rooms.api.v1.DeleteChatMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteChatMessageResponse;
  return proto.rooms.api.v1.DeleteChatMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteChatMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteChatMessageResponse}
 */
proto.rooms.api.v1.DeleteChatMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteChatMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteChatMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteChatMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteChatMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.DeleteChatMessageResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.DeleteChatMessageResponse} returns this
*/
proto.rooms.api.v1.DeleteChatMessageResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.DeleteChatMessageResponse} returns this
 */
proto.rooms.api.v1.DeleteChatMessageResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.DeleteChatMessageResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ClearChatMessagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ClearChatMessagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ClearChatMessagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ClearChatMessagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ClearChatMessagesRequest}
 */
proto.rooms.api.v1.ClearChatMessagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ClearChatMessagesRequest;
  return proto.rooms.api.v1.ClearChatMessagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ClearChatMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ClearChatMessagesRequest}
 */
proto.rooms.api.v1.ClearChatMessagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChatId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ClearChatMessagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ClearChatMessagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ClearChatMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ClearChatMessagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string chat_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.ClearChatMessagesRequest.prototype.getChatId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ClearChatMessagesRequest} returns this
 */
proto.rooms.api.v1.ClearChatMessagesRequest.prototype.setChatId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ClearChatMessagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ClearChatMessagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ClearChatMessagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ClearChatMessagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ClearChatMessagesResponse}
 */
proto.rooms.api.v1.ClearChatMessagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ClearChatMessagesResponse;
  return proto.rooms.api.v1.ClearChatMessagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ClearChatMessagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ClearChatMessagesResponse}
 */
proto.rooms.api.v1.ClearChatMessagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ClearChatMessagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ClearChatMessagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ClearChatMessagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ClearChatMessagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ClearChatMessagesResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ClearChatMessagesResponse} returns this
*/
proto.rooms.api.v1.ClearChatMessagesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ClearChatMessagesResponse} returns this
 */
proto.rooms.api.v1.ClearChatMessagesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ClearChatMessagesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetUserTicketInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetUserTicketInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetUserTicketInfoRequest}
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetUserTicketInfoRequest;
  return proto.rooms.api.v1.GetUserTicketInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetUserTicketInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetUserTicketInfoRequest}
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetUserTicketInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetUserTicketInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetUserTicketInfoRequest} returns this
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_id = 2;
 * @return {string}
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetUserTicketInfoRequest} returns this
 */
proto.rooms.api.v1.GetUserTicketInfoRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetUserTicketInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetUserTicketInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.UserTicketInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetUserTicketInfoResponse}
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetUserTicketInfoResponse;
  return proto.rooms.api.v1.GetUserTicketInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetUserTicketInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetUserTicketInfoResponse}
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.UserTicketInfo;
      reader.readMessage(value,rooms_models_pb.UserTicketInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetUserTicketInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetUserTicketInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.UserTicketInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserTicketInfo info = 1;
 * @return {?proto.rooms.api.v1.UserTicketInfo}
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.UserTicketInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.UserTicketInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.UserTicketInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetUserTicketInfoResponse} returns this
*/
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetUserTicketInfoResponse} returns this
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetUserTicketInfoResponse} returns this
*/
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetUserTicketInfoResponse} returns this
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetUserTicketInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListUserTicketsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListUserTicketsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListUserTicketsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListUserTicketsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    order: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListUserTicketsRequest}
 */
proto.rooms.api.v1.ListUserTicketsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListUserTicketsRequest;
  return proto.rooms.api.v1.ListUserTicketsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListUserTicketsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListUserTicketsRequest}
 */
proto.rooms.api.v1.ListUserTicketsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.rooms.api.v1.UserTicketStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListUserTicketsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListUserTicketsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListUserTicketsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated UserTicketStatus statuses = 1;
 * @return {!Array<!proto.rooms.api.v1.UserTicketStatus>}
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.UserTicketStatus>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.UserTicketStatus>} value
 * @return {!proto.rooms.api.v1.ListUserTicketsRequest} returns this
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.rooms.api.v1.UserTicketStatus} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListUserTicketsRequest} returns this
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListUserTicketsRequest} returns this
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListUserTicketsRequest} returns this
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListUserTicketsRequest} returns this
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string order = 4;
 * @return {string}
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListUserTicketsRequest} returns this
 */
proto.rooms.api.v1.ListUserTicketsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListUserTicketsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListUserTicketsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListUserTicketsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListUserTicketsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticketsList: jspb.Message.toObjectList(msg.getTicketsList(),
    rooms_models_pb.UserTicketInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListUserTicketsResponse}
 */
proto.rooms.api.v1.ListUserTicketsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListUserTicketsResponse;
  return proto.rooms.api.v1.ListUserTicketsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListUserTicketsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListUserTicketsResponse}
 */
proto.rooms.api.v1.ListUserTicketsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.UserTicketInfo;
      reader.readMessage(value,rooms_models_pb.UserTicketInfo.deserializeBinaryFromReader);
      msg.addTickets(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListUserTicketsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListUserTicketsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListUserTicketsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.UserTicketInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserTicketInfo tickets = 1;
 * @return {!Array<!proto.rooms.api.v1.UserTicketInfo>}
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.getTicketsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.UserTicketInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.UserTicketInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.UserTicketInfo>} value
 * @return {!proto.rooms.api.v1.ListUserTicketsResponse} returns this
*/
proto.rooms.api.v1.ListUserTicketsResponse.prototype.setTicketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.UserTicketInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.UserTicketInfo}
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.addTickets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.UserTicketInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListUserTicketsResponse} returns this
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.clearTicketsList = function() {
  return this.setTicketsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListUserTicketsResponse} returns this
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListUserTicketsResponse} returns this
*/
proto.rooms.api.v1.ListUserTicketsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListUserTicketsResponse} returns this
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListUserTicketsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.CountUserTicketsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountUserTicketsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountUserTicketsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountUserTicketsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountUserTicketsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountUserTicketsRequest}
 */
proto.rooms.api.v1.CountUserTicketsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountUserTicketsRequest;
  return proto.rooms.api.v1.CountUserTicketsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountUserTicketsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountUserTicketsRequest}
 */
proto.rooms.api.v1.CountUserTicketsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.rooms.api.v1.UserTicketStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountUserTicketsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountUserTicketsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountUserTicketsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountUserTicketsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated UserTicketStatus statuses = 1;
 * @return {!Array<!proto.rooms.api.v1.UserTicketStatus>}
 */
proto.rooms.api.v1.CountUserTicketsRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.UserTicketStatus>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.UserTicketStatus>} value
 * @return {!proto.rooms.api.v1.CountUserTicketsRequest} returns this
 */
proto.rooms.api.v1.CountUserTicketsRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.rooms.api.v1.UserTicketStatus} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.CountUserTicketsRequest} returns this
 */
proto.rooms.api.v1.CountUserTicketsRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.CountUserTicketsRequest} returns this
 */
proto.rooms.api.v1.CountUserTicketsRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountUserTicketsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountUserTicketsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountUserTicketsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountUserTicketsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountUserTicketsResponse}
 */
proto.rooms.api.v1.CountUserTicketsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountUserTicketsResponse;
  return proto.rooms.api.v1.CountUserTicketsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountUserTicketsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountUserTicketsResponse}
 */
proto.rooms.api.v1.CountUserTicketsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountUserTicketsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountUserTicketsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountUserTicketsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountUserTicketsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.rooms.api.v1.CountUserTicketsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CountUserTicketsResponse} returns this
 */
proto.rooms.api.v1.CountUserTicketsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CountUserTicketsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CountUserTicketsResponse} returns this
*/
proto.rooms.api.v1.CountUserTicketsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CountUserTicketsResponse} returns this
 */
proto.rooms.api.v1.CountUserTicketsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CountUserTicketsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    broadcast: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomInfoRequest}
 */
proto.rooms.api.v1.GetRoomInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomInfoRequest;
  return proto.rooms.api.v1.GetRoomInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomInfoRequest}
 */
proto.rooms.api.v1.GetRoomInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBroadcast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBroadcast();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetRoomInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetRoomInfoRequest} returns this
 */
proto.rooms.api.v1.GetRoomInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pid = 2;
 * @return {string}
 */
proto.rooms.api.v1.GetRoomInfoRequest.prototype.getPid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetRoomInfoRequest} returns this
 */
proto.rooms.api.v1.GetRoomInfoRequest.prototype.setPid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool broadcast = 3;
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomInfoRequest.prototype.getBroadcast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rooms.api.v1.GetRoomInfoRequest} returns this
 */
proto.rooms.api.v1.GetRoomInfoRequest.prototype.setBroadcast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomInfoResponse}
 */
proto.rooms.api.v1.GetRoomInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomInfoResponse;
  return proto.rooms.api.v1.GetRoomInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomInfoResponse}
 */
proto.rooms.api.v1.GetRoomInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInfo;
      reader.readMessage(value,rooms_models_pb.RoomInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomInfo}
 */
proto.rooms.api.v1.GetRoomInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomInfoResponse} returns this
*/
proto.rooms.api.v1.GetRoomInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomInfoResponse} returns this
 */
proto.rooms.api.v1.GetRoomInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetRoomInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomInfoResponse} returns this
*/
proto.rooms.api.v1.GetRoomInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomInfoResponse} returns this
 */
proto.rooms.api.v1.GetRoomInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomStateRequest}
 */
proto.rooms.api.v1.GetRoomStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomStateRequest;
  return proto.rooms.api.v1.GetRoomStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomStateRequest}
 */
proto.rooms.api.v1.GetRoomStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: (f = msg.getState()) && rooms_models_pb.RoomState.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomStateResponse}
 */
proto.rooms.api.v1.GetRoomStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomStateResponse;
  return proto.rooms.api.v1.GetRoomStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomStateResponse}
 */
proto.rooms.api.v1.GetRoomStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomState;
      reader.readMessage(value,rooms_models_pb.RoomState.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomState.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomState state = 1;
 * @return {?proto.rooms.api.v1.RoomState}
 */
proto.rooms.api.v1.GetRoomStateResponse.prototype.getState = function() {
  return /** @type{?proto.rooms.api.v1.RoomState} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomState, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomState|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomStateResponse} returns this
*/
proto.rooms.api.v1.GetRoomStateResponse.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomStateResponse} returns this
 */
proto.rooms.api.v1.GetRoomStateResponse.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomStateResponse.prototype.hasState = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetRoomStateResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomStateResponse} returns this
*/
proto.rooms.api.v1.GetRoomStateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomStateResponse} returns this
 */
proto.rooms.api.v1.GetRoomStateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomStateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.FindRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.FindRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.FindRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.FindRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.FindRoomRequest}
 */
proto.rooms.api.v1.FindRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.FindRoomRequest;
  return proto.rooms.api.v1.FindRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.FindRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.FindRoomRequest}
 */
proto.rooms.api.v1.FindRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.FindRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.FindRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.FindRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.FindRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.FindRoomRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.FindRoomRequest} returns this
 */
proto.rooms.api.v1.FindRoomRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.FindRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.FindRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.FindRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.FindRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.FindRoomResponse}
 */
proto.rooms.api.v1.FindRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.FindRoomResponse;
  return proto.rooms.api.v1.FindRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.FindRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.FindRoomResponse}
 */
proto.rooms.api.v1.FindRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInfo;
      reader.readMessage(value,rooms_models_pb.RoomInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.FindRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.FindRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.FindRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.FindRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomInfo}
 */
proto.rooms.api.v1.FindRoomResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomInfo|undefined} value
 * @return {!proto.rooms.api.v1.FindRoomResponse} returns this
*/
proto.rooms.api.v1.FindRoomResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.FindRoomResponse} returns this
 */
proto.rooms.api.v1.FindRoomResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.FindRoomResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.FindRoomResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.FindRoomResponse} returns this
*/
proto.rooms.api.v1.FindRoomResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.FindRoomResponse} returns this
 */
proto.rooms.api.v1.FindRoomResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.FindRoomResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListRoomsRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRoomsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRoomsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    rolesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    order: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRoomsRequest}
 */
proto.rooms.api.v1.ListRoomsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRoomsRequest;
  return proto.rooms.api.v1.ListRoomsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRoomsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRoomsRequest}
 */
proto.rooms.api.v1.ListRoomsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRoomsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRoomsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.ListRoomsRequest} returns this
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListRoomsRequest} returns this
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRoomsRequest} returns this
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * repeated int64 roles = 2;
 * @return {!Array<number>}
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.getRolesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.rooms.api.v1.ListRoomsRequest} returns this
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListRoomsRequest} returns this
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRoomsRequest} returns this
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional int64 offset = 3;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomsRequest} returns this
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomsRequest} returns this
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string order = 5;
 * @return {string}
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListRoomsRequest} returns this
 */
proto.rooms.api.v1.ListRoomsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListRoomsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRoomsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRoomsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    rooms_models_pb.RoomInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRoomsResponse}
 */
proto.rooms.api.v1.ListRoomsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRoomsResponse;
  return proto.rooms.api.v1.ListRoomsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRoomsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRoomsResponse}
 */
proto.rooms.api.v1.ListRoomsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInfo;
      reader.readMessage(value,rooms_models_pb.RoomInfo.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRoomsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRoomsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.RoomInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RoomInfo rooms = 1;
 * @return {!Array<!proto.rooms.api.v1.RoomInfo>}
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.RoomInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.RoomInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RoomInfo>} value
 * @return {!proto.rooms.api.v1.ListRoomsResponse} returns this
*/
proto.rooms.api.v1.ListRoomsResponse.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.RoomInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.RoomInfo}
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.RoomInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRoomsResponse} returns this
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomsResponse} returns this
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListRoomsResponse} returns this
*/
proto.rooms.api.v1.ListRoomsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListRoomsResponse} returns this
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListRoomsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.CountRoomsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRoomsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRoomsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRoomsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRoomsRequest}
 */
proto.rooms.api.v1.CountRoomsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRoomsRequest;
  return proto.rooms.api.v1.CountRoomsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRoomsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRoomsRequest}
 */
proto.rooms.api.v1.CountRoomsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRoomsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRoomsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRoomsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 roles = 1;
 * @return {!Array<number>}
 */
proto.rooms.api.v1.CountRoomsRequest.prototype.getRolesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.rooms.api.v1.CountRoomsRequest} returns this
 */
proto.rooms.api.v1.CountRoomsRequest.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.CountRoomsRequest} returns this
 */
proto.rooms.api.v1.CountRoomsRequest.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.CountRoomsRequest} returns this
 */
proto.rooms.api.v1.CountRoomsRequest.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRoomsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRoomsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRoomsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRoomsResponse}
 */
proto.rooms.api.v1.CountRoomsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRoomsResponse;
  return proto.rooms.api.v1.CountRoomsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRoomsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRoomsResponse}
 */
proto.rooms.api.v1.CountRoomsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRoomsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRoomsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRoomsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.rooms.api.v1.CountRoomsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CountRoomsResponse} returns this
 */
proto.rooms.api.v1.CountRoomsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CountRoomsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CountRoomsResponse} returns this
*/
proto.rooms.api.v1.CountRoomsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CountRoomsResponse} returns this
 */
proto.rooms.api.v1.CountRoomsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CountRoomsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SearchRoomsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SearchRoomsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SearchRoomsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchRoomsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SearchRoomsRequest}
 */
proto.rooms.api.v1.SearchRoomsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SearchRoomsRequest;
  return proto.rooms.api.v1.SearchRoomsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SearchRoomsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SearchRoomsRequest}
 */
proto.rooms.api.v1.SearchRoomsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SearchRoomsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SearchRoomsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SearchRoomsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchRoomsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.rooms.api.v1.SearchRoomsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.SearchRoomsRequest} returns this
 */
proto.rooms.api.v1.SearchRoomsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.rooms.api.v1.SearchRoomsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchRoomsRequest} returns this
 */
proto.rooms.api.v1.SearchRoomsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.rooms.api.v1.SearchRoomsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchRoomsRequest} returns this
 */
proto.rooms.api.v1.SearchRoomsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.SearchRoomsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SearchRoomsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SearchRoomsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchRoomsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomsList: jspb.Message.toObjectList(msg.getRoomsList(),
    rooms_models_pb.RoomInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SearchRoomsResponse}
 */
proto.rooms.api.v1.SearchRoomsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SearchRoomsResponse;
  return proto.rooms.api.v1.SearchRoomsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SearchRoomsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SearchRoomsResponse}
 */
proto.rooms.api.v1.SearchRoomsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInfo;
      reader.readMessage(value,rooms_models_pb.RoomInfo.deserializeBinaryFromReader);
      msg.addRooms(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SearchRoomsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SearchRoomsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchRoomsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.RoomInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RoomInfo rooms = 1;
 * @return {!Array<!proto.rooms.api.v1.RoomInfo>}
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.getRoomsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.RoomInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.RoomInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RoomInfo>} value
 * @return {!proto.rooms.api.v1.SearchRoomsResponse} returns this
*/
proto.rooms.api.v1.SearchRoomsResponse.prototype.setRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.RoomInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.RoomInfo}
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.addRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.RoomInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SearchRoomsResponse} returns this
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.clearRoomsList = function() {
  return this.setRoomsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchRoomsResponse} returns this
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.SearchRoomsResponse} returns this
*/
proto.rooms.api.v1.SearchRoomsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.SearchRoomsResponse} returns this
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.SearchRoomsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SuggestRoomsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SuggestRoomsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SuggestRoomsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SuggestRoomsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SuggestRoomsRequest}
 */
proto.rooms.api.v1.SuggestRoomsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SuggestRoomsRequest;
  return proto.rooms.api.v1.SuggestRoomsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SuggestRoomsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SuggestRoomsRequest}
 */
proto.rooms.api.v1.SuggestRoomsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SuggestRoomsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SuggestRoomsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SuggestRoomsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SuggestRoomsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.rooms.api.v1.SuggestRoomsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.SuggestRoomsRequest} returns this
 */
proto.rooms.api.v1.SuggestRoomsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.SuggestRoomsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SuggestRoomsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SuggestRoomsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SuggestRoomsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SuggestRoomsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    namesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SuggestRoomsResponse}
 */
proto.rooms.api.v1.SuggestRoomsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SuggestRoomsResponse;
  return proto.rooms.api.v1.SuggestRoomsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SuggestRoomsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SuggestRoomsResponse}
 */
proto.rooms.api.v1.SuggestRoomsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SuggestRoomsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SuggestRoomsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SuggestRoomsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SuggestRoomsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string names = 1;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.SuggestRoomsResponse.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.SuggestRoomsResponse} returns this
 */
proto.rooms.api.v1.SuggestRoomsResponse.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.SuggestRoomsResponse} returns this
 */
proto.rooms.api.v1.SuggestRoomsResponse.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SuggestRoomsResponse} returns this
 */
proto.rooms.api.v1.SuggestRoomsResponse.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.SuggestRoomsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.SuggestRoomsResponse} returns this
*/
proto.rooms.api.v1.SuggestRoomsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.SuggestRoomsResponse} returns this
 */
proto.rooms.api.v1.SuggestRoomsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.SuggestRoomsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CreateRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CreateRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    access: jspb.Message.getFieldWithDefault(msg, 5, 0),
    password: jspb.Message.getFieldWithDefault(msg, 6, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 7, ""),
    background: jspb.Message.getFieldWithDefault(msg, 8, ""),
    stream: (f = msg.getStream()) && proto.rooms.api.v1.CreatesRoomStream.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CreateRoomRequest}
 */
proto.rooms.api.v1.CreateRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CreateRoomRequest;
  return proto.rooms.api.v1.CreateRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CreateRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CreateRoomRequest}
 */
proto.rooms.api.v1.CreateRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {!proto.rooms.api.v1.RoomType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.rooms.api.v1.RoomAccess} */ (reader.readEnum());
      msg.setAccess(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackground(value);
      break;
    case 9:
      var value = new proto.rooms.api.v1.CreatesRoomStream;
      reader.readMessage(value,proto.rooms.api.v1.CreatesRoomStream.deserializeBinaryFromReader);
      msg.setStream(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CreateRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CreateRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAccess();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBackground();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStream();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.rooms.api.v1.CreatesRoomStream.serializeBinaryToWriter
    );
  }
};


/**
 * optional string pid = 1;
 * @return {string}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.getPid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.setPid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional RoomType type = 4;
 * @return {!proto.rooms.api.v1.RoomType}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.getType = function() {
  return /** @type {!proto.rooms.api.v1.RoomType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.rooms.api.v1.RoomType} value
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional RoomAccess access = 5;
 * @return {!proto.rooms.api.v1.RoomAccess}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.getAccess = function() {
  return /** @type {!proto.rooms.api.v1.RoomAccess} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.rooms.api.v1.RoomAccess} value
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.setAccess = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string password = 6;
 * @return {string}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string thumbnail = 7;
 * @return {string}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string background = 8;
 * @return {string}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.getBackground = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.setBackground = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional CreatesRoomStream stream = 9;
 * @return {?proto.rooms.api.v1.CreatesRoomStream}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.getStream = function() {
  return /** @type{?proto.rooms.api.v1.CreatesRoomStream} */ (
    jspb.Message.getWrapperField(this, proto.rooms.api.v1.CreatesRoomStream, 9));
};


/**
 * @param {?proto.rooms.api.v1.CreatesRoomStream|undefined} value
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
*/
proto.rooms.api.v1.CreateRoomRequest.prototype.setStream = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CreateRoomRequest} returns this
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.clearStream = function() {
  return this.setStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CreateRoomRequest.prototype.hasStream = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CreateRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CreateRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CreateRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CreateRoomResponse}
 */
proto.rooms.api.v1.CreateRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CreateRoomResponse;
  return proto.rooms.api.v1.CreateRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CreateRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CreateRoomResponse}
 */
proto.rooms.api.v1.CreateRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInfo;
      reader.readMessage(value,rooms_models_pb.RoomInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CreateRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CreateRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CreateRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomInfo}
 */
proto.rooms.api.v1.CreateRoomResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomInfo|undefined} value
 * @return {!proto.rooms.api.v1.CreateRoomResponse} returns this
*/
proto.rooms.api.v1.CreateRoomResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CreateRoomResponse} returns this
 */
proto.rooms.api.v1.CreateRoomResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CreateRoomResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CreateRoomResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CreateRoomResponse} returns this
*/
proto.rooms.api.v1.CreateRoomResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CreateRoomResponse} returns this
 */
proto.rooms.api.v1.CreateRoomResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CreateRoomResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CreatesRoomStream.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CreatesRoomStream} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreatesRoomStream.toObject = function(includeInstance, msg) {
  var f, obj = {
    sid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    muted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    paused: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    offset: jspb.Message.getFieldWithDefault(msg, 5, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CreatesRoomStream}
 */
proto.rooms.api.v1.CreatesRoomStream.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CreatesRoomStream;
  return proto.rooms.api.v1.CreatesRoomStream.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CreatesRoomStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CreatesRoomStream}
 */
proto.rooms.api.v1.CreatesRoomStream.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSid(value);
      break;
    case 2:
      var value = /** @type {!proto.rooms.api.v1.RoomStreamType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMuted(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaused(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CreatesRoomStream.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CreatesRoomStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreatesRoomStream.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMuted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPaused();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string sid = 1;
 * @return {string}
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.getSid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreatesRoomStream} returns this
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.setSid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RoomStreamType type = 2;
 * @return {!proto.rooms.api.v1.RoomStreamType}
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.getType = function() {
  return /** @type {!proto.rooms.api.v1.RoomStreamType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.rooms.api.v1.RoomStreamType} value
 * @return {!proto.rooms.api.v1.CreatesRoomStream} returns this
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool muted = 3;
 * @return {boolean}
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.getMuted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rooms.api.v1.CreatesRoomStream} returns this
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.setMuted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool paused = 4;
 * @return {boolean}
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.getPaused = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rooms.api.v1.CreatesRoomStream} returns this
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.setPaused = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 offset = 5;
 * @return {number}
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CreatesRoomStream} returns this
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 duration = 6;
 * @return {number}
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CreatesRoomStream} returns this
 */
proto.rooms.api.v1.CreatesRoomStream.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.DeliversRoomInvite.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeliversRoomInvite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeliversRoomInvite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeliversRoomInvite.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    userEmailsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeliversRoomInvite}
 */
proto.rooms.api.v1.DeliversRoomInvite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeliversRoomInvite;
  return proto.rooms.api.v1.DeliversRoomInvite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeliversRoomInvite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeliversRoomInvite}
 */
proto.rooms.api.v1.DeliversRoomInvite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeliversRoomInvite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeliversRoomInvite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeliversRoomInvite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUserEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.DeliversRoomInvite} returns this
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.DeliversRoomInvite} returns this
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.DeliversRoomInvite} returns this
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * repeated string user_emails = 2;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.getUserEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.DeliversRoomInvite} returns this
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.setUserEmailsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.DeliversRoomInvite} returns this
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.addUserEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.DeliversRoomInvite} returns this
 */
proto.rooms.api.v1.DeliversRoomInvite.prototype.clearUserEmailsList = function() {
  return this.setUserEmailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pid: (f = msg.getPid()) && rooms_models_pb.RoomPidValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && rooms_models_pb.RoomNameValue.toObject(includeInstance, f),
    limit: (f = msg.getLimit()) && rooms_models_pb.RoomLimitValue.toObject(includeInstance, f),
    access: (f = msg.getAccess()) && rooms_models_pb.RoomAccessValue.toObject(includeInstance, f),
    password: (f = msg.getPassword()) && rooms_models_pb.RoomPasswordValue.toObject(includeInstance, f),
    thumbnail: (f = msg.getThumbnail()) && rooms_models_pb.RoomThumbnailValue.toObject(includeInstance, f),
    background: (f = msg.getBackground()) && rooms_models_pb.RoomBackgroundValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateRoomRequest}
 */
proto.rooms.api.v1.UpdateRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateRoomRequest;
  return proto.rooms.api.v1.UpdateRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateRoomRequest}
 */
proto.rooms.api.v1.UpdateRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new rooms_models_pb.RoomPidValue;
      reader.readMessage(value,rooms_models_pb.RoomPidValue.deserializeBinaryFromReader);
      msg.setPid(value);
      break;
    case 3:
      var value = new rooms_models_pb.RoomNameValue;
      reader.readMessage(value,rooms_models_pb.RoomNameValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new rooms_models_pb.RoomLimitValue;
      reader.readMessage(value,rooms_models_pb.RoomLimitValue.deserializeBinaryFromReader);
      msg.setLimit(value);
      break;
    case 5:
      var value = new rooms_models_pb.RoomAccessValue;
      reader.readMessage(value,rooms_models_pb.RoomAccessValue.deserializeBinaryFromReader);
      msg.setAccess(value);
      break;
    case 6:
      var value = new rooms_models_pb.RoomPasswordValue;
      reader.readMessage(value,rooms_models_pb.RoomPasswordValue.deserializeBinaryFromReader);
      msg.setPassword(value);
      break;
    case 7:
      var value = new rooms_models_pb.RoomThumbnailValue;
      reader.readMessage(value,rooms_models_pb.RoomThumbnailValue.deserializeBinaryFromReader);
      msg.setThumbnail(value);
      break;
    case 8:
      var value = new rooms_models_pb.RoomBackgroundValue;
      reader.readMessage(value,rooms_models_pb.RoomBackgroundValue.deserializeBinaryFromReader);
      msg.setBackground(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      rooms_models_pb.RoomPidValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      rooms_models_pb.RoomNameValue.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      rooms_models_pb.RoomLimitValue.serializeBinaryToWriter
    );
  }
  f = message.getAccess();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      rooms_models_pb.RoomAccessValue.serializeBinaryToWriter
    );
  }
  f = message.getPassword();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      rooms_models_pb.RoomPasswordValue.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      rooms_models_pb.RoomThumbnailValue.serializeBinaryToWriter
    );
  }
  f = message.getBackground();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      rooms_models_pb.RoomBackgroundValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RoomPidValue pid = 2;
 * @return {?proto.rooms.api.v1.RoomPidValue}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.getPid = function() {
  return /** @type{?proto.rooms.api.v1.RoomPidValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomPidValue, 2));
};


/**
 * @param {?proto.rooms.api.v1.RoomPidValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomRequest.prototype.setPid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.clearPid = function() {
  return this.setPid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.hasPid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RoomNameValue name = 3;
 * @return {?proto.rooms.api.v1.RoomNameValue}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.getName = function() {
  return /** @type{?proto.rooms.api.v1.RoomNameValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomNameValue, 3));
};


/**
 * @param {?proto.rooms.api.v1.RoomNameValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RoomLimitValue limit = 4;
 * @return {?proto.rooms.api.v1.RoomLimitValue}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.getLimit = function() {
  return /** @type{?proto.rooms.api.v1.RoomLimitValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomLimitValue, 4));
};


/**
 * @param {?proto.rooms.api.v1.RoomLimitValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomRequest.prototype.setLimit = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.clearLimit = function() {
  return this.setLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RoomAccessValue access = 5;
 * @return {?proto.rooms.api.v1.RoomAccessValue}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.getAccess = function() {
  return /** @type{?proto.rooms.api.v1.RoomAccessValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomAccessValue, 5));
};


/**
 * @param {?proto.rooms.api.v1.RoomAccessValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomRequest.prototype.setAccess = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.clearAccess = function() {
  return this.setAccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.hasAccess = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RoomPasswordValue password = 6;
 * @return {?proto.rooms.api.v1.RoomPasswordValue}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.getPassword = function() {
  return /** @type{?proto.rooms.api.v1.RoomPasswordValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomPasswordValue, 6));
};


/**
 * @param {?proto.rooms.api.v1.RoomPasswordValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomRequest.prototype.setPassword = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.clearPassword = function() {
  return this.setPassword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.hasPassword = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RoomThumbnailValue thumbnail = 7;
 * @return {?proto.rooms.api.v1.RoomThumbnailValue}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.getThumbnail = function() {
  return /** @type{?proto.rooms.api.v1.RoomThumbnailValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomThumbnailValue, 7));
};


/**
 * @param {?proto.rooms.api.v1.RoomThumbnailValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomRequest.prototype.setThumbnail = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.clearThumbnail = function() {
  return this.setThumbnail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.hasThumbnail = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional RoomBackgroundValue background = 8;
 * @return {?proto.rooms.api.v1.RoomBackgroundValue}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.getBackground = function() {
  return /** @type{?proto.rooms.api.v1.RoomBackgroundValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomBackgroundValue, 8));
};


/**
 * @param {?proto.rooms.api.v1.RoomBackgroundValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomRequest.prototype.setBackground = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.clearBackground = function() {
  return this.setBackground(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomRequest.prototype.hasBackground = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateRoomResponse}
 */
proto.rooms.api.v1.UpdateRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateRoomResponse;
  return proto.rooms.api.v1.UpdateRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateRoomResponse}
 */
proto.rooms.api.v1.UpdateRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInfo;
      reader.readMessage(value,rooms_models_pb.RoomInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomInfo}
 */
proto.rooms.api.v1.UpdateRoomResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomInfo|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomResponse} returns this
*/
proto.rooms.api.v1.UpdateRoomResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomResponse} returns this
 */
proto.rooms.api.v1.UpdateRoomResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.UpdateRoomResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomResponse} returns this
*/
proto.rooms.api.v1.UpdateRoomResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomResponse} returns this
 */
proto.rooms.api.v1.UpdateRoomResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteRoomRequest}
 */
proto.rooms.api.v1.DeleteRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteRoomRequest;
  return proto.rooms.api.v1.DeleteRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteRoomRequest}
 */
proto.rooms.api.v1.DeleteRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.DeleteRoomRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.DeleteRoomRequest} returns this
 */
proto.rooms.api.v1.DeleteRoomRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteRoomResponse}
 */
proto.rooms.api.v1.DeleteRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteRoomResponse;
  return proto.rooms.api.v1.DeleteRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteRoomResponse}
 */
proto.rooms.api.v1.DeleteRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.DeleteRoomResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.DeleteRoomResponse} returns this
*/
proto.rooms.api.v1.DeleteRoomResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.DeleteRoomResponse} returns this
 */
proto.rooms.api.v1.DeleteRoomResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.DeleteRoomResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.LeaveRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.LeaveRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.LeaveRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.LeaveRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.LeaveRoomRequest}
 */
proto.rooms.api.v1.LeaveRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.LeaveRoomRequest;
  return proto.rooms.api.v1.LeaveRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.LeaveRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.LeaveRoomRequest}
 */
proto.rooms.api.v1.LeaveRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.LeaveRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.LeaveRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.LeaveRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.LeaveRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.LeaveRoomRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.LeaveRoomRequest} returns this
 */
proto.rooms.api.v1.LeaveRoomRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.LeaveRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.LeaveRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.LeaveRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.LeaveRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.LeaveRoomResponse}
 */
proto.rooms.api.v1.LeaveRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.LeaveRoomResponse;
  return proto.rooms.api.v1.LeaveRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.LeaveRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.LeaveRoomResponse}
 */
proto.rooms.api.v1.LeaveRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.LeaveRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.LeaveRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.LeaveRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.LeaveRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.LeaveRoomResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.LeaveRoomResponse} returns this
*/
proto.rooms.api.v1.LeaveRoomResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.LeaveRoomResponse} returns this
 */
proto.rooms.api.v1.LeaveRoomResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.LeaveRoomResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ActivateRoomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ActivateRoomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ActivateRoomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ActivateRoomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ActivateRoomRequest}
 */
proto.rooms.api.v1.ActivateRoomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ActivateRoomRequest;
  return proto.rooms.api.v1.ActivateRoomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ActivateRoomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ActivateRoomRequest}
 */
proto.rooms.api.v1.ActivateRoomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ActivateRoomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ActivateRoomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ActivateRoomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ActivateRoomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ActivateRoomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ActivateRoomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ActivateRoomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ActivateRoomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ActivateRoomResponse}
 */
proto.rooms.api.v1.ActivateRoomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ActivateRoomResponse;
  return proto.rooms.api.v1.ActivateRoomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ActivateRoomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ActivateRoomResponse}
 */
proto.rooms.api.v1.ActivateRoomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ActivateRoomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ActivateRoomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ActivateRoomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ActivateRoomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ActivateRoomResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ActivateRoomResponse} returns this
*/
proto.rooms.api.v1.ActivateRoomResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ActivateRoomResponse} returns this
 */
proto.rooms.api.v1.ActivateRoomResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ActivateRoomResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomUserInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomUserInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomUserInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomUserInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomUserInfoRequest}
 */
proto.rooms.api.v1.GetRoomUserInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomUserInfoRequest;
  return proto.rooms.api.v1.GetRoomUserInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomUserInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomUserInfoRequest}
 */
proto.rooms.api.v1.GetRoomUserInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomUserInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomUserInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomUserInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomUserInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetRoomUserInfoRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetRoomUserInfoRequest} returns this
 */
proto.rooms.api.v1.GetRoomUserInfoRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomUserInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomUserInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomUserInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomUserInfoResponse}
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomUserInfoResponse;
  return proto.rooms.api.v1.GetRoomUserInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomUserInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomUserInfoResponse}
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomUserInfo;
      reader.readMessage(value,rooms_models_pb.RoomUserInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomUserInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomUserInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomUserInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomUserInfo}
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomUserInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomUserInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomUserInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomUserInfoResponse} returns this
*/
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomUserInfoResponse} returns this
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomUserInfoResponse} returns this
*/
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomUserInfoResponse} returns this
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomUserInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListRoomUsersRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRoomUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRoomUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    statusesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    order: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest}
 */
proto.rooms.api.v1.ListRoomUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRoomUsersRequest;
  return proto.rooms.api.v1.ListRoomUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRoomUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest}
 */
proto.rooms.api.v1.ListRoomUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<!proto.rooms.api.v1.RoomUserStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRoomUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRoomUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated int64 roles = 1;
 * @return {!Array<number>}
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.getRolesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest} returns this
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest} returns this
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest} returns this
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * repeated RoomUserStatus statuses = 2;
 * @return {!Array<!proto.rooms.api.v1.RoomUserStatus>}
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.RoomUserStatus>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RoomUserStatus>} value
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest} returns this
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.rooms.api.v1.RoomUserStatus} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest} returns this
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest} returns this
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional int64 offset = 3;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest} returns this
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest} returns this
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string order = 5;
 * @return {string}
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListRoomUsersRequest} returns this
 */
proto.rooms.api.v1.ListRoomUsersRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListRoomUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRoomUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRoomUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    rooms_models_pb.RoomUserInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRoomUsersResponse}
 */
proto.rooms.api.v1.ListRoomUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRoomUsersResponse;
  return proto.rooms.api.v1.ListRoomUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRoomUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRoomUsersResponse}
 */
proto.rooms.api.v1.ListRoomUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomUserInfo;
      reader.readMessage(value,rooms_models_pb.RoomUserInfo.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRoomUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRoomUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.RoomUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RoomUserInfo users = 1;
 * @return {!Array<!proto.rooms.api.v1.RoomUserInfo>}
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.RoomUserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.RoomUserInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RoomUserInfo>} value
 * @return {!proto.rooms.api.v1.ListRoomUsersResponse} returns this
*/
proto.rooms.api.v1.ListRoomUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.RoomUserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.RoomUserInfo}
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.RoomUserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRoomUsersResponse} returns this
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomUsersResponse} returns this
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListRoomUsersResponse} returns this
*/
proto.rooms.api.v1.ListRoomUsersResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListRoomUsersResponse} returns this
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListRoomUsersResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.CountRoomUsersRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRoomUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRoomUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    statusesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRoomUsersRequest}
 */
proto.rooms.api.v1.CountRoomUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRoomUsersRequest;
  return proto.rooms.api.v1.CountRoomUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRoomUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRoomUsersRequest}
 */
proto.rooms.api.v1.CountRoomUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<!proto.rooms.api.v1.RoomUserStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRoomUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRoomUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * repeated int64 roles = 1;
 * @return {!Array<number>}
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.getRolesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.rooms.api.v1.CountRoomUsersRequest} returns this
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.CountRoomUsersRequest} returns this
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.CountRoomUsersRequest} returns this
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * repeated RoomUserStatus statuses = 2;
 * @return {!Array<!proto.rooms.api.v1.RoomUserStatus>}
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.RoomUserStatus>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RoomUserStatus>} value
 * @return {!proto.rooms.api.v1.CountRoomUsersRequest} returns this
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.rooms.api.v1.RoomUserStatus} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.CountRoomUsersRequest} returns this
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.CountRoomUsersRequest} returns this
 */
proto.rooms.api.v1.CountRoomUsersRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRoomUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRoomUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRoomUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRoomUsersResponse}
 */
proto.rooms.api.v1.CountRoomUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRoomUsersResponse;
  return proto.rooms.api.v1.CountRoomUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRoomUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRoomUsersResponse}
 */
proto.rooms.api.v1.CountRoomUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRoomUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRoomUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRoomUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.rooms.api.v1.CountRoomUsersResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CountRoomUsersResponse} returns this
 */
proto.rooms.api.v1.CountRoomUsersResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CountRoomUsersResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CountRoomUsersResponse} returns this
*/
proto.rooms.api.v1.CountRoomUsersResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CountRoomUsersResponse} returns this
 */
proto.rooms.api.v1.CountRoomUsersResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CountRoomUsersResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateRoomUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateRoomUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: (f = msg.getRole()) && rooms_models_pb.RoomUserRoleValue.toObject(includeInstance, f),
    states: (f = msg.getStates()) && rooms_models_pb.RoomUserStatesValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateRoomUserRequest}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateRoomUserRequest;
  return proto.rooms.api.v1.UpdateRoomUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateRoomUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateRoomUserRequest}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = new rooms_models_pb.RoomUserRoleValue;
      reader.readMessage(value,rooms_models_pb.RoomUserRoleValue.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 4:
      var value = new rooms_models_pb.RoomUserStatesValue;
      reader.readMessage(value,rooms_models_pb.RoomUserStatesValue.deserializeBinaryFromReader);
      msg.setStates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateRoomUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateRoomUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      rooms_models_pb.RoomUserRoleValue.serializeBinaryToWriter
    );
  }
  f = message.getStates();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      rooms_models_pb.RoomUserStatesValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.UpdateRoomUserRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.UpdateRoomUserRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RoomUserRoleValue role = 3;
 * @return {?proto.rooms.api.v1.RoomUserRoleValue}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.getRole = function() {
  return /** @type{?proto.rooms.api.v1.RoomUserRoleValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomUserRoleValue, 3));
};


/**
 * @param {?proto.rooms.api.v1.RoomUserRoleValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomUserRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomUserRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RoomUserStatesValue states = 4;
 * @return {?proto.rooms.api.v1.RoomUserStatesValue}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.getStates = function() {
  return /** @type{?proto.rooms.api.v1.RoomUserStatesValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomUserStatesValue, 4));
};


/**
 * @param {?proto.rooms.api.v1.RoomUserStatesValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomUserRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.setStates = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomUserRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.clearStates = function() {
  return this.setStates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomUserRequest.prototype.hasStates = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateRoomUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateRoomUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomUserInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateRoomUserResponse}
 */
proto.rooms.api.v1.UpdateRoomUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateRoomUserResponse;
  return proto.rooms.api.v1.UpdateRoomUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateRoomUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateRoomUserResponse}
 */
proto.rooms.api.v1.UpdateRoomUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomUserInfo;
      reader.readMessage(value,rooms_models_pb.RoomUserInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateRoomUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateRoomUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomUserInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomUserInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomUserInfo}
 */
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomUserInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomUserInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomUserInfo|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomUserResponse} returns this
*/
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomUserResponse} returns this
 */
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomUserResponse} returns this
*/
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomUserResponse} returns this
 */
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomUserResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.TokenizeRoomUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.TokenizeRoomUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomPid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invitePid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.TokenizeRoomUserRequest}
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.TokenizeRoomUserRequest;
  return proto.rooms.api.v1.TokenizeRoomUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.TokenizeRoomUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.TokenizeRoomUserRequest}
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomPid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitePid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.TokenizeRoomUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.TokenizeRoomUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomPid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvitePid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string room_pid = 1;
 * @return {string}
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.prototype.getRoomPid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.TokenizeRoomUserRequest} returns this
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.prototype.setRoomPid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string invite_pid = 2;
 * @return {string}
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.prototype.getInvitePid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.TokenizeRoomUserRequest} returns this
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.prototype.setInvitePid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.TokenizeRoomUserRequest} returns this
 */
proto.rooms.api.v1.TokenizeRoomUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.TokenizeRoomUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.TokenizeRoomUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.TokenizeRoomUserResponse}
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.TokenizeRoomUserResponse;
  return proto.rooms.api.v1.TokenizeRoomUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.TokenizeRoomUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.TokenizeRoomUserResponse}
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.TokenizeRoomUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.TokenizeRoomUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.TokenizeRoomUserResponse} returns this
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.TokenizeRoomUserResponse} returns this
*/
proto.rooms.api.v1.TokenizeRoomUserResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.TokenizeRoomUserResponse} returns this
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.TokenizeRoomUserResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DisconnectRoomUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DisconnectRoomUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DisconnectRoomUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DisconnectRoomUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DisconnectRoomUserRequest}
 */
proto.rooms.api.v1.DisconnectRoomUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DisconnectRoomUserRequest;
  return proto.rooms.api.v1.DisconnectRoomUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DisconnectRoomUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DisconnectRoomUserRequest}
 */
proto.rooms.api.v1.DisconnectRoomUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DisconnectRoomUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DisconnectRoomUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DisconnectRoomUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DisconnectRoomUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.DisconnectRoomUserRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.DisconnectRoomUserRequest} returns this
 */
proto.rooms.api.v1.DisconnectRoomUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DisconnectRoomUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DisconnectRoomUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DisconnectRoomUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DisconnectRoomUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DisconnectRoomUserResponse}
 */
proto.rooms.api.v1.DisconnectRoomUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DisconnectRoomUserResponse;
  return proto.rooms.api.v1.DisconnectRoomUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DisconnectRoomUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DisconnectRoomUserResponse}
 */
proto.rooms.api.v1.DisconnectRoomUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DisconnectRoomUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DisconnectRoomUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DisconnectRoomUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DisconnectRoomUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.DisconnectRoomUserResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.DisconnectRoomUserResponse} returns this
*/
proto.rooms.api.v1.DisconnectRoomUserResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.DisconnectRoomUserResponse} returns this
 */
proto.rooms.api.v1.DisconnectRoomUserResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.DisconnectRoomUserResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomInviteInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomInviteInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoRequest}
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomInviteInfoRequest;
  return proto.rooms.api.v1.GetRoomInviteInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomInviteInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoRequest}
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomInviteInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomInviteInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoRequest} returns this
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pid = 2;
 * @return {string}
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.prototype.getPid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoRequest} returns this
 */
proto.rooms.api.v1.GetRoomInviteInfoRequest.prototype.setPid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomInviteInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomInviteInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomInviteInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoResponse}
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomInviteInfoResponse;
  return proto.rooms.api.v1.GetRoomInviteInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomInviteInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoResponse}
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInviteInfo;
      reader.readMessage(value,rooms_models_pb.RoomInviteInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomInviteInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomInviteInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomInviteInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomInviteInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomInviteInfo}
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomInviteInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomInviteInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomInviteInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoResponse} returns this
*/
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoResponse} returns this
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoResponse} returns this
*/
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomInviteInfoResponse} returns this
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomInviteInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRoomInvitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRoomInvitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomInvitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    order: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRoomInvitesRequest}
 */
proto.rooms.api.v1.ListRoomInvitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRoomInvitesRequest;
  return proto.rooms.api.v1.ListRoomInvitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRoomInvitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRoomInvitesRequest}
 */
proto.rooms.api.v1.ListRoomInvitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRoomInvitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRoomInvitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomInvitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListRoomInvitesRequest} returns this
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomInvitesRequest} returns this
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomInvitesRequest} returns this
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string order = 4;
 * @return {string}
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListRoomInvitesRequest} returns this
 */
proto.rooms.api.v1.ListRoomInvitesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListRoomInvitesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRoomInvitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRoomInvitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomInvitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invitesList: jspb.Message.toObjectList(msg.getInvitesList(),
    rooms_models_pb.RoomInviteInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRoomInvitesResponse}
 */
proto.rooms.api.v1.ListRoomInvitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRoomInvitesResponse;
  return proto.rooms.api.v1.ListRoomInvitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRoomInvitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRoomInvitesResponse}
 */
proto.rooms.api.v1.ListRoomInvitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInviteInfo;
      reader.readMessage(value,rooms_models_pb.RoomInviteInfo.deserializeBinaryFromReader);
      msg.addInvites(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRoomInvitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRoomInvitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomInvitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvitesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.RoomInviteInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RoomInviteInfo invites = 1;
 * @return {!Array<!proto.rooms.api.v1.RoomInviteInfo>}
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.getInvitesList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.RoomInviteInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.RoomInviteInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RoomInviteInfo>} value
 * @return {!proto.rooms.api.v1.ListRoomInvitesResponse} returns this
*/
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.setInvitesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.RoomInviteInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.RoomInviteInfo}
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.addInvites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.RoomInviteInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRoomInvitesResponse} returns this
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.clearInvitesList = function() {
  return this.setInvitesList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomInvitesResponse} returns this
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListRoomInvitesResponse} returns this
*/
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListRoomInvitesResponse} returns this
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListRoomInvitesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRoomInvitesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRoomInvitesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRoomInvitesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomInvitesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRoomInvitesRequest}
 */
proto.rooms.api.v1.CountRoomInvitesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRoomInvitesRequest;
  return proto.rooms.api.v1.CountRoomInvitesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRoomInvitesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRoomInvitesRequest}
 */
proto.rooms.api.v1.CountRoomInvitesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRoomInvitesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRoomInvitesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRoomInvitesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomInvitesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.CountRoomInvitesRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CountRoomInvitesRequest} returns this
 */
proto.rooms.api.v1.CountRoomInvitesRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRoomInvitesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRoomInvitesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRoomInvitesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomInvitesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRoomInvitesResponse}
 */
proto.rooms.api.v1.CountRoomInvitesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRoomInvitesResponse;
  return proto.rooms.api.v1.CountRoomInvitesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRoomInvitesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRoomInvitesResponse}
 */
proto.rooms.api.v1.CountRoomInvitesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRoomInvitesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRoomInvitesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRoomInvitesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomInvitesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.rooms.api.v1.CountRoomInvitesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CountRoomInvitesResponse} returns this
 */
proto.rooms.api.v1.CountRoomInvitesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CountRoomInvitesResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CountRoomInvitesResponse} returns this
*/
proto.rooms.api.v1.CountRoomInvitesResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CountRoomInvitesResponse} returns this
 */
proto.rooms.api.v1.CountRoomInvitesResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CountRoomInvitesResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CreateRoomInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CreateRoomInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateRoomInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roomId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    expiresAt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CreateRoomInviteRequest}
 */
proto.rooms.api.v1.CreateRoomInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CreateRoomInviteRequest;
  return proto.rooms.api.v1.CreateRoomInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CreateRoomInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CreateRoomInviteRequest}
 */
proto.rooms.api.v1.CreateRoomInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CreateRoomInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CreateRoomInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateRoomInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getExpiresAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string pid = 1;
 * @return {string}
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.getPid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateRoomInviteRequest} returns this
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.setPid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string room_id = 2;
 * @return {string}
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateRoomInviteRequest} returns this
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 usages = 3;
 * @return {number}
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.getUsages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CreateRoomInviteRequest} returns this
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.setUsages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 expires_at = 4;
 * @return {number}
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.getExpiresAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CreateRoomInviteRequest} returns this
 */
proto.rooms.api.v1.CreateRoomInviteRequest.prototype.setExpiresAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CreateRoomInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CreateRoomInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateRoomInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomInviteInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CreateRoomInviteResponse}
 */
proto.rooms.api.v1.CreateRoomInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CreateRoomInviteResponse;
  return proto.rooms.api.v1.CreateRoomInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CreateRoomInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CreateRoomInviteResponse}
 */
proto.rooms.api.v1.CreateRoomInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInviteInfo;
      reader.readMessage(value,rooms_models_pb.RoomInviteInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CreateRoomInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CreateRoomInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateRoomInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomInviteInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomInviteInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomInviteInfo}
 */
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomInviteInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomInviteInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomInviteInfo|undefined} value
 * @return {!proto.rooms.api.v1.CreateRoomInviteResponse} returns this
*/
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CreateRoomInviteResponse} returns this
 */
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CreateRoomInviteResponse} returns this
*/
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CreateRoomInviteResponse} returns this
 */
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CreateRoomInviteResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateRoomInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateRoomInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    usages: (f = msg.getUsages()) && rooms_models_pb.RoomInviteUsagesValue.toObject(includeInstance, f),
    expiresAt: (f = msg.getExpiresAt()) && rooms_models_pb.RoomInviteExpiresAtValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateRoomInviteRequest}
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateRoomInviteRequest;
  return proto.rooms.api.v1.UpdateRoomInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateRoomInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateRoomInviteRequest}
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new rooms_models_pb.RoomInviteUsagesValue;
      reader.readMessage(value,rooms_models_pb.RoomInviteUsagesValue.deserializeBinaryFromReader);
      msg.setUsages(value);
      break;
    case 3:
      var value = new rooms_models_pb.RoomInviteExpiresAtValue;
      reader.readMessage(value,rooms_models_pb.RoomInviteExpiresAtValue.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateRoomInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateRoomInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsages();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      rooms_models_pb.RoomInviteUsagesValue.serializeBinaryToWriter
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      rooms_models_pb.RoomInviteExpiresAtValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.UpdateRoomInviteRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RoomInviteUsagesValue usages = 2;
 * @return {?proto.rooms.api.v1.RoomInviteUsagesValue}
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.getUsages = function() {
  return /** @type{?proto.rooms.api.v1.RoomInviteUsagesValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomInviteUsagesValue, 2));
};


/**
 * @param {?proto.rooms.api.v1.RoomInviteUsagesValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomInviteRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.setUsages = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomInviteRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.clearUsages = function() {
  return this.setUsages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.hasUsages = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RoomInviteExpiresAtValue expires_at = 3;
 * @return {?proto.rooms.api.v1.RoomInviteExpiresAtValue}
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.getExpiresAt = function() {
  return /** @type{?proto.rooms.api.v1.RoomInviteExpiresAtValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomInviteExpiresAtValue, 3));
};


/**
 * @param {?proto.rooms.api.v1.RoomInviteExpiresAtValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomInviteRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomInviteRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomInviteRequest.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateRoomInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateRoomInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomInviteInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateRoomInviteResponse}
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateRoomInviteResponse;
  return proto.rooms.api.v1.UpdateRoomInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateRoomInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateRoomInviteResponse}
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomInviteInfo;
      reader.readMessage(value,rooms_models_pb.RoomInviteInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateRoomInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateRoomInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomInviteInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomInviteInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomInviteInfo}
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomInviteInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomInviteInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomInviteInfo|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomInviteResponse} returns this
*/
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomInviteResponse} returns this
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomInviteResponse} returns this
*/
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomInviteResponse} returns this
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomInviteResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteRoomInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteRoomInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteRoomInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteRoomInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteRoomInviteRequest}
 */
proto.rooms.api.v1.DeleteRoomInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteRoomInviteRequest;
  return proto.rooms.api.v1.DeleteRoomInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteRoomInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteRoomInviteRequest}
 */
proto.rooms.api.v1.DeleteRoomInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteRoomInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteRoomInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteRoomInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteRoomInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.DeleteRoomInviteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.DeleteRoomInviteRequest} returns this
 */
proto.rooms.api.v1.DeleteRoomInviteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteRoomInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteRoomInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteRoomInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteRoomInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteRoomInviteResponse}
 */
proto.rooms.api.v1.DeleteRoomInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteRoomInviteResponse;
  return proto.rooms.api.v1.DeleteRoomInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteRoomInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteRoomInviteResponse}
 */
proto.rooms.api.v1.DeleteRoomInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteRoomInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteRoomInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteRoomInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteRoomInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.DeleteRoomInviteResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.DeleteRoomInviteResponse} returns this
*/
proto.rooms.api.v1.DeleteRoomInviteResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.DeleteRoomInviteResponse} returns this
 */
proto.rooms.api.v1.DeleteRoomInviteResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.DeleteRoomInviteResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeliverRoomInviteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeliverRoomInviteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    userEmailsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeliverRoomInviteRequest}
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeliverRoomInviteRequest;
  return proto.rooms.api.v1.DeliverRoomInviteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeliverRoomInviteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeliverRoomInviteRequest}
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeliverRoomInviteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeliverRoomInviteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getUserEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.DeliverRoomInviteRequest} returns this
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string user_ids = 2;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.DeliverRoomInviteRequest} returns this
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.DeliverRoomInviteRequest} returns this
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.DeliverRoomInviteRequest} returns this
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * repeated string user_emails = 3;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.getUserEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.DeliverRoomInviteRequest} returns this
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.setUserEmailsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.DeliverRoomInviteRequest} returns this
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.addUserEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.DeliverRoomInviteRequest} returns this
 */
proto.rooms.api.v1.DeliverRoomInviteRequest.prototype.clearUserEmailsList = function() {
  return this.setUserEmailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeliverRoomInviteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeliverRoomInviteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeliverRoomInviteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeliverRoomInviteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeliverRoomInviteResponse}
 */
proto.rooms.api.v1.DeliverRoomInviteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeliverRoomInviteResponse;
  return proto.rooms.api.v1.DeliverRoomInviteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeliverRoomInviteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeliverRoomInviteResponse}
 */
proto.rooms.api.v1.DeliverRoomInviteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeliverRoomInviteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeliverRoomInviteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeliverRoomInviteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeliverRoomInviteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.DeliverRoomInviteResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.DeliverRoomInviteResponse} returns this
*/
proto.rooms.api.v1.DeliverRoomInviteResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.DeliverRoomInviteResponse} returns this
 */
proto.rooms.api.v1.DeliverRoomInviteResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.DeliverRoomInviteResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomStreamInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomStreamInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomStreamInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomStreamInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomStreamInfoRequest}
 */
proto.rooms.api.v1.GetRoomStreamInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomStreamInfoRequest;
  return proto.rooms.api.v1.GetRoomStreamInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomStreamInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomStreamInfoRequest}
 */
proto.rooms.api.v1.GetRoomStreamInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomStreamInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomStreamInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomStreamInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomStreamInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetRoomStreamInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetRoomStreamInfoRequest} returns this
 */
proto.rooms.api.v1.GetRoomStreamInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRoomStreamInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRoomStreamInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomStreamInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRoomStreamInfoResponse}
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRoomStreamInfoResponse;
  return proto.rooms.api.v1.GetRoomStreamInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRoomStreamInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRoomStreamInfoResponse}
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomStreamInfo;
      reader.readMessage(value,rooms_models_pb.RoomStreamInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRoomStreamInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRoomStreamInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomStreamInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomStreamInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomStreamInfo}
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomStreamInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomStreamInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomStreamInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomStreamInfoResponse} returns this
*/
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomStreamInfoResponse} returns this
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetRoomStreamInfoResponse} returns this
*/
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRoomStreamInfoResponse} returns this
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRoomStreamInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRoomStreamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRoomStreamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRoomStreamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomStreamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    order: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRoomStreamsRequest}
 */
proto.rooms.api.v1.ListRoomStreamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRoomStreamsRequest;
  return proto.rooms.api.v1.ListRoomStreamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRoomStreamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRoomStreamsRequest}
 */
proto.rooms.api.v1.ListRoomStreamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRoomStreamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRoomStreamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRoomStreamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomStreamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 offset = 1;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomStreamsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomStreamsRequest} returns this
 */
proto.rooms.api.v1.ListRoomStreamsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomStreamsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomStreamsRequest} returns this
 */
proto.rooms.api.v1.ListRoomStreamsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string order = 3;
 * @return {string}
 */
proto.rooms.api.v1.ListRoomStreamsRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListRoomStreamsRequest} returns this
 */
proto.rooms.api.v1.ListRoomStreamsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListRoomStreamsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRoomStreamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRoomStreamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomStreamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    streamsList: jspb.Message.toObjectList(msg.getStreamsList(),
    rooms_models_pb.RoomStreamInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRoomStreamsResponse}
 */
proto.rooms.api.v1.ListRoomStreamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRoomStreamsResponse;
  return proto.rooms.api.v1.ListRoomStreamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRoomStreamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRoomStreamsResponse}
 */
proto.rooms.api.v1.ListRoomStreamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomStreamInfo;
      reader.readMessage(value,rooms_models_pb.RoomStreamInfo.deserializeBinaryFromReader);
      msg.addStreams(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRoomStreamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRoomStreamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRoomStreamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.RoomStreamInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RoomStreamInfo streams = 1;
 * @return {!Array<!proto.rooms.api.v1.RoomStreamInfo>}
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.getStreamsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.RoomStreamInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.RoomStreamInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RoomStreamInfo>} value
 * @return {!proto.rooms.api.v1.ListRoomStreamsResponse} returns this
*/
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.setStreamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.RoomStreamInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.RoomStreamInfo}
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.addStreams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.RoomStreamInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRoomStreamsResponse} returns this
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.clearStreamsList = function() {
  return this.setStreamsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRoomStreamsResponse} returns this
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListRoomStreamsResponse} returns this
*/
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListRoomStreamsResponse} returns this
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListRoomStreamsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRoomStreamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRoomStreamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRoomStreamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomStreamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRoomStreamsRequest}
 */
proto.rooms.api.v1.CountRoomStreamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRoomStreamsRequest;
  return proto.rooms.api.v1.CountRoomStreamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRoomStreamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRoomStreamsRequest}
 */
proto.rooms.api.v1.CountRoomStreamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRoomStreamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRoomStreamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRoomStreamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomStreamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRoomStreamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRoomStreamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRoomStreamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomStreamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRoomStreamsResponse}
 */
proto.rooms.api.v1.CountRoomStreamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRoomStreamsResponse;
  return proto.rooms.api.v1.CountRoomStreamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRoomStreamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRoomStreamsResponse}
 */
proto.rooms.api.v1.CountRoomStreamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRoomStreamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRoomStreamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRoomStreamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRoomStreamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.rooms.api.v1.CountRoomStreamsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CountRoomStreamsResponse} returns this
 */
proto.rooms.api.v1.CountRoomStreamsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CountRoomStreamsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CountRoomStreamsResponse} returns this
*/
proto.rooms.api.v1.CountRoomStreamsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CountRoomStreamsResponse} returns this
 */
proto.rooms.api.v1.CountRoomStreamsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CountRoomStreamsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateRoomStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateRoomStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && rooms_models_pb.RoomStreamMetadataValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateRoomStreamRequest}
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateRoomStreamRequest;
  return proto.rooms.api.v1.UpdateRoomStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateRoomStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateRoomStreamRequest}
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new rooms_models_pb.RoomStreamMetadataValue;
      reader.readMessage(value,rooms_models_pb.RoomStreamMetadataValue.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateRoomStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateRoomStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      rooms_models_pb.RoomStreamMetadataValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.UpdateRoomStreamRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RoomStreamMetadataValue metadata = 2;
 * @return {?proto.rooms.api.v1.RoomStreamMetadataValue}
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.prototype.getMetadata = function() {
  return /** @type{?proto.rooms.api.v1.RoomStreamMetadataValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomStreamMetadataValue, 2));
};


/**
 * @param {?proto.rooms.api.v1.RoomStreamMetadataValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomStreamRequest} returns this
*/
proto.rooms.api.v1.UpdateRoomStreamRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomStreamRequest} returns this
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomStreamRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateRoomStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateRoomStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomStreamInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateRoomStreamResponse}
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateRoomStreamResponse;
  return proto.rooms.api.v1.UpdateRoomStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateRoomStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateRoomStreamResponse}
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomStreamInfo;
      reader.readMessage(value,rooms_models_pb.RoomStreamInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateRoomStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateRoomStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomStreamInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomStreamInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomStreamInfo}
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomStreamInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomStreamInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomStreamInfo|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomStreamResponse} returns this
*/
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomStreamResponse} returns this
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.UpdateRoomStreamResponse} returns this
*/
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateRoomStreamResponse} returns this
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateRoomStreamResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.InsertRoomStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.InsertRoomStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.InsertRoomStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    muted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    paused: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    offset: jspb.Message.getFieldWithDefault(msg, 6, 0),
    related: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    suggest: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    duration: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.InsertRoomStreamRequest;
  return proto.rooms.api.v1.InsertRoomStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.InsertRoomStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSid(value);
      break;
    case 3:
      var value = /** @type {!proto.rooms.api.v1.RoomStreamType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMuted(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaused(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRelated(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuggest(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.InsertRoomStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.InsertRoomStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.InsertRoomStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMuted();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPaused();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getRelated();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getSuggest();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest} returns this
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sid = 2;
 * @return {string}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.getSid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest} returns this
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.setSid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RoomStreamType type = 3;
 * @return {!proto.rooms.api.v1.RoomStreamType}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.getType = function() {
  return /** @type {!proto.rooms.api.v1.RoomStreamType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.rooms.api.v1.RoomStreamType} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest} returns this
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool muted = 4;
 * @return {boolean}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.getMuted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest} returns this
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.setMuted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool paused = 5;
 * @return {boolean}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.getPaused = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest} returns this
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.setPaused = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 offset = 6;
 * @return {number}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest} returns this
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool related = 9;
 * @return {boolean}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.getRelated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest} returns this
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.setRelated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool suggest = 8;
 * @return {boolean}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.getSuggest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest} returns this
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.setSuggest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int64 duration = 7;
 * @return {number}
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamRequest} returns this
 */
proto.rooms.api.v1.InsertRoomStreamRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.InsertRoomStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.InsertRoomStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.InsertRoomStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomStreamInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.InsertRoomStreamResponse}
 */
proto.rooms.api.v1.InsertRoomStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.InsertRoomStreamResponse;
  return proto.rooms.api.v1.InsertRoomStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.InsertRoomStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.InsertRoomStreamResponse}
 */
proto.rooms.api.v1.InsertRoomStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomStreamInfo;
      reader.readMessage(value,rooms_models_pb.RoomStreamInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.InsertRoomStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.InsertRoomStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.InsertRoomStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomStreamInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomStreamInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomStreamInfo}
 */
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomStreamInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomStreamInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomStreamInfo|undefined} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamResponse} returns this
*/
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.InsertRoomStreamResponse} returns this
 */
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.InsertRoomStreamResponse} returns this
*/
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.InsertRoomStreamResponse} returns this
 */
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.InsertRoomStreamResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ChangeRoomStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ChangeRoomStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcePosition: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetPosition: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ChangeRoomStreamRequest}
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ChangeRoomStreamRequest;
  return proto.rooms.api.v1.ChangeRoomStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ChangeRoomStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ChangeRoomStreamRequest}
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourcePosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ChangeRoomStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ChangeRoomStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcePosition();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTargetPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 source_position = 1;
 * @return {number}
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.prototype.getSourcePosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ChangeRoomStreamRequest} returns this
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.prototype.setSourcePosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 target_position = 2;
 * @return {number}
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.prototype.getTargetPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ChangeRoomStreamRequest} returns this
 */
proto.rooms.api.v1.ChangeRoomStreamRequest.prototype.setTargetPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ChangeRoomStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ChangeRoomStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RoomStreamInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ChangeRoomStreamResponse}
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ChangeRoomStreamResponse;
  return proto.rooms.api.v1.ChangeRoomStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ChangeRoomStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ChangeRoomStreamResponse}
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RoomStreamInfo;
      reader.readMessage(value,rooms_models_pb.RoomStreamInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ChangeRoomStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ChangeRoomStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RoomStreamInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RoomStreamInfo info = 1;
 * @return {?proto.rooms.api.v1.RoomStreamInfo}
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RoomStreamInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RoomStreamInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RoomStreamInfo|undefined} value
 * @return {!proto.rooms.api.v1.ChangeRoomStreamResponse} returns this
*/
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ChangeRoomStreamResponse} returns this
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ChangeRoomStreamResponse} returns this
*/
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ChangeRoomStreamResponse} returns this
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ChangeRoomStreamResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.RemoveRoomStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.RemoveRoomStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.RemoveRoomStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.RemoveRoomStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.RemoveRoomStreamRequest}
 */
proto.rooms.api.v1.RemoveRoomStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.RemoveRoomStreamRequest;
  return proto.rooms.api.v1.RemoveRoomStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.RemoveRoomStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.RemoveRoomStreamRequest}
 */
proto.rooms.api.v1.RemoveRoomStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.RemoveRoomStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.RemoveRoomStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.RemoveRoomStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.RemoveRoomStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.RemoveRoomStreamRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.RemoveRoomStreamRequest} returns this
 */
proto.rooms.api.v1.RemoveRoomStreamRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.RemoveRoomStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.RemoveRoomStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.RemoveRoomStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.RemoveRoomStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.RemoveRoomStreamResponse}
 */
proto.rooms.api.v1.RemoveRoomStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.RemoveRoomStreamResponse;
  return proto.rooms.api.v1.RemoveRoomStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.RemoveRoomStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.RemoveRoomStreamResponse}
 */
proto.rooms.api.v1.RemoveRoomStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.RemoveRoomStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.RemoveRoomStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.RemoveRoomStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.RemoveRoomStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.RemoveRoomStreamResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.RemoveRoomStreamResponse} returns this
*/
proto.rooms.api.v1.RemoveRoomStreamResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.RemoveRoomStreamResponse} returns this
 */
proto.rooms.api.v1.RemoveRoomStreamResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.RemoveRoomStreamResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ClearRoomStreamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ClearRoomStreamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ClearRoomStreamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ClearRoomStreamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ClearRoomStreamsRequest}
 */
proto.rooms.api.v1.ClearRoomStreamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ClearRoomStreamsRequest;
  return proto.rooms.api.v1.ClearRoomStreamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ClearRoomStreamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ClearRoomStreamsRequest}
 */
proto.rooms.api.v1.ClearRoomStreamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ClearRoomStreamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ClearRoomStreamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ClearRoomStreamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ClearRoomStreamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.ClearRoomStreamsRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ClearRoomStreamsRequest} returns this
 */
proto.rooms.api.v1.ClearRoomStreamsRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ClearRoomStreamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ClearRoomStreamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ClearRoomStreamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ClearRoomStreamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ClearRoomStreamsResponse}
 */
proto.rooms.api.v1.ClearRoomStreamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ClearRoomStreamsResponse;
  return proto.rooms.api.v1.ClearRoomStreamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ClearRoomStreamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ClearRoomStreamsResponse}
 */
proto.rooms.api.v1.ClearRoomStreamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ClearRoomStreamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ClearRoomStreamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ClearRoomStreamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ClearRoomStreamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ClearRoomStreamsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ClearRoomStreamsResponse} returns this
*/
proto.rooms.api.v1.ClearRoomStreamsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ClearRoomStreamsResponse} returns this
 */
proto.rooms.api.v1.ClearRoomStreamsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ClearRoomStreamsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventInfoRequest}
 */
proto.rooms.api.v1.GetEventInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventInfoRequest;
  return proto.rooms.api.v1.GetEventInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventInfoRequest}
 */
proto.rooms.api.v1.GetEventInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetEventInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetEventInfoRequest} returns this
 */
proto.rooms.api.v1.GetEventInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pid = 2;
 * @return {string}
 */
proto.rooms.api.v1.GetEventInfoRequest.prototype.getPid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetEventInfoRequest} returns this
 */
proto.rooms.api.v1.GetEventInfoRequest.prototype.setPid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.EventInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventInfoResponse}
 */
proto.rooms.api.v1.GetEventInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventInfoResponse;
  return proto.rooms.api.v1.GetEventInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventInfoResponse}
 */
proto.rooms.api.v1.GetEventInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventInfo;
      reader.readMessage(value,rooms_models_pb.EventInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.EventInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventInfo info = 1;
 * @return {?proto.rooms.api.v1.EventInfo}
 */
proto.rooms.api.v1.GetEventInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.EventInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.EventInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetEventInfoResponse} returns this
*/
proto.rooms.api.v1.GetEventInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventInfoResponse} returns this
 */
proto.rooms.api.v1.GetEventInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetEventInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetEventInfoResponse} returns this
*/
proto.rooms.api.v1.GetEventInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventInfoResponse} returns this
 */
proto.rooms.api.v1.GetEventInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventStateRequest}
 */
proto.rooms.api.v1.GetEventStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventStateRequest;
  return proto.rooms.api.v1.GetEventStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventStateRequest}
 */
proto.rooms.api.v1.GetEventStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: (f = msg.getState()) && rooms_models_pb.EventState.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventStateResponse}
 */
proto.rooms.api.v1.GetEventStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventStateResponse;
  return proto.rooms.api.v1.GetEventStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventStateResponse}
 */
proto.rooms.api.v1.GetEventStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventState;
      reader.readMessage(value,rooms_models_pb.EventState.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.EventState.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventState state = 1;
 * @return {?proto.rooms.api.v1.EventState}
 */
proto.rooms.api.v1.GetEventStateResponse.prototype.getState = function() {
  return /** @type{?proto.rooms.api.v1.EventState} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventState, 1));
};


/**
 * @param {?proto.rooms.api.v1.EventState|undefined} value
 * @return {!proto.rooms.api.v1.GetEventStateResponse} returns this
*/
proto.rooms.api.v1.GetEventStateResponse.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventStateResponse} returns this
 */
proto.rooms.api.v1.GetEventStateResponse.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventStateResponse.prototype.hasState = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetEventStateResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetEventStateResponse} returns this
*/
proto.rooms.api.v1.GetEventStateResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventStateResponse} returns this
 */
proto.rooms.api.v1.GetEventStateResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventStateResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListEventsRequest.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    roomId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    offset: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    order: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListEventsRequest}
 */
proto.rooms.api.v1.ListEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListEventsRequest;
  return proto.rooms.api.v1.ListEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListEventsRequest}
 */
proto.rooms.api.v1.ListEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.rooms.api.v1.EventStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.ListEventsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional string room_id = 2;
 * @return {string}
 */
proto.rooms.api.v1.ListEventsRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated EventStatus statuses = 3;
 * @return {!Array<!proto.rooms.api.v1.EventStatus>}
 */
proto.rooms.api.v1.ListEventsRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.EventStatus>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventStatus>} value
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.rooms.api.v1.EventStatus} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional int64 offset = 4;
 * @return {number}
 */
proto.rooms.api.v1.ListEventsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 limit = 5;
 * @return {number}
 */
proto.rooms.api.v1.ListEventsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string order = 6;
 * @return {string}
 */
proto.rooms.api.v1.ListEventsRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListEventsRequest} returns this
 */
proto.rooms.api.v1.ListEventsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    rooms_models_pb.EventInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListEventsResponse}
 */
proto.rooms.api.v1.ListEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListEventsResponse;
  return proto.rooms.api.v1.ListEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListEventsResponse}
 */
proto.rooms.api.v1.ListEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventInfo;
      reader.readMessage(value,rooms_models_pb.EventInfo.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.EventInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventInfo events = 1;
 * @return {!Array<!proto.rooms.api.v1.EventInfo>}
 */
proto.rooms.api.v1.ListEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.EventInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.EventInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventInfo>} value
 * @return {!proto.rooms.api.v1.ListEventsResponse} returns this
*/
proto.rooms.api.v1.ListEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.EventInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.EventInfo}
 */
proto.rooms.api.v1.ListEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.EventInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListEventsResponse} returns this
 */
proto.rooms.api.v1.ListEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListEventsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListEventsResponse} returns this
 */
proto.rooms.api.v1.ListEventsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListEventsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListEventsResponse} returns this
*/
proto.rooms.api.v1.ListEventsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListEventsResponse} returns this
 */
proto.rooms.api.v1.ListEventsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListEventsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.CountEventsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountEventsRequest}
 */
proto.rooms.api.v1.CountEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountEventsRequest;
  return proto.rooms.api.v1.CountEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountEventsRequest}
 */
proto.rooms.api.v1.CountEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.rooms.api.v1.EventStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.CountEventsRequest.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CountEventsRequest} returns this
 */
proto.rooms.api.v1.CountEventsRequest.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated EventStatus statuses = 2;
 * @return {!Array<!proto.rooms.api.v1.EventStatus>}
 */
proto.rooms.api.v1.CountEventsRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.EventStatus>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventStatus>} value
 * @return {!proto.rooms.api.v1.CountEventsRequest} returns this
 */
proto.rooms.api.v1.CountEventsRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.rooms.api.v1.EventStatus} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.CountEventsRequest} returns this
 */
proto.rooms.api.v1.CountEventsRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.CountEventsRequest} returns this
 */
proto.rooms.api.v1.CountEventsRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountEventsResponse}
 */
proto.rooms.api.v1.CountEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountEventsResponse;
  return proto.rooms.api.v1.CountEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountEventsResponse}
 */
proto.rooms.api.v1.CountEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.rooms.api.v1.CountEventsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CountEventsResponse} returns this
 */
proto.rooms.api.v1.CountEventsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CountEventsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CountEventsResponse} returns this
*/
proto.rooms.api.v1.CountEventsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CountEventsResponse} returns this
 */
proto.rooms.api.v1.CountEventsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CountEventsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.SearchEventsRequest.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SearchEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SearchEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    typesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    genresList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    statusesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    startsAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    finishesAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 7, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SearchEventsRequest}
 */
proto.rooms.api.v1.SearchEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SearchEventsRequest;
  return proto.rooms.api.v1.SearchEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SearchEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SearchEventsRequest}
 */
proto.rooms.api.v1.SearchEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.rooms.api.v1.EventType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<!proto.rooms.api.v1.EventGenre>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGenres(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<!proto.rooms.api.v1.EventStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartsAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFinishesAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SearchEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SearchEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getGenresList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getStartsAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getFinishesAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated EventType types = 2;
 * @return {!Array<!proto.rooms.api.v1.EventType>}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.EventType>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventType>} value
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.rooms.api.v1.EventType} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * repeated EventGenre genres = 3;
 * @return {!Array<!proto.rooms.api.v1.EventGenre>}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.getGenresList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.EventGenre>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventGenre>} value
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.setGenresList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.rooms.api.v1.EventGenre} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.addGenres = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.clearGenresList = function() {
  return this.setGenresList([]);
};


/**
 * repeated EventStatus statuses = 4;
 * @return {!Array<!proto.rooms.api.v1.EventStatus>}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.EventStatus>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventStatus>} value
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.rooms.api.v1.EventStatus} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional int64 starts_at = 5;
 * @return {number}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.getStartsAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 finishes_at = 6;
 * @return {number}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.getFinishesAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.setFinishesAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 offset = 7;
 * @return {number}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 limit = 8;
 * @return {number}
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchEventsRequest} returns this
 */
proto.rooms.api.v1.SearchEventsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.SearchEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SearchEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SearchEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    rooms_models_pb.EventInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SearchEventsResponse}
 */
proto.rooms.api.v1.SearchEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SearchEventsResponse;
  return proto.rooms.api.v1.SearchEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SearchEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SearchEventsResponse}
 */
proto.rooms.api.v1.SearchEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventInfo;
      reader.readMessage(value,rooms_models_pb.EventInfo.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SearchEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SearchEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.EventInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventInfo events = 1;
 * @return {!Array<!proto.rooms.api.v1.EventInfo>}
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.EventInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.EventInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventInfo>} value
 * @return {!proto.rooms.api.v1.SearchEventsResponse} returns this
*/
proto.rooms.api.v1.SearchEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.EventInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.EventInfo}
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.EventInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SearchEventsResponse} returns this
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchEventsResponse} returns this
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.SearchEventsResponse} returns this
*/
proto.rooms.api.v1.SearchEventsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.SearchEventsResponse} returns this
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.SearchEventsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SuggestEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SuggestEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SuggestEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SuggestEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SuggestEventsRequest}
 */
proto.rooms.api.v1.SuggestEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SuggestEventsRequest;
  return proto.rooms.api.v1.SuggestEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SuggestEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SuggestEventsRequest}
 */
proto.rooms.api.v1.SuggestEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SuggestEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SuggestEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SuggestEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SuggestEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.rooms.api.v1.SuggestEventsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.SuggestEventsRequest} returns this
 */
proto.rooms.api.v1.SuggestEventsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.SuggestEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SuggestEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SuggestEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SuggestEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SuggestEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    namesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SuggestEventsResponse}
 */
proto.rooms.api.v1.SuggestEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SuggestEventsResponse;
  return proto.rooms.api.v1.SuggestEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SuggestEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SuggestEventsResponse}
 */
proto.rooms.api.v1.SuggestEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNames(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SuggestEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SuggestEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SuggestEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SuggestEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string names = 1;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.SuggestEventsResponse.prototype.getNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.SuggestEventsResponse} returns this
 */
proto.rooms.api.v1.SuggestEventsResponse.prototype.setNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.SuggestEventsResponse} returns this
 */
proto.rooms.api.v1.SuggestEventsResponse.prototype.addNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SuggestEventsResponse} returns this
 */
proto.rooms.api.v1.SuggestEventsResponse.prototype.clearNamesList = function() {
  return this.setNamesList([]);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.SuggestEventsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.SuggestEventsResponse} returns this
*/
proto.rooms.api.v1.SuggestEventsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.SuggestEventsResponse} returns this
 */
proto.rooms.api.v1.SuggestEventsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.SuggestEventsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.CreateEventRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CreateEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CreateEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    access: jspb.Message.getFieldWithDefault(msg, 4, 0),
    source: jspb.Message.getFieldWithDefault(msg, 5, 0),
    genresList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    summary: jspb.Message.getFieldWithDefault(msg, 7, ""),
    overseer: jspb.Message.getFieldWithDefault(msg, 8, 0),
    description: jspb.Message.getFieldWithDefault(msg, 9, ""),
    startsAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    finishesAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 12, ""),
    background: jspb.Message.getFieldWithDefault(msg, 13, ""),
    broadcastUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    broadcastOffset: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CreateEventRequest}
 */
proto.rooms.api.v1.CreateEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CreateEventRequest;
  return proto.rooms.api.v1.CreateEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CreateEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CreateEventRequest}
 */
proto.rooms.api.v1.CreateEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.rooms.api.v1.EventType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.rooms.api.v1.EventAccess} */ (reader.readEnum());
      msg.setAccess(value);
      break;
    case 5:
      var value = /** @type {!proto.rooms.api.v1.EventSource} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.rooms.api.v1.EventGenre>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGenres(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 8:
      var value = /** @type {!proto.rooms.api.v1.EventOverseer} */ (reader.readEnum());
      msg.setOverseer(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartsAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFinishesAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackground(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroadcastUrl(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBroadcastOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CreateEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CreateEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAccess();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getGenresList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOverseer();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStartsAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getFinishesAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBackground();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBroadcastUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBroadcastOffset();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
};


/**
 * optional string pid = 1;
 * @return {string}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getPid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setPid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EventType type = 3;
 * @return {!proto.rooms.api.v1.EventType}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getType = function() {
  return /** @type {!proto.rooms.api.v1.EventType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.rooms.api.v1.EventType} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional EventAccess access = 4;
 * @return {!proto.rooms.api.v1.EventAccess}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getAccess = function() {
  return /** @type {!proto.rooms.api.v1.EventAccess} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.rooms.api.v1.EventAccess} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setAccess = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional EventSource source = 5;
 * @return {!proto.rooms.api.v1.EventSource}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getSource = function() {
  return /** @type {!proto.rooms.api.v1.EventSource} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.rooms.api.v1.EventSource} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated EventGenre genres = 6;
 * @return {!Array<!proto.rooms.api.v1.EventGenre>}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getGenresList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.EventGenre>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventGenre>} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setGenresList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.rooms.api.v1.EventGenre} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.addGenres = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.clearGenresList = function() {
  return this.setGenresList([]);
};


/**
 * optional string summary = 7;
 * @return {string}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional EventOverseer overseer = 8;
 * @return {!proto.rooms.api.v1.EventOverseer}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getOverseer = function() {
  return /** @type {!proto.rooms.api.v1.EventOverseer} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.rooms.api.v1.EventOverseer} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setOverseer = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 starts_at = 10;
 * @return {number}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getStartsAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setStartsAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 finishes_at = 11;
 * @return {number}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getFinishesAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setFinishesAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string thumbnail = 12;
 * @return {string}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string background = 13;
 * @return {string}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getBackground = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setBackground = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string broadcast_url = 14;
 * @return {string}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getBroadcastUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setBroadcastUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 broadcast_offset = 15;
 * @return {number}
 */
proto.rooms.api.v1.CreateEventRequest.prototype.getBroadcastOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CreateEventRequest} returns this
 */
proto.rooms.api.v1.CreateEventRequest.prototype.setBroadcastOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CreateEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CreateEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CreateEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.EventInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CreateEventResponse}
 */
proto.rooms.api.v1.CreateEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CreateEventResponse;
  return proto.rooms.api.v1.CreateEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CreateEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CreateEventResponse}
 */
proto.rooms.api.v1.CreateEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventInfo;
      reader.readMessage(value,rooms_models_pb.EventInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CreateEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CreateEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CreateEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CreateEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.EventInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventInfo info = 1;
 * @return {?proto.rooms.api.v1.EventInfo}
 */
proto.rooms.api.v1.CreateEventResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.EventInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.EventInfo|undefined} value
 * @return {!proto.rooms.api.v1.CreateEventResponse} returns this
*/
proto.rooms.api.v1.CreateEventResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CreateEventResponse} returns this
 */
proto.rooms.api.v1.CreateEventResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CreateEventResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CreateEventResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CreateEventResponse} returns this
*/
proto.rooms.api.v1.CreateEventResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CreateEventResponse} returns this
 */
proto.rooms.api.v1.CreateEventResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CreateEventResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pid: (f = msg.getPid()) && rooms_models_pb.EventPidValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && rooms_models_pb.EventNameValue.toObject(includeInstance, f),
    access: (f = msg.getAccess()) && rooms_models_pb.EventAccessValue.toObject(includeInstance, f),
    layout: (f = msg.getLayout()) && rooms_models_pb.EventLayoutValue.toObject(includeInstance, f),
    genres: (f = msg.getGenres()) && rooms_models_pb.EventGenresValue.toObject(includeInstance, f),
    summary: (f = msg.getSummary()) && rooms_models_pb.EventSummaryValue.toObject(includeInstance, f),
    overseer: (f = msg.getOverseer()) && rooms_models_pb.EventOverseerValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && rooms_models_pb.EventDescriptionValue.toObject(includeInstance, f),
    startsAt: (f = msg.getStartsAt()) && rooms_models_pb.EventStartsAtValue.toObject(includeInstance, f),
    finishesAt: (f = msg.getFinishesAt()) && rooms_models_pb.EventFinishesAtValue.toObject(includeInstance, f),
    thumbnail: (f = msg.getThumbnail()) && rooms_models_pb.EventThumbnailValue.toObject(includeInstance, f),
    background: (f = msg.getBackground()) && rooms_models_pb.EventBackgroundValue.toObject(includeInstance, f),
    broadcastUrl: (f = msg.getBroadcastUrl()) && rooms_models_pb.EventBroadcastUrlValue.toObject(includeInstance, f),
    broadcastOffset: (f = msg.getBroadcastOffset()) && rooms_models_pb.EventBroadcastOffsetValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateEventRequest}
 */
proto.rooms.api.v1.UpdateEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateEventRequest;
  return proto.rooms.api.v1.UpdateEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateEventRequest}
 */
proto.rooms.api.v1.UpdateEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new rooms_models_pb.EventPidValue;
      reader.readMessage(value,rooms_models_pb.EventPidValue.deserializeBinaryFromReader);
      msg.setPid(value);
      break;
    case 3:
      var value = new rooms_models_pb.EventNameValue;
      reader.readMessage(value,rooms_models_pb.EventNameValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new rooms_models_pb.EventAccessValue;
      reader.readMessage(value,rooms_models_pb.EventAccessValue.deserializeBinaryFromReader);
      msg.setAccess(value);
      break;
    case 5:
      var value = new rooms_models_pb.EventLayoutValue;
      reader.readMessage(value,rooms_models_pb.EventLayoutValue.deserializeBinaryFromReader);
      msg.setLayout(value);
      break;
    case 6:
      var value = new rooms_models_pb.EventGenresValue;
      reader.readMessage(value,rooms_models_pb.EventGenresValue.deserializeBinaryFromReader);
      msg.setGenres(value);
      break;
    case 7:
      var value = new rooms_models_pb.EventSummaryValue;
      reader.readMessage(value,rooms_models_pb.EventSummaryValue.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 8:
      var value = new rooms_models_pb.EventOverseerValue;
      reader.readMessage(value,rooms_models_pb.EventOverseerValue.deserializeBinaryFromReader);
      msg.setOverseer(value);
      break;
    case 9:
      var value = new rooms_models_pb.EventDescriptionValue;
      reader.readMessage(value,rooms_models_pb.EventDescriptionValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 10:
      var value = new rooms_models_pb.EventStartsAtValue;
      reader.readMessage(value,rooms_models_pb.EventStartsAtValue.deserializeBinaryFromReader);
      msg.setStartsAt(value);
      break;
    case 11:
      var value = new rooms_models_pb.EventFinishesAtValue;
      reader.readMessage(value,rooms_models_pb.EventFinishesAtValue.deserializeBinaryFromReader);
      msg.setFinishesAt(value);
      break;
    case 12:
      var value = new rooms_models_pb.EventThumbnailValue;
      reader.readMessage(value,rooms_models_pb.EventThumbnailValue.deserializeBinaryFromReader);
      msg.setThumbnail(value);
      break;
    case 13:
      var value = new rooms_models_pb.EventBackgroundValue;
      reader.readMessage(value,rooms_models_pb.EventBackgroundValue.deserializeBinaryFromReader);
      msg.setBackground(value);
      break;
    case 14:
      var value = new rooms_models_pb.EventBroadcastUrlValue;
      reader.readMessage(value,rooms_models_pb.EventBroadcastUrlValue.deserializeBinaryFromReader);
      msg.setBroadcastUrl(value);
      break;
    case 15:
      var value = new rooms_models_pb.EventBroadcastOffsetValue;
      reader.readMessage(value,rooms_models_pb.EventBroadcastOffsetValue.deserializeBinaryFromReader);
      msg.setBroadcastOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      rooms_models_pb.EventPidValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      rooms_models_pb.EventNameValue.serializeBinaryToWriter
    );
  }
  f = message.getAccess();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      rooms_models_pb.EventAccessValue.serializeBinaryToWriter
    );
  }
  f = message.getLayout();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      rooms_models_pb.EventLayoutValue.serializeBinaryToWriter
    );
  }
  f = message.getGenres();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      rooms_models_pb.EventGenresValue.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      rooms_models_pb.EventSummaryValue.serializeBinaryToWriter
    );
  }
  f = message.getOverseer();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      rooms_models_pb.EventOverseerValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      rooms_models_pb.EventDescriptionValue.serializeBinaryToWriter
    );
  }
  f = message.getStartsAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      rooms_models_pb.EventStartsAtValue.serializeBinaryToWriter
    );
  }
  f = message.getFinishesAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      rooms_models_pb.EventFinishesAtValue.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      rooms_models_pb.EventThumbnailValue.serializeBinaryToWriter
    );
  }
  f = message.getBackground();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      rooms_models_pb.EventBackgroundValue.serializeBinaryToWriter
    );
  }
  f = message.getBroadcastUrl();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      rooms_models_pb.EventBroadcastUrlValue.serializeBinaryToWriter
    );
  }
  f = message.getBroadcastOffset();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      rooms_models_pb.EventBroadcastOffsetValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EventPidValue pid = 2;
 * @return {?proto.rooms.api.v1.EventPidValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getPid = function() {
  return /** @type{?proto.rooms.api.v1.EventPidValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventPidValue, 2));
};


/**
 * @param {?proto.rooms.api.v1.EventPidValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setPid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearPid = function() {
  return this.setPid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasPid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventNameValue name = 3;
 * @return {?proto.rooms.api.v1.EventNameValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getName = function() {
  return /** @type{?proto.rooms.api.v1.EventNameValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventNameValue, 3));
};


/**
 * @param {?proto.rooms.api.v1.EventNameValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EventAccessValue access = 4;
 * @return {?proto.rooms.api.v1.EventAccessValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getAccess = function() {
  return /** @type{?proto.rooms.api.v1.EventAccessValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventAccessValue, 4));
};


/**
 * @param {?proto.rooms.api.v1.EventAccessValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setAccess = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearAccess = function() {
  return this.setAccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasAccess = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional EventLayoutValue layout = 5;
 * @return {?proto.rooms.api.v1.EventLayoutValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getLayout = function() {
  return /** @type{?proto.rooms.api.v1.EventLayoutValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventLayoutValue, 5));
};


/**
 * @param {?proto.rooms.api.v1.EventLayoutValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setLayout = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearLayout = function() {
  return this.setLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasLayout = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EventGenresValue genres = 6;
 * @return {?proto.rooms.api.v1.EventGenresValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getGenres = function() {
  return /** @type{?proto.rooms.api.v1.EventGenresValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventGenresValue, 6));
};


/**
 * @param {?proto.rooms.api.v1.EventGenresValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setGenres = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearGenres = function() {
  return this.setGenres(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasGenres = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EventSummaryValue summary = 7;
 * @return {?proto.rooms.api.v1.EventSummaryValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getSummary = function() {
  return /** @type{?proto.rooms.api.v1.EventSummaryValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventSummaryValue, 7));
};


/**
 * @param {?proto.rooms.api.v1.EventSummaryValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EventOverseerValue overseer = 8;
 * @return {?proto.rooms.api.v1.EventOverseerValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getOverseer = function() {
  return /** @type{?proto.rooms.api.v1.EventOverseerValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventOverseerValue, 8));
};


/**
 * @param {?proto.rooms.api.v1.EventOverseerValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setOverseer = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearOverseer = function() {
  return this.setOverseer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasOverseer = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EventDescriptionValue description = 9;
 * @return {?proto.rooms.api.v1.EventDescriptionValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getDescription = function() {
  return /** @type{?proto.rooms.api.v1.EventDescriptionValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventDescriptionValue, 9));
};


/**
 * @param {?proto.rooms.api.v1.EventDescriptionValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional EventStartsAtValue starts_at = 10;
 * @return {?proto.rooms.api.v1.EventStartsAtValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getStartsAt = function() {
  return /** @type{?proto.rooms.api.v1.EventStartsAtValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventStartsAtValue, 10));
};


/**
 * @param {?proto.rooms.api.v1.EventStartsAtValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setStartsAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearStartsAt = function() {
  return this.setStartsAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasStartsAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional EventFinishesAtValue finishes_at = 11;
 * @return {?proto.rooms.api.v1.EventFinishesAtValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getFinishesAt = function() {
  return /** @type{?proto.rooms.api.v1.EventFinishesAtValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventFinishesAtValue, 11));
};


/**
 * @param {?proto.rooms.api.v1.EventFinishesAtValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setFinishesAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearFinishesAt = function() {
  return this.setFinishesAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasFinishesAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional EventThumbnailValue thumbnail = 12;
 * @return {?proto.rooms.api.v1.EventThumbnailValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getThumbnail = function() {
  return /** @type{?proto.rooms.api.v1.EventThumbnailValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventThumbnailValue, 12));
};


/**
 * @param {?proto.rooms.api.v1.EventThumbnailValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setThumbnail = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearThumbnail = function() {
  return this.setThumbnail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasThumbnail = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional EventBackgroundValue background = 13;
 * @return {?proto.rooms.api.v1.EventBackgroundValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getBackground = function() {
  return /** @type{?proto.rooms.api.v1.EventBackgroundValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventBackgroundValue, 13));
};


/**
 * @param {?proto.rooms.api.v1.EventBackgroundValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setBackground = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearBackground = function() {
  return this.setBackground(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasBackground = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional EventBroadcastUrlValue broadcast_url = 14;
 * @return {?proto.rooms.api.v1.EventBroadcastUrlValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getBroadcastUrl = function() {
  return /** @type{?proto.rooms.api.v1.EventBroadcastUrlValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventBroadcastUrlValue, 14));
};


/**
 * @param {?proto.rooms.api.v1.EventBroadcastUrlValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setBroadcastUrl = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearBroadcastUrl = function() {
  return this.setBroadcastUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasBroadcastUrl = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional EventBroadcastOffsetValue broadcast_offset = 15;
 * @return {?proto.rooms.api.v1.EventBroadcastOffsetValue}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.getBroadcastOffset = function() {
  return /** @type{?proto.rooms.api.v1.EventBroadcastOffsetValue} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventBroadcastOffsetValue, 15));
};


/**
 * @param {?proto.rooms.api.v1.EventBroadcastOffsetValue|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
*/
proto.rooms.api.v1.UpdateEventRequest.prototype.setBroadcastOffset = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventRequest} returns this
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.clearBroadcastOffset = function() {
  return this.setBroadcastOffset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventRequest.prototype.hasBroadcastOffset = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.UpdateEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.UpdateEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.UpdateEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.EventInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.UpdateEventResponse}
 */
proto.rooms.api.v1.UpdateEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.UpdateEventResponse;
  return proto.rooms.api.v1.UpdateEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.UpdateEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.UpdateEventResponse}
 */
proto.rooms.api.v1.UpdateEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventInfo;
      reader.readMessage(value,rooms_models_pb.EventInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.UpdateEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.UpdateEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.UpdateEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.UpdateEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.EventInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventInfo info = 1;
 * @return {?proto.rooms.api.v1.EventInfo}
 */
proto.rooms.api.v1.UpdateEventResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.EventInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.EventInfo|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventResponse} returns this
*/
proto.rooms.api.v1.UpdateEventResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventResponse} returns this
 */
proto.rooms.api.v1.UpdateEventResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.UpdateEventResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.UpdateEventResponse} returns this
*/
proto.rooms.api.v1.UpdateEventResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.UpdateEventResponse} returns this
 */
proto.rooms.api.v1.UpdateEventResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.UpdateEventResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteEventRequest}
 */
proto.rooms.api.v1.DeleteEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteEventRequest;
  return proto.rooms.api.v1.DeleteEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteEventRequest}
 */
proto.rooms.api.v1.DeleteEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.DeleteEventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.DeleteEventRequest} returns this
 */
proto.rooms.api.v1.DeleteEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteEventResponse}
 */
proto.rooms.api.v1.DeleteEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteEventResponse;
  return proto.rooms.api.v1.DeleteEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteEventResponse}
 */
proto.rooms.api.v1.DeleteEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.DeleteEventResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.DeleteEventResponse} returns this
*/
proto.rooms.api.v1.DeleteEventResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.DeleteEventResponse} returns this
 */
proto.rooms.api.v1.DeleteEventResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.DeleteEventResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.StartEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.StartEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.StartEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.StartEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.StartEventRequest}
 */
proto.rooms.api.v1.StartEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.StartEventRequest;
  return proto.rooms.api.v1.StartEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.StartEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.StartEventRequest}
 */
proto.rooms.api.v1.StartEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.StartEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.StartEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.StartEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.StartEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.StartEventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.StartEventRequest} returns this
 */
proto.rooms.api.v1.StartEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.StartEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.StartEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.StartEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.StartEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.StartEventResponse}
 */
proto.rooms.api.v1.StartEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.StartEventResponse;
  return proto.rooms.api.v1.StartEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.StartEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.StartEventResponse}
 */
proto.rooms.api.v1.StartEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.StartEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.StartEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.StartEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.StartEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.StartEventResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.StartEventResponse} returns this
*/
proto.rooms.api.v1.StartEventResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.StartEventResponse} returns this
 */
proto.rooms.api.v1.StartEventResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.StartEventResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.FinishEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.FinishEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.FinishEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.FinishEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.FinishEventRequest}
 */
proto.rooms.api.v1.FinishEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.FinishEventRequest;
  return proto.rooms.api.v1.FinishEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.FinishEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.FinishEventRequest}
 */
proto.rooms.api.v1.FinishEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.FinishEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.FinishEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.FinishEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.FinishEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.FinishEventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.FinishEventRequest} returns this
 */
proto.rooms.api.v1.FinishEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.FinishEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.FinishEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.FinishEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.FinishEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.FinishEventResponse}
 */
proto.rooms.api.v1.FinishEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.FinishEventResponse;
  return proto.rooms.api.v1.FinishEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.FinishEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.FinishEventResponse}
 */
proto.rooms.api.v1.FinishEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.FinishEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.FinishEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.FinishEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.FinishEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.FinishEventResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.FinishEventResponse} returns this
*/
proto.rooms.api.v1.FinishEventResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.FinishEventResponse} returns this
 */
proto.rooms.api.v1.FinishEventResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.FinishEventResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CancelEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CancelEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CancelEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CancelEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CancelEventRequest}
 */
proto.rooms.api.v1.CancelEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CancelEventRequest;
  return proto.rooms.api.v1.CancelEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CancelEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CancelEventRequest}
 */
proto.rooms.api.v1.CancelEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CancelEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CancelEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CancelEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CancelEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.CancelEventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.CancelEventRequest} returns this
 */
proto.rooms.api.v1.CancelEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CancelEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CancelEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CancelEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CancelEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CancelEventResponse}
 */
proto.rooms.api.v1.CancelEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CancelEventResponse;
  return proto.rooms.api.v1.CancelEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CancelEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CancelEventResponse}
 */
proto.rooms.api.v1.CancelEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CancelEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CancelEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CancelEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CancelEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CancelEventResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CancelEventResponse} returns this
*/
proto.rooms.api.v1.CancelEventResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CancelEventResponse} returns this
 */
proto.rooms.api.v1.CancelEventResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CancelEventResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.RestartEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.RestartEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.RestartEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.RestartEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.RestartEventRequest}
 */
proto.rooms.api.v1.RestartEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.RestartEventRequest;
  return proto.rooms.api.v1.RestartEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.RestartEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.RestartEventRequest}
 */
proto.rooms.api.v1.RestartEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.RestartEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.RestartEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.RestartEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.RestartEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.RestartEventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.RestartEventRequest} returns this
 */
proto.rooms.api.v1.RestartEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.RestartEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.RestartEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.RestartEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.RestartEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.RestartEventResponse}
 */
proto.rooms.api.v1.RestartEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.RestartEventResponse;
  return proto.rooms.api.v1.RestartEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.RestartEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.RestartEventResponse}
 */
proto.rooms.api.v1.RestartEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.RestartEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.RestartEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.RestartEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.RestartEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.RestartEventResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.RestartEventResponse} returns this
*/
proto.rooms.api.v1.RestartEventResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.RestartEventResponse} returns this
 */
proto.rooms.api.v1.RestartEventResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.RestartEventResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.TokenizeEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.TokenizeEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.TokenizeEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.TokenizeEventRequest}
 */
proto.rooms.api.v1.TokenizeEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.TokenizeEventRequest;
  return proto.rooms.api.v1.TokenizeEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.TokenizeEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.TokenizeEventRequest}
 */
proto.rooms.api.v1.TokenizeEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.TokenizeEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.TokenizeEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.TokenizeEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.TokenizeEventRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.TokenizeEventRequest} returns this
 */
proto.rooms.api.v1.TokenizeEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.TokenizeEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.TokenizeEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.TokenizeEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.TokenizeEventResponse}
 */
proto.rooms.api.v1.TokenizeEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.TokenizeEventResponse;
  return proto.rooms.api.v1.TokenizeEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.TokenizeEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.TokenizeEventResponse}
 */
proto.rooms.api.v1.TokenizeEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.TokenizeEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.TokenizeEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.TokenizeEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.rooms.api.v1.TokenizeEventResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.TokenizeEventResponse} returns this
 */
proto.rooms.api.v1.TokenizeEventResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.TokenizeEventResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.TokenizeEventResponse} returns this
*/
proto.rooms.api.v1.TokenizeEventResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.TokenizeEventResponse} returns this
 */
proto.rooms.api.v1.TokenizeEventResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.TokenizeEventResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventPerformerInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventPerformerInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventPerformerInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventPerformerInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventPerformerInfoRequest}
 */
proto.rooms.api.v1.GetEventPerformerInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventPerformerInfoRequest;
  return proto.rooms.api.v1.GetEventPerformerInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventPerformerInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventPerformerInfoRequest}
 */
proto.rooms.api.v1.GetEventPerformerInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventPerformerInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventPerformerInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventPerformerInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventPerformerInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetEventPerformerInfoRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetEventPerformerInfoRequest} returns this
 */
proto.rooms.api.v1.GetEventPerformerInfoRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventPerformerInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventPerformerInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.EventPerformerInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventPerformerInfoResponse}
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventPerformerInfoResponse;
  return proto.rooms.api.v1.GetEventPerformerInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventPerformerInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventPerformerInfoResponse}
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventPerformerInfo;
      reader.readMessage(value,rooms_models_pb.EventPerformerInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventPerformerInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventPerformerInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.EventPerformerInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventPerformerInfo info = 1;
 * @return {?proto.rooms.api.v1.EventPerformerInfo}
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.EventPerformerInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventPerformerInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.EventPerformerInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetEventPerformerInfoResponse} returns this
*/
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventPerformerInfoResponse} returns this
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetEventPerformerInfoResponse} returns this
*/
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventPerformerInfoResponse} returns this
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventPerformerInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListEventPerformersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListEventPerformersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListEventPerformersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventPerformersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    order: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListEventPerformersRequest}
 */
proto.rooms.api.v1.ListEventPerformersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListEventPerformersRequest;
  return proto.rooms.api.v1.ListEventPerformersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListEventPerformersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListEventPerformersRequest}
 */
proto.rooms.api.v1.ListEventPerformersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListEventPerformersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListEventPerformersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListEventPerformersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventPerformersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 offset = 1;
 * @return {number}
 */
proto.rooms.api.v1.ListEventPerformersRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListEventPerformersRequest} returns this
 */
proto.rooms.api.v1.ListEventPerformersRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListEventPerformersRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListEventPerformersRequest} returns this
 */
proto.rooms.api.v1.ListEventPerformersRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string order = 3;
 * @return {string}
 */
proto.rooms.api.v1.ListEventPerformersRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListEventPerformersRequest} returns this
 */
proto.rooms.api.v1.ListEventPerformersRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListEventPerformersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListEventPerformersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListEventPerformersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventPerformersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    performersList: jspb.Message.toObjectList(msg.getPerformersList(),
    rooms_models_pb.EventPerformerInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListEventPerformersResponse}
 */
proto.rooms.api.v1.ListEventPerformersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListEventPerformersResponse;
  return proto.rooms.api.v1.ListEventPerformersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListEventPerformersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListEventPerformersResponse}
 */
proto.rooms.api.v1.ListEventPerformersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventPerformerInfo;
      reader.readMessage(value,rooms_models_pb.EventPerformerInfo.deserializeBinaryFromReader);
      msg.addPerformers(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListEventPerformersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListEventPerformersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventPerformersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerformersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.EventPerformerInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventPerformerInfo performers = 1;
 * @return {!Array<!proto.rooms.api.v1.EventPerformerInfo>}
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.getPerformersList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.EventPerformerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.EventPerformerInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventPerformerInfo>} value
 * @return {!proto.rooms.api.v1.ListEventPerformersResponse} returns this
*/
proto.rooms.api.v1.ListEventPerformersResponse.prototype.setPerformersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.EventPerformerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.EventPerformerInfo}
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.addPerformers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.EventPerformerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListEventPerformersResponse} returns this
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.clearPerformersList = function() {
  return this.setPerformersList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListEventPerformersResponse} returns this
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListEventPerformersResponse} returns this
*/
proto.rooms.api.v1.ListEventPerformersResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListEventPerformersResponse} returns this
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListEventPerformersResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountEventPerformersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountEventPerformersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountEventPerformersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountEventPerformersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountEventPerformersRequest}
 */
proto.rooms.api.v1.CountEventPerformersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountEventPerformersRequest;
  return proto.rooms.api.v1.CountEventPerformersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountEventPerformersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountEventPerformersRequest}
 */
proto.rooms.api.v1.CountEventPerformersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountEventPerformersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountEventPerformersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountEventPerformersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountEventPerformersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountEventPerformersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountEventPerformersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountEventPerformersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountEventPerformersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountEventPerformersResponse}
 */
proto.rooms.api.v1.CountEventPerformersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountEventPerformersResponse;
  return proto.rooms.api.v1.CountEventPerformersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountEventPerformersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountEventPerformersResponse}
 */
proto.rooms.api.v1.CountEventPerformersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountEventPerformersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountEventPerformersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountEventPerformersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountEventPerformersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.rooms.api.v1.CountEventPerformersResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CountEventPerformersResponse} returns this
 */
proto.rooms.api.v1.CountEventPerformersResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CountEventPerformersResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CountEventPerformersResponse} returns this
*/
proto.rooms.api.v1.CountEventPerformersResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CountEventPerformersResponse} returns this
 */
proto.rooms.api.v1.CountEventPerformersResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CountEventPerformersResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SwapEventPerformersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SwapEventPerformersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SwapEventPerformersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SwapEventPerformersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcePosition: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetPosition: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SwapEventPerformersRequest}
 */
proto.rooms.api.v1.SwapEventPerformersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SwapEventPerformersRequest;
  return proto.rooms.api.v1.SwapEventPerformersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SwapEventPerformersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SwapEventPerformersRequest}
 */
proto.rooms.api.v1.SwapEventPerformersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourcePosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTargetPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SwapEventPerformersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SwapEventPerformersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SwapEventPerformersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SwapEventPerformersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcePosition();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTargetPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 source_position = 1;
 * @return {number}
 */
proto.rooms.api.v1.SwapEventPerformersRequest.prototype.getSourcePosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SwapEventPerformersRequest} returns this
 */
proto.rooms.api.v1.SwapEventPerformersRequest.prototype.setSourcePosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 target_position = 2;
 * @return {number}
 */
proto.rooms.api.v1.SwapEventPerformersRequest.prototype.getTargetPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SwapEventPerformersRequest} returns this
 */
proto.rooms.api.v1.SwapEventPerformersRequest.prototype.setTargetPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.SwapEventPerformersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SwapEventPerformersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SwapEventPerformersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SwapEventPerformersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    performersList: jspb.Message.toObjectList(msg.getPerformersList(),
    rooms_models_pb.EventPerformerInfo.toObject, includeInstance),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SwapEventPerformersResponse}
 */
proto.rooms.api.v1.SwapEventPerformersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SwapEventPerformersResponse;
  return proto.rooms.api.v1.SwapEventPerformersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SwapEventPerformersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SwapEventPerformersResponse}
 */
proto.rooms.api.v1.SwapEventPerformersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventPerformerInfo;
      reader.readMessage(value,rooms_models_pb.EventPerformerInfo.deserializeBinaryFromReader);
      msg.addPerformers(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SwapEventPerformersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SwapEventPerformersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SwapEventPerformersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerformersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.EventPerformerInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventPerformerInfo performers = 1;
 * @return {!Array<!proto.rooms.api.v1.EventPerformerInfo>}
 */
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.getPerformersList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.EventPerformerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.EventPerformerInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventPerformerInfo>} value
 * @return {!proto.rooms.api.v1.SwapEventPerformersResponse} returns this
*/
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.setPerformersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.EventPerformerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.EventPerformerInfo}
 */
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.addPerformers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.EventPerformerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SwapEventPerformersResponse} returns this
 */
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.clearPerformersList = function() {
  return this.setPerformersList([]);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.SwapEventPerformersResponse} returns this
*/
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.SwapEventPerformersResponse} returns this
 */
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.SwapEventPerformersResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SwitchEventPerformerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SwitchEventPerformerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SwitchEventPerformerRequest}
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SwitchEventPerformerRequest;
  return proto.rooms.api.v1.SwitchEventPerformerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SwitchEventPerformerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SwitchEventPerformerRequest}
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SwitchEventPerformerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SwitchEventPerformerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.SwitchEventPerformerRequest} returns this
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 position = 2;
 * @return {number}
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SwitchEventPerformerRequest} returns this
 */
proto.rooms.api.v1.SwitchEventPerformerRequest.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SwitchEventPerformerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SwitchEventPerformerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.EventPerformerInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SwitchEventPerformerResponse}
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SwitchEventPerformerResponse;
  return proto.rooms.api.v1.SwitchEventPerformerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SwitchEventPerformerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SwitchEventPerformerResponse}
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventPerformerInfo;
      reader.readMessage(value,rooms_models_pb.EventPerformerInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SwitchEventPerformerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SwitchEventPerformerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.EventPerformerInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventPerformerInfo info = 1;
 * @return {?proto.rooms.api.v1.EventPerformerInfo}
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.EventPerformerInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventPerformerInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.EventPerformerInfo|undefined} value
 * @return {!proto.rooms.api.v1.SwitchEventPerformerResponse} returns this
*/
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.SwitchEventPerformerResponse} returns this
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.SwitchEventPerformerResponse} returns this
*/
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.SwitchEventPerformerResponse} returns this
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.SwitchEventPerformerResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteEventPerformerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteEventPerformerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteEventPerformerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteEventPerformerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteEventPerformerRequest}
 */
proto.rooms.api.v1.DeleteEventPerformerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteEventPerformerRequest;
  return proto.rooms.api.v1.DeleteEventPerformerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteEventPerformerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteEventPerformerRequest}
 */
proto.rooms.api.v1.DeleteEventPerformerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteEventPerformerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteEventPerformerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteEventPerformerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteEventPerformerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.DeleteEventPerformerRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.DeleteEventPerformerRequest} returns this
 */
proto.rooms.api.v1.DeleteEventPerformerRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.DeleteEventPerformerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.DeleteEventPerformerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.DeleteEventPerformerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteEventPerformerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.DeleteEventPerformerResponse}
 */
proto.rooms.api.v1.DeleteEventPerformerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.DeleteEventPerformerResponse;
  return proto.rooms.api.v1.DeleteEventPerformerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.DeleteEventPerformerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.DeleteEventPerformerResponse}
 */
proto.rooms.api.v1.DeleteEventPerformerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.DeleteEventPerformerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.DeleteEventPerformerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.DeleteEventPerformerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.DeleteEventPerformerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional error.api.v1.Error error = 1;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.DeleteEventPerformerResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 1));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.DeleteEventPerformerResponse} returns this
*/
proto.rooms.api.v1.DeleteEventPerformerResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.DeleteEventPerformerResponse} returns this
 */
proto.rooms.api.v1.DeleteEventPerformerResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.DeleteEventPerformerResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ConfirmEventPerformerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ConfirmEventPerformerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ConfirmEventPerformerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ConfirmEventPerformerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ConfirmEventPerformerRequest}
 */
proto.rooms.api.v1.ConfirmEventPerformerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ConfirmEventPerformerRequest;
  return proto.rooms.api.v1.ConfirmEventPerformerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ConfirmEventPerformerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ConfirmEventPerformerRequest}
 */
proto.rooms.api.v1.ConfirmEventPerformerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ConfirmEventPerformerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ConfirmEventPerformerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ConfirmEventPerformerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ConfirmEventPerformerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ConfirmEventPerformerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ConfirmEventPerformerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.EventPerformerInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ConfirmEventPerformerResponse}
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ConfirmEventPerformerResponse;
  return proto.rooms.api.v1.ConfirmEventPerformerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ConfirmEventPerformerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ConfirmEventPerformerResponse}
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventPerformerInfo;
      reader.readMessage(value,rooms_models_pb.EventPerformerInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ConfirmEventPerformerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ConfirmEventPerformerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.EventPerformerInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventPerformerInfo info = 1;
 * @return {?proto.rooms.api.v1.EventPerformerInfo}
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.EventPerformerInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventPerformerInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.EventPerformerInfo|undefined} value
 * @return {!proto.rooms.api.v1.ConfirmEventPerformerResponse} returns this
*/
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ConfirmEventPerformerResponse} returns this
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ConfirmEventPerformerResponse} returns this
*/
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ConfirmEventPerformerResponse} returns this
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ConfirmEventPerformerResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetTiktokObjectInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetTiktokObjectInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetTiktokObjectInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetTiktokObjectInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetTiktokObjectInfoRequest}
 */
proto.rooms.api.v1.GetTiktokObjectInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetTiktokObjectInfoRequest;
  return proto.rooms.api.v1.GetTiktokObjectInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetTiktokObjectInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetTiktokObjectInfoRequest}
 */
proto.rooms.api.v1.GetTiktokObjectInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetTiktokObjectInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetTiktokObjectInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetTiktokObjectInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetTiktokObjectInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetTiktokObjectInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetTiktokObjectInfoRequest} returns this
 */
proto.rooms.api.v1.GetTiktokObjectInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetTiktokObjectInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetTiktokObjectInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.TiktokObjectInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetTiktokObjectInfoResponse}
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetTiktokObjectInfoResponse;
  return proto.rooms.api.v1.GetTiktokObjectInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetTiktokObjectInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetTiktokObjectInfoResponse}
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.TiktokObjectInfo;
      reader.readMessage(value,rooms_models_pb.TiktokObjectInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetTiktokObjectInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetTiktokObjectInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.TiktokObjectInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional TiktokObjectInfo info = 1;
 * @return {?proto.rooms.api.v1.TiktokObjectInfo}
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.TiktokObjectInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.TiktokObjectInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.TiktokObjectInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetTiktokObjectInfoResponse} returns this
*/
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetTiktokObjectInfoResponse} returns this
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetTiktokObjectInfoResponse} returns this
*/
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetTiktokObjectInfoResponse} returns this
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetTiktokObjectInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SearchTiktokObjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SearchTiktokObjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsRequest}
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SearchTiktokObjectsRequest;
  return proto.rooms.api.v1.SearchTiktokObjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SearchTiktokObjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsRequest}
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SearchTiktokObjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SearchTiktokObjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsRequest} returns this
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsRequest} returns this
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsRequest} returns this
 */
proto.rooms.api.v1.SearchTiktokObjectsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SearchTiktokObjectsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SearchTiktokObjectsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    rooms_models_pb.TiktokObjectInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsResponse}
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SearchTiktokObjectsResponse;
  return proto.rooms.api.v1.SearchTiktokObjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SearchTiktokObjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsResponse}
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.TiktokObjectInfo;
      reader.readMessage(value,rooms_models_pb.TiktokObjectInfo.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SearchTiktokObjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SearchTiktokObjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.TiktokObjectInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TiktokObjectInfo objects = 1;
 * @return {!Array<!proto.rooms.api.v1.TiktokObjectInfo>}
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.TiktokObjectInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.TiktokObjectInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.TiktokObjectInfo>} value
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsResponse} returns this
*/
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.TiktokObjectInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.TiktokObjectInfo}
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.TiktokObjectInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsResponse} returns this
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsResponse} returns this
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsResponse} returns this
*/
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.SearchTiktokObjectsResponse} returns this
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.SearchTiktokObjectsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetYoutubeObjectInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetYoutubeObjectInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetYoutubeObjectInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetYoutubeObjectInfoRequest}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetYoutubeObjectInfoRequest;
  return proto.rooms.api.v1.GetYoutubeObjectInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetYoutubeObjectInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetYoutubeObjectInfoRequest}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetYoutubeObjectInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetYoutubeObjectInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetYoutubeObjectInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetYoutubeObjectInfoRequest} returns this
 */
proto.rooms.api.v1.GetYoutubeObjectInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetYoutubeObjectInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetYoutubeObjectInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.YoutubeObjectInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetYoutubeObjectInfoResponse}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetYoutubeObjectInfoResponse;
  return proto.rooms.api.v1.GetYoutubeObjectInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetYoutubeObjectInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetYoutubeObjectInfoResponse}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.YoutubeObjectInfo;
      reader.readMessage(value,rooms_models_pb.YoutubeObjectInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetYoutubeObjectInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetYoutubeObjectInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.YoutubeObjectInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional YoutubeObjectInfo info = 1;
 * @return {?proto.rooms.api.v1.YoutubeObjectInfo}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.YoutubeObjectInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.YoutubeObjectInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.YoutubeObjectInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetYoutubeObjectInfoResponse} returns this
*/
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetYoutubeObjectInfoResponse} returns this
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetYoutubeObjectInfoResponse} returns this
*/
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetYoutubeObjectInfoResponse} returns this
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetYoutubeObjectInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SearchYoutubeObjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SearchYoutubeObjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsRequest}
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SearchYoutubeObjectsRequest;
  return proto.rooms.api.v1.SearchYoutubeObjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SearchYoutubeObjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsRequest}
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SearchYoutubeObjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SearchYoutubeObjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsRequest} returns this
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsRequest} returns this
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsRequest} returns this
 */
proto.rooms.api.v1.SearchYoutubeObjectsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.SearchYoutubeObjectsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.SearchYoutubeObjectsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    rooms_models_pb.YoutubeObjectInfo.toObject, includeInstance),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsResponse}
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.SearchYoutubeObjectsResponse;
  return proto.rooms.api.v1.SearchYoutubeObjectsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.SearchYoutubeObjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsResponse}
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.YoutubeObjectInfo;
      reader.readMessage(value,rooms_models_pb.YoutubeObjectInfo.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.SearchYoutubeObjectsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.SearchYoutubeObjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.YoutubeObjectInfo.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated YoutubeObjectInfo objects = 1;
 * @return {!Array<!proto.rooms.api.v1.YoutubeObjectInfo>}
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.YoutubeObjectInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.YoutubeObjectInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.YoutubeObjectInfo>} value
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsResponse} returns this
*/
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.YoutubeObjectInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.YoutubeObjectInfo}
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.YoutubeObjectInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsResponse} returns this
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsResponse} returns this
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsResponse} returns this
*/
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.SearchYoutubeObjectsResponse} returns this
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.SearchYoutubeObjectsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.TokenizeStorageObjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.TokenizeStorageObjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hash: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectRequest}
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.TokenizeStorageObjectRequest;
  return proto.rooms.api.v1.TokenizeStorageObjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.TokenizeStorageObjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectRequest}
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.TokenizeStorageObjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.TokenizeStorageObjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectRequest} returns this
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 size = 2;
 * @return {number}
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectRequest} returns this
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string hash = 3;
 * @return {string}
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectRequest} returns this
 */
proto.rooms.api.v1.TokenizeStorageObjectRequest.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.TokenizeStorageObjectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.TokenizeStorageObjectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectResponse}
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.TokenizeStorageObjectResponse;
  return proto.rooms.api.v1.TokenizeStorageObjectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.TokenizeStorageObjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectResponse}
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.TokenizeStorageObjectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.TokenizeStorageObjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectResponse} returns this
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectResponse} returns this
*/
proto.rooms.api.v1.TokenizeStorageObjectResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.TokenizeStorageObjectResponse} returns this
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.TokenizeStorageObjectResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventReportInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventReportInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventReportInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventReportInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventReportInfoRequest}
 */
proto.rooms.api.v1.GetEventReportInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventReportInfoRequest;
  return proto.rooms.api.v1.GetEventReportInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventReportInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventReportInfoRequest}
 */
proto.rooms.api.v1.GetEventReportInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventReportInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventReportInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventReportInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventReportInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetEventReportInfoRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetEventReportInfoRequest} returns this
 */
proto.rooms.api.v1.GetEventReportInfoRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventReportInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventReportInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventReportInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.EventReportInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventReportInfoResponse}
 */
proto.rooms.api.v1.GetEventReportInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventReportInfoResponse;
  return proto.rooms.api.v1.GetEventReportInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventReportInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventReportInfoResponse}
 */
proto.rooms.api.v1.GetEventReportInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventReportInfo;
      reader.readMessage(value,rooms_models_pb.EventReportInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventReportInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventReportInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventReportInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.EventReportInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventReportInfo info = 1;
 * @return {?proto.rooms.api.v1.EventReportInfo}
 */
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.EventReportInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventReportInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.EventReportInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetEventReportInfoResponse} returns this
*/
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventReportInfoResponse} returns this
 */
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetEventReportInfoResponse} returns this
*/
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventReportInfoResponse} returns this
 */
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventReportInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListEventReportsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListEventReportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListEventReportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListEventReportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventReportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListEventReportsRequest}
 */
proto.rooms.api.v1.ListEventReportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListEventReportsRequest;
  return proto.rooms.api.v1.ListEventReportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListEventReportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListEventReportsRequest}
 */
proto.rooms.api.v1.ListEventReportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addEventIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListEventReportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListEventReportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListEventReportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventReportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string event_ids = 1;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.ListEventReportsRequest.prototype.getEventIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.ListEventReportsRequest} returns this
 */
proto.rooms.api.v1.ListEventReportsRequest.prototype.setEventIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListEventReportsRequest} returns this
 */
proto.rooms.api.v1.ListEventReportsRequest.prototype.addEventIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListEventReportsRequest} returns this
 */
proto.rooms.api.v1.ListEventReportsRequest.prototype.clearEventIdsList = function() {
  return this.setEventIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListEventReportsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListEventReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListEventReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListEventReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    rooms_models_pb.EventReportInfo.toObject, includeInstance),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListEventReportsResponse}
 */
proto.rooms.api.v1.ListEventReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListEventReportsResponse;
  return proto.rooms.api.v1.ListEventReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListEventReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListEventReportsResponse}
 */
proto.rooms.api.v1.ListEventReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventReportInfo;
      reader.readMessage(value,rooms_models_pb.EventReportInfo.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListEventReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListEventReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListEventReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListEventReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.EventReportInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventReportInfo reports = 1;
 * @return {!Array<!proto.rooms.api.v1.EventReportInfo>}
 */
proto.rooms.api.v1.ListEventReportsResponse.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.EventReportInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.EventReportInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.EventReportInfo>} value
 * @return {!proto.rooms.api.v1.ListEventReportsResponse} returns this
*/
proto.rooms.api.v1.ListEventReportsResponse.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.EventReportInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.EventReportInfo}
 */
proto.rooms.api.v1.ListEventReportsResponse.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.EventReportInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListEventReportsResponse} returns this
 */
proto.rooms.api.v1.ListEventReportsResponse.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListEventReportsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListEventReportsResponse} returns this
*/
proto.rooms.api.v1.ListEventReportsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListEventReportsResponse} returns this
 */
proto.rooms.api.v1.ListEventReportsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListEventReportsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventOwnerReportInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventOwnerReportInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventOwnerReportInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventOwnerReportInfoRequest}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventOwnerReportInfoRequest;
  return proto.rooms.api.v1.GetEventOwnerReportInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventOwnerReportInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventOwnerReportInfoRequest}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventOwnerReportInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventOwnerReportInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventOwnerReportInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetEventOwnerReportInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetEventOwnerReportInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.EventOwnerReportInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetEventOwnerReportInfoResponse}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetEventOwnerReportInfoResponse;
  return proto.rooms.api.v1.GetEventOwnerReportInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetEventOwnerReportInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetEventOwnerReportInfoResponse}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.EventOwnerReportInfo;
      reader.readMessage(value,rooms_models_pb.EventOwnerReportInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetEventOwnerReportInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetEventOwnerReportInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.EventOwnerReportInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventOwnerReportInfo info = 1;
 * @return {?proto.rooms.api.v1.EventOwnerReportInfo}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.EventOwnerReportInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.EventOwnerReportInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.EventOwnerReportInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetEventOwnerReportInfoResponse} returns this
*/
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventOwnerReportInfoResponse} returns this
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetEventOwnerReportInfoResponse} returns this
*/
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetEventOwnerReportInfoResponse} returns this
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetEventOwnerReportInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRecommendationInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRecommendationInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRecommendationInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRecommendationInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRecommendationInfoRequest}
 */
proto.rooms.api.v1.GetRecommendationInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRecommendationInfoRequest;
  return proto.rooms.api.v1.GetRecommendationInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRecommendationInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRecommendationInfoRequest}
 */
proto.rooms.api.v1.GetRecommendationInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRecommendationInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRecommendationInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRecommendationInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRecommendationInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.rooms.api.v1.GetRecommendationInfoRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.GetRecommendationInfoRequest} returns this
 */
proto.rooms.api.v1.GetRecommendationInfoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.GetRecommendationInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.GetRecommendationInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && rooms_models_pb.RecommendationInfo.toObject(includeInstance, f),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.GetRecommendationInfoResponse}
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.GetRecommendationInfoResponse;
  return proto.rooms.api.v1.GetRecommendationInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.GetRecommendationInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.GetRecommendationInfoResponse}
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RecommendationInfo;
      reader.readMessage(value,rooms_models_pb.RecommendationInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.GetRecommendationInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.GetRecommendationInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      rooms_models_pb.RecommendationInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional RecommendationInfo info = 1;
 * @return {?proto.rooms.api.v1.RecommendationInfo}
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.rooms.api.v1.RecommendationInfo} */ (
    jspb.Message.getWrapperField(this, rooms_models_pb.RecommendationInfo, 1));
};


/**
 * @param {?proto.rooms.api.v1.RecommendationInfo|undefined} value
 * @return {!proto.rooms.api.v1.GetRecommendationInfoResponse} returns this
*/
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRecommendationInfoResponse} returns this
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.GetRecommendationInfoResponse} returns this
*/
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.GetRecommendationInfoResponse} returns this
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.GetRecommendationInfoResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListRecommendationsRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRecommendationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRecommendationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRecommendationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    typesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    order: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest}
 */
proto.rooms.api.v1.ListRecommendationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRecommendationsRequest;
  return proto.rooms.api.v1.ListRecommendationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRecommendationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest}
 */
proto.rooms.api.v1.ListRecommendationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.rooms.api.v1.RecommendationType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRecommendationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRecommendationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRecommendationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest} returns this
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest} returns this
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest} returns this
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * repeated RecommendationType types = 2;
 * @return {!Array<!proto.rooms.api.v1.RecommendationType>}
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.RecommendationType>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RecommendationType>} value
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest} returns this
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.rooms.api.v1.RecommendationType} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest} returns this
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest} returns this
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * optional int64 offset = 3;
 * @return {number}
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest} returns this
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 limit = 4;
 * @return {number}
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest} returns this
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string order = 5;
 * @return {string}
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.rooms.api.v1.ListRecommendationsRequest} returns this
 */
proto.rooms.api.v1.ListRecommendationsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.ListRecommendationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.ListRecommendationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.ListRecommendationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRecommendationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendationsList: jspb.Message.toObjectList(msg.getRecommendationsList(),
    rooms_models_pb.RecommendationInfo.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.ListRecommendationsResponse}
 */
proto.rooms.api.v1.ListRecommendationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.ListRecommendationsResponse;
  return proto.rooms.api.v1.ListRecommendationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.ListRecommendationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.ListRecommendationsResponse}
 */
proto.rooms.api.v1.ListRecommendationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new rooms_models_pb.RecommendationInfo;
      reader.readMessage(value,rooms_models_pb.RecommendationInfo.deserializeBinaryFromReader);
      msg.addRecommendations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.ListRecommendationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.ListRecommendationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.ListRecommendationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      rooms_models_pb.RecommendationInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RecommendationInfo recommendations = 1;
 * @return {!Array<!proto.rooms.api.v1.RecommendationInfo>}
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.getRecommendationsList = function() {
  return /** @type{!Array<!proto.rooms.api.v1.RecommendationInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, rooms_models_pb.RecommendationInfo, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RecommendationInfo>} value
 * @return {!proto.rooms.api.v1.ListRecommendationsResponse} returns this
*/
proto.rooms.api.v1.ListRecommendationsResponse.prototype.setRecommendationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.rooms.api.v1.RecommendationInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.RecommendationInfo}
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.addRecommendations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.rooms.api.v1.RecommendationInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.ListRecommendationsResponse} returns this
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.clearRecommendationsList = function() {
  return this.setRecommendationsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.ListRecommendationsResponse} returns this
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional error.api.v1.Error error = 3;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 3));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.ListRecommendationsResponse} returns this
*/
proto.rooms.api.v1.ListRecommendationsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.ListRecommendationsResponse} returns this
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.ListRecommendationsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.rooms.api.v1.CountRecommendationsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRecommendationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRecommendationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRecommendationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRecommendationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    typesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRecommendationsRequest}
 */
proto.rooms.api.v1.CountRecommendationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRecommendationsRequest;
  return proto.rooms.api.v1.CountRecommendationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRecommendationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRecommendationsRequest}
 */
proto.rooms.api.v1.CountRecommendationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.rooms.api.v1.RecommendationType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRecommendationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRecommendationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRecommendationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRecommendationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated RecommendationType types = 1;
 * @return {!Array<!proto.rooms.api.v1.RecommendationType>}
 */
proto.rooms.api.v1.CountRecommendationsRequest.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.rooms.api.v1.RecommendationType>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.rooms.api.v1.RecommendationType>} value
 * @return {!proto.rooms.api.v1.CountRecommendationsRequest} returns this
 */
proto.rooms.api.v1.CountRecommendationsRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.rooms.api.v1.RecommendationType} value
 * @param {number=} opt_index
 * @return {!proto.rooms.api.v1.CountRecommendationsRequest} returns this
 */
proto.rooms.api.v1.CountRecommendationsRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.rooms.api.v1.CountRecommendationsRequest} returns this
 */
proto.rooms.api.v1.CountRecommendationsRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.rooms.api.v1.CountRecommendationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.rooms.api.v1.CountRecommendationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.rooms.api.v1.CountRecommendationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRecommendationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.rooms.api.v1.CountRecommendationsResponse}
 */
proto.rooms.api.v1.CountRecommendationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.rooms.api.v1.CountRecommendationsResponse;
  return proto.rooms.api.v1.CountRecommendationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.rooms.api.v1.CountRecommendationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.rooms.api.v1.CountRecommendationsResponse}
 */
proto.rooms.api.v1.CountRecommendationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.rooms.api.v1.CountRecommendationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.rooms.api.v1.CountRecommendationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.rooms.api.v1.CountRecommendationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.rooms.api.v1.CountRecommendationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.rooms.api.v1.CountRecommendationsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.rooms.api.v1.CountRecommendationsResponse} returns this
 */
proto.rooms.api.v1.CountRecommendationsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.rooms.api.v1.CountRecommendationsResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.rooms.api.v1.CountRecommendationsResponse} returns this
*/
proto.rooms.api.v1.CountRecommendationsResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.rooms.api.v1.CountRecommendationsResponse} returns this
 */
proto.rooms.api.v1.CountRecommendationsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.rooms.api.v1.CountRecommendationsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.rooms.api.v1);
