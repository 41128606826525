import {useAppSelector} from '../../store/hooks';
import {selectClientRole, selectTheme} from '../../store/slices/user';
import {UserRole} from '../../services/roomServices';
import {Button} from '../../sharedComponents/Button/Button';
import {ticketProcess$} from '../modals/Ticket/useTicketProcess';
import {RoomStreamEvent} from '../../store/slices/roomStreams';
import React, {FC, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {PlaylistElementS} from '../../pages/Room/relatedComponents/RoomStreams/PlaylistElementS';
import {getIcon} from '../../pages/Room/relatedComponents/RoomStreams/PlaylistElement';
import {authProcess$} from '../../pages/TestPage/useAuthProcess';
import {ThemeType} from '../../styles/theme';
import defaultThumbnail from '../../assets/defaultThumbnail.svg';
import defaultThumbnailLight from '../../assets/defaultThumbnailLight.svg';
import {closeNotification} from '../../utils/showNotification';
import {RoomStreamType} from '../../services/gRPC/rooms/enums_pb';
import {ScrollableVerticalText} from '../../sharedComponents/ScrollableText/ScrollableText';
import {remm} from '../../utils/remm';

const ComingSoonTicket = ({eventId}: { eventId: string }) => {
	const userRole = useAppSelector(selectClientRole);

	const handleClick = () => {
		ticketProcess$({eventId}).subscribe();
		closeNotification('comingSoon');
	};

	return (
		<ComingSoonTicketS>
			{userRole === UserRole.GUEST ? <>
				<div className="ComingSoonTicketS_accentText">
					You need to be logged to watch the event
				</div>
				<div className="ComingSoonTicketS_buttonContainer">
					<Button onClick={() => authProcess$().subscribe()}>LOGIN</Button>
				</div>

			</> : <>
				<div className="ComingSoonTicketS_accentText">
					You need a ticket to watch this event
				</div>
				<div className="ComingSoonTicketS_buttonContainer">
					<Button className="primary" onClick={handleClick}>GET TICKET</Button>
				</div>
			</>}
		</ComingSoonTicketS>
	);
};

interface ComingSoonProps {
	stream: RoomStreamEvent,
}

export const EventComingSoon: FC<ComingSoonProps> = ({stream}) => {
	const videoTitleSpan = useRef<HTMLSpanElement>(null);
	const [scrollDuration, setScrollDuration] = useState(0);
	const theme = useAppSelector(selectTheme);

	const title = stream.info.name;

	useEffect(() => {
		if (videoTitleSpan.current && title) {
			setScrollDuration((videoTitleSpan.current.offsetHeight - videoTitleSpan.current.parentElement!.offsetHeight) / 10);
		}
	}, [title]);


	return (
		<ComingSoonS>
			<div className="ComingSoonS_title">Coming Soon</div>
			<PlaylistVideoS thumbnailUrl={stream.info?.thumbnailUrl || (theme === ThemeType.DARK ? defaultThumbnail : defaultThumbnailLight)}>
				<div className="PlaylistS_element-thumbnail">
					<div className="PlaylistS_element-type">
						<i className={getIcon(RoomStreamType.RSTYPE_EVENT)} style={{fontSize: '20px'}}/>
					</div>
				</div>
				<div className="PlaylistS_element-description">
					<ScrollableVerticalText lines={3} duration={scrollDuration} height={45}>
						<span ref={videoTitleSpan}>{title || 'Video is no longer available'}</span>
					</ScrollableVerticalText>

				</div>
			</PlaylistVideoS>
			<ComingSoonTicket eventId={stream.metadata!.sid}/>
		</ComingSoonS>
	);
};


export const ComingSoonS = styled.div`
	width: 100%;

	.ComingSoonS_title {
		margin-bottom: 0.5rem;
		font-weight: 600;
		font-size: ${remm('16px')};
	}
`;

const PlaylistVideoS = styled(PlaylistElementS)<{ thumbnailUrl: string }>`
	padding: 0;

	&:hover {
		background-color: transparent;
	}
`;

export const ComingSoonTicketS = styled.div`
	display: flex;
	justify-content: space-between;

	.ComingSoonTicketS_accentText {
		font-weight: 700;
		font-size: ${remm('13px')};
		color: ${({theme}) => theme.warningColor};
		max-width: 140px;
	}

	.ComingSoonTicketS_buttonContainer {
		width: 150px;
	}
`;
