import {mergeMap} from 'rxjs';
import {tap} from 'rxjs/operators';
import {getUserInfoService$} from '../../services/userServices';
import {searchEventsService$} from '../../services/roomServices';
import {userActions} from '../../store/slices/user';
import {eventsActions} from '../../store/slices/events';
import {store} from '../../store/store';
import {EMIT} from '../utils';

export const loadGuestData$ = () => EMIT.pipe(
	/// user
	mergeMap(() => getUserInfoService$().pipe(
		tap((userInfo) => {
			store.dispatch(userActions.setUserInfo(userInfo));
		})
	)),

	// load async data that is not needed just on start
	tap(() => {

		/// active events
		searchEventsService$({activeEvents: true}).pipe(
			tap((result) => {
				store.dispatch(eventsActions.addEvents(result.eventsList));
			})
		).subscribe();

	})
);
