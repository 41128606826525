import React, {FC, useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {catchError, concatMap, map, mergeMap, tap} from 'rxjs/operators';
import {emit, EMIT, replaceUrl} from '../../utils/utils';
import {
	createRoomUserTokenService$,
	getRoomInfoService$,
	getRoomInviteInfoService$,
	getRoomStateService$,
	listEventsService$,
	RoomUserRole,
	UserRole
} from '../../services/roomServices';
import {EMPTY, forkJoin, interval, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {
	clearRoomGlobalRef,
	roomGlobalRef,
	RoomMode,
	updateRoomMode,
	updateScreenMaxBitrate,
	updateVideoMaxBitrate
} from './utils/roomGlobalRef';
import {userJoinRoomSenderWS$} from '../../services/ws/senders.ws';
import {EventInfo, RoomInfo} from '../../services/gRPC/rooms/models_pb';
import {Error as GrpcError, ErrorType} from '../../services/gRPC/error/models_pb';
import {listUsersService$} from '../../services/userServices';
import {selectClientId, selectClientRole, selectClientUserInfo} from '../../store/slices/user';
import {store} from '../../store/store';
import {cancelModalExceptWithType, modalsSystem} from '../../singleComponents/modals/modalSystem';
import {ModalType} from '../../singleComponents/modals/modal.types';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {roomUsersActions} from '../../store/slices/roomUsers';
import {roomActions, selectIsPerformerRoom, selectRoomId, selectRoomPid} from '../../store/slices/room';
import {roomsActions} from '../../store/slices/rooms';
import {RoomAccess, RoomStreamType, RoomType} from '../../services/gRPC/rooms/enums_pb';
import {roomInit$} from './utils/processes/useRoomInit';
import {refreshCanvasStart} from './utils/processes/refreshCanvasLoop';
import {LoadingCover} from '../../singleComponents/LoadingCover/LoadingCover';
import {authProcess$} from '../TestPage/useAuthProcess';
import {useRoomWsListeners} from './utils/listeners/useRoomWsListeners';
import {initPcReceiver, initPcSender} from './utils/listeners/initPc';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {SubscriptionManager} from './utils/SubscriptionManager';
import loadable from '@loadable/component';
import {RoomStreamNotEvent, roomStreamsActions} from '../../store/slices/roomStreams';
import {chatsActions} from '../../store/slices/chats';
import {Layout} from '../../services/gRPC/mcu/models_pb';
import {LayoutNames} from './utils/listeners/initPcSenderChannelListeners';

export const closeRoomComponent = new Subject<typeof emit>();
export const loadRoomComponent = new Subject<typeof emit>();

const PerformerRoomLazy = loadable(async () => {
	const {PerformerRoom} = await import('./PerformerRoom');
	return PerformerRoom;
});

const UserRoomLazy = loadable(async () => {
	const {UserRoom} = await import('./UserRoom');
	return UserRoom;
});

export const PreRoomPage: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const roomPid = useParams<{ roomPid?: string }>().roomPid;
	const invitePid = useParams<{ invitePid?: string }>().invitePid;
	const [displayRoom, setDisplayRoom] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isPerformerRoom = useAppSelector(selectIsPerformerRoom);
	const [searchParams] = useSearchParams();

	const {
		bothTypeRoomWsListeners$$,
		onlyPerformerRoomWsListeners$$,
		onlyWatchRoomWsListeners$$
	} = useRoomWsListeners();

	useEffect(() => {
		updateRoomMode(searchParams.get('mode'));
		updateVideoMaxBitrate(searchParams.get('maxVideoBitrate'));
		updateScreenMaxBitrate(searchParams.get('maxScreenBitrate'));

		const sub1 = closeRoomComponent.subscribe(() => {
			setDisplayRoom(false);
		});
		const sub2 = loadRoomComponent.pipe(concatMap(() => loadRoom())).subscribe();

		const loadRoomProcess = loadRoom().subscribe();
		//on first render loadRoom
		return () => {
			sub1.unsubscribe();
			sub2.unsubscribe();
			loadRoomProcess.unsubscribe();
		};
	}, []);

	useEffect(() => {
		return () => {
			cancelModalExceptWithType(ModalType.ROOM_CREATE);
		};
	}, []);

	const loadRoom = useCallback(() => {

		const clientId = selectClientId(store.getState());
		const clientUserInfo = selectClientUserInfo(store.getState());
		const clientRole = selectClientRole(store.getState());
		let subscriptionUserToken: Subscription;
		let room: RoomInfo.AsObject;
		let token: string;
		let password: string;

		return EMIT.pipe(
			// get token based on roomPid or invitePid.
			mergeMap(() => {
				// check if we trying to get to room by roomPid or by invitePid.
				if (roomPid) {
					return EMIT.pipe(
						mergeMap(() => room ? of(room) : getRoomInfoService$({roomPid: roomPid})),
						catchError((err) => {
							if (err.type === ErrorType.CANNOT_BE_FOUND) {
								modalsSystem(ModalType.INFO, {
									title: 'Casa Not Found',
									text: `The casa does not exist.
								         Please click button below to go to dashboard.`,
									preventCoverClick: true
								}).pipe(
									tap(() => navigate('/'))
								).subscribe();
							}
							return EMPTY;
						}),
						mergeMap((roomInfo) => {
							if (clientRole === UserRole.GUEST && roomInfo.type === RoomType.RTYPE_BROADCAST) {
								return modalsSystem(ModalType.INFO, {
									title: 'Account Required',
									text: `You have to be logged in to access this casa.`,
									preventCoverClick: true,
									btnText: 'LOG IN'
								}).pipe(
									mergeMap(() => authProcess$({preventCoverClick: true})),
									mergeMap(() => loadRoom()),
									mergeMap(() => EMPTY)
								);
							} else if (roomInfo.access === RoomAccess.RACCESS_PROTECTED && roomInfo.user === undefined && !invitePid && roomInfo.password) {
								return modalsSystem(ModalType.ROOM_PASSWORD).pipe(
									tap((data) => password = data.password),
									map(() => roomInfo)
								);
							} else {
								return of(roomInfo);
							}
						})
					);
				} else if (invitePid) {
					return getRoomInviteInfoService$({invitePid}).pipe(
						catchError((err) => {
							console.error(err);
							modalsSystem(ModalType.INFO, {
								title: 'Invitation Is Invalid',
								text: `The invite doesn't exist or has expired.
								       Please click button below to go to dashboard.`,
								preventCoverClick: true
							}).pipe(
								tap(() => navigate('/'))
							).subscribe();
							return EMPTY;
						}),
						concatMap(invite => room ? of(room) : getRoomInfoService$({roomId: invite.roomId}))
					);
				}
				return throwError(() => new Error('Neither roomPid nor invitePid is set.'));
			}),
			tap(() => setIsLoading(true)),
			// save room info
			tap((roomInfo) => {
				room = roomInfo as RoomInfo.AsObject;
			}),
			// Tokenize
			mergeMap(() => {
				const args = roomPid && password ? {roomPid, password} : invitePid ? {invitePid} : {roomPid: roomPid!};

				const createRoomUserTokenCatchError = catchError((err: GrpcError.AsObject) => {
					setIsLoading(false);
					if (err.type === ErrorType.UNAVAILABLE) {
						modalsSystem(ModalType.INFO, {
							title: 'Casa Full',
							text: `The room is full. Try later.
								         Please click button below to go to dashboard.`,
							preventCoverClick: true
						}).pipe(
							tap(() => navigate('/'))
						).subscribe();
					} else if (err.type === ErrorType.FORBIDDEN) {
						if (invitePid) {
							modalsSystem(ModalType.INFO, {
								title: 'Invitation Is Invalid',
								text: `The invite cannot be accepted.
								       Please click button below to go to dashboard.`,
								preventCoverClick: true
							}).pipe(
								tap(() => navigate('/'))
							).subscribe();
						} else if (err.message.includes('invitation required')) {
							modalsSystem(ModalType.INFO, {
								title: 'Casa Private',
								text: `You need an invitation to enter this casa.
								         Please click button below to go to dashboard.`,
								preventCoverClick: true
							}).pipe(
								tap(() => navigate('/'))
							).subscribe();
						} else {
							modalsSystem(ModalType.INFO, {
								title: 'Invalid Password',
								text: `You provided an invalid password.`,
								preventCoverClick: true
							}).pipe(
								tap(() => loadRoom().subscribe())
							).subscribe();
						}
					} else {
						console.error(err);
					}
					return EMPTY;
				});

				return createRoomUserTokenService$(args).pipe(
					// save token
					tap(receivedToken => token = receivedToken),
					// check if tokenizer returned error
					createRoomUserTokenCatchError,
					// when user stays at select devices modal, refresh token.
					tap(() => {
						if (!subscriptionUserToken) {
							subscriptionUserToken = interval(280 * 1000).subscribe(() => {
								createRoomUserTokenService$(args).pipe(
									tap(receivedToken => token = receivedToken)
								).subscribe(receivedToken => token = receivedToken);
							});
						}
					})
				);
			}),
			// show select devices modal
			tap(() => setIsLoading(false)),
			mergeMap(() => modalsSystem(ModalType.ROOM_SELECT_DEVICES)),
			tap(() => setIsLoading(true)),
			// start listening to WS staff & create RTC connections
			tap(() => {
				if (room.pid) {
					roomGlobalRef.currentRoomPid = room.pid;
				}
				roomGlobalRef.isUserLeavingRoom = false;

				if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
					initPcReceiver(room.type);
					// activate subscription manager (cannot do it in roomGlobalRef due to circular dependency)
					roomGlobalRef.subscriptionManager = new SubscriptionManager();
				}
				initPcSender(clientId);


				// WS subscriptions
				roomGlobalRef.flagsQueue?.close();
				roomGlobalRef.statusesQueue?.close();
				roomGlobalRef.roomUsersStatusesQueue.close();
				roomGlobalRef.roomSubscriptions.add(bothTypeRoomWsListeners$$().subscribe());
				if (room.type === RoomType.RTYPE_BROADCAST) {
					roomGlobalRef.roomSubscriptions.add(onlyPerformerRoomWsListeners$$().subscribe());
				} else {
					roomGlobalRef.roomSubscriptions.add(onlyWatchRoomWsListeners$$().subscribe());
				}
			}),
			// join room using token
			mergeMap(() => userJoinRoomSenderWS$(token)),
			catchError(err => {
				if (err.message === 'failurePacket') {
					return modalsSystem(ModalType.INFO, {
						title: 'Warning',
						text: `You have a session running in other tab. If you proceed your previous session will be terminated`,
						preventCoverClick: true,
						cancelButton: true,
						cancelButtonAction: () => {
							navigate('/');
						},
						btnText: 'JOIN'
					}).pipe(
						mergeMap(() => userJoinRoomSenderWS$(token, true).pipe(
							catchError(err => {
								console.error(err);
								const data = {
									title: 'Connection Error',
									text: `Error during joining casa.\n${err}\n\nPlease try again and refresh the page.`,
									preventCoverClick: true,
									hideButton: true
								};
								modalsSystem(ModalType.INFO, data).subscribe();
								return EMPTY;
							})
						))
					);
				}
				return throwError(err);
			}),
			// at this point the interval with token refreshing is no longer needed
			tap(() => subscriptionUserToken?.unsubscribe())
		).pipe(
			// get roomState
			map(() => Date.now()),
			mergeMap((date) => getRoomStateService$().pipe(
				// get extra data about user based on roomState
				mergeMap((roomState) => {
					const list = roomState.usersList.filter(user => user.userId !== clientId).map(el => el.userId);
					if (list.length) {
						return listUsersService$(list).pipe(
							map((userInfoList) => ({
								roomState: roomState,
								userInfoList: [...userInfoList, clientUserInfo],
								date
							}))
						);
					} else {
						return of({roomState: roomState, userInfoList: [clientUserInfo], date});
					}
				}),
				tap(({roomState, userInfoList, date}) => {
					// fill store with data about playlist
					const streamsList = roomState.streamsList;
					console.log(roomState.streamsList);
					const zucasaQueuedEventsSids = streamsList.filter(i => i.type === RoomStreamType.RSTYPE_EVENT).map(i => i.metadata!.sid);

					if (zucasaQueuedEventsSids.length) {
						forkJoin([listEventsService$({idsList: zucasaQueuedEventsSids})]).subscribe(
							(results) => {
								const streamsWithInfo = streamsList.map(s => {
									switch (s.type) {
										case RoomStreamType.RSTYPE_EVENT:
											return {...s, info: results[0].eventsList.find((e: EventInfo.AsObject) => e.id === s.metadata!.sid)!};
										default:
											return s as RoomStreamNotEvent;
									}
								});
								dispatch(roomStreamsActions.setRoomStreams(streamsWithInfo));
							}
						);
					} else {
						dispatch(roomStreamsActions.setRoomStreams(streamsList as RoomStreamNotEvent[]));
					}
					// fill stores with all other data
					dispatch(roomUsersActions.upsertUsersBasedOnResponses({roomState, userInfoList}));
					roomGlobalRef.flagsQueue?.open(date);
					roomGlobalRef.statusesQueue?.open(date);
					roomGlobalRef.roomUsersStatusesQueue.open(date);
					if (roomState.info?.type === RoomType.RTYPE_BROADCAST && roomState.info.user?.role === RoomUserRole.HOST) {
						dispatch(roomsActions.setBroadcastRoom({...roomState.info!, event: roomState.event?.info}));
					} else {
						dispatch(roomsActions.addRoom({...roomState.info!, event: roomState.event?.info}));
					}
					if (roomState.info?.type === RoomType.RTYPE_BROADCAST && roomState.event?.performersList.length) {
						store.dispatch(roomActions.setPerformer(roomState.event.performersList[0]));
					}
					dispatch(roomActions.setInfo(roomState.info!));
					dispatch(roomStreamsActions.setEvent(roomState.event!));
					dispatch(roomActions.setChat(roomState.chat!));
					const roomChatData = roomState.chat!;
					const eventChatData = roomState.event?.chat;
					dispatch(chatsActions.setChat({
						id: roomChatData.id,
						type: 'ROOM',
						status: roomChatData.status,
						createdAt: roomChatData.createdAt,
						updatedAt: roomChatData.updatedAt
					}));
					if (eventChatData) {
						dispatch(chatsActions.setChat({
							id: eventChatData.id,
							type: 'EVENT',
							status: eventChatData.status,
							createdAt: eventChatData.createdAt,
							updatedAt: eventChatData.updatedAt
						}));
					}
				})
			)),
			// if used invitation - change link
			tap(() => {
				if (invitePid) {
					const roomPid = selectRoomPid(store.getState())!;
					invitePid && replaceUrl('c/' + roomPid);
				}
			}),
			mergeMap(() => {
				// part 2 of room loading below (pc listeners, channels listeners, first offer, first flags sending, event starting)
				return roomInit$({roomId: selectRoomId(store.getState())!});
			})
		).pipe(
			catchError(err => {
				console.error(err);
				const data = {
					title: 'Connection Error',
					text: `Error during joining casa.\n${err}\n\nPlease try again and refresh the page.`,
					preventCoverClick: true,
					hideButton: true
				};
				modalsSystem(ModalType.INFO, data).subscribe();
				return EMPTY;
			}),
			tap(() => {
				if (roomGlobalRef.currentMode !== RoomMode.NO_RECEIVER) {
					roomGlobalRef.subscriptionManager!.open();
				}
				setDisplayRoom(true);
				refreshCanvasStart();
			})
		);

	}, []) as () => Observable<[{ layout: Omit<Layout.AsObject, 'name'> & { name: LayoutNames } }, undefined, undefined] | undefined>;

	useEffect(() => {
		return () => {
			clearRoomGlobalRef();
			dispatch(roomActions.clearSlice());
			dispatch(roomUsersActions.clearSlice());
		};
	}, []);

	return (
		<>
			{!displayRoom ?
				isLoading ?
					<LoadingCover withSpinner={true}/>
					:
					<LoadingCover/>
				:
				isPerformerRoom ?
					<PerformerRoomLazy/>
					:
					<UserRoomLazy/>
			}
		</>
	);
};
