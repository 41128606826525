import styled from 'styled-components';

export const McuSupplierS = styled.div`

	canvas#mcuCanvas {
		width: 0;
		height: 0;
		pointer-events: none;
		opacity: 0;
		position: absolute;
	}

	video#mcuVideo {
		width: 0;
		height: 0;
		pointer-events: none;
		opacity: 0;
		position: absolute;
	}
	
`;
