import React, {FC, useEffect, useState} from 'react';
import {Navigate, useLocation, useRoutes} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {createEventProcess$, createEventProcessData, CreateEventProcessData} from '../modals/Event/Create/createEventProcess';
import update from 'immutability-helper';
import {UserRole} from '../../services/roomServices';
import {useAppSelector} from '../../store/hooks';
import {selectClientRole} from '../../store/slices/user';
import {authProcess$} from '../../pages/TestPage/useAuthProcess';
import {store} from '../../store/store';
import {performerActions} from '../../store/slices/performer';
import {purchaseHistoryActions} from '../../store/slices/purchaseHistory';
import loadable from '@loadable/component';
import {DashboardPage} from '../../pages/Dashboard/DashboardPage';
import {appLoader$, appLoaderFinished$} from '../../utils/customHooks/useAppLoader';
import {LoadingCover} from '../LoadingCover/LoadingCover';

const ErrorPageLazy = loadable(async () => {
	const {ErrorPage} = await import('../../pages/Error/ErrorPage');
	return ErrorPage;
});

const ResetPasswordLazy = loadable(async () => {
	const {ResetPassword} = await import('../../pages/ResetPassword');
	return ResetPassword;
});

const MaintenancePageLazy = loadable(async () => {
	const {MaintenancePage} = await import('../../pages/Maintenance/MaintenancePage');
	return MaintenancePage;
});

const ManifestPageLazy = loadable(async () => {
	const {ManifestPage} = await import('../../pages/Manifest/ManifestPage');
	return ManifestPage;
});


export const AppRouter = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const pathname = location.pathname;
		if (pathname === '/') {
			appLoader$().subscribe({
				error: (err) => {
					console.error(err);
					navigate('/error', {
						replace: true,
						state: {errorMsg: 'Error with server connection. Please try again and refresh the page.'}
					});
				}
			});
		}
	}, []);

	return useRoutes([
		{path: '/error', element: <ErrorPageLazy/>},
		{path: '/reset-password', element: <ResetPasswordLazy/>},
		{path: '/maintenance', element: <MaintenancePageLazy/>},
		{path: '/manifest', element: <ManifestPageLazy/>},
		{path: '*', element: <LoadedDataGuard/>},
		{path: '/', element: <DashboardNewLazy/>}
	]);
};

const LoadedDataGuard = () => {
	const navigate = useNavigate();
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		appLoaderFinished$.subscribe(() => {
			setLoaded(true);
		});
		appLoader$().subscribe({
			error: (err) => {
				console.error(err);
				navigate('/error', {
					replace: true,
					state: {errorMsg: 'Error with server connection. Please try again and refresh the page.'}
				});
			}
		});
	}, []);

	return <>{loaded ?
		<AppLoadedRouter/>
		:
		<div className="d-flex flex-column justify-content-center align-items-center vh-100">
			<LoadingCover withSpinner={true}/>
		</div>
	}</>;

};

const NotFoundPageLazy = loadable(async () => {
	const {NotFoundPage} = await import('../../pages/NotFound/NotFoundPage');
	return NotFoundPage;
});

const BrowseCasasPageLazy = loadable(async () => {
	const {BrowseCasasPage} = await import('../../pages/BrowseCasas/BrowseCasas');
	return BrowseCasasPage;
});

const DashboardNewLazy = loadable(async () => {
	const {DashboardPage} = await import('../../pages/Dashboard/DashboardPage');
	return DashboardPage;
});

const PurchaseHistoryPageLazy = loadable(async () => {
	const {PurchaseHistoryPage} = await import('../../pages/PurchaseHistory/PurchaseHistory');
	return PurchaseHistoryPage;
});

const PerformerPanelPageLazy = loadable(async () => {
	const {PerformerPanelPage} = await import('../../pages/PerformerPanel/PerformerPanel');
	return PerformerPanelPage;
});

const PerformerPanelEventPreviewPageLazy = loadable(async () => {
	const {PerformerPanelEventPreviewPage} = await import('../../pages/PerformerPanel/PerformerPanelEventPreview');
	return PerformerPanelEventPreviewPage;
});

const PerformerPanelEventVouchersPageLazy = loadable(async () => {
	const {PerformerPanelEventVouchersPage} = await import('../../pages/PerformerPanel/PerformerPanelEventVouchers');
	return PerformerPanelEventVouchersPage;
});

const DashboardSingleEventPageLazy = loadable(async () => {
	const {DashboardSingleEventPage} = await import('../../pages/Event/DashboardSingleEventPage');
	return DashboardSingleEventPage;
});

const PreRoomPageLazy = loadable(async () => {
	const {PreRoomPage} = await import('../../pages/Room/PreRoomPage');
	return PreRoomPage;
});

const AppLoadedRouter: FC = () => {

	const clientRole = useAppSelector(selectClientRole);
	const location = useLocation();
	const navigate = useNavigate();

	const authIfNecessary = () => {
		if (clientRole === UserRole.GUEST) {
			navigate('/');
			authProcess$().subscribe(() => navigate(-1));
		}
	};

	useEffect(() => {
		const pathname = location.pathname;

		/// reset performer related store when user leaves performer panel
		if (!pathname.includes('performer')) {
			store.dispatch(performerActions.reset());
		} else {
			authIfNecessary();
		}
		/// reset purchase history related store when user leaves purchase-history
		if (!pathname.includes('purchase')) {
			store.dispatch(purchaseHistoryActions.reset());
		} else {
			authIfNecessary();
		}

		switch (pathname) {
			case '/browse-casas': {
				authIfNecessary();
				break;
			}
			case '/auth': {
				authProcess$().subscribe();
				break;
			}
			case '/signup': {
				clientRole > UserRole.GUEST && navigate('/');
				authProcess$().subscribe();
				break;
			}
			case '/login': {
				clientRole > UserRole.GUEST && navigate('/');
				authProcess$().subscribe();
				break;
			}
			case '/partner-created': {
				const createEventProcessDataLS = localStorage.getItem('createEventProcessData');
				const createEventProcessDataParsed = createEventProcessDataLS ?
					(JSON.parse(createEventProcessDataLS).createEventProcessData as any) :
					undefined;

				const eventName = createEventProcessDataParsed.createEventDataModal.eventName as string | undefined;
				const startDate = createEventProcessDataParsed.createEventDataModal.startDate as string | undefined;
				const startTime = createEventProcessDataParsed.createEventDataModal.startTime as string | undefined;
				const duration = createEventProcessDataParsed.createEventDataModal.duration as number | undefined;
				const createEventProcessDataFormatted = update(createEventProcessDataParsed, {
					createEventDataModal: {
						eventName: {$set: eventName},
						startDate: {$set: startDate ? new Date(startDate) : undefined},
						startTime: {$set: startTime ? new Date(startTime) : undefined},
						duration: {$set: duration}
					}
				}) as CreateEventProcessData;

				if (createEventProcessDataFormatted) {
					createEventProcessData.createEventDataModal = createEventProcessDataFormatted.createEventDataModal;
					createEventProcessData.createEventDetailsModal = createEventProcessDataFormatted.createEventDetailsModal;
					localStorage.removeItem('createEventProcessData');

					navigate('/');
					createEventProcess$().subscribe();
				}
				break;
			}
		}
	}, [location]);

	return useRoutes([
		{path: '/not-found', element: <NotFoundPageLazy/>},
		{path: '/browse-casas', element: <BrowseCasasPageLazy/>},
		{path: '/purchase-history', element: <PurchaseHistoryPageLazy/>},
		{path: '/performer-panel', element: <PerformerPanelPageLazy/>},
		{path: '/performer-panel/e/:eventPid', element: <PerformerPanelEventPreviewPageLazy/>},
		{path: '/performer-panel/e/:eventPid/vouchers', element: <PerformerPanelEventVouchersPageLazy/>},
		{path: '/e/:eventPid', element: <DashboardSingleEventPageLazy/>},
		{path: '/i/:invitePid', element: <PreRoomPageLazy/>},
		{path: '/room/:roomPid', element: <PreRoomPageLazy/>},
		{path: '/c/:roomPid', element: <PreRoomPageLazy/>},
		{path: '/', element: <DashboardNewLazy/>},
		{path: '*', element: <Navigate to="/not-found"/>}
	]);
};
