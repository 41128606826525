import {
	DeleteChatMessageRequest as DeleteUserChatMessageRequest,
	DeleteChatMessageResponse as DeleteUserChatMessageResponse,
	ListChatMessagesRequest as ListUserChatMessagesRequest,
	ListChatMessagesResponse as ListUserChatMessagesResponse,
	UpdateChatMessageRequest as UpdateUserChatMessageRequest,
	UpdateChatMessageResponse as UpdateUserChatMessageResponse
} from '../gRPC/users/services.public_pb';
import {
	DeleteChatMessageRequest as DeleteRoomChatMessageRequest,
	DeleteChatMessageResponse as DeleteRoomChatMessageResponse,
	ListChatMessagesRequest as ListRoomChatMessagesRequest,
	ListChatMessagesResponse as ListRoomChatMessagesResponse,
	UpdateChatMessageRequest as UpdateRoomChatMessageRequest,
	UpdateChatMessageResponse as UpdateRoomChatMessageResponse
} from '../gRPC/rooms/services.public_pb';
import {from, Observable, of, throwError} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {PublicServiceClient as UserService} from '../gRPC/users/Services.publicServiceClientPb';
import {PublicServiceClient as RoomService} from '../gRPC/rooms/Services.publicServiceClientPb';
import {getCurrentTokenResultsFirebase$} from '../firebaseAuth';
import {envVars} from '../../environments/env';
import {ChatMessageContentValue as ChatMessageContentValueUsers} from '../gRPC/users/models_pb';
import {ChatMessageContentValue as ChatMessageContentValueRooms} from '../gRPC/rooms/models_pb';
import {interceptError} from '../../utils/errorInterceptor';
import {IdTokenResult} from 'firebase/auth';

const serviceUser = new UserService(envVars.apiUsersUrl);
const serviceRoom = new RoomService(envVars.apiRoomsUrl);
const supportedUsersVersion = envVars.supportedUsersVersion;
const supportedRoomsVersion = envVars.supportedRoomsVersion;

const prepareHeaders = (token: IdTokenResult, service?: 'rooms' | 'users') => {
	return {
		'Authorization': 'Bearer ' + token.token,
		'Supported-Service-Version': service === 'rooms' ? supportedRoomsVersion : supportedUsersVersion
	};
};

///
/// Private
///

export function listUserChatMessages$(chatId: string, offset?: number, limit?: number): Observable<ListUserChatMessagesResponse.AsObject> {

	const request = new ListUserChatMessagesRequest();
	request.setChatId(chatId);
	offset && request.setOffset(offset);
	limit && request.setLimit(limit);
	request.setOrder('DESC');

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token, 'users')),
		mergeMap(header => from(serviceUser.listChatMessages(request, header))),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);

}

export function deleteUserChatMessage(messageId: string): Observable<DeleteUserChatMessageResponse.AsObject> {

	const request = new DeleteUserChatMessageRequest();
	request.setId(messageId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token, 'users')),
		mergeMap(header => from(serviceUser.deleteChatMessage(request, header))),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);

}

export function updateUserChatMessage$(messageId: string, updateContent: string): Observable<UpdateUserChatMessageResponse.AsObject> {

	const request = new UpdateUserChatMessageRequest();
	request.setId(messageId);
	request.setContent(new ChatMessageContentValueUsers().setValue(updateContent));

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token, 'users')),
		mergeMap(header => from(serviceUser.updateChatMessage(request, header))),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);

}

///
/// Room
///

export function listRoomChatMessages$(chatId: string, offset?: number, limit?: number): Observable<ListRoomChatMessagesResponse.AsObject> {

	const request = new ListRoomChatMessagesRequest();
	request.setChatId(chatId);
	request.setOrder('DESC');
	offset && request.setOffset(offset);
	limit && request.setLimit(limit);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token, 'rooms')),
		mergeMap(header => from(serviceRoom.listChatMessages(request, header))),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);

}


export function deleteRoomChatMessage$(messageId: string): Observable<DeleteRoomChatMessageResponse.AsObject> {

	const request = new DeleteRoomChatMessageRequest();
	request.setId(messageId);

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token, 'rooms')),
		mergeMap(header => from(serviceRoom.deleteChatMessage(request, header))),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);

}

export function updateRoomChatMessage$(messageId: string, updateContent: string): Observable<UpdateRoomChatMessageResponse.AsObject> {

	const request = new UpdateRoomChatMessageRequest();
	request.setId(messageId);
	request.setContent(new ChatMessageContentValueRooms().setValue(updateContent));

	return getCurrentTokenResultsFirebase$().pipe(
		map(token => prepareHeaders(token, 'rooms')),
		mergeMap(header => from(serviceRoom.updateChatMessage(request, header))),
		map(a => a.toObject()),
		mergeMap((data) => data.error ? throwError(interceptError(data.error)) : of(data))
	);

}
