/* eslint-disable */
// source: babysfu/services.public.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var error_models_pb = require('../error/models_pb.js');
goog.object.extend(proto, error_models_pb);
goog.exportSymbol('proto.babysfu.api.v1.CreateConnectionRequest', null, global);
goog.exportSymbol('proto.babysfu.api.v1.CreateConnectionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.babysfu.api.v1.CreateConnectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.babysfu.api.v1.CreateConnectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.babysfu.api.v1.CreateConnectionRequest.displayName = 'proto.babysfu.api.v1.CreateConnectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.babysfu.api.v1.CreateConnectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.babysfu.api.v1.CreateConnectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.babysfu.api.v1.CreateConnectionResponse.displayName = 'proto.babysfu.api.v1.CreateConnectionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.babysfu.api.v1.CreateConnectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.babysfu.api.v1.CreateConnectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.babysfu.api.v1.CreateConnectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.babysfu.api.v1.CreateConnectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sdpOffer: msg.getSdpOffer_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.babysfu.api.v1.CreateConnectionRequest}
 */
proto.babysfu.api.v1.CreateConnectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.babysfu.api.v1.CreateConnectionRequest;
  return proto.babysfu.api.v1.CreateConnectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.babysfu.api.v1.CreateConnectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.babysfu.api.v1.CreateConnectionRequest}
 */
proto.babysfu.api.v1.CreateConnectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSdpOffer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.babysfu.api.v1.CreateConnectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.babysfu.api.v1.CreateConnectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.babysfu.api.v1.CreateConnectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.babysfu.api.v1.CreateConnectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSdpOffer_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.babysfu.api.v1.CreateConnectionRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.babysfu.api.v1.CreateConnectionRequest} returns this
 */
proto.babysfu.api.v1.CreateConnectionRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes sdp_offer = 2;
 * @return {string}
 */
proto.babysfu.api.v1.CreateConnectionRequest.prototype.getSdpOffer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes sdp_offer = 2;
 * This is a type-conversion wrapper around `getSdpOffer()`
 * @return {string}
 */
proto.babysfu.api.v1.CreateConnectionRequest.prototype.getSdpOffer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSdpOffer()));
};


/**
 * optional bytes sdp_offer = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSdpOffer()`
 * @return {!Uint8Array}
 */
proto.babysfu.api.v1.CreateConnectionRequest.prototype.getSdpOffer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSdpOffer()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.babysfu.api.v1.CreateConnectionRequest} returns this
 */
proto.babysfu.api.v1.CreateConnectionRequest.prototype.setSdpOffer = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.babysfu.api.v1.CreateConnectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.babysfu.api.v1.CreateConnectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.babysfu.api.v1.CreateConnectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.babysfu.api.v1.CreateConnectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sdpAnswer: msg.getSdpAnswer_asB64(),
    error: (f = msg.getError()) && error_models_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.babysfu.api.v1.CreateConnectionResponse}
 */
proto.babysfu.api.v1.CreateConnectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.babysfu.api.v1.CreateConnectionResponse;
  return proto.babysfu.api.v1.CreateConnectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.babysfu.api.v1.CreateConnectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.babysfu.api.v1.CreateConnectionResponse}
 */
proto.babysfu.api.v1.CreateConnectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSdpAnswer(value);
      break;
    case 2:
      var value = new error_models_pb.Error;
      reader.readMessage(value,error_models_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.babysfu.api.v1.CreateConnectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.babysfu.api.v1.CreateConnectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.babysfu.api.v1.CreateConnectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.babysfu.api.v1.CreateConnectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSdpAnswer_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      error_models_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes sdp_answer = 1;
 * @return {string}
 */
proto.babysfu.api.v1.CreateConnectionResponse.prototype.getSdpAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes sdp_answer = 1;
 * This is a type-conversion wrapper around `getSdpAnswer()`
 * @return {string}
 */
proto.babysfu.api.v1.CreateConnectionResponse.prototype.getSdpAnswer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSdpAnswer()));
};


/**
 * optional bytes sdp_answer = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSdpAnswer()`
 * @return {!Uint8Array}
 */
proto.babysfu.api.v1.CreateConnectionResponse.prototype.getSdpAnswer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSdpAnswer()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.babysfu.api.v1.CreateConnectionResponse} returns this
 */
proto.babysfu.api.v1.CreateConnectionResponse.prototype.setSdpAnswer = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional error.api.v1.Error error = 2;
 * @return {?proto.error.api.v1.Error}
 */
proto.babysfu.api.v1.CreateConnectionResponse.prototype.getError = function() {
  return /** @type{?proto.error.api.v1.Error} */ (
    jspb.Message.getWrapperField(this, error_models_pb.Error, 2));
};


/**
 * @param {?proto.error.api.v1.Error|undefined} value
 * @return {!proto.babysfu.api.v1.CreateConnectionResponse} returns this
*/
proto.babysfu.api.v1.CreateConnectionResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.babysfu.api.v1.CreateConnectionResponse} returns this
 */
proto.babysfu.api.v1.CreateConnectionResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.babysfu.api.v1.CreateConnectionResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.babysfu.api.v1);
