import {roomGlobalRef} from '../roomGlobalRef';
import {SfuMessageType} from '../../../../services/sfu/incomingMessagesTypes.sfu';
import {ClientType} from '../../../../services/gRPC/sfu/enums_pb';
import pako from 'pako';
import {merge} from 'rxjs';
import {store} from '../../../../store/store';
import {roomUsersActions, selectScreenSharingUserId} from '../../../../store/slices/roomUsers';
import {tap} from 'rxjs/operators';
import {sfuReceiverChannelOutput$$} from './pcReceiverOfferAndChannelReady';
import {batchActions} from 'redux-batched-actions';
import {roomActions, selectMcuVideoStreamGrid} from '../../../../store/slices/room';
import {disconnectSFU, mcuMuteToggleSenderSFU} from '../../../../services/sfu/senders.sfu';
import {retrieveClientType} from '../../../../utils/browserTypeDetection';
import {reconnectPcReceiver} from './reconnectPcReceiver';
import {pixelWatch$$} from '../../relatedComponents/McuSupplier/McuSupplier';
import {selectRoomStreams} from '../../../../store/slices/roomStreams';

export const initPcReceiverChannelListeners = () => {
	return merge(
		sfuReceiverChannelOutput$$(SfuMessageType.LAYOUT).pipe(
			tap((packet) => {
				const layout = packet.layout!;
				switch (layout.name) {
					case 'sfu': {
						pixelWatch$$.next(false);
						store.dispatch(batchActions([
							roomActions.setMcuVideoStreamEnabled(false),
							roomActions.setMcuVideoStreamGrid(undefined),
							roomUsersActions.setUsersPositions(undefined)
						], 'LAYOUT sfu'));
						roomGlobalRef.videoStreamMuteManager.unmuteAllStreams();
						mcuMuteToggleSenderSFU(true, roomGlobalRef.mediaReceiverServerChannel);
						break;
					}
					case 'grid_2x2':
					case 'grid_4x2':
					case 'grid_6x3':
					case 'grid_8x4':
					case 'grid_10x5': {
						// ignore for safari - don't use mcu grid mode
						if (retrieveClientType() === ClientType.SAFARI) {
							return;
						}
						const newGridName = layout.name;
						const currentGridName = selectMcuVideoStreamGrid(store.getState())?.name;
						if (newGridName !== currentGridName) {
							pixelWatch$$.next(true);
						}
						store.dispatch(batchActions([
							roomActions.setMcuVideoStreamEnabled(true),
							roomUsersActions.setUsersPositions(layout.configList)
						], 'LAYOUT mcu grid'));
						if (!(selectScreenSharingUserId(store.getState()) || selectRoomStreams(store.getState()).length)) {
							roomGlobalRef.videoStreamMuteManager.muteAllStreams();
							mcuMuteToggleSenderSFU(false, roomGlobalRef.mediaReceiverServerChannel);
						}
						break;
					}
					case 'broadcast_one_16:9': {
						pixelWatch$$.next(false);
						store.dispatch(batchActions([
							roomActions.setPerformerMediaStream({type: 'camera', value: true}),
							roomActions.setMcuVideoStreamEnabled(false),
							roomActions.setMcuVideoStreamGrid(undefined),
							roomUsersActions.setUsersPositions(undefined)
						], 'LAYOUT broadcast'));
						roomGlobalRef.videoStreamMuteManager.unmuteAllStreams();
						mcuMuteToggleSenderSFU(false, roomGlobalRef.mediaReceiverServerChannel);
						break;
					}
				}
			})
		),

		sfuReceiverChannelOutput$$(SfuMessageType.STREAMS_ANSWER_PACKET).pipe(tap((data) => {
			console.log(`%c Subscription response (add, remove): `, 'color: #EF722F; font-weight: 900; background: black');
			console.log(data.addRequestsList);
			console.log(data.removalRequestsList);
			const uint8Array = Uint8Array.from(atob(data.sdpAnswer as string), c => c.charCodeAt(0));
			const offerSdpAnswer = JSON.parse(pako.inflate(uint8Array, {to: 'string'}));
			roomGlobalRef.pcMediaReceiver!.setRemoteDescription(offerSdpAnswer);
		})),

		sfuReceiverChannelOutput$$(SfuMessageType.SUBSCRIPTION_RTT).pipe(tap((data) => {
			store.dispatch(roomUsersActions.setUserPing({userId: data.subId, ping: data.rtt}));
		})),

		sfuReceiverChannelOutput$$(SfuMessageType.MIGRATION_REQUIRED).pipe(tap(() => {
			disconnectSFU(roomGlobalRef.mediaReceiverServerChannel);
			reconnectPcReceiver();
		})),

		sfuReceiverChannelOutput$$(SfuMessageType.DISCONNECTED).pipe(tap(() => {
			reconnectPcReceiver();
		}))
	);

};
