import {flagSenderWS$} from '../../../../services/ws/senders.ws';
import {RoomUserFlag} from '../../../../services/gRPC/rooms/enums_pb';
import {
	selectClientId,
	selectHasClientCamOff,
	selectIsClientMuted,
	selectIsSharingScreenAudio,
	selectIsSharingScreenVideo
} from '../../../../store/slices/user';
import {store} from '../../../../store/store';
import {roomUsersActions, selectClientFlags, selectUserFlags} from '../../../../store/slices/roomUsers';
import {startSharingScreen, stopSharingScreen} from './displayMedia';
import {roomActions, selectIsSfuScreenAudioInputReady, selectIsSfuScreenVideoInputReady} from '../../../../store/slices/room';
import {throwError} from 'rxjs';
import {Notification, showNotification} from '../../../../utils/showNotification';
import {filter, take} from 'rxjs/operators';
import {sfuSenderChannelOutput$$} from '../listeners/pcSenderOfferAndChannelReady';
import {SfuMessageType} from '../../../../services/sfu/incomingMessagesTypes.sfu';
import {StreamType} from '../../../../services/gRPC/sfu/enums_pb';

type updateUserFlagsArgs = {
	action: 'add' | 'remove';
	flags: RoomUserFlag[];
} | { action: 'clear' };

const mergeFlagArrays = (action: 'add' | 'remove', currentFlags: RoomUserFlag[], incomingFlags: RoomUserFlag[]) => {
	if (action === 'add') {
		return incomingFlags.reduce((acc: RoomUserFlag[], nextFlag) => {
			return acc.find((currentFlag) => currentFlag === nextFlag) ? [...acc] : [...acc, nextFlag];
		}, currentFlags);
	} else if (action === 'remove') {
		return currentFlags.filter(currentFlag => !incomingFlags.find(flag => currentFlag === flag));
	} else {
		return [];
	}
};

export const toggleClientMute = () => {
	if (selectIsClientMuted(store.getState())) {
		updateClientFlagsService$({action: 'remove', flags: [RoomUserFlag.RUFLAG_DISABLED_AUDIO]}).subscribe({
			error: err => console.error(`error while updating flags: ${err} 1`)
		});
	} else {
		updateClientFlagsService$({action: 'add', flags: [RoomUserFlag.RUFLAG_DISABLED_AUDIO]}).subscribe({
			error: err => console.error(`error while updating flags: ${err} 2`)
		});
	}
};

export const toggleClientCamOff = () => {
	if (selectHasClientCamOff(store.getState())) {
		updateClientFlagsService$({action: 'remove', flags: [RoomUserFlag.RUFLAG_DISABLED_VIDEO]}).subscribe({
			error: err => console.error(`error while updating flags: ${err} 3`)
		});
	} else {
		updateClientFlagsService$({action: 'add', flags: [RoomUserFlag.RUFLAG_DISABLED_VIDEO]}).subscribe({
			error: err => console.error(`error while updating flags: ${err} 4`)
		});
	}
};

export const toggleScreenShare = () => {

	if (selectIsSharingScreenVideo(store.getState())) {
		stopSharingScreen();
		updateClientFlagsService$({action: 'remove', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO]}).subscribe({
			error: err => console.error(`error while updating flags: ${err} 5 `)
		});

		if (selectIsSharingScreenAudio(store.getState())) {
			updateClientFlagsService$({action: 'remove', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO]}).subscribe({
				error: err => console.error(`error while updating flags: ${err} 6`)
			});
		}

	} else {

		const abortScreenShare = (err: Error) => {
			stopSharingScreen();
			showNotification(Notification.ERROR, 'We could not start your screen share because error occurred. Please try again.', 'abortScreenShare');
			console.error(`error while updating flags: ${err} 13`);
		};

		startSharingScreen()?.subscribe((stream) => {
			if (selectIsSfuScreenVideoInputReady(store.getState())) {
				updateClientFlagsService$({action: 'add', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO]}).subscribe({
					error: err => {
						abortScreenShare(err);
						updateClientFlagsService$({action: 'remove', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO]}).subscribe({
							error: err => console.error(`error while updating flags: ${err} 7`)
						});
					}
				});
			} else {
				sfuSenderChannelOutput$$(SfuMessageType.LOCAL_INPUT_READY).pipe(
					filter(packet => packet.streamtype === StreamType.SCREEN),
					take(1)
				).subscribe(() => {
					store.dispatch(roomActions.setIsSfuScreenVideoInputReady(true));
					updateClientFlagsService$({action: 'add', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO]}).subscribe({
						error: err => {
							abortScreenShare(err);
							updateClientFlagsService$({action: 'remove', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_VIDEO]}).subscribe({
								error: err => console.error(`error while updating flags: ${err} 8`)
							});
						}
					});
				});
			}

			if (stream.getAudioTracks().length && selectIsSfuScreenAudioInputReady(store.getState())) {
				updateClientFlagsService$({action: 'add', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO]}).subscribe({
					error: err => {
						abortScreenShare(err);
						updateClientFlagsService$({action: 'remove', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO]}).subscribe({
							error: err => console.error(`error while updating flags: ${err} 9`)
						});
					}
				});
			} else if (!selectIsSfuScreenAudioInputReady(store.getState())) {
				sfuSenderChannelOutput$$(SfuMessageType.LOCAL_INPUT_READY).pipe(
					filter(packet => packet.streamtype === StreamType.SCREEN_AUDIO),
					take(1)
				).subscribe(() => {
					store.dispatch(roomActions.setIsSfuScreenAudioInputReady(true));
					updateClientFlagsService$({action: 'add', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO]}).subscribe({
						error: err => {
							abortScreenShare(err);
							updateClientFlagsService$({action: 'remove', flags: [RoomUserFlag.RUFLAG_SHARING_SCREEN_AUDIO]}).subscribe({
								error: err => console.error(`error while updating flags: ${err} 10`)
							});
						}
					});
				});
			}
		});
	}
};

export const updateClientFlagsService$ = (args: updateUserFlagsArgs) => {
	const timestamp = Date.now();
	const clientId = selectClientId(store.getState());
	const action = args.action;
	if (action === 'clear') {
		store.dispatch(roomUsersActions.clearUserFlags({userId: clientId}));
		return flagSenderWS$([], timestamp);
	}
	const incomingFlags = args.flags;
	const roomUserFlags = selectUserFlags(clientId)(store.getState());
	const currentFlags: RoomUserFlag[] = roomUserFlags ? [...Object.keys(roomUserFlags).map(flag => Number(flag))] : [];
	const updatedFlags = mergeFlagArrays(action, currentFlags, incomingFlags);
	const isListDiff = currentFlags.length !== updatedFlags.length; // it may be buggy in case of different flags but same length
	if (isListDiff) {
		if (action === 'add') {
			store.dispatch(roomUsersActions.addUserFlags({userId: clientId, flags: incomingFlags, timestamp}));
			store.dispatch(roomUsersActions.applyUserFlags({userId: clientId, flags: incomingFlags, timestamp}));
		} else if (action === 'remove') {
			store.dispatch(roomUsersActions.removeUserFlags({userId: clientId, flags: incomingFlags}));
			store.dispatch(roomUsersActions.rejectUserFlags({userId: clientId, flags: incomingFlags}));
		}
		return flagSenderWS$([...updatedFlags], timestamp);
	} else {
		return throwError(() => new Error('no difference between flags 11'));
	}
};

// only used for rejoin when WS disconnected
export const resendCurrentClientUserFlags = () => {
	const timestamp = Date.now();
	const clientFlags = selectClientFlags(store.getState())!;
	const clientFlagsArr: RoomUserFlag[] = Object.keys(clientFlags).map(flag => Number(flag));
	clientFlags && flagSenderWS$(clientFlagsArr, timestamp).subscribe();
};
