import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {EventTicketVoucherInfo} from '../../services/gRPC/payments/models_pb';

const ItemsPerPage = 5;

export interface PerformerState {
	eventVouchers: EventTicketVoucherInfo.AsObject[]
	totalEventVouchers: number,
	currentEventVouchersPage: number,
}

export const initialState: PerformerState = {
	eventVouchers: [],
	totalEventVouchers: 0,
	currentEventVouchersPage: 0
};

const distinctVoucherObjects = (vouchers: EventTicketVoucherInfo.AsObject[]) => {
	return [...new Map(vouchers.map(item =>
		[item.id, item])).values()];
};

export const vouchersSlice = createSlice({
	name: 'vouchers',
	initialState,
	reducers: {
		addEventVoucher: (state, action: PayloadAction<EventTicketVoucherInfo.AsObject>) => {
			const updatedEventVouchers = distinctVoucherObjects([...state.eventVouchers, action.payload]);
			if (updatedEventVouchers.length > state.totalEventVouchers) {
				state.totalEventVouchers = updatedEventVouchers.length;
			}
			state.eventVouchers = updatedEventVouchers;
		},
		addEventVouchers: (state, action: PayloadAction<EventTicketVoucherInfo.AsObject[]>) => {
			const updatedEventVouchers = distinctVoucherObjects([...state.eventVouchers, ...action.payload]);
			if (updatedEventVouchers.length > state.totalEventVouchers) {
				state.totalEventVouchers = updatedEventVouchers.length;
			}
			state.eventVouchers = updatedEventVouchers;
		},
		updateEventVoucher: (state, action: PayloadAction<EventTicketVoucherInfo.AsObject>) => {
			state.eventVouchers = state.eventVouchers.filter((voucher) => voucher.id !== action.payload.id);
			state.eventVouchers.push(action.payload);
		},
		deleteEventVoucher: (state, action: PayloadAction<{ voucherId: string }>) => {
			state.eventVouchers = state.eventVouchers.filter((voucher) => voucher.id !== action.payload.voucherId);
			state.totalEventVouchers -= 1;

			/// if there is no items on current page, go back to previous one
			if (state.currentEventVouchersPage > 0 && Math.ceil(state.eventVouchers.length / ItemsPerPage) - 1 !== state.currentEventVouchersPage) {
				state.currentEventVouchersPage -= 1;
			}
		},
		setTotalEventVouchers: (state, action: PayloadAction<number>) => {
			state.totalEventVouchers = action.payload;
		},
		setCurrentEventVouchersPage: (state, action: PayloadAction<number>) => {
			state.currentEventVouchersPage = action.payload;
		},
		reset: (state) => {
			state.eventVouchers = [];
			state.totalEventVouchers = 0;
			state.currentEventVouchersPage = 0;
		}
	}
});

export const vouchersActions = vouchersSlice.actions;
export const selectEventVouchers = (state: RootState) => [...state.vouchers.eventVouchers]
	.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
export const selectTotalEventVouchers = (state: RootState) => state.vouchers.totalEventVouchers;
export const selectCurrentEventVouchersPage = (state: RootState) => state.vouchers.currentEventVouchersPage;

export const selectEventVoucherById = (voucherId: string) => {
	return createSelector(
		selectEventVouchers,
		vouchers => vouchers.find((voucher) => voucher.id === voucherId)
	);
};

export const selectEventVouchersByIndexes = (start: number, end: number) => {
	return createSelector(
		selectEventVouchers,
		vouchers => vouchers.slice(start, end)
	);
};

